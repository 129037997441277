/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: statistic.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./transaction";
import * as dependency_3 from "./models";
import * as pb_1 from "google-protobuf";
export class IntervalStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2]];
    constructor(data?: any[] | ({
        from?: number;
        to?: number;
        interval?: number;
    } & (({
        all?: boolean;
        blockchainIndex?: never;
    } | {
        all?: never;
        blockchainIndex?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("from" in data && data.from != undefined) {
                this.from = data.from;
            }
            if ("to" in data && data.to != undefined) {
                this.to = data.to;
            }
            if ("interval" in data && data.interval != undefined) {
                this.interval = data.interval;
            }
        }
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainIndex() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearBlockchainIndex(): void {
        this.blockchainIndex = undefined!;
    }
    get from() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set from(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get to() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set to(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get interval() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set interval(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "blockchainIndex";
        } = {
            0: "none",
            1: "all",
            2: "blockchainIndex"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2])]!;
    }
    static fromObject(data?: IntervalStatRequest.AsObjectPartial): IntervalStatRequest {
        if (!data) {
            return new IntervalStatRequest();
        }
        const message = new IntervalStatRequest({});
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.from != null) {
            message.from = data.from;
        }
        if (data.to != null) {
            message.to = data.to;
        }
        if (data.interval != null) {
            message.interval = data.interval;
        }
        return message;
    }
    toObject() {
        const data: IntervalStatRequest.AsObject = {
            all: this.all,
            blockchainIndex: this.blockchainIndex,
            from: this.from,
            to: this.to,
            interval: this.interval
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAll)
            writer.writeBool(1, this.all);
        if (this.hasBlockchainIndex)
            writer.writeInt64(2, this.blockchainIndex);
        if (this.from != 0)
            writer.writeInt64(3, this.from);
        if (this.to != 0)
            writer.writeInt64(4, this.to);
        if (this.interval != 0)
            writer.writeInt64(5, this.interval);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IntervalStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IntervalStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.all = reader.readBool();
                    break;
                case 2:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 3:
                    message.from = reader.readInt64();
                    break;
                case 4:
                    message.to = reader.readInt64();
                    break;
                case 5:
                    message.interval = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): IntervalStatRequest {
        return IntervalStatRequest.deserialize(bytes);
    }
}
export namespace IntervalStatRequest {
    export type AsObject = {
        all: boolean;
        blockchainIndex: number;
        from: number;
        to: number;
        interval: number;
    };
    export type AsObjectPartial = {
        all?: boolean;
        blockchainIndex?: number;
        from?: number;
        to?: number;
        interval?: number;
    };
}
export class IntervalStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        credits?: Map<number, IntervalStatResponse.CreditsValues>;
        collectedFees?: Map<number, IntervalStatResponse.FeesValues>;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("credits" in data && data.credits != undefined) {
                this.credits = data.credits;
            }
            if ("collectedFees" in data && data.collectedFees != undefined) {
                this.collectedFees = data.collectedFees;
            }
        }
        if (!this.credits)
            this.credits = new Map();
        if (!this.collectedFees)
            this.collectedFees = new Map();
    }
    get credits() {
        return pb_1.Message.getField(this, 1) as any as Map<number, IntervalStatResponse.CreditsValues>;
    }
    set credits(value: Map<number, IntervalStatResponse.CreditsValues>) {
        pb_1.Message.setField(this, 1, value as any);
    }
    get collectedFees() {
        return pb_1.Message.getField(this, 2) as any as Map<number, IntervalStatResponse.FeesValues>;
    }
    set collectedFees(value: Map<number, IntervalStatResponse.FeesValues>) {
        pb_1.Message.setField(this, 2, value as any);
    }
    static fromObject(data?: IntervalStatResponse.AsObjectPartial): IntervalStatResponse {
        if (!data) {
            return new IntervalStatResponse();
        }
        const message = new IntervalStatResponse({});
        if (typeof data.credits == "object") {
            message.credits = new Map(Object.entries(data.credits).map(([key, value]) => [Number(key), IntervalStatResponse.CreditsValues.fromObject(value)]));
        }
        if (typeof data.collectedFees == "object") {
            message.collectedFees = new Map(Object.entries(data.collectedFees).map(([key, value]) => [Number(key), IntervalStatResponse.FeesValues.fromObject(value)]));
        }
        return message;
    }
    toObject() {
        const data: IntervalStatResponse.AsObject = {
            credits: (Object.fromEntries)((Array.from)(this.credits).map(([key, value]) => [key, value.toObject()])),
            collectedFees: (Object.fromEntries)((Array.from)(this.collectedFees).map(([key, value]) => [key, value.toObject()]))
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        for (const [key, value] of this.credits) {
            writer.writeMessage(1, this.credits, () => {
                writer.writeInt64(1, key);
                writer.writeMessage(2, value, () => value!.serialize(writer));
            });
        }
        for (const [key, value] of this.collectedFees) {
            writer.writeMessage(2, this.collectedFees, () => {
                writer.writeInt64(1, key);
                writer.writeMessage(2, value, () => value!.serialize(writer));
            });
        }
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IntervalStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IntervalStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.credits as any, reader, reader.readInt64, () => {
                        let value;
                        reader.readMessage(message, () => value = IntervalStatResponse.CreditsValues.deserialize(reader));
                        return value;
                    }));
                    break;
                case 2:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.collectedFees as any, reader, reader.readInt64, () => {
                        let value;
                        reader.readMessage(message, () => value = IntervalStatResponse.FeesValues.deserialize(reader));
                        return value;
                    }));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): IntervalStatResponse {
        return IntervalStatResponse.deserialize(bytes);
    }
}
export namespace IntervalStatResponse {
    export type AsObject = {
        credits: {
            [key: number]: IntervalStatResponse.CreditsValues.AsObject;
        };
        collectedFees: {
            [key: number]: IntervalStatResponse.FeesValues.AsObject;
        };
    };
    export type AsObjectPartial = {
        credits?: {
            [key: number]: IntervalStatResponse.CreditsValues.AsObject;
        };
        collectedFees?: {
            [key: number]: IntervalStatResponse.FeesValues.AsObject;
        };
    };
    export class CreditsValue extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            timestamp?: number;
            value?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("timestamp" in data && data.timestamp != undefined) {
                    this.timestamp = data.timestamp;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
            }
        }
        get timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set timestamp(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set value(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: CreditsValue.AsObjectPartial): CreditsValue {
            if (!data) {
                return new CreditsValue();
            }
            const message = new CreditsValue({});
            if (data.timestamp != null) {
                message.timestamp = data.timestamp;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            return message;
        }
        toObject() {
            const data: CreditsValue.AsObject = {
                timestamp: this.timestamp,
                value: this.value
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.timestamp != 0)
                writer.writeInt64(1, this.timestamp);
            if (this.value != 0)
                writer.writeInt64(2, this.value);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreditsValue {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreditsValue();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.timestamp = reader.readInt64();
                        break;
                    case 2:
                        message.value = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreditsValue {
            return CreditsValue.deserialize(bytes);
        }
    }
    export namespace CreditsValue {
        export type AsObject = {
            timestamp: number;
            value: number;
        };
        export type AsObjectPartial = {
            timestamp?: number;
            value?: number;
        };
    }
    export class CreditsValues extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            values?: IntervalStatResponse.CreditsValue[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("values" in data && data.values != undefined) {
                    this.values = data.values;
                }
            }
        }
        get values() {
            return pb_1.Message.getRepeatedWrapperField(this, IntervalStatResponse.CreditsValue, 1) as IntervalStatResponse.CreditsValue[];
        }
        set values(value: IntervalStatResponse.CreditsValue[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data?: CreditsValues.AsObjectPartial): CreditsValues {
            if (!data) {
                return new CreditsValues();
            }
            const message = new CreditsValues({});
            if (data.values != null) {
                message.values = data.values.map(item => IntervalStatResponse.CreditsValue.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: CreditsValues.AsObject = {
                values: this.values.map((item: IntervalStatResponse.CreditsValue) => item.toObject())
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.values.length)
                writer.writeRepeatedMessage(1, this.values, (item: IntervalStatResponse.CreditsValue) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreditsValues {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreditsValues();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.values, () => pb_1.Message.addToRepeatedWrapperField(message, 1, IntervalStatResponse.CreditsValue.deserialize(reader), IntervalStatResponse.CreditsValue));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreditsValues {
            return CreditsValues.deserialize(bytes);
        }
    }
    export namespace CreditsValues {
        export type AsObject = {
            values: IntervalStatResponse.CreditsValue.AsObject[];
        };
        export type AsObjectPartial = {
            values?: IntervalStatResponse.CreditsValue.AsObjectPartial[];
        };
    }
    export class FeesValue extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            timestamp?: number;
            value?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("timestamp" in data && data.timestamp != undefined) {
                    this.timestamp = data.timestamp;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
            }
        }
        get timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set timestamp(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set value(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: FeesValue.AsObjectPartial): FeesValue {
            if (!data) {
                return new FeesValue();
            }
            const message = new FeesValue({});
            if (data.timestamp != null) {
                message.timestamp = data.timestamp;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            return message;
        }
        toObject() {
            const data: FeesValue.AsObject = {
                timestamp: this.timestamp,
                value: this.value
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.timestamp != 0)
                writer.writeInt64(1, this.timestamp);
            if (this.value.length)
                writer.writeString(2, this.value);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FeesValue {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FeesValue();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.timestamp = reader.readInt64();
                        break;
                    case 2:
                        message.value = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): FeesValue {
            return FeesValue.deserialize(bytes);
        }
    }
    export namespace FeesValue {
        export type AsObject = {
            timestamp: number;
            value: string;
        };
        export type AsObjectPartial = {
            timestamp?: number;
            value?: string;
        };
    }
    export class FeesValues extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            values?: IntervalStatResponse.FeesValue[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("values" in data && data.values != undefined) {
                    this.values = data.values;
                }
            }
        }
        get values() {
            return pb_1.Message.getRepeatedWrapperField(this, IntervalStatResponse.FeesValue, 1) as IntervalStatResponse.FeesValue[];
        }
        set values(value: IntervalStatResponse.FeesValue[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data?: FeesValues.AsObjectPartial): FeesValues {
            if (!data) {
                return new FeesValues();
            }
            const message = new FeesValues({});
            if (data.values != null) {
                message.values = data.values.map(item => IntervalStatResponse.FeesValue.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: FeesValues.AsObject = {
                values: this.values.map((item: IntervalStatResponse.FeesValue) => item.toObject())
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.values.length)
                writer.writeRepeatedMessage(1, this.values, (item: IntervalStatResponse.FeesValue) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): FeesValues {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new FeesValues();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.values, () => pb_1.Message.addToRepeatedWrapperField(message, 1, IntervalStatResponse.FeesValue.deserialize(reader), IntervalStatResponse.FeesValue));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): FeesValues {
            return FeesValues.deserialize(bytes);
        }
    }
    export namespace FeesValues {
        export type AsObject = {
            values: IntervalStatResponse.FeesValue.AsObject[];
        };
        export type AsObjectPartial = {
            values?: IntervalStatResponse.FeesValue.AsObjectPartial[];
        };
    }
}
export class OverallStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data?: OverallStatRequest.AsObjectPartial): OverallStatRequest {
        if (!data) {
            return new OverallStatRequest();
        }
        const message = new OverallStatRequest({});
        return message;
    }
    toObject() {
        const data: OverallStatRequest.AsObject = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OverallStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OverallStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OverallStatRequest {
        return OverallStatRequest.deserialize(bytes);
    }
}
export namespace OverallStatRequest {
    export type AsObject = {};
    export type AsObjectPartial = {};
}
export class OverallStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        totalTransactions?: number;
        transactionsPerSecond?: number;
        medianGasPrice?: string;
        collectedFees?: string;
        dailyTransactions?: number[];
        totalBlockchains?: number;
        stakingRatio?: number;
        stakingReward?: number;
        walletCount?: number;
        totalValidators?: number;
        timeToFinalityMs?: number;
        totalActiveValidators?: number;
        supply?: string;
        locked?: string;
        jrc20tokens?: number;
        auction?: string;
        paymasterInventory?: string;
        airdrop?: string;
        totalStake?: string;
        floatingSupply?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("totalTransactions" in data && data.totalTransactions != undefined) {
                this.totalTransactions = data.totalTransactions;
            }
            if ("transactionsPerSecond" in data && data.transactionsPerSecond != undefined) {
                this.transactionsPerSecond = data.transactionsPerSecond;
            }
            if ("medianGasPrice" in data && data.medianGasPrice != undefined) {
                this.medianGasPrice = data.medianGasPrice;
            }
            if ("collectedFees" in data && data.collectedFees != undefined) {
                this.collectedFees = data.collectedFees;
            }
            if ("dailyTransactions" in data && data.dailyTransactions != undefined) {
                this.dailyTransactions = data.dailyTransactions;
            }
            if ("totalBlockchains" in data && data.totalBlockchains != undefined) {
                this.totalBlockchains = data.totalBlockchains;
            }
            if ("stakingRatio" in data && data.stakingRatio != undefined) {
                this.stakingRatio = data.stakingRatio;
            }
            if ("stakingReward" in data && data.stakingReward != undefined) {
                this.stakingReward = data.stakingReward;
            }
            if ("walletCount" in data && data.walletCount != undefined) {
                this.walletCount = data.walletCount;
            }
            if ("totalValidators" in data && data.totalValidators != undefined) {
                this.totalValidators = data.totalValidators;
            }
            if ("timeToFinalityMs" in data && data.timeToFinalityMs != undefined) {
                this.timeToFinalityMs = data.timeToFinalityMs;
            }
            if ("totalActiveValidators" in data && data.totalActiveValidators != undefined) {
                this.totalActiveValidators = data.totalActiveValidators;
            }
            if ("supply" in data && data.supply != undefined) {
                this.supply = data.supply;
            }
            if ("locked" in data && data.locked != undefined) {
                this.locked = data.locked;
            }
            if ("jrc20tokens" in data && data.jrc20tokens != undefined) {
                this.jrc20tokens = data.jrc20tokens;
            }
            if ("auction" in data && data.auction != undefined) {
                this.auction = data.auction;
            }
            if ("paymasterInventory" in data && data.paymasterInventory != undefined) {
                this.paymasterInventory = data.paymasterInventory;
            }
            if ("airdrop" in data && data.airdrop != undefined) {
                this.airdrop = data.airdrop;
            }
            if ("totalStake" in data && data.totalStake != undefined) {
                this.totalStake = data.totalStake;
            }
            if ("floatingSupply" in data && data.floatingSupply != undefined) {
                this.floatingSupply = data.floatingSupply;
            }
        }
    }
    get totalTransactions() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set totalTransactions(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get transactionsPerSecond() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set transactionsPerSecond(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get medianGasPrice() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set medianGasPrice(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get collectedFees() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set collectedFees(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get dailyTransactions() {
        return pb_1.Message.getFieldWithDefault(this, 5, []) as number[];
    }
    set dailyTransactions(value: number[]) {
        pb_1.Message.setField(this, 5, value);
    }
    get totalBlockchains() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set totalBlockchains(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get stakingRatio() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set stakingRatio(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get stakingReward() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set stakingReward(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    get walletCount() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set walletCount(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get totalValidators() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set totalValidators(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get timeToFinalityMs() {
        return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
    }
    set timeToFinalityMs(value: number) {
        pb_1.Message.setField(this, 11, value);
    }
    get totalActiveValidators() {
        return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
    }
    set totalActiveValidators(value: number) {
        pb_1.Message.setField(this, 12, value);
    }
    get supply() {
        return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
    }
    set supply(value: string) {
        pb_1.Message.setField(this, 13, value);
    }
    get locked() {
        return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
    }
    set locked(value: string) {
        pb_1.Message.setField(this, 14, value);
    }
    get jrc20tokens() {
        return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
    }
    set jrc20tokens(value: number) {
        pb_1.Message.setField(this, 15, value);
    }
    get auction() {
        return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
    }
    set auction(value: string) {
        pb_1.Message.setField(this, 16, value);
    }
    get paymasterInventory() {
        return pb_1.Message.getFieldWithDefault(this, 17, "") as string;
    }
    set paymasterInventory(value: string) {
        pb_1.Message.setField(this, 17, value);
    }
    get airdrop() {
        return pb_1.Message.getFieldWithDefault(this, 18, "") as string;
    }
    set airdrop(value: string) {
        pb_1.Message.setField(this, 18, value);
    }
    get totalStake() {
        return pb_1.Message.getFieldWithDefault(this, 19, "") as string;
    }
    set totalStake(value: string) {
        pb_1.Message.setField(this, 19, value);
    }
    get floatingSupply() {
        return pb_1.Message.getFieldWithDefault(this, 20, "") as string;
    }
    set floatingSupply(value: string) {
        pb_1.Message.setField(this, 20, value);
    }
    static fromObject(data?: OverallStatResponse.AsObjectPartial): OverallStatResponse {
        if (!data) {
            return new OverallStatResponse();
        }
        const message = new OverallStatResponse({});
        if (data.totalTransactions != null) {
            message.totalTransactions = data.totalTransactions;
        }
        if (data.transactionsPerSecond != null) {
            message.transactionsPerSecond = data.transactionsPerSecond;
        }
        if (data.medianGasPrice != null) {
            message.medianGasPrice = data.medianGasPrice;
        }
        if (data.collectedFees != null) {
            message.collectedFees = data.collectedFees;
        }
        if (data.dailyTransactions != null) {
            message.dailyTransactions = data.dailyTransactions;
        }
        if (data.totalBlockchains != null) {
            message.totalBlockchains = data.totalBlockchains;
        }
        if (data.stakingRatio != null) {
            message.stakingRatio = data.stakingRatio;
        }
        if (data.stakingReward != null) {
            message.stakingReward = data.stakingReward;
        }
        if (data.walletCount != null) {
            message.walletCount = data.walletCount;
        }
        if (data.totalValidators != null) {
            message.totalValidators = data.totalValidators;
        }
        if (data.timeToFinalityMs != null) {
            message.timeToFinalityMs = data.timeToFinalityMs;
        }
        if (data.totalActiveValidators != null) {
            message.totalActiveValidators = data.totalActiveValidators;
        }
        if (data.supply != null) {
            message.supply = data.supply;
        }
        if (data.locked != null) {
            message.locked = data.locked;
        }
        if (data.jrc20tokens != null) {
            message.jrc20tokens = data.jrc20tokens;
        }
        if (data.auction != null) {
            message.auction = data.auction;
        }
        if (data.paymasterInventory != null) {
            message.paymasterInventory = data.paymasterInventory;
        }
        if (data.airdrop != null) {
            message.airdrop = data.airdrop;
        }
        if (data.totalStake != null) {
            message.totalStake = data.totalStake;
        }
        if (data.floatingSupply != null) {
            message.floatingSupply = data.floatingSupply;
        }
        return message;
    }
    toObject() {
        const data: OverallStatResponse.AsObject = {
            totalTransactions: this.totalTransactions,
            transactionsPerSecond: this.transactionsPerSecond,
            medianGasPrice: this.medianGasPrice,
            collectedFees: this.collectedFees,
            dailyTransactions: this.dailyTransactions,
            totalBlockchains: this.totalBlockchains,
            stakingRatio: this.stakingRatio,
            stakingReward: this.stakingReward,
            walletCount: this.walletCount,
            totalValidators: this.totalValidators,
            timeToFinalityMs: this.timeToFinalityMs,
            totalActiveValidators: this.totalActiveValidators,
            supply: this.supply,
            locked: this.locked,
            jrc20tokens: this.jrc20tokens,
            auction: this.auction,
            paymasterInventory: this.paymasterInventory,
            airdrop: this.airdrop,
            totalStake: this.totalStake,
            floatingSupply: this.floatingSupply
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.totalTransactions != 0)
            writer.writeInt64(1, this.totalTransactions);
        if (this.transactionsPerSecond != 0)
            writer.writeDouble(2, this.transactionsPerSecond);
        if (this.medianGasPrice.length)
            writer.writeString(3, this.medianGasPrice);
        if (this.collectedFees.length)
            writer.writeString(4, this.collectedFees);
        if (this.dailyTransactions.length)
            writer.writePackedInt64(5, this.dailyTransactions);
        if (this.totalBlockchains != 0)
            writer.writeInt64(6, this.totalBlockchains);
        if (this.stakingRatio != 0)
            writer.writeInt64(7, this.stakingRatio);
        if (this.stakingReward != 0)
            writer.writeInt64(8, this.stakingReward);
        if (this.walletCount != 0)
            writer.writeInt64(9, this.walletCount);
        if (this.totalValidators != 0)
            writer.writeInt64(10, this.totalValidators);
        if (this.timeToFinalityMs != 0)
            writer.writeInt64(11, this.timeToFinalityMs);
        if (this.totalActiveValidators != 0)
            writer.writeInt64(12, this.totalActiveValidators);
        if (this.supply.length)
            writer.writeString(13, this.supply);
        if (this.locked.length)
            writer.writeString(14, this.locked);
        if (this.jrc20tokens != 0)
            writer.writeInt64(15, this.jrc20tokens);
        if (this.auction.length)
            writer.writeString(16, this.auction);
        if (this.paymasterInventory.length)
            writer.writeString(17, this.paymasterInventory);
        if (this.airdrop.length)
            writer.writeString(18, this.airdrop);
        if (this.totalStake.length)
            writer.writeString(19, this.totalStake);
        if (this.floatingSupply.length)
            writer.writeString(20, this.floatingSupply);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OverallStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OverallStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.totalTransactions = reader.readInt64();
                    break;
                case 2:
                    message.transactionsPerSecond = reader.readDouble();
                    break;
                case 3:
                    message.medianGasPrice = reader.readString();
                    break;
                case 4:
                    message.collectedFees = reader.readString();
                    break;
                case 5:
                    message.dailyTransactions = reader.readPackedInt64();
                    break;
                case 6:
                    message.totalBlockchains = reader.readInt64();
                    break;
                case 7:
                    message.stakingRatio = reader.readInt64();
                    break;
                case 8:
                    message.stakingReward = reader.readInt64();
                    break;
                case 9:
                    message.walletCount = reader.readInt64();
                    break;
                case 10:
                    message.totalValidators = reader.readInt64();
                    break;
                case 11:
                    message.timeToFinalityMs = reader.readInt64();
                    break;
                case 12:
                    message.totalActiveValidators = reader.readInt64();
                    break;
                case 13:
                    message.supply = reader.readString();
                    break;
                case 14:
                    message.locked = reader.readString();
                    break;
                case 15:
                    message.jrc20tokens = reader.readInt64();
                    break;
                case 16:
                    message.auction = reader.readString();
                    break;
                case 17:
                    message.paymasterInventory = reader.readString();
                    break;
                case 18:
                    message.airdrop = reader.readString();
                    break;
                case 19:
                    message.totalStake = reader.readString();
                    break;
                case 20:
                    message.floatingSupply = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): OverallStatResponse {
        return OverallStatResponse.deserialize(bytes);
    }
}
export namespace OverallStatResponse {
    export type AsObject = {
        totalTransactions: number;
        transactionsPerSecond: number;
        medianGasPrice: string;
        collectedFees: string;
        dailyTransactions: number[];
        totalBlockchains: number;
        stakingRatio: number;
        stakingReward: number;
        walletCount: number;
        totalValidators: number;
        timeToFinalityMs: number;
        totalActiveValidators: number;
        supply: string;
        locked: string;
        jrc20tokens: number;
        auction: string;
        paymasterInventory: string;
        airdrop: string;
        totalStake: string;
        floatingSupply: string;
    };
    export type AsObjectPartial = {
        totalTransactions?: number;
        transactionsPerSecond?: number;
        medianGasPrice?: string;
        collectedFees?: string;
        dailyTransactions?: number[];
        totalBlockchains?: number;
        stakingRatio?: number;
        stakingReward?: number;
        walletCount?: number;
        totalValidators?: number;
        timeToFinalityMs?: number;
        totalActiveValidators?: number;
        supply?: string;
        locked?: string;
        jrc20tokens?: number;
        auction?: string;
        paymasterInventory?: string;
        airdrop?: string;
        totalStake?: string;
        floatingSupply?: string;
    };
}
export class BlockchainStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: BlockchainStatRequest.AsObjectPartial): BlockchainStatRequest {
        if (!data) {
            return new BlockchainStatRequest();
        }
        const message = new BlockchainStatRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        return message;
    }
    toObject() {
        const data: BlockchainStatRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainStatRequest {
        return BlockchainStatRequest.deserialize(bytes);
    }
}
export namespace BlockchainStatRequest {
    export type AsObject = {
        blockchainIndex: number;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
    };
}
export class BlockchainStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        totalTransactions?: number;
        transactionsPerSecond?: number;
        latestBlock?: number;
        latestBlockTimestamp?: number;
        dailyTransactions?: number[];
        timeToFinalityMs?: number;
        credits?: number;
        collectedFees?: string;
        medianGasPrice?: string;
        walletCount?: number;
        supply?: string;
        supernetThreshold?: number;
        supernetControlKeys?: string[];
        paymasterInventory?: string;
        auction?: string;
        airdrop?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5, 13], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("totalTransactions" in data && data.totalTransactions != undefined) {
                this.totalTransactions = data.totalTransactions;
            }
            if ("transactionsPerSecond" in data && data.transactionsPerSecond != undefined) {
                this.transactionsPerSecond = data.transactionsPerSecond;
            }
            if ("latestBlock" in data && data.latestBlock != undefined) {
                this.latestBlock = data.latestBlock;
            }
            if ("latestBlockTimestamp" in data && data.latestBlockTimestamp != undefined) {
                this.latestBlockTimestamp = data.latestBlockTimestamp;
            }
            if ("dailyTransactions" in data && data.dailyTransactions != undefined) {
                this.dailyTransactions = data.dailyTransactions;
            }
            if ("timeToFinalityMs" in data && data.timeToFinalityMs != undefined) {
                this.timeToFinalityMs = data.timeToFinalityMs;
            }
            if ("credits" in data && data.credits != undefined) {
                this.credits = data.credits;
            }
            if ("collectedFees" in data && data.collectedFees != undefined) {
                this.collectedFees = data.collectedFees;
            }
            if ("medianGasPrice" in data && data.medianGasPrice != undefined) {
                this.medianGasPrice = data.medianGasPrice;
            }
            if ("walletCount" in data && data.walletCount != undefined) {
                this.walletCount = data.walletCount;
            }
            if ("supply" in data && data.supply != undefined) {
                this.supply = data.supply;
            }
            if ("supernetThreshold" in data && data.supernetThreshold != undefined) {
                this.supernetThreshold = data.supernetThreshold;
            }
            if ("supernetControlKeys" in data && data.supernetControlKeys != undefined) {
                this.supernetControlKeys = data.supernetControlKeys;
            }
            if ("paymasterInventory" in data && data.paymasterInventory != undefined) {
                this.paymasterInventory = data.paymasterInventory;
            }
            if ("auction" in data && data.auction != undefined) {
                this.auction = data.auction;
            }
            if ("airdrop" in data && data.airdrop != undefined) {
                this.airdrop = data.airdrop;
            }
        }
    }
    get totalTransactions() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set totalTransactions(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get transactionsPerSecond() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set transactionsPerSecond(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get latestBlock() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set latestBlock(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get latestBlockTimestamp() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set latestBlockTimestamp(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get dailyTransactions() {
        return pb_1.Message.getFieldWithDefault(this, 5, []) as number[];
    }
    set dailyTransactions(value: number[]) {
        pb_1.Message.setField(this, 5, value);
    }
    get timeToFinalityMs() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set timeToFinalityMs(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    get credits() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set credits(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get collectedFees() {
        return pb_1.Message.getFieldWithDefault(this, 8, "") as string;
    }
    set collectedFees(value: string) {
        pb_1.Message.setField(this, 8, value);
    }
    get medianGasPrice() {
        return pb_1.Message.getFieldWithDefault(this, 9, "") as string;
    }
    set medianGasPrice(value: string) {
        pb_1.Message.setField(this, 9, value);
    }
    get walletCount() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set walletCount(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get supply() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set supply(value: string) {
        pb_1.Message.setField(this, 11, value);
    }
    get supernetThreshold() {
        return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
    }
    set supernetThreshold(value: number) {
        pb_1.Message.setField(this, 12, value);
    }
    get supernetControlKeys() {
        return pb_1.Message.getFieldWithDefault(this, 13, []) as string[];
    }
    set supernetControlKeys(value: string[]) {
        pb_1.Message.setField(this, 13, value);
    }
    get paymasterInventory() {
        return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
    }
    set paymasterInventory(value: string) {
        pb_1.Message.setField(this, 14, value);
    }
    get auction() {
        return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
    }
    set auction(value: string) {
        pb_1.Message.setField(this, 15, value);
    }
    get airdrop() {
        return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
    }
    set airdrop(value: string) {
        pb_1.Message.setField(this, 16, value);
    }
    static fromObject(data?: BlockchainStatResponse.AsObjectPartial): BlockchainStatResponse {
        if (!data) {
            return new BlockchainStatResponse();
        }
        const message = new BlockchainStatResponse({});
        if (data.totalTransactions != null) {
            message.totalTransactions = data.totalTransactions;
        }
        if (data.transactionsPerSecond != null) {
            message.transactionsPerSecond = data.transactionsPerSecond;
        }
        if (data.latestBlock != null) {
            message.latestBlock = data.latestBlock;
        }
        if (data.latestBlockTimestamp != null) {
            message.latestBlockTimestamp = data.latestBlockTimestamp;
        }
        if (data.dailyTransactions != null) {
            message.dailyTransactions = data.dailyTransactions;
        }
        if (data.timeToFinalityMs != null) {
            message.timeToFinalityMs = data.timeToFinalityMs;
        }
        if (data.credits != null) {
            message.credits = data.credits;
        }
        if (data.collectedFees != null) {
            message.collectedFees = data.collectedFees;
        }
        if (data.medianGasPrice != null) {
            message.medianGasPrice = data.medianGasPrice;
        }
        if (data.walletCount != null) {
            message.walletCount = data.walletCount;
        }
        if (data.supply != null) {
            message.supply = data.supply;
        }
        if (data.supernetThreshold != null) {
            message.supernetThreshold = data.supernetThreshold;
        }
        if (data.supernetControlKeys != null) {
            message.supernetControlKeys = data.supernetControlKeys;
        }
        if (data.paymasterInventory != null) {
            message.paymasterInventory = data.paymasterInventory;
        }
        if (data.auction != null) {
            message.auction = data.auction;
        }
        if (data.airdrop != null) {
            message.airdrop = data.airdrop;
        }
        return message;
    }
    toObject() {
        const data: BlockchainStatResponse.AsObject = {
            totalTransactions: this.totalTransactions,
            transactionsPerSecond: this.transactionsPerSecond,
            latestBlock: this.latestBlock,
            latestBlockTimestamp: this.latestBlockTimestamp,
            dailyTransactions: this.dailyTransactions,
            timeToFinalityMs: this.timeToFinalityMs,
            credits: this.credits,
            collectedFees: this.collectedFees,
            medianGasPrice: this.medianGasPrice,
            walletCount: this.walletCount,
            supply: this.supply,
            supernetThreshold: this.supernetThreshold,
            supernetControlKeys: this.supernetControlKeys,
            paymasterInventory: this.paymasterInventory,
            auction: this.auction,
            airdrop: this.airdrop
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.totalTransactions != 0)
            writer.writeInt64(1, this.totalTransactions);
        if (this.transactionsPerSecond != 0)
            writer.writeDouble(2, this.transactionsPerSecond);
        if (this.latestBlock != 0)
            writer.writeInt64(3, this.latestBlock);
        if (this.latestBlockTimestamp != 0)
            writer.writeInt64(4, this.latestBlockTimestamp);
        if (this.dailyTransactions.length)
            writer.writePackedInt64(5, this.dailyTransactions);
        if (this.timeToFinalityMs != 0)
            writer.writeInt64(6, this.timeToFinalityMs);
        if (this.credits != 0)
            writer.writeInt64(7, this.credits);
        if (this.collectedFees.length)
            writer.writeString(8, this.collectedFees);
        if (this.medianGasPrice.length)
            writer.writeString(9, this.medianGasPrice);
        if (this.walletCount != 0)
            writer.writeInt64(10, this.walletCount);
        if (this.supply.length)
            writer.writeString(11, this.supply);
        if (this.supernetThreshold != 0)
            writer.writeInt64(12, this.supernetThreshold);
        if (this.supernetControlKeys.length)
            writer.writeRepeatedString(13, this.supernetControlKeys);
        if (this.paymasterInventory.length)
            writer.writeString(14, this.paymasterInventory);
        if (this.auction.length)
            writer.writeString(15, this.auction);
        if (this.airdrop.length)
            writer.writeString(16, this.airdrop);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.totalTransactions = reader.readInt64();
                    break;
                case 2:
                    message.transactionsPerSecond = reader.readDouble();
                    break;
                case 3:
                    message.latestBlock = reader.readInt64();
                    break;
                case 4:
                    message.latestBlockTimestamp = reader.readInt64();
                    break;
                case 5:
                    message.dailyTransactions = reader.readPackedInt64();
                    break;
                case 6:
                    message.timeToFinalityMs = reader.readInt64();
                    break;
                case 7:
                    message.credits = reader.readInt64();
                    break;
                case 8:
                    message.collectedFees = reader.readString();
                    break;
                case 9:
                    message.medianGasPrice = reader.readString();
                    break;
                case 10:
                    message.walletCount = reader.readInt64();
                    break;
                case 11:
                    message.supply = reader.readString();
                    break;
                case 12:
                    message.supernetThreshold = reader.readInt64();
                    break;
                case 13:
                    pb_1.Message.addToRepeatedField(message, 13, reader.readString());
                    break;
                case 14:
                    message.paymasterInventory = reader.readString();
                    break;
                case 15:
                    message.auction = reader.readString();
                    break;
                case 16:
                    message.airdrop = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainStatResponse {
        return BlockchainStatResponse.deserialize(bytes);
    }
}
export namespace BlockchainStatResponse {
    export type AsObject = {
        totalTransactions: number;
        transactionsPerSecond: number;
        latestBlock: number;
        latestBlockTimestamp: number;
        dailyTransactions: number[];
        timeToFinalityMs: number;
        credits: number;
        collectedFees: string;
        medianGasPrice: string;
        walletCount: number;
        supply: string;
        supernetThreshold: number;
        supernetControlKeys: string[];
        paymasterInventory: string;
        auction: string;
        airdrop: string;
    };
    export type AsObjectPartial = {
        totalTransactions?: number;
        transactionsPerSecond?: number;
        latestBlock?: number;
        latestBlockTimestamp?: number;
        dailyTransactions?: number[];
        timeToFinalityMs?: number;
        credits?: number;
        collectedFees?: string;
        medianGasPrice?: string;
        walletCount?: number;
        supply?: string;
        supernetThreshold?: number;
        supernetControlKeys?: string[];
        paymasterInventory?: string;
        auction?: string;
        airdrop?: string;
    };
}
export class BlockchainYearStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: BlockchainYearStatRequest.AsObjectPartial): BlockchainYearStatRequest {
        if (!data) {
            return new BlockchainYearStatRequest();
        }
        const message = new BlockchainYearStatRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        return message;
    }
    toObject() {
        const data: BlockchainYearStatRequest.AsObject = {
            blockchainIndex: this.blockchainIndex
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainYearStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainYearStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainYearStatRequest {
        return BlockchainYearStatRequest.deserialize(bytes);
    }
}
export namespace BlockchainYearStatRequest {
    export type AsObject = {
        blockchainIndex: number;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
    };
}
export class BlockchainYearStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        totalTransactions?: number[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("totalTransactions" in data && data.totalTransactions != undefined) {
                this.totalTransactions = data.totalTransactions;
            }
        }
    }
    get totalTransactions() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as number[];
    }
    set totalTransactions(value: number[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: BlockchainYearStatResponse.AsObjectPartial): BlockchainYearStatResponse {
        if (!data) {
            return new BlockchainYearStatResponse();
        }
        const message = new BlockchainYearStatResponse({});
        if (data.totalTransactions != null) {
            message.totalTransactions = data.totalTransactions;
        }
        return message;
    }
    toObject() {
        const data: BlockchainYearStatResponse.AsObject = {
            totalTransactions: this.totalTransactions
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.totalTransactions.length)
            writer.writePackedInt64(1, this.totalTransactions);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockchainYearStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockchainYearStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.totalTransactions = reader.readPackedInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BlockchainYearStatResponse {
        return BlockchainYearStatResponse.deserialize(bytes);
    }
}
export namespace BlockchainYearStatResponse {
    export type AsObject = {
        totalTransactions: number[];
    };
    export type AsObjectPartial = {
        totalTransactions?: number[];
    };
}
export class InternalBlocksStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIds?: number[];
        timestamp?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIds" in data && data.blockchainIds != undefined) {
                this.blockchainIds = data.blockchainIds;
            }
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
        }
    }
    get blockchainIds() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as number[];
    }
    set blockchainIds(value: number[]) {
        pb_1.Message.setField(this, 1, value);
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: InternalBlocksStatRequest.AsObjectPartial): InternalBlocksStatRequest {
        if (!data) {
            return new InternalBlocksStatRequest();
        }
        const message = new InternalBlocksStatRequest({});
        if (data.blockchainIds != null) {
            message.blockchainIds = data.blockchainIds;
        }
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        return message;
    }
    toObject() {
        const data: InternalBlocksStatRequest.AsObject = {
            blockchainIds: this.blockchainIds,
            timestamp: this.timestamp
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIds.length)
            writer.writePackedInt64(1, this.blockchainIds);
        if (this.timestamp != 0)
            writer.writeInt64(2, this.timestamp);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalBlocksStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalBlocksStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIds = reader.readPackedInt64();
                    break;
                case 2:
                    message.timestamp = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalBlocksStatRequest {
        return InternalBlocksStatRequest.deserialize(bytes);
    }
}
export namespace InternalBlocksStatRequest {
    export type AsObject = {
        blockchainIds: number[];
        timestamp: number;
    };
    export type AsObjectPartial = {
        blockchainIds?: number[];
        timestamp?: number;
    };
}
export class InternalBlocksStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        latestBlocks?: Map<number, number>;
        latestBlockTimestamps?: Map<number, number>;
        timeToFinalityMs?: Map<number, number>;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("latestBlocks" in data && data.latestBlocks != undefined) {
                this.latestBlocks = data.latestBlocks;
            }
            if ("latestBlockTimestamps" in data && data.latestBlockTimestamps != undefined) {
                this.latestBlockTimestamps = data.latestBlockTimestamps;
            }
            if ("timeToFinalityMs" in data && data.timeToFinalityMs != undefined) {
                this.timeToFinalityMs = data.timeToFinalityMs;
            }
        }
        if (!this.latestBlocks)
            this.latestBlocks = new Map();
        if (!this.latestBlockTimestamps)
            this.latestBlockTimestamps = new Map();
        if (!this.timeToFinalityMs)
            this.timeToFinalityMs = new Map();
    }
    get latestBlocks() {
        return pb_1.Message.getField(this, 1) as any as Map<number, number>;
    }
    set latestBlocks(value: Map<number, number>) {
        pb_1.Message.setField(this, 1, value as any);
    }
    get latestBlockTimestamps() {
        return pb_1.Message.getField(this, 2) as any as Map<number, number>;
    }
    set latestBlockTimestamps(value: Map<number, number>) {
        pb_1.Message.setField(this, 2, value as any);
    }
    get timeToFinalityMs() {
        return pb_1.Message.getField(this, 3) as any as Map<number, number>;
    }
    set timeToFinalityMs(value: Map<number, number>) {
        pb_1.Message.setField(this, 3, value as any);
    }
    static fromObject(data?: InternalBlocksStatResponse.AsObjectPartial): InternalBlocksStatResponse {
        if (!data) {
            return new InternalBlocksStatResponse();
        }
        const message = new InternalBlocksStatResponse({});
        if (typeof data.latestBlocks == "object") {
            message.latestBlocks = new Map(Object.entries(data.latestBlocks).map(([key, value]) => [Number(key), value]));
        }
        if (typeof data.latestBlockTimestamps == "object") {
            message.latestBlockTimestamps = new Map(Object.entries(data.latestBlockTimestamps).map(([key, value]) => [Number(key), value]));
        }
        if (typeof data.timeToFinalityMs == "object") {
            message.timeToFinalityMs = new Map(Object.entries(data.timeToFinalityMs).map(([key, value]) => [Number(key), value]));
        }
        return message;
    }
    toObject() {
        const data: InternalBlocksStatResponse.AsObject = {
            latestBlocks: (Object.fromEntries)(this.latestBlocks),
            latestBlockTimestamps: (Object.fromEntries)(this.latestBlockTimestamps),
            timeToFinalityMs: (Object.fromEntries)(this.timeToFinalityMs)
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        for (const [key, value] of this.latestBlocks) {
            writer.writeMessage(1, this.latestBlocks, () => {
                writer.writeInt64(1, key);
                writer.writeInt64(2, value);
            });
        }
        for (const [key, value] of this.latestBlockTimestamps) {
            writer.writeMessage(2, this.latestBlockTimestamps, () => {
                writer.writeInt64(1, key);
                writer.writeInt64(2, value);
            });
        }
        for (const [key, value] of this.timeToFinalityMs) {
            writer.writeMessage(3, this.timeToFinalityMs, () => {
                writer.writeInt64(1, key);
                writer.writeInt64(2, value);
            });
        }
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalBlocksStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalBlocksStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.latestBlocks as any, reader, reader.readInt64, reader.readInt64));
                    break;
                case 2:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.latestBlockTimestamps as any, reader, reader.readInt64, reader.readInt64));
                    break;
                case 3:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.timeToFinalityMs as any, reader, reader.readInt64, reader.readInt64));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalBlocksStatResponse {
        return InternalBlocksStatResponse.deserialize(bytes);
    }
}
export namespace InternalBlocksStatResponse {
    export type AsObject = {
        latestBlocks: {
            [key: number]: number;
        };
        latestBlockTimestamps: {
            [key: number]: number;
        };
        timeToFinalityMs: {
            [key: number]: number;
        };
    };
    export type AsObjectPartial = {
        latestBlocks?: {
            [key: number]: number;
        };
        latestBlockTimestamps?: {
            [key: number]: number;
        };
        timeToFinalityMs?: {
            [key: number]: number;
        };
    };
}
export class InternalBlockchainsStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data?: InternalBlockchainsStatRequest.AsObjectPartial): InternalBlockchainsStatRequest {
        if (!data) {
            return new InternalBlockchainsStatRequest();
        }
        const message = new InternalBlockchainsStatRequest({});
        return message;
    }
    toObject() {
        const data: InternalBlockchainsStatRequest.AsObject = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalBlockchainsStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalBlockchainsStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalBlockchainsStatRequest {
        return InternalBlockchainsStatRequest.deserialize(bytes);
    }
}
export namespace InternalBlockchainsStatRequest {
    export type AsObject = {};
    export type AsObjectPartial = {};
}
export class InternalBlockchainsStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainsCount?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainsCount" in data && data.blockchainsCount != undefined) {
                this.blockchainsCount = data.blockchainsCount;
            }
        }
    }
    get blockchainsCount() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainsCount(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: InternalBlockchainsStatResponse.AsObjectPartial): InternalBlockchainsStatResponse {
        if (!data) {
            return new InternalBlockchainsStatResponse();
        }
        const message = new InternalBlockchainsStatResponse({});
        if (data.blockchainsCount != null) {
            message.blockchainsCount = data.blockchainsCount;
        }
        return message;
    }
    toObject() {
        const data: InternalBlockchainsStatResponse.AsObject = {
            blockchainsCount: this.blockchainsCount
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainsCount != 0)
            writer.writeInt64(1, this.blockchainsCount);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalBlockchainsStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalBlockchainsStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainsCount = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalBlockchainsStatResponse {
        return InternalBlockchainsStatResponse.deserialize(bytes);
    }
}
export namespace InternalBlockchainsStatResponse {
    export type AsObject = {
        blockchainsCount: number;
    };
    export type AsObjectPartial = {
        blockchainsCount?: number;
    };
}
export class InternalTransactionsStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIds?: number[];
        timestamp?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIds" in data && data.blockchainIds != undefined) {
                this.blockchainIds = data.blockchainIds;
            }
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
        }
    }
    get blockchainIds() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as number[];
    }
    set blockchainIds(value: number[]) {
        pb_1.Message.setField(this, 1, value);
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: InternalTransactionsStatRequest.AsObjectPartial): InternalTransactionsStatRequest {
        if (!data) {
            return new InternalTransactionsStatRequest();
        }
        const message = new InternalTransactionsStatRequest({});
        if (data.blockchainIds != null) {
            message.blockchainIds = data.blockchainIds;
        }
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        return message;
    }
    toObject() {
        const data: InternalTransactionsStatRequest.AsObject = {
            blockchainIds: this.blockchainIds,
            timestamp: this.timestamp
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIds.length)
            writer.writePackedInt64(1, this.blockchainIds);
        if (this.timestamp != 0)
            writer.writeInt64(2, this.timestamp);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalTransactionsStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalTransactionsStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIds = reader.readPackedInt64();
                    break;
                case 2:
                    message.timestamp = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalTransactionsStatRequest {
        return InternalTransactionsStatRequest.deserialize(bytes);
    }
}
export namespace InternalTransactionsStatRequest {
    export type AsObject = {
        blockchainIds: number[];
        timestamp: number;
    };
    export type AsObjectPartial = {
        blockchainIds?: number[];
        timestamp?: number;
    };
}
export class InternalTransactionsStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        totalTransactions?: number;
        totalTransactionsByBc?: Map<number, number>;
        medianGasPrice?: string;
        dailyTransactions?: number;
        walletCount?: number;
        dailyTransactionsByBc?: Map<number, number>;
        hourlyTransactionsByBc?: Map<number, number>;
        walletCountByBc?: Map<number, number>;
        medianGasPriceByBc?: Map<number, string>;
        locked?: string;
        thresholdBySupernet?: Map<string, number>;
        controlKeysBySupernet?: Map<string, InternalTransactionsStatResponse.ControlKeys>;
        floating?: string;
        auctionLocked?: string;
        airdropLocked?: string;
        paymasterLocked?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("totalTransactions" in data && data.totalTransactions != undefined) {
                this.totalTransactions = data.totalTransactions;
            }
            if ("totalTransactionsByBc" in data && data.totalTransactionsByBc != undefined) {
                this.totalTransactionsByBc = data.totalTransactionsByBc;
            }
            if ("medianGasPrice" in data && data.medianGasPrice != undefined) {
                this.medianGasPrice = data.medianGasPrice;
            }
            if ("dailyTransactions" in data && data.dailyTransactions != undefined) {
                this.dailyTransactions = data.dailyTransactions;
            }
            if ("walletCount" in data && data.walletCount != undefined) {
                this.walletCount = data.walletCount;
            }
            if ("dailyTransactionsByBc" in data && data.dailyTransactionsByBc != undefined) {
                this.dailyTransactionsByBc = data.dailyTransactionsByBc;
            }
            if ("hourlyTransactionsByBc" in data && data.hourlyTransactionsByBc != undefined) {
                this.hourlyTransactionsByBc = data.hourlyTransactionsByBc;
            }
            if ("walletCountByBc" in data && data.walletCountByBc != undefined) {
                this.walletCountByBc = data.walletCountByBc;
            }
            if ("medianGasPriceByBc" in data && data.medianGasPriceByBc != undefined) {
                this.medianGasPriceByBc = data.medianGasPriceByBc;
            }
            if ("locked" in data && data.locked != undefined) {
                this.locked = data.locked;
            }
            if ("thresholdBySupernet" in data && data.thresholdBySupernet != undefined) {
                this.thresholdBySupernet = data.thresholdBySupernet;
            }
            if ("controlKeysBySupernet" in data && data.controlKeysBySupernet != undefined) {
                this.controlKeysBySupernet = data.controlKeysBySupernet;
            }
            if ("floating" in data && data.floating != undefined) {
                this.floating = data.floating;
            }
            if ("auctionLocked" in data && data.auctionLocked != undefined) {
                this.auctionLocked = data.auctionLocked;
            }
            if ("airdropLocked" in data && data.airdropLocked != undefined) {
                this.airdropLocked = data.airdropLocked;
            }
            if ("paymasterLocked" in data && data.paymasterLocked != undefined) {
                this.paymasterLocked = data.paymasterLocked;
            }
        }
        if (!this.totalTransactionsByBc)
            this.totalTransactionsByBc = new Map();
        if (!this.dailyTransactionsByBc)
            this.dailyTransactionsByBc = new Map();
        if (!this.hourlyTransactionsByBc)
            this.hourlyTransactionsByBc = new Map();
        if (!this.walletCountByBc)
            this.walletCountByBc = new Map();
        if (!this.medianGasPriceByBc)
            this.medianGasPriceByBc = new Map();
        if (!this.thresholdBySupernet)
            this.thresholdBySupernet = new Map();
        if (!this.controlKeysBySupernet)
            this.controlKeysBySupernet = new Map();
    }
    get totalTransactions() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set totalTransactions(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get totalTransactionsByBc() {
        return pb_1.Message.getField(this, 2) as any as Map<number, number>;
    }
    set totalTransactionsByBc(value: Map<number, number>) {
        pb_1.Message.setField(this, 2, value as any);
    }
    get medianGasPrice() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set medianGasPrice(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get dailyTransactions() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set dailyTransactions(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get walletCount() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set walletCount(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get dailyTransactionsByBc() {
        return pb_1.Message.getField(this, 6) as any as Map<number, number>;
    }
    set dailyTransactionsByBc(value: Map<number, number>) {
        pb_1.Message.setField(this, 6, value as any);
    }
    get hourlyTransactionsByBc() {
        return pb_1.Message.getField(this, 7) as any as Map<number, number>;
    }
    set hourlyTransactionsByBc(value: Map<number, number>) {
        pb_1.Message.setField(this, 7, value as any);
    }
    get walletCountByBc() {
        return pb_1.Message.getField(this, 8) as any as Map<number, number>;
    }
    set walletCountByBc(value: Map<number, number>) {
        pb_1.Message.setField(this, 8, value as any);
    }
    get medianGasPriceByBc() {
        return pb_1.Message.getField(this, 9) as any as Map<number, string>;
    }
    set medianGasPriceByBc(value: Map<number, string>) {
        pb_1.Message.setField(this, 9, value as any);
    }
    get locked() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set locked(value: string) {
        pb_1.Message.setField(this, 10, value);
    }
    get thresholdBySupernet() {
        return pb_1.Message.getField(this, 11) as any as Map<string, number>;
    }
    set thresholdBySupernet(value: Map<string, number>) {
        pb_1.Message.setField(this, 11, value as any);
    }
    get controlKeysBySupernet() {
        return pb_1.Message.getField(this, 12) as any as Map<string, InternalTransactionsStatResponse.ControlKeys>;
    }
    set controlKeysBySupernet(value: Map<string, InternalTransactionsStatResponse.ControlKeys>) {
        pb_1.Message.setField(this, 12, value as any);
    }
    get floating() {
        return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
    }
    set floating(value: string) {
        pb_1.Message.setField(this, 13, value);
    }
    get auctionLocked() {
        return pb_1.Message.getFieldWithDefault(this, 14, "") as string;
    }
    set auctionLocked(value: string) {
        pb_1.Message.setField(this, 14, value);
    }
    get airdropLocked() {
        return pb_1.Message.getFieldWithDefault(this, 15, "") as string;
    }
    set airdropLocked(value: string) {
        pb_1.Message.setField(this, 15, value);
    }
    get paymasterLocked() {
        return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
    }
    set paymasterLocked(value: string) {
        pb_1.Message.setField(this, 16, value);
    }
    static fromObject(data?: InternalTransactionsStatResponse.AsObjectPartial): InternalTransactionsStatResponse {
        if (!data) {
            return new InternalTransactionsStatResponse();
        }
        const message = new InternalTransactionsStatResponse({});
        if (data.totalTransactions != null) {
            message.totalTransactions = data.totalTransactions;
        }
        if (typeof data.totalTransactionsByBc == "object") {
            message.totalTransactionsByBc = new Map(Object.entries(data.totalTransactionsByBc).map(([key, value]) => [Number(key), value]));
        }
        if (data.medianGasPrice != null) {
            message.medianGasPrice = data.medianGasPrice;
        }
        if (data.dailyTransactions != null) {
            message.dailyTransactions = data.dailyTransactions;
        }
        if (data.walletCount != null) {
            message.walletCount = data.walletCount;
        }
        if (typeof data.dailyTransactionsByBc == "object") {
            message.dailyTransactionsByBc = new Map(Object.entries(data.dailyTransactionsByBc).map(([key, value]) => [Number(key), value]));
        }
        if (typeof data.hourlyTransactionsByBc == "object") {
            message.hourlyTransactionsByBc = new Map(Object.entries(data.hourlyTransactionsByBc).map(([key, value]) => [Number(key), value]));
        }
        if (typeof data.walletCountByBc == "object") {
            message.walletCountByBc = new Map(Object.entries(data.walletCountByBc).map(([key, value]) => [Number(key), value]));
        }
        if (typeof data.medianGasPriceByBc == "object") {
            message.medianGasPriceByBc = new Map(Object.entries(data.medianGasPriceByBc).map(([key, value]) => [Number(key), value]));
        }
        if (data.locked != null) {
            message.locked = data.locked;
        }
        if (typeof data.thresholdBySupernet == "object") {
            message.thresholdBySupernet = new Map(Object.entries(data.thresholdBySupernet));
        }
        if (typeof data.controlKeysBySupernet == "object") {
            message.controlKeysBySupernet = new Map(Object.entries(data.controlKeysBySupernet).map(([key, value]) => [key, InternalTransactionsStatResponse.ControlKeys.fromObject(value)]));
        }
        if (data.floating != null) {
            message.floating = data.floating;
        }
        if (data.auctionLocked != null) {
            message.auctionLocked = data.auctionLocked;
        }
        if (data.airdropLocked != null) {
            message.airdropLocked = data.airdropLocked;
        }
        if (data.paymasterLocked != null) {
            message.paymasterLocked = data.paymasterLocked;
        }
        return message;
    }
    toObject() {
        const data: InternalTransactionsStatResponse.AsObject = {
            totalTransactions: this.totalTransactions,
            totalTransactionsByBc: (Object.fromEntries)(this.totalTransactionsByBc),
            medianGasPrice: this.medianGasPrice,
            dailyTransactions: this.dailyTransactions,
            walletCount: this.walletCount,
            dailyTransactionsByBc: (Object.fromEntries)(this.dailyTransactionsByBc),
            hourlyTransactionsByBc: (Object.fromEntries)(this.hourlyTransactionsByBc),
            walletCountByBc: (Object.fromEntries)(this.walletCountByBc),
            medianGasPriceByBc: (Object.fromEntries)(this.medianGasPriceByBc),
            locked: this.locked,
            thresholdBySupernet: (Object.fromEntries)(this.thresholdBySupernet),
            controlKeysBySupernet: (Object.fromEntries)((Array.from)(this.controlKeysBySupernet).map(([key, value]) => [key, value.toObject()])),
            floating: this.floating,
            auctionLocked: this.auctionLocked,
            airdropLocked: this.airdropLocked,
            paymasterLocked: this.paymasterLocked
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.totalTransactions != 0)
            writer.writeInt64(1, this.totalTransactions);
        for (const [key, value] of this.totalTransactionsByBc) {
            writer.writeMessage(2, this.totalTransactionsByBc, () => {
                writer.writeInt64(1, key);
                writer.writeInt64(2, value);
            });
        }
        if (this.medianGasPrice.length)
            writer.writeString(3, this.medianGasPrice);
        if (this.dailyTransactions != 0)
            writer.writeInt64(4, this.dailyTransactions);
        if (this.walletCount != 0)
            writer.writeInt64(5, this.walletCount);
        for (const [key, value] of this.dailyTransactionsByBc) {
            writer.writeMessage(6, this.dailyTransactionsByBc, () => {
                writer.writeInt64(1, key);
                writer.writeInt64(2, value);
            });
        }
        for (const [key, value] of this.hourlyTransactionsByBc) {
            writer.writeMessage(7, this.hourlyTransactionsByBc, () => {
                writer.writeInt64(1, key);
                writer.writeInt64(2, value);
            });
        }
        for (const [key, value] of this.walletCountByBc) {
            writer.writeMessage(8, this.walletCountByBc, () => {
                writer.writeInt64(1, key);
                writer.writeInt64(2, value);
            });
        }
        for (const [key, value] of this.medianGasPriceByBc) {
            writer.writeMessage(9, this.medianGasPriceByBc, () => {
                writer.writeInt64(1, key);
                writer.writeString(2, value);
            });
        }
        if (this.locked.length)
            writer.writeString(10, this.locked);
        for (const [key, value] of this.thresholdBySupernet) {
            writer.writeMessage(11, this.thresholdBySupernet, () => {
                writer.writeString(1, key);
                writer.writeInt64(2, value);
            });
        }
        for (const [key, value] of this.controlKeysBySupernet) {
            writer.writeMessage(12, this.controlKeysBySupernet, () => {
                writer.writeString(1, key);
                writer.writeMessage(2, value, () => value!.serialize(writer));
            });
        }
        if (this.floating.length)
            writer.writeString(13, this.floating);
        if (this.auctionLocked.length)
            writer.writeString(14, this.auctionLocked);
        if (this.airdropLocked.length)
            writer.writeString(15, this.airdropLocked);
        if (this.paymasterLocked.length)
            writer.writeString(16, this.paymasterLocked);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalTransactionsStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalTransactionsStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.totalTransactions = reader.readInt64();
                    break;
                case 2:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.totalTransactionsByBc as any, reader, reader.readInt64, reader.readInt64));
                    break;
                case 3:
                    message.medianGasPrice = reader.readString();
                    break;
                case 4:
                    message.dailyTransactions = reader.readInt64();
                    break;
                case 5:
                    message.walletCount = reader.readInt64();
                    break;
                case 6:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.dailyTransactionsByBc as any, reader, reader.readInt64, reader.readInt64));
                    break;
                case 7:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.hourlyTransactionsByBc as any, reader, reader.readInt64, reader.readInt64));
                    break;
                case 8:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.walletCountByBc as any, reader, reader.readInt64, reader.readInt64));
                    break;
                case 9:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.medianGasPriceByBc as any, reader, reader.readInt64, reader.readString));
                    break;
                case 10:
                    message.locked = reader.readString();
                    break;
                case 11:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.thresholdBySupernet as any, reader, reader.readString, reader.readInt64));
                    break;
                case 12:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.controlKeysBySupernet as any, reader, reader.readString, () => {
                        let value;
                        reader.readMessage(message, () => value = InternalTransactionsStatResponse.ControlKeys.deserialize(reader));
                        return value;
                    }));
                    break;
                case 13:
                    message.floating = reader.readString();
                    break;
                case 14:
                    message.auctionLocked = reader.readString();
                    break;
                case 15:
                    message.airdropLocked = reader.readString();
                    break;
                case 16:
                    message.paymasterLocked = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalTransactionsStatResponse {
        return InternalTransactionsStatResponse.deserialize(bytes);
    }
}
export namespace InternalTransactionsStatResponse {
    export type AsObject = {
        totalTransactions: number;
        totalTransactionsByBc: {
            [key: number]: number;
        };
        medianGasPrice: string;
        dailyTransactions: number;
        walletCount: number;
        dailyTransactionsByBc: {
            [key: number]: number;
        };
        hourlyTransactionsByBc: {
            [key: number]: number;
        };
        walletCountByBc: {
            [key: number]: number;
        };
        medianGasPriceByBc: {
            [key: number]: string;
        };
        locked: string;
        thresholdBySupernet: {
            [key: string]: number;
        };
        controlKeysBySupernet: {
            [key: string]: InternalTransactionsStatResponse.ControlKeys.AsObject;
        };
        floating: string;
        auctionLocked: string;
        airdropLocked: string;
        paymasterLocked: string;
    };
    export type AsObjectPartial = {
        totalTransactions?: number;
        totalTransactionsByBc?: {
            [key: number]: number;
        };
        medianGasPrice?: string;
        dailyTransactions?: number;
        walletCount?: number;
        dailyTransactionsByBc?: {
            [key: number]: number;
        };
        hourlyTransactionsByBc?: {
            [key: number]: number;
        };
        walletCountByBc?: {
            [key: number]: number;
        };
        medianGasPriceByBc?: {
            [key: number]: string;
        };
        locked?: string;
        thresholdBySupernet?: {
            [key: string]: number;
        };
        controlKeysBySupernet?: {
            [key: string]: InternalTransactionsStatResponse.ControlKeys.AsObject;
        };
        floating?: string;
        auctionLocked?: string;
        airdropLocked?: string;
        paymasterLocked?: string;
    };
    export class ControlKeys extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            addresses?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("addresses" in data && data.addresses != undefined) {
                    this.addresses = data.addresses;
                }
            }
        }
        get addresses() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set addresses(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: ControlKeys.AsObjectPartial): ControlKeys {
            if (!data) {
                return new ControlKeys();
            }
            const message = new ControlKeys({});
            if (data.addresses != null) {
                message.addresses = data.addresses;
            }
            return message;
        }
        toObject() {
            const data: ControlKeys.AsObject = {
                addresses: this.addresses
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.addresses.length)
                writer.writeRepeatedString(1, this.addresses);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ControlKeys {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ControlKeys();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ControlKeys {
            return ControlKeys.deserialize(bytes);
        }
    }
    export namespace ControlKeys {
        export type AsObject = {
            addresses: string[];
        };
        export type AsObjectPartial = {
            addresses?: string[];
        };
    }
}
export class InternalTokensStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIds?: number[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIds" in data && data.blockchainIds != undefined) {
                this.blockchainIds = data.blockchainIds;
            }
        }
    }
    get blockchainIds() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as number[];
    }
    set blockchainIds(value: number[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: InternalTokensStatRequest.AsObjectPartial): InternalTokensStatRequest {
        if (!data) {
            return new InternalTokensStatRequest();
        }
        const message = new InternalTokensStatRequest({});
        if (data.blockchainIds != null) {
            message.blockchainIds = data.blockchainIds;
        }
        return message;
    }
    toObject() {
        const data: InternalTokensStatRequest.AsObject = {
            blockchainIds: this.blockchainIds
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIds.length)
            writer.writePackedInt64(1, this.blockchainIds);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalTokensStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalTokensStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIds = reader.readPackedInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalTokensStatRequest {
        return InternalTokensStatRequest.deserialize(bytes);
    }
}
export namespace InternalTokensStatRequest {
    export type AsObject = {
        blockchainIds: number[];
    };
    export type AsObjectPartial = {
        blockchainIds?: number[];
    };
}
export class InternalTokensStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        supplyByBc?: Map<number, string>;
        jrc20tokens?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("supplyByBc" in data && data.supplyByBc != undefined) {
                this.supplyByBc = data.supplyByBc;
            }
            if ("jrc20tokens" in data && data.jrc20tokens != undefined) {
                this.jrc20tokens = data.jrc20tokens;
            }
        }
        if (!this.supplyByBc)
            this.supplyByBc = new Map();
    }
    get supplyByBc() {
        return pb_1.Message.getField(this, 1) as any as Map<number, string>;
    }
    set supplyByBc(value: Map<number, string>) {
        pb_1.Message.setField(this, 1, value as any);
    }
    get jrc20tokens() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set jrc20tokens(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: InternalTokensStatResponse.AsObjectPartial): InternalTokensStatResponse {
        if (!data) {
            return new InternalTokensStatResponse();
        }
        const message = new InternalTokensStatResponse({});
        if (typeof data.supplyByBc == "object") {
            message.supplyByBc = new Map(Object.entries(data.supplyByBc).map(([key, value]) => [Number(key), value]));
        }
        if (data.jrc20tokens != null) {
            message.jrc20tokens = data.jrc20tokens;
        }
        return message;
    }
    toObject() {
        const data: InternalTokensStatResponse.AsObject = {
            supplyByBc: (Object.fromEntries)(this.supplyByBc),
            jrc20tokens: this.jrc20tokens
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        for (const [key, value] of this.supplyByBc) {
            writer.writeMessage(1, this.supplyByBc, () => {
                writer.writeInt64(1, key);
                writer.writeString(2, value);
            });
        }
        if (this.jrc20tokens != 0)
            writer.writeInt64(2, this.jrc20tokens);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalTokensStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalTokensStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.supplyByBc as any, reader, reader.readInt64, reader.readString));
                    break;
                case 2:
                    message.jrc20tokens = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalTokensStatResponse {
        return InternalTokensStatResponse.deserialize(bytes);
    }
}
export namespace InternalTokensStatResponse {
    export type AsObject = {
        supplyByBc: {
            [key: number]: string;
        };
        jrc20tokens: number;
    };
    export type AsObjectPartial = {
        supplyByBc?: {
            [key: number]: string;
        };
        jrc20tokens?: number;
    };
}
export class InternalCreditsStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIds?: number[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIds" in data && data.blockchainIds != undefined) {
                this.blockchainIds = data.blockchainIds;
            }
        }
    }
    get blockchainIds() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as number[];
    }
    set blockchainIds(value: number[]) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: InternalCreditsStatRequest.AsObjectPartial): InternalCreditsStatRequest {
        if (!data) {
            return new InternalCreditsStatRequest();
        }
        const message = new InternalCreditsStatRequest({});
        if (data.blockchainIds != null) {
            message.blockchainIds = data.blockchainIds;
        }
        return message;
    }
    toObject() {
        const data: InternalCreditsStatRequest.AsObject = {
            blockchainIds: this.blockchainIds
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIds.length)
            writer.writePackedInt64(1, this.blockchainIds);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalCreditsStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalCreditsStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIds = reader.readPackedInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalCreditsStatRequest {
        return InternalCreditsStatRequest.deserialize(bytes);
    }
}
export namespace InternalCreditsStatRequest {
    export type AsObject = {
        blockchainIds: number[];
    };
    export type AsObjectPartial = {
        blockchainIds?: number[];
    };
}
export class InternalCreditsStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        creditsCountByBc?: Map<number, number>;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("creditsCountByBc" in data && data.creditsCountByBc != undefined) {
                this.creditsCountByBc = data.creditsCountByBc;
            }
        }
        if (!this.creditsCountByBc)
            this.creditsCountByBc = new Map();
    }
    get creditsCountByBc() {
        return pb_1.Message.getField(this, 1) as any as Map<number, number>;
    }
    set creditsCountByBc(value: Map<number, number>) {
        pb_1.Message.setField(this, 1, value as any);
    }
    static fromObject(data?: InternalCreditsStatResponse.AsObjectPartial): InternalCreditsStatResponse {
        if (!data) {
            return new InternalCreditsStatResponse();
        }
        const message = new InternalCreditsStatResponse({});
        if (typeof data.creditsCountByBc == "object") {
            message.creditsCountByBc = new Map(Object.entries(data.creditsCountByBc).map(([key, value]) => [Number(key), value]));
        }
        return message;
    }
    toObject() {
        const data: InternalCreditsStatResponse.AsObject = {
            creditsCountByBc: (Object.fromEntries)(this.creditsCountByBc)
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        for (const [key, value] of this.creditsCountByBc) {
            writer.writeMessage(1, this.creditsCountByBc, () => {
                writer.writeInt64(1, key);
                writer.writeInt64(2, value);
            });
        }
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalCreditsStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalCreditsStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.creditsCountByBc as any, reader, reader.readInt64, reader.readInt64));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalCreditsStatResponse {
        return InternalCreditsStatResponse.deserialize(bytes);
    }
}
export namespace InternalCreditsStatResponse {
    export type AsObject = {
        creditsCountByBc: {
            [key: number]: number;
        };
    };
    export type AsObjectPartial = {
        creditsCountByBc?: {
            [key: number]: number;
        };
    };
}
export class InternalValidatorsStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data?: InternalValidatorsStatRequest.AsObjectPartial): InternalValidatorsStatRequest {
        if (!data) {
            return new InternalValidatorsStatRequest();
        }
        const message = new InternalValidatorsStatRequest({});
        return message;
    }
    toObject() {
        const data: InternalValidatorsStatRequest.AsObject = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalValidatorsStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalValidatorsStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalValidatorsStatRequest {
        return InternalValidatorsStatRequest.deserialize(bytes);
    }
}
export namespace InternalValidatorsStatRequest {
    export type AsObject = {};
    export type AsObjectPartial = {};
}
export class InternalValidatorsStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        totalStaking?: string;
        stakingReward?: number;
        validatorsCount?: number;
        averageStakingRewardPerYear?: number;
        activeValidatorsCount?: number;
        potentialReward?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("totalStaking" in data && data.totalStaking != undefined) {
                this.totalStaking = data.totalStaking;
            }
            if ("stakingReward" in data && data.stakingReward != undefined) {
                this.stakingReward = data.stakingReward;
            }
            if ("validatorsCount" in data && data.validatorsCount != undefined) {
                this.validatorsCount = data.validatorsCount;
            }
            if ("averageStakingRewardPerYear" in data && data.averageStakingRewardPerYear != undefined) {
                this.averageStakingRewardPerYear = data.averageStakingRewardPerYear;
            }
            if ("activeValidatorsCount" in data && data.activeValidatorsCount != undefined) {
                this.activeValidatorsCount = data.activeValidatorsCount;
            }
            if ("potentialReward" in data && data.potentialReward != undefined) {
                this.potentialReward = data.potentialReward;
            }
        }
    }
    get totalStaking() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set totalStaking(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get stakingReward() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set stakingReward(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get validatorsCount() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set validatorsCount(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get averageStakingRewardPerYear() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set averageStakingRewardPerYear(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get activeValidatorsCount() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set activeValidatorsCount(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get potentialReward() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set potentialReward(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    static fromObject(data?: InternalValidatorsStatResponse.AsObjectPartial): InternalValidatorsStatResponse {
        if (!data) {
            return new InternalValidatorsStatResponse();
        }
        const message = new InternalValidatorsStatResponse({});
        if (data.totalStaking != null) {
            message.totalStaking = data.totalStaking;
        }
        if (data.stakingReward != null) {
            message.stakingReward = data.stakingReward;
        }
        if (data.validatorsCount != null) {
            message.validatorsCount = data.validatorsCount;
        }
        if (data.averageStakingRewardPerYear != null) {
            message.averageStakingRewardPerYear = data.averageStakingRewardPerYear;
        }
        if (data.activeValidatorsCount != null) {
            message.activeValidatorsCount = data.activeValidatorsCount;
        }
        if (data.potentialReward != null) {
            message.potentialReward = data.potentialReward;
        }
        return message;
    }
    toObject() {
        const data: InternalValidatorsStatResponse.AsObject = {
            totalStaking: this.totalStaking,
            stakingReward: this.stakingReward,
            validatorsCount: this.validatorsCount,
            averageStakingRewardPerYear: this.averageStakingRewardPerYear,
            activeValidatorsCount: this.activeValidatorsCount,
            potentialReward: this.potentialReward
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.totalStaking.length)
            writer.writeString(1, this.totalStaking);
        if (this.stakingReward != 0)
            writer.writeInt64(2, this.stakingReward);
        if (this.validatorsCount != 0)
            writer.writeInt64(3, this.validatorsCount);
        if (this.averageStakingRewardPerYear != 0)
            writer.writeInt64(4, this.averageStakingRewardPerYear);
        if (this.activeValidatorsCount != 0)
            writer.writeInt64(5, this.activeValidatorsCount);
        if (this.potentialReward.length)
            writer.writeString(6, this.potentialReward);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InternalValidatorsStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InternalValidatorsStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.totalStaking = reader.readString();
                    break;
                case 2:
                    message.stakingReward = reader.readInt64();
                    break;
                case 3:
                    message.validatorsCount = reader.readInt64();
                    break;
                case 4:
                    message.averageStakingRewardPerYear = reader.readInt64();
                    break;
                case 5:
                    message.activeValidatorsCount = reader.readInt64();
                    break;
                case 6:
                    message.potentialReward = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): InternalValidatorsStatResponse {
        return InternalValidatorsStatResponse.deserialize(bytes);
    }
}
export namespace InternalValidatorsStatResponse {
    export type AsObject = {
        totalStaking: string;
        stakingReward: number;
        validatorsCount: number;
        averageStakingRewardPerYear: number;
        activeValidatorsCount: number;
        potentialReward: string;
    };
    export type AsObjectPartial = {
        totalStaking?: string;
        stakingReward?: number;
        validatorsCount?: number;
        averageStakingRewardPerYear?: number;
        activeValidatorsCount?: number;
        potentialReward?: string;
    };
}
export class CollectedFeesStatRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        page?: dependency_3.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_3.Page, 1) as dependency_3.Page | undefined;
    }
    set page(value: dependency_3.Page | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: CollectedFeesStatRequest.AsObjectPartial): CollectedFeesStatRequest {
        if (!data) {
            return new CollectedFeesStatRequest();
        }
        const message = new CollectedFeesStatRequest({});
        if (data.page != null) {
            message.page = dependency_3.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: CollectedFeesStatRequest.AsObject = {};
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasPage)
            writer.writeMessage(1, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CollectedFeesStatRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CollectedFeesStatRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.page, () => message.page = dependency_3.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): CollectedFeesStatRequest {
        return CollectedFeesStatRequest.deserialize(bytes);
    }
}
export namespace CollectedFeesStatRequest {
    export type AsObject = {
        page?: dependency_3.Page.AsObject;
    };
    export type AsObjectPartial = {
        page?: dependency_3.Page.AsObjectPartial;
    };
}
export class CollectedFees extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        address?: string;
        collectedFees?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("collectedFees" in data && data.collectedFees != undefined) {
                this.collectedFees = data.collectedFees;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get collectedFees() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set collectedFees(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: CollectedFees.AsObjectPartial): CollectedFees {
        if (!data) {
            return new CollectedFees();
        }
        const message = new CollectedFees({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.collectedFees != null) {
            message.collectedFees = data.collectedFees;
        }
        return message;
    }
    toObject() {
        const data: CollectedFees.AsObject = {
            address: this.address,
            collectedFees: this.collectedFees
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (this.collectedFees.length)
            writer.writeString(2, this.collectedFees);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CollectedFees {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CollectedFees();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.collectedFees = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): CollectedFees {
        return CollectedFees.deserialize(bytes);
    }
}
export namespace CollectedFees {
    export type AsObject = {
        address: string;
        collectedFees: string;
    };
    export type AsObjectPartial = {
        address?: string;
        collectedFees?: string;
    };
}
export class WeeklyFees extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        timestamp?: number;
        collectedFees?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
            if ("collectedFees" in data && data.collectedFees != undefined) {
                this.collectedFees = data.collectedFees;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get collectedFees() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set collectedFees(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data?: WeeklyFees.AsObjectPartial): WeeklyFees {
        if (!data) {
            return new WeeklyFees();
        }
        const message = new WeeklyFees({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        if (data.collectedFees != null) {
            message.collectedFees = data.collectedFees;
        }
        return message;
    }
    toObject() {
        const data: WeeklyFees.AsObject = {
            blockchainIndex: this.blockchainIndex,
            timestamp: this.timestamp,
            collectedFees: this.collectedFees
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.timestamp != 0)
            writer.writeInt64(2, this.timestamp);
        if (this.collectedFees.length)
            writer.writeString(3, this.collectedFees);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): WeeklyFees {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new WeeklyFees();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.timestamp = reader.readInt64();
                    break;
                case 3:
                    message.collectedFees = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): WeeklyFees {
        return WeeklyFees.deserialize(bytes);
    }
}
export namespace WeeklyFees {
    export type AsObject = {
        blockchainIndex: number;
        timestamp: number;
        collectedFees: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        timestamp?: number;
        collectedFees?: string;
    };
}
export class CollectedFeesStatResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        xChainFees?: dependency_2.XChainCollectedFeesResponse;
        topAddressesFees?: CollectedFees[];
        topContractsFees?: CollectedFees[];
        weeklyFees?: WeeklyFees[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2, 3, 4], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("xChainFees" in data && data.xChainFees != undefined) {
                this.xChainFees = data.xChainFees;
            }
            if ("topAddressesFees" in data && data.topAddressesFees != undefined) {
                this.topAddressesFees = data.topAddressesFees;
            }
            if ("topContractsFees" in data && data.topContractsFees != undefined) {
                this.topContractsFees = data.topContractsFees;
            }
            if ("weeklyFees" in data && data.weeklyFees != undefined) {
                this.weeklyFees = data.weeklyFees;
            }
        }
    }
    get xChainFees() {
        return pb_1.Message.getWrapperField(this, dependency_2.XChainCollectedFeesResponse, 1) as dependency_2.XChainCollectedFeesResponse | undefined;
    }
    set xChainFees(value: dependency_2.XChainCollectedFeesResponse | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasXChainFees() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearXChainFees(): void {
        this.xChainFees = undefined!;
    }
    get topAddressesFees() {
        return pb_1.Message.getRepeatedWrapperField(this, CollectedFees, 2) as CollectedFees[];
    }
    set topAddressesFees(value: CollectedFees[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    get topContractsFees() {
        return pb_1.Message.getRepeatedWrapperField(this, CollectedFees, 3) as CollectedFees[];
    }
    set topContractsFees(value: CollectedFees[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    get weeklyFees() {
        return pb_1.Message.getRepeatedWrapperField(this, WeeklyFees, 4) as WeeklyFees[];
    }
    set weeklyFees(value: WeeklyFees[]) {
        pb_1.Message.setRepeatedWrapperField(this, 4, value);
    }
    static fromObject(data?: CollectedFeesStatResponse.AsObjectPartial): CollectedFeesStatResponse {
        if (!data) {
            return new CollectedFeesStatResponse();
        }
        const message = new CollectedFeesStatResponse({});
        if (data.xChainFees != null) {
            message.xChainFees = dependency_2.XChainCollectedFeesResponse.fromObject(data.xChainFees);
        }
        if (data.topAddressesFees != null) {
            message.topAddressesFees = data.topAddressesFees.map(item => CollectedFees.fromObject(item));
        }
        if (data.topContractsFees != null) {
            message.topContractsFees = data.topContractsFees.map(item => CollectedFees.fromObject(item));
        }
        if (data.weeklyFees != null) {
            message.weeklyFees = data.weeklyFees.map(item => WeeklyFees.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: CollectedFeesStatResponse.AsObject = {
            topAddressesFees: this.topAddressesFees.map((item: CollectedFees) => item.toObject()),
            topContractsFees: this.topContractsFees.map((item: CollectedFees) => item.toObject()),
            weeklyFees: this.weeklyFees.map((item: WeeklyFees) => item.toObject())
        };
        if (this.xChainFees != null) {
            data.xChainFees = this.xChainFees.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasXChainFees)
            writer.writeMessage(1, this.xChainFees, () => this.xChainFees!.serialize(writer));
        if (this.topAddressesFees.length)
            writer.writeRepeatedMessage(2, this.topAddressesFees, (item: CollectedFees) => item!.serialize(writer));
        if (this.topContractsFees.length)
            writer.writeRepeatedMessage(3, this.topContractsFees, (item: CollectedFees) => item!.serialize(writer));
        if (this.weeklyFees.length)
            writer.writeRepeatedMessage(4, this.weeklyFees, (item: WeeklyFees) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CollectedFeesStatResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CollectedFeesStatResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.xChainFees, () => message.xChainFees = dependency_2.XChainCollectedFeesResponse.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.topAddressesFees, () => pb_1.Message.addToRepeatedWrapperField(message, 2, CollectedFees.deserialize(reader), CollectedFees));
                    break;
                case 3:
                    reader.readMessage(message.topContractsFees, () => pb_1.Message.addToRepeatedWrapperField(message, 3, CollectedFees.deserialize(reader), CollectedFees));
                    break;
                case 4:
                    reader.readMessage(message.weeklyFees, () => pb_1.Message.addToRepeatedWrapperField(message, 4, WeeklyFees.deserialize(reader), WeeklyFees));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): CollectedFeesStatResponse {
        return CollectedFeesStatResponse.deserialize(bytes);
    }
}
export namespace CollectedFeesStatResponse {
    export type AsObject = {
        xChainFees?: dependency_2.XChainCollectedFeesResponse.AsObject;
        topAddressesFees: CollectedFees.AsObject[];
        topContractsFees: CollectedFees.AsObject[];
        weeklyFees: WeeklyFees.AsObject[];
    };
    export type AsObjectPartial = {
        xChainFees?: dependency_2.XChainCollectedFeesResponse.AsObjectPartial;
        topAddressesFees?: CollectedFees.AsObjectPartial[];
        topContractsFees?: CollectedFees.AsObjectPartial[];
        weeklyFees?: WeeklyFees.AsObjectPartial[];
    };
}
export class HotDAppsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        page?: dependency_3.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_3.Page, 1) as dependency_3.Page | undefined;
    }
    set page(value: dependency_3.Page | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: HotDAppsRequest.AsObjectPartial): HotDAppsRequest {
        if (!data) {
            return new HotDAppsRequest();
        }
        const message = new HotDAppsRequest({});
        if (data.page != null) {
            message.page = dependency_3.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: HotDAppsRequest.AsObject = {};
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasPage)
            writer.writeMessage(1, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HotDAppsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HotDAppsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.page, () => message.page = dependency_3.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): HotDAppsRequest {
        return HotDAppsRequest.deserialize(bytes);
    }
}
export namespace HotDAppsRequest {
    export type AsObject = {
        page?: dependency_3.Page.AsObject;
    };
    export type AsObjectPartial = {
        page?: dependency_3.Page.AsObjectPartial;
    };
}
export class DApp extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        name?: string;
        contractKind?: number;
        address?: string;
        blockchainIndex?: number;
        txCount?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("contractKind" in data && data.contractKind != undefined) {
                this.contractKind = data.contractKind;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("txCount" in data && data.txCount != undefined) {
                this.txCount = data.txCount;
            }
        }
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get contractKind() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set contractKind(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get txCount() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set txCount(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data?: DApp.AsObjectPartial): DApp {
        if (!data) {
            return new DApp();
        }
        const message = new DApp({});
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.contractKind != null) {
            message.contractKind = data.contractKind;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.txCount != null) {
            message.txCount = data.txCount;
        }
        return message;
    }
    toObject() {
        const data: DApp.AsObject = {
            name: this.name,
            contractKind: this.contractKind,
            address: this.address,
            blockchainIndex: this.blockchainIndex,
            txCount: this.txCount
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.name.length)
            writer.writeString(1, this.name);
        if (this.contractKind != 0)
            writer.writeInt32(2, this.contractKind);
        if (this.address.length)
            writer.writeString(3, this.address);
        if (this.blockchainIndex != 0)
            writer.writeInt64(4, this.blockchainIndex);
        if (this.txCount != 0)
            writer.writeInt64(5, this.txCount);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): DApp {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new DApp();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.name = reader.readString();
                    break;
                case 2:
                    message.contractKind = reader.readInt32();
                    break;
                case 3:
                    message.address = reader.readString();
                    break;
                case 4:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 5:
                    message.txCount = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): DApp {
        return DApp.deserialize(bytes);
    }
}
export namespace DApp {
    export type AsObject = {
        name: string;
        contractKind: number;
        address: string;
        blockchainIndex: number;
        txCount: number;
    };
    export type AsObjectPartial = {
        name?: string;
        contractKind?: number;
        address?: string;
        blockchainIndex?: number;
        txCount?: number;
    };
}
export class HotDAppsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        list?: DApp[];
        navigation?: dependency_3.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("list" in data && data.list != undefined) {
                this.list = data.list;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get list() {
        return pb_1.Message.getRepeatedWrapperField(this, DApp, 1) as DApp[];
    }
    set list(value: DApp[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_3.NavigationData, 2) as dependency_3.NavigationData | undefined;
    }
    set navigation(value: dependency_3.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: HotDAppsResponse.AsObjectPartial): HotDAppsResponse {
        if (!data) {
            return new HotDAppsResponse();
        }
        const message = new HotDAppsResponse({});
        if (data.list != null) {
            message.list = data.list.map(item => DApp.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_3.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: HotDAppsResponse.AsObject = {
            list: this.list.map((item: DApp) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.list.length)
            writer.writeRepeatedMessage(1, this.list, (item: DApp) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): HotDAppsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new HotDAppsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, DApp.deserialize(reader), DApp));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_3.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): HotDAppsResponse {
        return HotDAppsResponse.deserialize(bytes);
    }
}
export namespace HotDAppsResponse {
    export type AsObject = {
        list: DApp.AsObject[];
        navigation?: dependency_3.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        list?: DApp.AsObjectPartial[];
        navigation?: dependency_3.NavigationData.AsObjectPartial;
    };
}
export class RichListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        page?: dependency_3.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_3.Page, 1) as dependency_3.Page | undefined;
    }
    set page(value: dependency_3.Page | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: RichListRequest.AsObjectPartial): RichListRequest {
        if (!data) {
            return new RichListRequest();
        }
        const message = new RichListRequest({});
        if (data.page != null) {
            message.page = dependency_3.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: RichListRequest.AsObject = {};
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasPage)
            writer.writeMessage(1, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RichListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RichListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.page, () => message.page = dependency_3.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): RichListRequest {
        return RichListRequest.deserialize(bytes);
    }
}
export namespace RichListRequest {
    export type AsObject = {
        page?: dependency_3.Page.AsObject;
    };
    export type AsObjectPartial = {
        page?: dependency_3.Page.AsObjectPartial;
    };
}
export class Account extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        address?: string;
        balance?: string;
        balanceValue?: number;
        circulatingPercentage?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("balance" in data && data.balance != undefined) {
                this.balance = data.balance;
            }
            if ("balanceValue" in data && data.balanceValue != undefined) {
                this.balanceValue = data.balanceValue;
            }
            if ("circulatingPercentage" in data && data.circulatingPercentage != undefined) {
                this.circulatingPercentage = data.circulatingPercentage;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get balance() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set balance(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get balanceValue() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set balanceValue(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get circulatingPercentage() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set circulatingPercentage(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data?: Account.AsObjectPartial): Account {
        if (!data) {
            return new Account();
        }
        const message = new Account({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.balance != null) {
            message.balance = data.balance;
        }
        if (data.balanceValue != null) {
            message.balanceValue = data.balanceValue;
        }
        if (data.circulatingPercentage != null) {
            message.circulatingPercentage = data.circulatingPercentage;
        }
        return message;
    }
    toObject() {
        const data: Account.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address,
            balance: this.balance,
            balanceValue: this.balanceValue,
            circulatingPercentage: this.circulatingPercentage
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.address.length)
            writer.writeString(2, this.address);
        if (this.balance.length)
            writer.writeString(3, this.balance);
        if (this.balanceValue != 0)
            writer.writeInt32(4, this.balanceValue);
        if (this.circulatingPercentage != 0)
            writer.writeDouble(5, this.circulatingPercentage);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Account {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Account();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    message.balance = reader.readString();
                    break;
                case 4:
                    message.balanceValue = reader.readInt32();
                    break;
                case 5:
                    message.circulatingPercentage = reader.readDouble();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Account {
        return Account.deserialize(bytes);
    }
}
export namespace Account {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        balance: string;
        balanceValue: number;
        circulatingPercentage: number;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        balance?: string;
        balanceValue?: number;
        circulatingPercentage?: number;
    };
}
export class RichListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        list?: Account[];
        navigation?: dependency_3.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("list" in data && data.list != undefined) {
                this.list = data.list;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get list() {
        return pb_1.Message.getRepeatedWrapperField(this, Account, 1) as Account[];
    }
    set list(value: Account[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_3.NavigationData, 2) as dependency_3.NavigationData | undefined;
    }
    set navigation(value: dependency_3.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: RichListResponse.AsObjectPartial): RichListResponse {
        if (!data) {
            return new RichListResponse();
        }
        const message = new RichListResponse({});
        if (data.list != null) {
            message.list = data.list.map(item => Account.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_3.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: RichListResponse.AsObject = {
            list: this.list.map((item: Account) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.list.length)
            writer.writeRepeatedMessage(1, this.list, (item: Account) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RichListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RichListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Account.deserialize(reader), Account));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_3.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): RichListResponse {
        return RichListResponse.deserialize(bytes);
    }
}
export namespace RichListResponse {
    export type AsObject = {
        list: Account.AsObject[];
        navigation?: dependency_3.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        list?: Account.AsObjectPartial[];
        navigation?: dependency_3.NavigationData.AsObjectPartial;
    };
}
export class NetworkUsageRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2]];
    constructor(data?: any[] | ({} & (({
        all?: boolean;
        blockchainIndex?: never;
    } | {
        all?: never;
        blockchainIndex?: number;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
        }
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainIndex() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearBlockchainIndex(): void {
        this.blockchainIndex = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "blockchainIndex";
        } = {
            0: "none",
            1: "all",
            2: "blockchainIndex"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2])]!;
    }
    static fromObject(data?: NetworkUsageRequest.AsObjectPartial): NetworkUsageRequest {
        if (!data) {
            return new NetworkUsageRequest();
        }
        const message = new NetworkUsageRequest({});
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        return message;
    }
    toObject() {
        const data: NetworkUsageRequest.AsObject = {
            all: this.all,
            blockchainIndex: this.blockchainIndex
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAll)
            writer.writeBool(1, this.all);
        if (this.hasBlockchainIndex)
            writer.writeInt64(2, this.blockchainIndex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NetworkUsageRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NetworkUsageRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.all = reader.readBool();
                    break;
                case 2:
                    message.blockchainIndex = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NetworkUsageRequest {
        return NetworkUsageRequest.deserialize(bytes);
    }
}
export namespace NetworkUsageRequest {
    export type AsObject = {
        all: boolean;
        blockchainIndex: number;
    };
    export type AsObjectPartial = {
        all?: boolean;
        blockchainIndex?: number;
    };
}
export class NetworkUsageResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        credits?: Map<number, NetworkUsageResponse.CreditsValues>;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("credits" in data && data.credits != undefined) {
                this.credits = data.credits;
            }
        }
        if (!this.credits)
            this.credits = new Map();
    }
    get credits() {
        return pb_1.Message.getField(this, 1) as any as Map<number, NetworkUsageResponse.CreditsValues>;
    }
    set credits(value: Map<number, NetworkUsageResponse.CreditsValues>) {
        pb_1.Message.setField(this, 1, value as any);
    }
    static fromObject(data?: NetworkUsageResponse.AsObjectPartial): NetworkUsageResponse {
        if (!data) {
            return new NetworkUsageResponse();
        }
        const message = new NetworkUsageResponse({});
        if (typeof data.credits == "object") {
            message.credits = new Map(Object.entries(data.credits).map(([key, value]) => [Number(key), NetworkUsageResponse.CreditsValues.fromObject(value)]));
        }
        return message;
    }
    toObject() {
        const data: NetworkUsageResponse.AsObject = {
            credits: (Object.fromEntries)((Array.from)(this.credits).map(([key, value]) => [key, value.toObject()]))
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        for (const [key, value] of this.credits) {
            writer.writeMessage(1, this.credits, () => {
                writer.writeInt64(1, key);
                writer.writeMessage(2, value, () => value!.serialize(writer));
            });
        }
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NetworkUsageResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NetworkUsageResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.credits as any, reader, reader.readInt64, () => {
                        let value;
                        reader.readMessage(message, () => value = NetworkUsageResponse.CreditsValues.deserialize(reader));
                        return value;
                    }));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NetworkUsageResponse {
        return NetworkUsageResponse.deserialize(bytes);
    }
}
export namespace NetworkUsageResponse {
    export type AsObject = {
        credits: {
            [key: number]: NetworkUsageResponse.CreditsValues.AsObject;
        };
    };
    export type AsObjectPartial = {
        credits?: {
            [key: number]: NetworkUsageResponse.CreditsValues.AsObject;
        };
    };
    export class CreditsValues extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            lastWeek?: number;
            lastMonth?: number;
            lastQuarter?: number;
            latest?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("lastWeek" in data && data.lastWeek != undefined) {
                    this.lastWeek = data.lastWeek;
                }
                if ("lastMonth" in data && data.lastMonth != undefined) {
                    this.lastMonth = data.lastMonth;
                }
                if ("lastQuarter" in data && data.lastQuarter != undefined) {
                    this.lastQuarter = data.lastQuarter;
                }
                if ("latest" in data && data.latest != undefined) {
                    this.latest = data.latest;
                }
            }
        }
        get lastWeek() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set lastWeek(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get lastMonth() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set lastMonth(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get lastQuarter() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set lastQuarter(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get latest() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set latest(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data?: CreditsValues.AsObjectPartial): CreditsValues {
            if (!data) {
                return new CreditsValues();
            }
            const message = new CreditsValues({});
            if (data.lastWeek != null) {
                message.lastWeek = data.lastWeek;
            }
            if (data.lastMonth != null) {
                message.lastMonth = data.lastMonth;
            }
            if (data.lastQuarter != null) {
                message.lastQuarter = data.lastQuarter;
            }
            if (data.latest != null) {
                message.latest = data.latest;
            }
            return message;
        }
        toObject() {
            const data: CreditsValues.AsObject = {
                lastWeek: this.lastWeek,
                lastMonth: this.lastMonth,
                lastQuarter: this.lastQuarter,
                latest: this.latest
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.lastWeek != 0)
                writer.writeInt64(1, this.lastWeek);
            if (this.lastMonth != 0)
                writer.writeInt64(2, this.lastMonth);
            if (this.lastQuarter != 0)
                writer.writeInt64(3, this.lastQuarter);
            if (this.latest != 0)
                writer.writeInt64(4, this.latest);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreditsValues {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreditsValues();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.lastWeek = reader.readInt64();
                        break;
                    case 2:
                        message.lastMonth = reader.readInt64();
                        break;
                    case 3:
                        message.lastQuarter = reader.readInt64();
                        break;
                    case 4:
                        message.latest = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreditsValues {
            return CreditsValues.deserialize(bytes);
        }
    }
    export namespace CreditsValues {
        export type AsObject = {
            lastWeek: number;
            lastMonth: number;
            lastQuarter: number;
            latest: number;
        };
        export type AsObjectPartial = {
            lastWeek?: number;
            lastMonth?: number;
            lastQuarter?: number;
            latest?: number;
        };
    }
}
