import "./MobileNavbar.scss";

import React, { cloneElement, memo, useState } from "react";
import { NavLink } from "react-router-dom";

import { Logo } from "../../../assets/svg/svgIcons";
import useHeaderBannerStore from "../../stores/HeaderBannerStore";
import ElementNavBar from "../ElementNavbar/ElementNavbar";
import { NavItemType } from "../Navbar";
import BurgerClose from "./burger-close.svg?react";
import BurgerOpen from "./burger-open.svg?react";

interface MobileNavbarProps {
  navItems: NavItemType[];
  children?: React.ReactNode;
  showLinkLogo?: boolean;
}
const MobileNavbar: React.FC<MobileNavbarProps> = ({
  navItems,
  children,
  showLinkLogo,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const { visible, height } = useHeaderBannerStore();

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <nav
      className={`mobileNavbar`}
      role="navigation"
      aria-label="Site Navigation"
      style={{ marginTop: visible ? height : 0 }}
    >
      <div className="navbar-toggler">
        <NavLink to="/">
          <Logo />
        </NavLink>
        {collapsed ? (
          <BurgerClose onClick={toggleNavbar} className={`burger-close-btn`} />
        ) : (
          <BurgerOpen onClick={toggleNavbar} className={`burger-open-btn`} />
        )}
      </div>
      <div className={`navbar-collapse ${collapsed ? "show" : ""}`}>
        <ul className="navbar-nav">
          {navItems.map((item) => (
            <ElementNavBar
              key={item.href + item.text}
              navItem={item}
              onLinkClick={() => setCollapsed(false)}
              showLinkLogo={showLinkLogo}
            />
          ))}
        </ul>
        {/* pass toggleNavbar to children */}
        {children &&
          cloneElement(children as React.ReactElement, { toggleNavbar })}
      </div>
    </nav>
  );
};

export default memo(MobileNavbar);
