/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: api.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./address";
import * as dependency_3 from "./auth";
import * as dependency_4 from "./block";
import * as dependency_5 from "./blockchain";
import * as dependency_6 from "./erc20";
import * as dependency_7 from "./erc721";
import * as dependency_8 from "./erc1155";
import * as dependency_9 from "./evm";
import * as dependency_10 from "./models";
import * as dependency_11 from "./search";
import * as dependency_12 from "./statistic";
import * as dependency_13 from "./token";
import * as dependency_14 from "./transaction";
import * as dependency_15 from "./validator";
import * as dependency_16 from "./asset";
import * as dependency_17 from "./credits";
import * as pb_1 from "google-protobuf";
export class Request extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4, 5, 6, 7, 8, 14, 18, 24, 88, 31, 32, 33, 34, 35, 80, 36, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 68, 69, 70, 71, 72, 92, 76, 79, 85, 38, 81, 39, 50, 51, 52, 53, 54, 60, 82, 61, 62, 63, 64, 65, 66, 55, 83, 56, 57, 58, 59, 67, 73, 74, 75, 77, 78, 84, 86, 87, 89, 91, 93, 94]];
    constructor(data?: any[] | ({} & (({
        supernetList?: dependency_5.SupernetListRequest;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: dependency_5.BlockchainListRequest;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: dependency_4.BlockListRequest;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: dependency_4.BlockListForChainRequest;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: dependency_14.TransactionListRequest;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: dependency_14.TransactionListForChainRequest;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: dependency_15.ValidatorListRequest;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: dependency_11.SearchRequest;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: dependency_15.DelegationListRequest;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: dependency_2.AddressDataRequest;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: dependency_2.UtxoChainsBalancesRequest;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: dependency_12.OverallStatRequest;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: dependency_12.BlockchainStatRequest;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: dependency_12.BlockchainYearStatRequest;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: dependency_14.TransactionDetailsRequest;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: dependency_6.ERC20TokenListRequest;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: dependency_6.ERC20TokenListUnfilteredRequest;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: dependency_6.ERC20TokenDetailsRequest;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: dependency_9.LogListRequest;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: dependency_9.InternalTransactionListRequest;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: dependency_6.ERC20TransferListRequest;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: dependency_6.ERC20HolderListRequest;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: dependency_5.SupernetUpdateRequest;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: dependency_5.BlockchainUpdateRequest;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: dependency_5.BlockchainUpdateIconRequest;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: dependency_6.ERC20UpdateRequest;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: dependency_6.ERC20UpdateIconRequest;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: dependency_3.CheckLogPassRequest;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: dependency_10.ServerTimeRequest;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: dependency_12.HotDAppsRequest;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: dependency_12.RichListRequest;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: dependency_12.CollectedFeesStatRequest;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: dependency_14.TransactionListForWalletRequest;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: dependency_14.TransactionListForWalletByIndexListRequest;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: dependency_15.StakesRequest;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: dependency_5.BlockchainListUnfilteredRequest;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: dependency_12.IntervalStatRequest;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: dependency_7.ERC721TokenListRequest;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: dependency_7.ERC721TokenListUnfilteredRequest;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: dependency_7.ERC721TokenDetailsRequest;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: dependency_7.ERC721TransferListRequest;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: dependency_7.ERC721HolderListRequest;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: dependency_7.ERC721InventoryListRequest;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: dependency_7.ERC721UpdateRequest;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: dependency_7.ERC721UpdateIconRequest;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: dependency_8.ERC1155TokenListRequest;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: dependency_8.ERC1155TokenListUnfilteredRequest;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: dependency_8.ERC1155TokenDetailsRequest;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: dependency_8.ERC1155TransferListRequest;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: dependency_8.ERC1155HolderListRequest;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: dependency_8.ERC1155InventoryListRequest;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: dependency_8.ERC1155UpdateRequest;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: dependency_8.ERC1155UpdateIconRequest;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: dependency_13.TokenListRequest;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: dependency_13.TokenListUnfilteredRequest;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: dependency_13.TokenDetailsRequest;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: dependency_13.TransferListRequest;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: dependency_13.HolderListRequest;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: dependency_13.NFTDetailsRequest;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: dependency_13.InventoryListRequest;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: dependency_16.AssetDataRequest;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: dependency_16.AssetTransactionListRequest;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: dependency_16.AssetBalancesRequest;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: dependency_15.TotalStakeAmountRequest;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: dependency_17.BytesForChainRequest;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: dependency_17.BlockBytesRequest;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: dependency_12.NetworkUsageRequest;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: dependency_14.NativeTransferListRequest;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: dependency_15.NodeDetailsUpdateRequest;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: dependency_15.NodeDetailsRequest;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: dependency_7.RefetchERC721MetadataRequest;
        refetchErc1155Metadata?: never;
    } | {
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        blockListForChain?: never;
        transactionList?: never;
        transactionListForChain?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20ListUnfiltered?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        blockchainListUnfiltered?: never;
        intervalStat?: never;
        erc721List?: never;
        erc721ListUnfiltered?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155ListUnfiltered?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenListUnfiltered?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
        refetchErc721Metadata?: never;
        refetchErc1155Metadata?: dependency_8.RefetchERC1155MetadataRequest;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("supernetList" in data && data.supernetList != undefined) {
                this.supernetList = data.supernetList;
            }
            if ("blockchainList" in data && data.blockchainList != undefined) {
                this.blockchainList = data.blockchainList;
            }
            if ("blockList" in data && data.blockList != undefined) {
                this.blockList = data.blockList;
            }
            if ("blockListForChain" in data && data.blockListForChain != undefined) {
                this.blockListForChain = data.blockListForChain;
            }
            if ("transactionList" in data && data.transactionList != undefined) {
                this.transactionList = data.transactionList;
            }
            if ("transactionListForChain" in data && data.transactionListForChain != undefined) {
                this.transactionListForChain = data.transactionListForChain;
            }
            if ("validatorList" in data && data.validatorList != undefined) {
                this.validatorList = data.validatorList;
            }
            if ("search" in data && data.search != undefined) {
                this.search = data.search;
            }
            if ("delegatorList" in data && data.delegatorList != undefined) {
                this.delegatorList = data.delegatorList;
            }
            if ("addressData" in data && data.addressData != undefined) {
                this.addressData = data.addressData;
            }
            if ("utxoChainsBalances" in data && data.utxoChainsBalances != undefined) {
                this.utxoChainsBalances = data.utxoChainsBalances;
            }
            if ("overallStat" in data && data.overallStat != undefined) {
                this.overallStat = data.overallStat;
            }
            if ("blockchainStat" in data && data.blockchainStat != undefined) {
                this.blockchainStat = data.blockchainStat;
            }
            if ("blockchainYearStat" in data && data.blockchainYearStat != undefined) {
                this.blockchainYearStat = data.blockchainYearStat;
            }
            if ("transactionDetails" in data && data.transactionDetails != undefined) {
                this.transactionDetails = data.transactionDetails;
            }
            if ("erc20List" in data && data.erc20List != undefined) {
                this.erc20List = data.erc20List;
            }
            if ("erc20ListUnfiltered" in data && data.erc20ListUnfiltered != undefined) {
                this.erc20ListUnfiltered = data.erc20ListUnfiltered;
            }
            if ("erc20Details" in data && data.erc20Details != undefined) {
                this.erc20Details = data.erc20Details;
            }
            if ("logList" in data && data.logList != undefined) {
                this.logList = data.logList;
            }
            if ("internalTransactionList" in data && data.internalTransactionList != undefined) {
                this.internalTransactionList = data.internalTransactionList;
            }
            if ("erc20TransferList" in data && data.erc20TransferList != undefined) {
                this.erc20TransferList = data.erc20TransferList;
            }
            if ("erc20HolderList" in data && data.erc20HolderList != undefined) {
                this.erc20HolderList = data.erc20HolderList;
            }
            if ("supernetUpdate" in data && data.supernetUpdate != undefined) {
                this.supernetUpdate = data.supernetUpdate;
            }
            if ("blockchainUpdate" in data && data.blockchainUpdate != undefined) {
                this.blockchainUpdate = data.blockchainUpdate;
            }
            if ("blockchainUpdateIcon" in data && data.blockchainUpdateIcon != undefined) {
                this.blockchainUpdateIcon = data.blockchainUpdateIcon;
            }
            if ("erc20Update" in data && data.erc20Update != undefined) {
                this.erc20Update = data.erc20Update;
            }
            if ("erc20UpdateIcon" in data && data.erc20UpdateIcon != undefined) {
                this.erc20UpdateIcon = data.erc20UpdateIcon;
            }
            if ("checkLogPass" in data && data.checkLogPass != undefined) {
                this.checkLogPass = data.checkLogPass;
            }
            if ("serverTime" in data && data.serverTime != undefined) {
                this.serverTime = data.serverTime;
            }
            if ("hotDApps" in data && data.hotDApps != undefined) {
                this.hotDApps = data.hotDApps;
            }
            if ("richList" in data && data.richList != undefined) {
                this.richList = data.richList;
            }
            if ("collectedFeesStat" in data && data.collectedFeesStat != undefined) {
                this.collectedFeesStat = data.collectedFeesStat;
            }
            if ("transactionListForWallet" in data && data.transactionListForWallet != undefined) {
                this.transactionListForWallet = data.transactionListForWallet;
            }
            if ("transactionListForWalletByIndexList" in data && data.transactionListForWalletByIndexList != undefined) {
                this.transactionListForWalletByIndexList = data.transactionListForWalletByIndexList;
            }
            if ("stakes" in data && data.stakes != undefined) {
                this.stakes = data.stakes;
            }
            if ("blockchainListUnfiltered" in data && data.blockchainListUnfiltered != undefined) {
                this.blockchainListUnfiltered = data.blockchainListUnfiltered;
            }
            if ("intervalStat" in data && data.intervalStat != undefined) {
                this.intervalStat = data.intervalStat;
            }
            if ("erc721List" in data && data.erc721List != undefined) {
                this.erc721List = data.erc721List;
            }
            if ("erc721ListUnfiltered" in data && data.erc721ListUnfiltered != undefined) {
                this.erc721ListUnfiltered = data.erc721ListUnfiltered;
            }
            if ("erc721Details" in data && data.erc721Details != undefined) {
                this.erc721Details = data.erc721Details;
            }
            if ("erc721TransferList" in data && data.erc721TransferList != undefined) {
                this.erc721TransferList = data.erc721TransferList;
            }
            if ("erc721HolderList" in data && data.erc721HolderList != undefined) {
                this.erc721HolderList = data.erc721HolderList;
            }
            if ("erc721Inventory" in data && data.erc721Inventory != undefined) {
                this.erc721Inventory = data.erc721Inventory;
            }
            if ("erc721Update" in data && data.erc721Update != undefined) {
                this.erc721Update = data.erc721Update;
            }
            if ("erc721UpdateIcon" in data && data.erc721UpdateIcon != undefined) {
                this.erc721UpdateIcon = data.erc721UpdateIcon;
            }
            if ("erc1155List" in data && data.erc1155List != undefined) {
                this.erc1155List = data.erc1155List;
            }
            if ("erc1155ListUnfiltered" in data && data.erc1155ListUnfiltered != undefined) {
                this.erc1155ListUnfiltered = data.erc1155ListUnfiltered;
            }
            if ("erc1155Details" in data && data.erc1155Details != undefined) {
                this.erc1155Details = data.erc1155Details;
            }
            if ("erc1155TransferList" in data && data.erc1155TransferList != undefined) {
                this.erc1155TransferList = data.erc1155TransferList;
            }
            if ("erc1155HolderList" in data && data.erc1155HolderList != undefined) {
                this.erc1155HolderList = data.erc1155HolderList;
            }
            if ("erc1155Inventory" in data && data.erc1155Inventory != undefined) {
                this.erc1155Inventory = data.erc1155Inventory;
            }
            if ("erc1155Update" in data && data.erc1155Update != undefined) {
                this.erc1155Update = data.erc1155Update;
            }
            if ("erc1155UpdateIcon" in data && data.erc1155UpdateIcon != undefined) {
                this.erc1155UpdateIcon = data.erc1155UpdateIcon;
            }
            if ("tokenList" in data && data.tokenList != undefined) {
                this.tokenList = data.tokenList;
            }
            if ("tokenListUnfiltered" in data && data.tokenListUnfiltered != undefined) {
                this.tokenListUnfiltered = data.tokenListUnfiltered;
            }
            if ("tokenDetails" in data && data.tokenDetails != undefined) {
                this.tokenDetails = data.tokenDetails;
            }
            if ("transferList" in data && data.transferList != undefined) {
                this.transferList = data.transferList;
            }
            if ("holderList" in data && data.holderList != undefined) {
                this.holderList = data.holderList;
            }
            if ("nftDetails" in data && data.nftDetails != undefined) {
                this.nftDetails = data.nftDetails;
            }
            if ("inventoryList" in data && data.inventoryList != undefined) {
                this.inventoryList = data.inventoryList;
            }
            if ("assetData" in data && data.assetData != undefined) {
                this.assetData = data.assetData;
            }
            if ("assetTransactions" in data && data.assetTransactions != undefined) {
                this.assetTransactions = data.assetTransactions;
            }
            if ("assetBalances" in data && data.assetBalances != undefined) {
                this.assetBalances = data.assetBalances;
            }
            if ("totalStakeAmount" in data && data.totalStakeAmount != undefined) {
                this.totalStakeAmount = data.totalStakeAmount;
            }
            if ("bytesForChain" in data && data.bytesForChain != undefined) {
                this.bytesForChain = data.bytesForChain;
            }
            if ("blockBytes" in data && data.blockBytes != undefined) {
                this.blockBytes = data.blockBytes;
            }
            if ("networkUsage" in data && data.networkUsage != undefined) {
                this.networkUsage = data.networkUsage;
            }
            if ("nativeTransfers" in data && data.nativeTransfers != undefined) {
                this.nativeTransfers = data.nativeTransfers;
            }
            if ("nodeDetailsUpdate" in data && data.nodeDetailsUpdate != undefined) {
                this.nodeDetailsUpdate = data.nodeDetailsUpdate;
            }
            if ("nodeDetails" in data && data.nodeDetails != undefined) {
                this.nodeDetails = data.nodeDetails;
            }
            if ("refetchErc721Metadata" in data && data.refetchErc721Metadata != undefined) {
                this.refetchErc721Metadata = data.refetchErc721Metadata;
            }
            if ("refetchErc1155Metadata" in data && data.refetchErc1155Metadata != undefined) {
                this.refetchErc1155Metadata = data.refetchErc1155Metadata;
            }
        }
    }
    get supernetList() {
        return pb_1.Message.getWrapperField(this, dependency_5.SupernetListRequest, 2) as dependency_5.SupernetListRequest | undefined;
    }
    set supernetList(value: dependency_5.SupernetListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasSupernetList() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearSupernetList(): void {
        this.supernetList = undefined!;
    }
    get blockchainList() {
        return pb_1.Message.getWrapperField(this, dependency_5.BlockchainListRequest, 3) as dependency_5.BlockchainListRequest | undefined;
    }
    set blockchainList(value: dependency_5.BlockchainListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainList() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearBlockchainList(): void {
        this.blockchainList = undefined!;
    }
    get blockList() {
        return pb_1.Message.getWrapperField(this, dependency_4.BlockListRequest, 4) as dependency_4.BlockListRequest | undefined;
    }
    set blockList(value: dependency_4.BlockListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasBlockList() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearBlockList(): void {
        this.blockList = undefined!;
    }
    get blockListForChain() {
        return pb_1.Message.getWrapperField(this, dependency_4.BlockListForChainRequest, 5) as dependency_4.BlockListForChainRequest | undefined;
    }
    set blockListForChain(value: dependency_4.BlockListForChainRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasBlockListForChain() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearBlockListForChain(): void {
        this.blockListForChain = undefined!;
    }
    get transactionList() {
        return pb_1.Message.getWrapperField(this, dependency_14.TransactionListRequest, 6) as dependency_14.TransactionListRequest | undefined;
    }
    set transactionList(value: dependency_14.TransactionListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasTransactionList() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearTransactionList(): void {
        this.transactionList = undefined!;
    }
    get transactionListForChain() {
        return pb_1.Message.getWrapperField(this, dependency_14.TransactionListForChainRequest, 7) as dependency_14.TransactionListForChainRequest | undefined;
    }
    set transactionListForChain(value: dependency_14.TransactionListForChainRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasTransactionListForChain() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearTransactionListForChain(): void {
        this.transactionListForChain = undefined!;
    }
    get validatorList() {
        return pb_1.Message.getWrapperField(this, dependency_15.ValidatorListRequest, 8) as dependency_15.ValidatorListRequest | undefined;
    }
    set validatorList(value: dependency_15.ValidatorListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0]!, value);
    }
    get hasValidatorList() {
        return pb_1.Message.getField(this, 8) != null;
    }
    clearValidatorList(): void {
        this.validatorList = undefined!;
    }
    get search() {
        return pb_1.Message.getWrapperField(this, dependency_11.SearchRequest, 14) as dependency_11.SearchRequest | undefined;
    }
    set search(value: dependency_11.SearchRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0]!, value);
    }
    get hasSearch() {
        return pb_1.Message.getField(this, 14) != null;
    }
    clearSearch(): void {
        this.search = undefined!;
    }
    get delegatorList() {
        return pb_1.Message.getWrapperField(this, dependency_15.DelegationListRequest, 18) as dependency_15.DelegationListRequest | undefined;
    }
    set delegatorList(value: dependency_15.DelegationListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0]!, value);
    }
    get hasDelegatorList() {
        return pb_1.Message.getField(this, 18) != null;
    }
    clearDelegatorList(): void {
        this.delegatorList = undefined!;
    }
    get addressData() {
        return pb_1.Message.getWrapperField(this, dependency_2.AddressDataRequest, 24) as dependency_2.AddressDataRequest | undefined;
    }
    set addressData(value: dependency_2.AddressDataRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 24, this.#one_of_decls[0]!, value);
    }
    get hasAddressData() {
        return pb_1.Message.getField(this, 24) != null;
    }
    clearAddressData(): void {
        this.addressData = undefined!;
    }
    get utxoChainsBalances() {
        return pb_1.Message.getWrapperField(this, dependency_2.UtxoChainsBalancesRequest, 88) as dependency_2.UtxoChainsBalancesRequest | undefined;
    }
    set utxoChainsBalances(value: dependency_2.UtxoChainsBalancesRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 88, this.#one_of_decls[0]!, value);
    }
    get hasUtxoChainsBalances() {
        return pb_1.Message.getField(this, 88) != null;
    }
    clearUtxoChainsBalances(): void {
        this.utxoChainsBalances = undefined!;
    }
    get overallStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.OverallStatRequest, 31) as dependency_12.OverallStatRequest | undefined;
    }
    set overallStat(value: dependency_12.OverallStatRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 31, this.#one_of_decls[0]!, value);
    }
    get hasOverallStat() {
        return pb_1.Message.getField(this, 31) != null;
    }
    clearOverallStat(): void {
        this.overallStat = undefined!;
    }
    get blockchainStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.BlockchainStatRequest, 32) as dependency_12.BlockchainStatRequest | undefined;
    }
    set blockchainStat(value: dependency_12.BlockchainStatRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 32, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainStat() {
        return pb_1.Message.getField(this, 32) != null;
    }
    clearBlockchainStat(): void {
        this.blockchainStat = undefined!;
    }
    get blockchainYearStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.BlockchainYearStatRequest, 33) as dependency_12.BlockchainYearStatRequest | undefined;
    }
    set blockchainYearStat(value: dependency_12.BlockchainYearStatRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 33, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainYearStat() {
        return pb_1.Message.getField(this, 33) != null;
    }
    clearBlockchainYearStat(): void {
        this.blockchainYearStat = undefined!;
    }
    get transactionDetails() {
        return pb_1.Message.getWrapperField(this, dependency_14.TransactionDetailsRequest, 34) as dependency_14.TransactionDetailsRequest | undefined;
    }
    set transactionDetails(value: dependency_14.TransactionDetailsRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 34, this.#one_of_decls[0]!, value);
    }
    get hasTransactionDetails() {
        return pb_1.Message.getField(this, 34) != null;
    }
    clearTransactionDetails(): void {
        this.transactionDetails = undefined!;
    }
    get erc20List() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20TokenListRequest, 35) as dependency_6.ERC20TokenListRequest | undefined;
    }
    set erc20List(value: dependency_6.ERC20TokenListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 35, this.#one_of_decls[0]!, value);
    }
    get hasErc20List() {
        return pb_1.Message.getField(this, 35) != null;
    }
    clearErc20List(): void {
        this.erc20List = undefined!;
    }
    get erc20ListUnfiltered() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20TokenListUnfilteredRequest, 80) as dependency_6.ERC20TokenListUnfilteredRequest | undefined;
    }
    set erc20ListUnfiltered(value: dependency_6.ERC20TokenListUnfilteredRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 80, this.#one_of_decls[0]!, value);
    }
    get hasErc20ListUnfiltered() {
        return pb_1.Message.getField(this, 80) != null;
    }
    clearErc20ListUnfiltered(): void {
        this.erc20ListUnfiltered = undefined!;
    }
    get erc20Details() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20TokenDetailsRequest, 36) as dependency_6.ERC20TokenDetailsRequest | undefined;
    }
    set erc20Details(value: dependency_6.ERC20TokenDetailsRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 36, this.#one_of_decls[0]!, value);
    }
    get hasErc20Details() {
        return pb_1.Message.getField(this, 36) != null;
    }
    clearErc20Details(): void {
        this.erc20Details = undefined!;
    }
    get logList() {
        return pb_1.Message.getWrapperField(this, dependency_9.LogListRequest, 40) as dependency_9.LogListRequest | undefined;
    }
    set logList(value: dependency_9.LogListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 40, this.#one_of_decls[0]!, value);
    }
    get hasLogList() {
        return pb_1.Message.getField(this, 40) != null;
    }
    clearLogList(): void {
        this.logList = undefined!;
    }
    get internalTransactionList() {
        return pb_1.Message.getWrapperField(this, dependency_9.InternalTransactionListRequest, 41) as dependency_9.InternalTransactionListRequest | undefined;
    }
    set internalTransactionList(value: dependency_9.InternalTransactionListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 41, this.#one_of_decls[0]!, value);
    }
    get hasInternalTransactionList() {
        return pb_1.Message.getField(this, 41) != null;
    }
    clearInternalTransactionList(): void {
        this.internalTransactionList = undefined!;
    }
    get erc20TransferList() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20TransferListRequest, 42) as dependency_6.ERC20TransferListRequest | undefined;
    }
    set erc20TransferList(value: dependency_6.ERC20TransferListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 42, this.#one_of_decls[0]!, value);
    }
    get hasErc20TransferList() {
        return pb_1.Message.getField(this, 42) != null;
    }
    clearErc20TransferList(): void {
        this.erc20TransferList = undefined!;
    }
    get erc20HolderList() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20HolderListRequest, 43) as dependency_6.ERC20HolderListRequest | undefined;
    }
    set erc20HolderList(value: dependency_6.ERC20HolderListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 43, this.#one_of_decls[0]!, value);
    }
    get hasErc20HolderList() {
        return pb_1.Message.getField(this, 43) != null;
    }
    clearErc20HolderList(): void {
        this.erc20HolderList = undefined!;
    }
    get supernetUpdate() {
        return pb_1.Message.getWrapperField(this, dependency_5.SupernetUpdateRequest, 44) as dependency_5.SupernetUpdateRequest | undefined;
    }
    set supernetUpdate(value: dependency_5.SupernetUpdateRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 44, this.#one_of_decls[0]!, value);
    }
    get hasSupernetUpdate() {
        return pb_1.Message.getField(this, 44) != null;
    }
    clearSupernetUpdate(): void {
        this.supernetUpdate = undefined!;
    }
    get blockchainUpdate() {
        return pb_1.Message.getWrapperField(this, dependency_5.BlockchainUpdateRequest, 45) as dependency_5.BlockchainUpdateRequest | undefined;
    }
    set blockchainUpdate(value: dependency_5.BlockchainUpdateRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 45, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainUpdate() {
        return pb_1.Message.getField(this, 45) != null;
    }
    clearBlockchainUpdate(): void {
        this.blockchainUpdate = undefined!;
    }
    get blockchainUpdateIcon() {
        return pb_1.Message.getWrapperField(this, dependency_5.BlockchainUpdateIconRequest, 46) as dependency_5.BlockchainUpdateIconRequest | undefined;
    }
    set blockchainUpdateIcon(value: dependency_5.BlockchainUpdateIconRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 46, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainUpdateIcon() {
        return pb_1.Message.getField(this, 46) != null;
    }
    clearBlockchainUpdateIcon(): void {
        this.blockchainUpdateIcon = undefined!;
    }
    get erc20Update() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20UpdateRequest, 47) as dependency_6.ERC20UpdateRequest | undefined;
    }
    set erc20Update(value: dependency_6.ERC20UpdateRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 47, this.#one_of_decls[0]!, value);
    }
    get hasErc20Update() {
        return pb_1.Message.getField(this, 47) != null;
    }
    clearErc20Update(): void {
        this.erc20Update = undefined!;
    }
    get erc20UpdateIcon() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20UpdateIconRequest, 48) as dependency_6.ERC20UpdateIconRequest | undefined;
    }
    set erc20UpdateIcon(value: dependency_6.ERC20UpdateIconRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 48, this.#one_of_decls[0]!, value);
    }
    get hasErc20UpdateIcon() {
        return pb_1.Message.getField(this, 48) != null;
    }
    clearErc20UpdateIcon(): void {
        this.erc20UpdateIcon = undefined!;
    }
    get checkLogPass() {
        return pb_1.Message.getWrapperField(this, dependency_3.CheckLogPassRequest, 49) as dependency_3.CheckLogPassRequest | undefined;
    }
    set checkLogPass(value: dependency_3.CheckLogPassRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 49, this.#one_of_decls[0]!, value);
    }
    get hasCheckLogPass() {
        return pb_1.Message.getField(this, 49) != null;
    }
    clearCheckLogPass(): void {
        this.checkLogPass = undefined!;
    }
    get serverTime() {
        return pb_1.Message.getWrapperField(this, dependency_10.ServerTimeRequest, 68) as dependency_10.ServerTimeRequest | undefined;
    }
    set serverTime(value: dependency_10.ServerTimeRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 68, this.#one_of_decls[0]!, value);
    }
    get hasServerTime() {
        return pb_1.Message.getField(this, 68) != null;
    }
    clearServerTime(): void {
        this.serverTime = undefined!;
    }
    get hotDApps() {
        return pb_1.Message.getWrapperField(this, dependency_12.HotDAppsRequest, 69) as dependency_12.HotDAppsRequest | undefined;
    }
    set hotDApps(value: dependency_12.HotDAppsRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 69, this.#one_of_decls[0]!, value);
    }
    get hasHotDApps() {
        return pb_1.Message.getField(this, 69) != null;
    }
    clearHotDApps(): void {
        this.hotDApps = undefined!;
    }
    get richList() {
        return pb_1.Message.getWrapperField(this, dependency_12.RichListRequest, 70) as dependency_12.RichListRequest | undefined;
    }
    set richList(value: dependency_12.RichListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 70, this.#one_of_decls[0]!, value);
    }
    get hasRichList() {
        return pb_1.Message.getField(this, 70) != null;
    }
    clearRichList(): void {
        this.richList = undefined!;
    }
    get collectedFeesStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.CollectedFeesStatRequest, 71) as dependency_12.CollectedFeesStatRequest | undefined;
    }
    set collectedFeesStat(value: dependency_12.CollectedFeesStatRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 71, this.#one_of_decls[0]!, value);
    }
    get hasCollectedFeesStat() {
        return pb_1.Message.getField(this, 71) != null;
    }
    clearCollectedFeesStat(): void {
        this.collectedFeesStat = undefined!;
    }
    get transactionListForWallet() {
        return pb_1.Message.getWrapperField(this, dependency_14.TransactionListForWalletRequest, 72) as dependency_14.TransactionListForWalletRequest | undefined;
    }
    set transactionListForWallet(value: dependency_14.TransactionListForWalletRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 72, this.#one_of_decls[0]!, value);
    }
    get hasTransactionListForWallet() {
        return pb_1.Message.getField(this, 72) != null;
    }
    clearTransactionListForWallet(): void {
        this.transactionListForWallet = undefined!;
    }
    get transactionListForWalletByIndexList() {
        return pb_1.Message.getWrapperField(this, dependency_14.TransactionListForWalletByIndexListRequest, 92) as dependency_14.TransactionListForWalletByIndexListRequest | undefined;
    }
    set transactionListForWalletByIndexList(value: dependency_14.TransactionListForWalletByIndexListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 92, this.#one_of_decls[0]!, value);
    }
    get hasTransactionListForWalletByIndexList() {
        return pb_1.Message.getField(this, 92) != null;
    }
    clearTransactionListForWalletByIndexList(): void {
        this.transactionListForWalletByIndexList = undefined!;
    }
    get stakes() {
        return pb_1.Message.getWrapperField(this, dependency_15.StakesRequest, 76) as dependency_15.StakesRequest | undefined;
    }
    set stakes(value: dependency_15.StakesRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 76, this.#one_of_decls[0]!, value);
    }
    get hasStakes() {
        return pb_1.Message.getField(this, 76) != null;
    }
    clearStakes(): void {
        this.stakes = undefined!;
    }
    get blockchainListUnfiltered() {
        return pb_1.Message.getWrapperField(this, dependency_5.BlockchainListUnfilteredRequest, 79) as dependency_5.BlockchainListUnfilteredRequest | undefined;
    }
    set blockchainListUnfiltered(value: dependency_5.BlockchainListUnfilteredRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 79, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainListUnfiltered() {
        return pb_1.Message.getField(this, 79) != null;
    }
    clearBlockchainListUnfiltered(): void {
        this.blockchainListUnfiltered = undefined!;
    }
    get intervalStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.IntervalStatRequest, 85) as dependency_12.IntervalStatRequest | undefined;
    }
    set intervalStat(value: dependency_12.IntervalStatRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 85, this.#one_of_decls[0]!, value);
    }
    get hasIntervalStat() {
        return pb_1.Message.getField(this, 85) != null;
    }
    clearIntervalStat(): void {
        this.intervalStat = undefined!;
    }
    get erc721List() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721TokenListRequest, 38) as dependency_7.ERC721TokenListRequest | undefined;
    }
    set erc721List(value: dependency_7.ERC721TokenListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 38, this.#one_of_decls[0]!, value);
    }
    get hasErc721List() {
        return pb_1.Message.getField(this, 38) != null;
    }
    clearErc721List(): void {
        this.erc721List = undefined!;
    }
    get erc721ListUnfiltered() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721TokenListUnfilteredRequest, 81) as dependency_7.ERC721TokenListUnfilteredRequest | undefined;
    }
    set erc721ListUnfiltered(value: dependency_7.ERC721TokenListUnfilteredRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 81, this.#one_of_decls[0]!, value);
    }
    get hasErc721ListUnfiltered() {
        return pb_1.Message.getField(this, 81) != null;
    }
    clearErc721ListUnfiltered(): void {
        this.erc721ListUnfiltered = undefined!;
    }
    get erc721Details() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721TokenDetailsRequest, 39) as dependency_7.ERC721TokenDetailsRequest | undefined;
    }
    set erc721Details(value: dependency_7.ERC721TokenDetailsRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 39, this.#one_of_decls[0]!, value);
    }
    get hasErc721Details() {
        return pb_1.Message.getField(this, 39) != null;
    }
    clearErc721Details(): void {
        this.erc721Details = undefined!;
    }
    get erc721TransferList() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721TransferListRequest, 50) as dependency_7.ERC721TransferListRequest | undefined;
    }
    set erc721TransferList(value: dependency_7.ERC721TransferListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 50, this.#one_of_decls[0]!, value);
    }
    get hasErc721TransferList() {
        return pb_1.Message.getField(this, 50) != null;
    }
    clearErc721TransferList(): void {
        this.erc721TransferList = undefined!;
    }
    get erc721HolderList() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721HolderListRequest, 51) as dependency_7.ERC721HolderListRequest | undefined;
    }
    set erc721HolderList(value: dependency_7.ERC721HolderListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 51, this.#one_of_decls[0]!, value);
    }
    get hasErc721HolderList() {
        return pb_1.Message.getField(this, 51) != null;
    }
    clearErc721HolderList(): void {
        this.erc721HolderList = undefined!;
    }
    get erc721Inventory() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721InventoryListRequest, 52) as dependency_7.ERC721InventoryListRequest | undefined;
    }
    set erc721Inventory(value: dependency_7.ERC721InventoryListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 52, this.#one_of_decls[0]!, value);
    }
    get hasErc721Inventory() {
        return pb_1.Message.getField(this, 52) != null;
    }
    clearErc721Inventory(): void {
        this.erc721Inventory = undefined!;
    }
    get erc721Update() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721UpdateRequest, 53) as dependency_7.ERC721UpdateRequest | undefined;
    }
    set erc721Update(value: dependency_7.ERC721UpdateRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 53, this.#one_of_decls[0]!, value);
    }
    get hasErc721Update() {
        return pb_1.Message.getField(this, 53) != null;
    }
    clearErc721Update(): void {
        this.erc721Update = undefined!;
    }
    get erc721UpdateIcon() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721UpdateIconRequest, 54) as dependency_7.ERC721UpdateIconRequest | undefined;
    }
    set erc721UpdateIcon(value: dependency_7.ERC721UpdateIconRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 54, this.#one_of_decls[0]!, value);
    }
    get hasErc721UpdateIcon() {
        return pb_1.Message.getField(this, 54) != null;
    }
    clearErc721UpdateIcon(): void {
        this.erc721UpdateIcon = undefined!;
    }
    get erc1155List() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155TokenListRequest, 60) as dependency_8.ERC1155TokenListRequest | undefined;
    }
    set erc1155List(value: dependency_8.ERC1155TokenListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 60, this.#one_of_decls[0]!, value);
    }
    get hasErc1155List() {
        return pb_1.Message.getField(this, 60) != null;
    }
    clearErc1155List(): void {
        this.erc1155List = undefined!;
    }
    get erc1155ListUnfiltered() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155TokenListUnfilteredRequest, 82) as dependency_8.ERC1155TokenListUnfilteredRequest | undefined;
    }
    set erc1155ListUnfiltered(value: dependency_8.ERC1155TokenListUnfilteredRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 82, this.#one_of_decls[0]!, value);
    }
    get hasErc1155ListUnfiltered() {
        return pb_1.Message.getField(this, 82) != null;
    }
    clearErc1155ListUnfiltered(): void {
        this.erc1155ListUnfiltered = undefined!;
    }
    get erc1155Details() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155TokenDetailsRequest, 61) as dependency_8.ERC1155TokenDetailsRequest | undefined;
    }
    set erc1155Details(value: dependency_8.ERC1155TokenDetailsRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 61, this.#one_of_decls[0]!, value);
    }
    get hasErc1155Details() {
        return pb_1.Message.getField(this, 61) != null;
    }
    clearErc1155Details(): void {
        this.erc1155Details = undefined!;
    }
    get erc1155TransferList() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155TransferListRequest, 62) as dependency_8.ERC1155TransferListRequest | undefined;
    }
    set erc1155TransferList(value: dependency_8.ERC1155TransferListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 62, this.#one_of_decls[0]!, value);
    }
    get hasErc1155TransferList() {
        return pb_1.Message.getField(this, 62) != null;
    }
    clearErc1155TransferList(): void {
        this.erc1155TransferList = undefined!;
    }
    get erc1155HolderList() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155HolderListRequest, 63) as dependency_8.ERC1155HolderListRequest | undefined;
    }
    set erc1155HolderList(value: dependency_8.ERC1155HolderListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 63, this.#one_of_decls[0]!, value);
    }
    get hasErc1155HolderList() {
        return pb_1.Message.getField(this, 63) != null;
    }
    clearErc1155HolderList(): void {
        this.erc1155HolderList = undefined!;
    }
    get erc1155Inventory() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155InventoryListRequest, 64) as dependency_8.ERC1155InventoryListRequest | undefined;
    }
    set erc1155Inventory(value: dependency_8.ERC1155InventoryListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 64, this.#one_of_decls[0]!, value);
    }
    get hasErc1155Inventory() {
        return pb_1.Message.getField(this, 64) != null;
    }
    clearErc1155Inventory(): void {
        this.erc1155Inventory = undefined!;
    }
    get erc1155Update() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155UpdateRequest, 65) as dependency_8.ERC1155UpdateRequest | undefined;
    }
    set erc1155Update(value: dependency_8.ERC1155UpdateRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 65, this.#one_of_decls[0]!, value);
    }
    get hasErc1155Update() {
        return pb_1.Message.getField(this, 65) != null;
    }
    clearErc1155Update(): void {
        this.erc1155Update = undefined!;
    }
    get erc1155UpdateIcon() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155UpdateIconRequest, 66) as dependency_8.ERC1155UpdateIconRequest | undefined;
    }
    set erc1155UpdateIcon(value: dependency_8.ERC1155UpdateIconRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 66, this.#one_of_decls[0]!, value);
    }
    get hasErc1155UpdateIcon() {
        return pb_1.Message.getField(this, 66) != null;
    }
    clearErc1155UpdateIcon(): void {
        this.erc1155UpdateIcon = undefined!;
    }
    get tokenList() {
        return pb_1.Message.getWrapperField(this, dependency_13.TokenListRequest, 55) as dependency_13.TokenListRequest | undefined;
    }
    set tokenList(value: dependency_13.TokenListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 55, this.#one_of_decls[0]!, value);
    }
    get hasTokenList() {
        return pb_1.Message.getField(this, 55) != null;
    }
    clearTokenList(): void {
        this.tokenList = undefined!;
    }
    get tokenListUnfiltered() {
        return pb_1.Message.getWrapperField(this, dependency_13.TokenListUnfilteredRequest, 83) as dependency_13.TokenListUnfilteredRequest | undefined;
    }
    set tokenListUnfiltered(value: dependency_13.TokenListUnfilteredRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 83, this.#one_of_decls[0]!, value);
    }
    get hasTokenListUnfiltered() {
        return pb_1.Message.getField(this, 83) != null;
    }
    clearTokenListUnfiltered(): void {
        this.tokenListUnfiltered = undefined!;
    }
    get tokenDetails() {
        return pb_1.Message.getWrapperField(this, dependency_13.TokenDetailsRequest, 56) as dependency_13.TokenDetailsRequest | undefined;
    }
    set tokenDetails(value: dependency_13.TokenDetailsRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 56, this.#one_of_decls[0]!, value);
    }
    get hasTokenDetails() {
        return pb_1.Message.getField(this, 56) != null;
    }
    clearTokenDetails(): void {
        this.tokenDetails = undefined!;
    }
    get transferList() {
        return pb_1.Message.getWrapperField(this, dependency_13.TransferListRequest, 57) as dependency_13.TransferListRequest | undefined;
    }
    set transferList(value: dependency_13.TransferListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 57, this.#one_of_decls[0]!, value);
    }
    get hasTransferList() {
        return pb_1.Message.getField(this, 57) != null;
    }
    clearTransferList(): void {
        this.transferList = undefined!;
    }
    get holderList() {
        return pb_1.Message.getWrapperField(this, dependency_13.HolderListRequest, 58) as dependency_13.HolderListRequest | undefined;
    }
    set holderList(value: dependency_13.HolderListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 58, this.#one_of_decls[0]!, value);
    }
    get hasHolderList() {
        return pb_1.Message.getField(this, 58) != null;
    }
    clearHolderList(): void {
        this.holderList = undefined!;
    }
    get nftDetails() {
        return pb_1.Message.getWrapperField(this, dependency_13.NFTDetailsRequest, 59) as dependency_13.NFTDetailsRequest | undefined;
    }
    set nftDetails(value: dependency_13.NFTDetailsRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 59, this.#one_of_decls[0]!, value);
    }
    get hasNftDetails() {
        return pb_1.Message.getField(this, 59) != null;
    }
    clearNftDetails(): void {
        this.nftDetails = undefined!;
    }
    get inventoryList() {
        return pb_1.Message.getWrapperField(this, dependency_13.InventoryListRequest, 67) as dependency_13.InventoryListRequest | undefined;
    }
    set inventoryList(value: dependency_13.InventoryListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 67, this.#one_of_decls[0]!, value);
    }
    get hasInventoryList() {
        return pb_1.Message.getField(this, 67) != null;
    }
    clearInventoryList(): void {
        this.inventoryList = undefined!;
    }
    get assetData() {
        return pb_1.Message.getWrapperField(this, dependency_16.AssetDataRequest, 73) as dependency_16.AssetDataRequest | undefined;
    }
    set assetData(value: dependency_16.AssetDataRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 73, this.#one_of_decls[0]!, value);
    }
    get hasAssetData() {
        return pb_1.Message.getField(this, 73) != null;
    }
    clearAssetData(): void {
        this.assetData = undefined!;
    }
    get assetTransactions() {
        return pb_1.Message.getWrapperField(this, dependency_16.AssetTransactionListRequest, 74) as dependency_16.AssetTransactionListRequest | undefined;
    }
    set assetTransactions(value: dependency_16.AssetTransactionListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 74, this.#one_of_decls[0]!, value);
    }
    get hasAssetTransactions() {
        return pb_1.Message.getField(this, 74) != null;
    }
    clearAssetTransactions(): void {
        this.assetTransactions = undefined!;
    }
    get assetBalances() {
        return pb_1.Message.getWrapperField(this, dependency_16.AssetBalancesRequest, 75) as dependency_16.AssetBalancesRequest | undefined;
    }
    set assetBalances(value: dependency_16.AssetBalancesRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 75, this.#one_of_decls[0]!, value);
    }
    get hasAssetBalances() {
        return pb_1.Message.getField(this, 75) != null;
    }
    clearAssetBalances(): void {
        this.assetBalances = undefined!;
    }
    get totalStakeAmount() {
        return pb_1.Message.getWrapperField(this, dependency_15.TotalStakeAmountRequest, 77) as dependency_15.TotalStakeAmountRequest | undefined;
    }
    set totalStakeAmount(value: dependency_15.TotalStakeAmountRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 77, this.#one_of_decls[0]!, value);
    }
    get hasTotalStakeAmount() {
        return pb_1.Message.getField(this, 77) != null;
    }
    clearTotalStakeAmount(): void {
        this.totalStakeAmount = undefined!;
    }
    get bytesForChain() {
        return pb_1.Message.getWrapperField(this, dependency_17.BytesForChainRequest, 78) as dependency_17.BytesForChainRequest | undefined;
    }
    set bytesForChain(value: dependency_17.BytesForChainRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 78, this.#one_of_decls[0]!, value);
    }
    get hasBytesForChain() {
        return pb_1.Message.getField(this, 78) != null;
    }
    clearBytesForChain(): void {
        this.bytesForChain = undefined!;
    }
    get blockBytes() {
        return pb_1.Message.getWrapperField(this, dependency_17.BlockBytesRequest, 84) as dependency_17.BlockBytesRequest | undefined;
    }
    set blockBytes(value: dependency_17.BlockBytesRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 84, this.#one_of_decls[0]!, value);
    }
    get hasBlockBytes() {
        return pb_1.Message.getField(this, 84) != null;
    }
    clearBlockBytes(): void {
        this.blockBytes = undefined!;
    }
    get networkUsage() {
        return pb_1.Message.getWrapperField(this, dependency_12.NetworkUsageRequest, 86) as dependency_12.NetworkUsageRequest | undefined;
    }
    set networkUsage(value: dependency_12.NetworkUsageRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 86, this.#one_of_decls[0]!, value);
    }
    get hasNetworkUsage() {
        return pb_1.Message.getField(this, 86) != null;
    }
    clearNetworkUsage(): void {
        this.networkUsage = undefined!;
    }
    get nativeTransfers() {
        return pb_1.Message.getWrapperField(this, dependency_14.NativeTransferListRequest, 87) as dependency_14.NativeTransferListRequest | undefined;
    }
    set nativeTransfers(value: dependency_14.NativeTransferListRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 87, this.#one_of_decls[0]!, value);
    }
    get hasNativeTransfers() {
        return pb_1.Message.getField(this, 87) != null;
    }
    clearNativeTransfers(): void {
        this.nativeTransfers = undefined!;
    }
    get nodeDetailsUpdate() {
        return pb_1.Message.getWrapperField(this, dependency_15.NodeDetailsUpdateRequest, 89) as dependency_15.NodeDetailsUpdateRequest | undefined;
    }
    set nodeDetailsUpdate(value: dependency_15.NodeDetailsUpdateRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 89, this.#one_of_decls[0]!, value);
    }
    get hasNodeDetailsUpdate() {
        return pb_1.Message.getField(this, 89) != null;
    }
    clearNodeDetailsUpdate(): void {
        this.nodeDetailsUpdate = undefined!;
    }
    get nodeDetails() {
        return pb_1.Message.getWrapperField(this, dependency_15.NodeDetailsRequest, 91) as dependency_15.NodeDetailsRequest | undefined;
    }
    set nodeDetails(value: dependency_15.NodeDetailsRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 91, this.#one_of_decls[0]!, value);
    }
    get hasNodeDetails() {
        return pb_1.Message.getField(this, 91) != null;
    }
    clearNodeDetails(): void {
        this.nodeDetails = undefined!;
    }
    get refetchErc721Metadata() {
        return pb_1.Message.getWrapperField(this, dependency_7.RefetchERC721MetadataRequest, 93) as dependency_7.RefetchERC721MetadataRequest | undefined;
    }
    set refetchErc721Metadata(value: dependency_7.RefetchERC721MetadataRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 93, this.#one_of_decls[0]!, value);
    }
    get hasRefetchErc721Metadata() {
        return pb_1.Message.getField(this, 93) != null;
    }
    clearRefetchErc721Metadata(): void {
        this.refetchErc721Metadata = undefined!;
    }
    get refetchErc1155Metadata() {
        return pb_1.Message.getWrapperField(this, dependency_8.RefetchERC1155MetadataRequest, 94) as dependency_8.RefetchERC1155MetadataRequest | undefined;
    }
    set refetchErc1155Metadata(value: dependency_8.RefetchERC1155MetadataRequest | undefined) {
        pb_1.Message.setOneofWrapperField(this, 94, this.#one_of_decls[0]!, value);
    }
    get hasRefetchErc1155Metadata() {
        return pb_1.Message.getField(this, 94) != null;
    }
    clearRefetchErc1155Metadata(): void {
        this.refetchErc1155Metadata = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "supernetList" | "blockchainList" | "blockList" | "blockListForChain" | "transactionList" | "transactionListForChain" | "validatorList" | "search" | "delegatorList" | "addressData" | "utxoChainsBalances" | "overallStat" | "blockchainStat" | "blockchainYearStat" | "transactionDetails" | "erc20List" | "erc20ListUnfiltered" | "erc20Details" | "logList" | "internalTransactionList" | "erc20TransferList" | "erc20HolderList" | "supernetUpdate" | "blockchainUpdate" | "blockchainUpdateIcon" | "erc20Update" | "erc20UpdateIcon" | "checkLogPass" | "serverTime" | "hotDApps" | "richList" | "collectedFeesStat" | "transactionListForWallet" | "transactionListForWalletByIndexList" | "stakes" | "blockchainListUnfiltered" | "intervalStat" | "erc721List" | "erc721ListUnfiltered" | "erc721Details" | "erc721TransferList" | "erc721HolderList" | "erc721Inventory" | "erc721Update" | "erc721UpdateIcon" | "erc1155List" | "erc1155ListUnfiltered" | "erc1155Details" | "erc1155TransferList" | "erc1155HolderList" | "erc1155Inventory" | "erc1155Update" | "erc1155UpdateIcon" | "tokenList" | "tokenListUnfiltered" | "tokenDetails" | "transferList" | "holderList" | "nftDetails" | "inventoryList" | "assetData" | "assetTransactions" | "assetBalances" | "totalStakeAmount" | "bytesForChain" | "blockBytes" | "networkUsage" | "nativeTransfers" | "nodeDetailsUpdate" | "nodeDetails" | "refetchErc721Metadata" | "refetchErc1155Metadata";
        } = {
            0: "none",
            2: "supernetList",
            3: "blockchainList",
            4: "blockList",
            5: "blockListForChain",
            6: "transactionList",
            7: "transactionListForChain",
            8: "validatorList",
            14: "search",
            18: "delegatorList",
            24: "addressData",
            88: "utxoChainsBalances",
            31: "overallStat",
            32: "blockchainStat",
            33: "blockchainYearStat",
            34: "transactionDetails",
            35: "erc20List",
            80: "erc20ListUnfiltered",
            36: "erc20Details",
            40: "logList",
            41: "internalTransactionList",
            42: "erc20TransferList",
            43: "erc20HolderList",
            44: "supernetUpdate",
            45: "blockchainUpdate",
            46: "blockchainUpdateIcon",
            47: "erc20Update",
            48: "erc20UpdateIcon",
            49: "checkLogPass",
            68: "serverTime",
            69: "hotDApps",
            70: "richList",
            71: "collectedFeesStat",
            72: "transactionListForWallet",
            92: "transactionListForWalletByIndexList",
            76: "stakes",
            79: "blockchainListUnfiltered",
            85: "intervalStat",
            38: "erc721List",
            81: "erc721ListUnfiltered",
            39: "erc721Details",
            50: "erc721TransferList",
            51: "erc721HolderList",
            52: "erc721Inventory",
            53: "erc721Update",
            54: "erc721UpdateIcon",
            60: "erc1155List",
            82: "erc1155ListUnfiltered",
            61: "erc1155Details",
            62: "erc1155TransferList",
            63: "erc1155HolderList",
            64: "erc1155Inventory",
            65: "erc1155Update",
            66: "erc1155UpdateIcon",
            55: "tokenList",
            83: "tokenListUnfiltered",
            56: "tokenDetails",
            57: "transferList",
            58: "holderList",
            59: "nftDetails",
            67: "inventoryList",
            73: "assetData",
            74: "assetTransactions",
            75: "assetBalances",
            77: "totalStakeAmount",
            78: "bytesForChain",
            84: "blockBytes",
            86: "networkUsage",
            87: "nativeTransfers",
            89: "nodeDetailsUpdate",
            91: "nodeDetails",
            93: "refetchErc721Metadata",
            94: "refetchErc1155Metadata"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4, 5, 6, 7, 8, 14, 18, 24, 88, 31, 32, 33, 34, 35, 80, 36, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 68, 69, 70, 71, 72, 92, 76, 79, 85, 38, 81, 39, 50, 51, 52, 53, 54, 60, 82, 61, 62, 63, 64, 65, 66, 55, 83, 56, 57, 58, 59, 67, 73, 74, 75, 77, 78, 84, 86, 87, 89, 91, 93, 94])]!;
    }
    static fromObject(data?: Request.AsObjectPartial): Request {
        if (!data) {
            return new Request();
        }
        const message = new Request({});
        if (data.supernetList != null) {
            message.supernetList = dependency_5.SupernetListRequest.fromObject(data.supernetList);
        }
        if (data.blockchainList != null) {
            message.blockchainList = dependency_5.BlockchainListRequest.fromObject(data.blockchainList);
        }
        if (data.blockList != null) {
            message.blockList = dependency_4.BlockListRequest.fromObject(data.blockList);
        }
        if (data.blockListForChain != null) {
            message.blockListForChain = dependency_4.BlockListForChainRequest.fromObject(data.blockListForChain);
        }
        if (data.transactionList != null) {
            message.transactionList = dependency_14.TransactionListRequest.fromObject(data.transactionList);
        }
        if (data.transactionListForChain != null) {
            message.transactionListForChain = dependency_14.TransactionListForChainRequest.fromObject(data.transactionListForChain);
        }
        if (data.validatorList != null) {
            message.validatorList = dependency_15.ValidatorListRequest.fromObject(data.validatorList);
        }
        if (data.search != null) {
            message.search = dependency_11.SearchRequest.fromObject(data.search);
        }
        if (data.delegatorList != null) {
            message.delegatorList = dependency_15.DelegationListRequest.fromObject(data.delegatorList);
        }
        if (data.addressData != null) {
            message.addressData = dependency_2.AddressDataRequest.fromObject(data.addressData);
        }
        if (data.utxoChainsBalances != null) {
            message.utxoChainsBalances = dependency_2.UtxoChainsBalancesRequest.fromObject(data.utxoChainsBalances);
        }
        if (data.overallStat != null) {
            message.overallStat = dependency_12.OverallStatRequest.fromObject(data.overallStat);
        }
        if (data.blockchainStat != null) {
            message.blockchainStat = dependency_12.BlockchainStatRequest.fromObject(data.blockchainStat);
        }
        if (data.blockchainYearStat != null) {
            message.blockchainYearStat = dependency_12.BlockchainYearStatRequest.fromObject(data.blockchainYearStat);
        }
        if (data.transactionDetails != null) {
            message.transactionDetails = dependency_14.TransactionDetailsRequest.fromObject(data.transactionDetails);
        }
        if (data.erc20List != null) {
            message.erc20List = dependency_6.ERC20TokenListRequest.fromObject(data.erc20List);
        }
        if (data.erc20ListUnfiltered != null) {
            message.erc20ListUnfiltered = dependency_6.ERC20TokenListUnfilteredRequest.fromObject(data.erc20ListUnfiltered);
        }
        if (data.erc20Details != null) {
            message.erc20Details = dependency_6.ERC20TokenDetailsRequest.fromObject(data.erc20Details);
        }
        if (data.logList != null) {
            message.logList = dependency_9.LogListRequest.fromObject(data.logList);
        }
        if (data.internalTransactionList != null) {
            message.internalTransactionList = dependency_9.InternalTransactionListRequest.fromObject(data.internalTransactionList);
        }
        if (data.erc20TransferList != null) {
            message.erc20TransferList = dependency_6.ERC20TransferListRequest.fromObject(data.erc20TransferList);
        }
        if (data.erc20HolderList != null) {
            message.erc20HolderList = dependency_6.ERC20HolderListRequest.fromObject(data.erc20HolderList);
        }
        if (data.supernetUpdate != null) {
            message.supernetUpdate = dependency_5.SupernetUpdateRequest.fromObject(data.supernetUpdate);
        }
        if (data.blockchainUpdate != null) {
            message.blockchainUpdate = dependency_5.BlockchainUpdateRequest.fromObject(data.blockchainUpdate);
        }
        if (data.blockchainUpdateIcon != null) {
            message.blockchainUpdateIcon = dependency_5.BlockchainUpdateIconRequest.fromObject(data.blockchainUpdateIcon);
        }
        if (data.erc20Update != null) {
            message.erc20Update = dependency_6.ERC20UpdateRequest.fromObject(data.erc20Update);
        }
        if (data.erc20UpdateIcon != null) {
            message.erc20UpdateIcon = dependency_6.ERC20UpdateIconRequest.fromObject(data.erc20UpdateIcon);
        }
        if (data.checkLogPass != null) {
            message.checkLogPass = dependency_3.CheckLogPassRequest.fromObject(data.checkLogPass);
        }
        if (data.serverTime != null) {
            message.serverTime = dependency_10.ServerTimeRequest.fromObject(data.serverTime);
        }
        if (data.hotDApps != null) {
            message.hotDApps = dependency_12.HotDAppsRequest.fromObject(data.hotDApps);
        }
        if (data.richList != null) {
            message.richList = dependency_12.RichListRequest.fromObject(data.richList);
        }
        if (data.collectedFeesStat != null) {
            message.collectedFeesStat = dependency_12.CollectedFeesStatRequest.fromObject(data.collectedFeesStat);
        }
        if (data.transactionListForWallet != null) {
            message.transactionListForWallet = dependency_14.TransactionListForWalletRequest.fromObject(data.transactionListForWallet);
        }
        if (data.transactionListForWalletByIndexList != null) {
            message.transactionListForWalletByIndexList = dependency_14.TransactionListForWalletByIndexListRequest.fromObject(data.transactionListForWalletByIndexList);
        }
        if (data.stakes != null) {
            message.stakes = dependency_15.StakesRequest.fromObject(data.stakes);
        }
        if (data.blockchainListUnfiltered != null) {
            message.blockchainListUnfiltered = dependency_5.BlockchainListUnfilteredRequest.fromObject(data.blockchainListUnfiltered);
        }
        if (data.intervalStat != null) {
            message.intervalStat = dependency_12.IntervalStatRequest.fromObject(data.intervalStat);
        }
        if (data.erc721List != null) {
            message.erc721List = dependency_7.ERC721TokenListRequest.fromObject(data.erc721List);
        }
        if (data.erc721ListUnfiltered != null) {
            message.erc721ListUnfiltered = dependency_7.ERC721TokenListUnfilteredRequest.fromObject(data.erc721ListUnfiltered);
        }
        if (data.erc721Details != null) {
            message.erc721Details = dependency_7.ERC721TokenDetailsRequest.fromObject(data.erc721Details);
        }
        if (data.erc721TransferList != null) {
            message.erc721TransferList = dependency_7.ERC721TransferListRequest.fromObject(data.erc721TransferList);
        }
        if (data.erc721HolderList != null) {
            message.erc721HolderList = dependency_7.ERC721HolderListRequest.fromObject(data.erc721HolderList);
        }
        if (data.erc721Inventory != null) {
            message.erc721Inventory = dependency_7.ERC721InventoryListRequest.fromObject(data.erc721Inventory);
        }
        if (data.erc721Update != null) {
            message.erc721Update = dependency_7.ERC721UpdateRequest.fromObject(data.erc721Update);
        }
        if (data.erc721UpdateIcon != null) {
            message.erc721UpdateIcon = dependency_7.ERC721UpdateIconRequest.fromObject(data.erc721UpdateIcon);
        }
        if (data.erc1155List != null) {
            message.erc1155List = dependency_8.ERC1155TokenListRequest.fromObject(data.erc1155List);
        }
        if (data.erc1155ListUnfiltered != null) {
            message.erc1155ListUnfiltered = dependency_8.ERC1155TokenListUnfilteredRequest.fromObject(data.erc1155ListUnfiltered);
        }
        if (data.erc1155Details != null) {
            message.erc1155Details = dependency_8.ERC1155TokenDetailsRequest.fromObject(data.erc1155Details);
        }
        if (data.erc1155TransferList != null) {
            message.erc1155TransferList = dependency_8.ERC1155TransferListRequest.fromObject(data.erc1155TransferList);
        }
        if (data.erc1155HolderList != null) {
            message.erc1155HolderList = dependency_8.ERC1155HolderListRequest.fromObject(data.erc1155HolderList);
        }
        if (data.erc1155Inventory != null) {
            message.erc1155Inventory = dependency_8.ERC1155InventoryListRequest.fromObject(data.erc1155Inventory);
        }
        if (data.erc1155Update != null) {
            message.erc1155Update = dependency_8.ERC1155UpdateRequest.fromObject(data.erc1155Update);
        }
        if (data.erc1155UpdateIcon != null) {
            message.erc1155UpdateIcon = dependency_8.ERC1155UpdateIconRequest.fromObject(data.erc1155UpdateIcon);
        }
        if (data.tokenList != null) {
            message.tokenList = dependency_13.TokenListRequest.fromObject(data.tokenList);
        }
        if (data.tokenListUnfiltered != null) {
            message.tokenListUnfiltered = dependency_13.TokenListUnfilteredRequest.fromObject(data.tokenListUnfiltered);
        }
        if (data.tokenDetails != null) {
            message.tokenDetails = dependency_13.TokenDetailsRequest.fromObject(data.tokenDetails);
        }
        if (data.transferList != null) {
            message.transferList = dependency_13.TransferListRequest.fromObject(data.transferList);
        }
        if (data.holderList != null) {
            message.holderList = dependency_13.HolderListRequest.fromObject(data.holderList);
        }
        if (data.nftDetails != null) {
            message.nftDetails = dependency_13.NFTDetailsRequest.fromObject(data.nftDetails);
        }
        if (data.inventoryList != null) {
            message.inventoryList = dependency_13.InventoryListRequest.fromObject(data.inventoryList);
        }
        if (data.assetData != null) {
            message.assetData = dependency_16.AssetDataRequest.fromObject(data.assetData);
        }
        if (data.assetTransactions != null) {
            message.assetTransactions = dependency_16.AssetTransactionListRequest.fromObject(data.assetTransactions);
        }
        if (data.assetBalances != null) {
            message.assetBalances = dependency_16.AssetBalancesRequest.fromObject(data.assetBalances);
        }
        if (data.totalStakeAmount != null) {
            message.totalStakeAmount = dependency_15.TotalStakeAmountRequest.fromObject(data.totalStakeAmount);
        }
        if (data.bytesForChain != null) {
            message.bytesForChain = dependency_17.BytesForChainRequest.fromObject(data.bytesForChain);
        }
        if (data.blockBytes != null) {
            message.blockBytes = dependency_17.BlockBytesRequest.fromObject(data.blockBytes);
        }
        if (data.networkUsage != null) {
            message.networkUsage = dependency_12.NetworkUsageRequest.fromObject(data.networkUsage);
        }
        if (data.nativeTransfers != null) {
            message.nativeTransfers = dependency_14.NativeTransferListRequest.fromObject(data.nativeTransfers);
        }
        if (data.nodeDetailsUpdate != null) {
            message.nodeDetailsUpdate = dependency_15.NodeDetailsUpdateRequest.fromObject(data.nodeDetailsUpdate);
        }
        if (data.nodeDetails != null) {
            message.nodeDetails = dependency_15.NodeDetailsRequest.fromObject(data.nodeDetails);
        }
        if (data.refetchErc721Metadata != null) {
            message.refetchErc721Metadata = dependency_7.RefetchERC721MetadataRequest.fromObject(data.refetchErc721Metadata);
        }
        if (data.refetchErc1155Metadata != null) {
            message.refetchErc1155Metadata = dependency_8.RefetchERC1155MetadataRequest.fromObject(data.refetchErc1155Metadata);
        }
        return message;
    }
    toObject() {
        const data: Request.AsObject = {};
        if (this.supernetList != null) {
            data.supernetList = this.supernetList.toObject();
        }
        if (this.blockchainList != null) {
            data.blockchainList = this.blockchainList.toObject();
        }
        if (this.blockList != null) {
            data.blockList = this.blockList.toObject();
        }
        if (this.blockListForChain != null) {
            data.blockListForChain = this.blockListForChain.toObject();
        }
        if (this.transactionList != null) {
            data.transactionList = this.transactionList.toObject();
        }
        if (this.transactionListForChain != null) {
            data.transactionListForChain = this.transactionListForChain.toObject();
        }
        if (this.validatorList != null) {
            data.validatorList = this.validatorList.toObject();
        }
        if (this.search != null) {
            data.search = this.search.toObject();
        }
        if (this.delegatorList != null) {
            data.delegatorList = this.delegatorList.toObject();
        }
        if (this.addressData != null) {
            data.addressData = this.addressData.toObject();
        }
        if (this.utxoChainsBalances != null) {
            data.utxoChainsBalances = this.utxoChainsBalances.toObject();
        }
        if (this.overallStat != null) {
            data.overallStat = this.overallStat.toObject();
        }
        if (this.blockchainStat != null) {
            data.blockchainStat = this.blockchainStat.toObject();
        }
        if (this.blockchainYearStat != null) {
            data.blockchainYearStat = this.blockchainYearStat.toObject();
        }
        if (this.transactionDetails != null) {
            data.transactionDetails = this.transactionDetails.toObject();
        }
        if (this.erc20List != null) {
            data.erc20List = this.erc20List.toObject();
        }
        if (this.erc20ListUnfiltered != null) {
            data.erc20ListUnfiltered = this.erc20ListUnfiltered.toObject();
        }
        if (this.erc20Details != null) {
            data.erc20Details = this.erc20Details.toObject();
        }
        if (this.logList != null) {
            data.logList = this.logList.toObject();
        }
        if (this.internalTransactionList != null) {
            data.internalTransactionList = this.internalTransactionList.toObject();
        }
        if (this.erc20TransferList != null) {
            data.erc20TransferList = this.erc20TransferList.toObject();
        }
        if (this.erc20HolderList != null) {
            data.erc20HolderList = this.erc20HolderList.toObject();
        }
        if (this.supernetUpdate != null) {
            data.supernetUpdate = this.supernetUpdate.toObject();
        }
        if (this.blockchainUpdate != null) {
            data.blockchainUpdate = this.blockchainUpdate.toObject();
        }
        if (this.blockchainUpdateIcon != null) {
            data.blockchainUpdateIcon = this.blockchainUpdateIcon.toObject();
        }
        if (this.erc20Update != null) {
            data.erc20Update = this.erc20Update.toObject();
        }
        if (this.erc20UpdateIcon != null) {
            data.erc20UpdateIcon = this.erc20UpdateIcon.toObject();
        }
        if (this.checkLogPass != null) {
            data.checkLogPass = this.checkLogPass.toObject();
        }
        if (this.serverTime != null) {
            data.serverTime = this.serverTime.toObject();
        }
        if (this.hotDApps != null) {
            data.hotDApps = this.hotDApps.toObject();
        }
        if (this.richList != null) {
            data.richList = this.richList.toObject();
        }
        if (this.collectedFeesStat != null) {
            data.collectedFeesStat = this.collectedFeesStat.toObject();
        }
        if (this.transactionListForWallet != null) {
            data.transactionListForWallet = this.transactionListForWallet.toObject();
        }
        if (this.transactionListForWalletByIndexList != null) {
            data.transactionListForWalletByIndexList = this.transactionListForWalletByIndexList.toObject();
        }
        if (this.stakes != null) {
            data.stakes = this.stakes.toObject();
        }
        if (this.blockchainListUnfiltered != null) {
            data.blockchainListUnfiltered = this.blockchainListUnfiltered.toObject();
        }
        if (this.intervalStat != null) {
            data.intervalStat = this.intervalStat.toObject();
        }
        if (this.erc721List != null) {
            data.erc721List = this.erc721List.toObject();
        }
        if (this.erc721ListUnfiltered != null) {
            data.erc721ListUnfiltered = this.erc721ListUnfiltered.toObject();
        }
        if (this.erc721Details != null) {
            data.erc721Details = this.erc721Details.toObject();
        }
        if (this.erc721TransferList != null) {
            data.erc721TransferList = this.erc721TransferList.toObject();
        }
        if (this.erc721HolderList != null) {
            data.erc721HolderList = this.erc721HolderList.toObject();
        }
        if (this.erc721Inventory != null) {
            data.erc721Inventory = this.erc721Inventory.toObject();
        }
        if (this.erc721Update != null) {
            data.erc721Update = this.erc721Update.toObject();
        }
        if (this.erc721UpdateIcon != null) {
            data.erc721UpdateIcon = this.erc721UpdateIcon.toObject();
        }
        if (this.erc1155List != null) {
            data.erc1155List = this.erc1155List.toObject();
        }
        if (this.erc1155ListUnfiltered != null) {
            data.erc1155ListUnfiltered = this.erc1155ListUnfiltered.toObject();
        }
        if (this.erc1155Details != null) {
            data.erc1155Details = this.erc1155Details.toObject();
        }
        if (this.erc1155TransferList != null) {
            data.erc1155TransferList = this.erc1155TransferList.toObject();
        }
        if (this.erc1155HolderList != null) {
            data.erc1155HolderList = this.erc1155HolderList.toObject();
        }
        if (this.erc1155Inventory != null) {
            data.erc1155Inventory = this.erc1155Inventory.toObject();
        }
        if (this.erc1155Update != null) {
            data.erc1155Update = this.erc1155Update.toObject();
        }
        if (this.erc1155UpdateIcon != null) {
            data.erc1155UpdateIcon = this.erc1155UpdateIcon.toObject();
        }
        if (this.tokenList != null) {
            data.tokenList = this.tokenList.toObject();
        }
        if (this.tokenListUnfiltered != null) {
            data.tokenListUnfiltered = this.tokenListUnfiltered.toObject();
        }
        if (this.tokenDetails != null) {
            data.tokenDetails = this.tokenDetails.toObject();
        }
        if (this.transferList != null) {
            data.transferList = this.transferList.toObject();
        }
        if (this.holderList != null) {
            data.holderList = this.holderList.toObject();
        }
        if (this.nftDetails != null) {
            data.nftDetails = this.nftDetails.toObject();
        }
        if (this.inventoryList != null) {
            data.inventoryList = this.inventoryList.toObject();
        }
        if (this.assetData != null) {
            data.assetData = this.assetData.toObject();
        }
        if (this.assetTransactions != null) {
            data.assetTransactions = this.assetTransactions.toObject();
        }
        if (this.assetBalances != null) {
            data.assetBalances = this.assetBalances.toObject();
        }
        if (this.totalStakeAmount != null) {
            data.totalStakeAmount = this.totalStakeAmount.toObject();
        }
        if (this.bytesForChain != null) {
            data.bytesForChain = this.bytesForChain.toObject();
        }
        if (this.blockBytes != null) {
            data.blockBytes = this.blockBytes.toObject();
        }
        if (this.networkUsage != null) {
            data.networkUsage = this.networkUsage.toObject();
        }
        if (this.nativeTransfers != null) {
            data.nativeTransfers = this.nativeTransfers.toObject();
        }
        if (this.nodeDetailsUpdate != null) {
            data.nodeDetailsUpdate = this.nodeDetailsUpdate.toObject();
        }
        if (this.nodeDetails != null) {
            data.nodeDetails = this.nodeDetails.toObject();
        }
        if (this.refetchErc721Metadata != null) {
            data.refetchErc721Metadata = this.refetchErc721Metadata.toObject();
        }
        if (this.refetchErc1155Metadata != null) {
            data.refetchErc1155Metadata = this.refetchErc1155Metadata.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasSupernetList)
            writer.writeMessage(2, this.supernetList, () => this.supernetList!.serialize(writer));
        if (this.hasBlockchainList)
            writer.writeMessage(3, this.blockchainList, () => this.blockchainList!.serialize(writer));
        if (this.hasBlockList)
            writer.writeMessage(4, this.blockList, () => this.blockList!.serialize(writer));
        if (this.hasBlockListForChain)
            writer.writeMessage(5, this.blockListForChain, () => this.blockListForChain!.serialize(writer));
        if (this.hasTransactionList)
            writer.writeMessage(6, this.transactionList, () => this.transactionList!.serialize(writer));
        if (this.hasTransactionListForChain)
            writer.writeMessage(7, this.transactionListForChain, () => this.transactionListForChain!.serialize(writer));
        if (this.hasValidatorList)
            writer.writeMessage(8, this.validatorList, () => this.validatorList!.serialize(writer));
        if (this.hasSearch)
            writer.writeMessage(14, this.search, () => this.search!.serialize(writer));
        if (this.hasDelegatorList)
            writer.writeMessage(18, this.delegatorList, () => this.delegatorList!.serialize(writer));
        if (this.hasAddressData)
            writer.writeMessage(24, this.addressData, () => this.addressData!.serialize(writer));
        if (this.hasUtxoChainsBalances)
            writer.writeMessage(88, this.utxoChainsBalances, () => this.utxoChainsBalances!.serialize(writer));
        if (this.hasOverallStat)
            writer.writeMessage(31, this.overallStat, () => this.overallStat!.serialize(writer));
        if (this.hasBlockchainStat)
            writer.writeMessage(32, this.blockchainStat, () => this.blockchainStat!.serialize(writer));
        if (this.hasBlockchainYearStat)
            writer.writeMessage(33, this.blockchainYearStat, () => this.blockchainYearStat!.serialize(writer));
        if (this.hasTransactionDetails)
            writer.writeMessage(34, this.transactionDetails, () => this.transactionDetails!.serialize(writer));
        if (this.hasErc20List)
            writer.writeMessage(35, this.erc20List, () => this.erc20List!.serialize(writer));
        if (this.hasErc20ListUnfiltered)
            writer.writeMessage(80, this.erc20ListUnfiltered, () => this.erc20ListUnfiltered!.serialize(writer));
        if (this.hasErc20Details)
            writer.writeMessage(36, this.erc20Details, () => this.erc20Details!.serialize(writer));
        if (this.hasLogList)
            writer.writeMessage(40, this.logList, () => this.logList!.serialize(writer));
        if (this.hasInternalTransactionList)
            writer.writeMessage(41, this.internalTransactionList, () => this.internalTransactionList!.serialize(writer));
        if (this.hasErc20TransferList)
            writer.writeMessage(42, this.erc20TransferList, () => this.erc20TransferList!.serialize(writer));
        if (this.hasErc20HolderList)
            writer.writeMessage(43, this.erc20HolderList, () => this.erc20HolderList!.serialize(writer));
        if (this.hasSupernetUpdate)
            writer.writeMessage(44, this.supernetUpdate, () => this.supernetUpdate!.serialize(writer));
        if (this.hasBlockchainUpdate)
            writer.writeMessage(45, this.blockchainUpdate, () => this.blockchainUpdate!.serialize(writer));
        if (this.hasBlockchainUpdateIcon)
            writer.writeMessage(46, this.blockchainUpdateIcon, () => this.blockchainUpdateIcon!.serialize(writer));
        if (this.hasErc20Update)
            writer.writeMessage(47, this.erc20Update, () => this.erc20Update!.serialize(writer));
        if (this.hasErc20UpdateIcon)
            writer.writeMessage(48, this.erc20UpdateIcon, () => this.erc20UpdateIcon!.serialize(writer));
        if (this.hasCheckLogPass)
            writer.writeMessage(49, this.checkLogPass, () => this.checkLogPass!.serialize(writer));
        if (this.hasServerTime)
            writer.writeMessage(68, this.serverTime, () => this.serverTime!.serialize(writer));
        if (this.hasHotDApps)
            writer.writeMessage(69, this.hotDApps, () => this.hotDApps!.serialize(writer));
        if (this.hasRichList)
            writer.writeMessage(70, this.richList, () => this.richList!.serialize(writer));
        if (this.hasCollectedFeesStat)
            writer.writeMessage(71, this.collectedFeesStat, () => this.collectedFeesStat!.serialize(writer));
        if (this.hasTransactionListForWallet)
            writer.writeMessage(72, this.transactionListForWallet, () => this.transactionListForWallet!.serialize(writer));
        if (this.hasTransactionListForWalletByIndexList)
            writer.writeMessage(92, this.transactionListForWalletByIndexList, () => this.transactionListForWalletByIndexList!.serialize(writer));
        if (this.hasStakes)
            writer.writeMessage(76, this.stakes, () => this.stakes!.serialize(writer));
        if (this.hasBlockchainListUnfiltered)
            writer.writeMessage(79, this.blockchainListUnfiltered, () => this.blockchainListUnfiltered!.serialize(writer));
        if (this.hasIntervalStat)
            writer.writeMessage(85, this.intervalStat, () => this.intervalStat!.serialize(writer));
        if (this.hasErc721List)
            writer.writeMessage(38, this.erc721List, () => this.erc721List!.serialize(writer));
        if (this.hasErc721ListUnfiltered)
            writer.writeMessage(81, this.erc721ListUnfiltered, () => this.erc721ListUnfiltered!.serialize(writer));
        if (this.hasErc721Details)
            writer.writeMessage(39, this.erc721Details, () => this.erc721Details!.serialize(writer));
        if (this.hasErc721TransferList)
            writer.writeMessage(50, this.erc721TransferList, () => this.erc721TransferList!.serialize(writer));
        if (this.hasErc721HolderList)
            writer.writeMessage(51, this.erc721HolderList, () => this.erc721HolderList!.serialize(writer));
        if (this.hasErc721Inventory)
            writer.writeMessage(52, this.erc721Inventory, () => this.erc721Inventory!.serialize(writer));
        if (this.hasErc721Update)
            writer.writeMessage(53, this.erc721Update, () => this.erc721Update!.serialize(writer));
        if (this.hasErc721UpdateIcon)
            writer.writeMessage(54, this.erc721UpdateIcon, () => this.erc721UpdateIcon!.serialize(writer));
        if (this.hasErc1155List)
            writer.writeMessage(60, this.erc1155List, () => this.erc1155List!.serialize(writer));
        if (this.hasErc1155ListUnfiltered)
            writer.writeMessage(82, this.erc1155ListUnfiltered, () => this.erc1155ListUnfiltered!.serialize(writer));
        if (this.hasErc1155Details)
            writer.writeMessage(61, this.erc1155Details, () => this.erc1155Details!.serialize(writer));
        if (this.hasErc1155TransferList)
            writer.writeMessage(62, this.erc1155TransferList, () => this.erc1155TransferList!.serialize(writer));
        if (this.hasErc1155HolderList)
            writer.writeMessage(63, this.erc1155HolderList, () => this.erc1155HolderList!.serialize(writer));
        if (this.hasErc1155Inventory)
            writer.writeMessage(64, this.erc1155Inventory, () => this.erc1155Inventory!.serialize(writer));
        if (this.hasErc1155Update)
            writer.writeMessage(65, this.erc1155Update, () => this.erc1155Update!.serialize(writer));
        if (this.hasErc1155UpdateIcon)
            writer.writeMessage(66, this.erc1155UpdateIcon, () => this.erc1155UpdateIcon!.serialize(writer));
        if (this.hasTokenList)
            writer.writeMessage(55, this.tokenList, () => this.tokenList!.serialize(writer));
        if (this.hasTokenListUnfiltered)
            writer.writeMessage(83, this.tokenListUnfiltered, () => this.tokenListUnfiltered!.serialize(writer));
        if (this.hasTokenDetails)
            writer.writeMessage(56, this.tokenDetails, () => this.tokenDetails!.serialize(writer));
        if (this.hasTransferList)
            writer.writeMessage(57, this.transferList, () => this.transferList!.serialize(writer));
        if (this.hasHolderList)
            writer.writeMessage(58, this.holderList, () => this.holderList!.serialize(writer));
        if (this.hasNftDetails)
            writer.writeMessage(59, this.nftDetails, () => this.nftDetails!.serialize(writer));
        if (this.hasInventoryList)
            writer.writeMessage(67, this.inventoryList, () => this.inventoryList!.serialize(writer));
        if (this.hasAssetData)
            writer.writeMessage(73, this.assetData, () => this.assetData!.serialize(writer));
        if (this.hasAssetTransactions)
            writer.writeMessage(74, this.assetTransactions, () => this.assetTransactions!.serialize(writer));
        if (this.hasAssetBalances)
            writer.writeMessage(75, this.assetBalances, () => this.assetBalances!.serialize(writer));
        if (this.hasTotalStakeAmount)
            writer.writeMessage(77, this.totalStakeAmount, () => this.totalStakeAmount!.serialize(writer));
        if (this.hasBytesForChain)
            writer.writeMessage(78, this.bytesForChain, () => this.bytesForChain!.serialize(writer));
        if (this.hasBlockBytes)
            writer.writeMessage(84, this.blockBytes, () => this.blockBytes!.serialize(writer));
        if (this.hasNetworkUsage)
            writer.writeMessage(86, this.networkUsage, () => this.networkUsage!.serialize(writer));
        if (this.hasNativeTransfers)
            writer.writeMessage(87, this.nativeTransfers, () => this.nativeTransfers!.serialize(writer));
        if (this.hasNodeDetailsUpdate)
            writer.writeMessage(89, this.nodeDetailsUpdate, () => this.nodeDetailsUpdate!.serialize(writer));
        if (this.hasNodeDetails)
            writer.writeMessage(91, this.nodeDetails, () => this.nodeDetails!.serialize(writer));
        if (this.hasRefetchErc721Metadata)
            writer.writeMessage(93, this.refetchErc721Metadata, () => this.refetchErc721Metadata!.serialize(writer));
        if (this.hasRefetchErc1155Metadata)
            writer.writeMessage(94, this.refetchErc1155Metadata, () => this.refetchErc1155Metadata!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Request {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Request();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 2:
                    reader.readMessage(message.supernetList, () => message.supernetList = dependency_5.SupernetListRequest.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.blockchainList, () => message.blockchainList = dependency_5.BlockchainListRequest.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.blockList, () => message.blockList = dependency_4.BlockListRequest.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.blockListForChain, () => message.blockListForChain = dependency_4.BlockListForChainRequest.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.transactionList, () => message.transactionList = dependency_14.TransactionListRequest.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.transactionListForChain, () => message.transactionListForChain = dependency_14.TransactionListForChainRequest.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.validatorList, () => message.validatorList = dependency_15.ValidatorListRequest.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.search, () => message.search = dependency_11.SearchRequest.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.delegatorList, () => message.delegatorList = dependency_15.DelegationListRequest.deserialize(reader));
                    break;
                case 24:
                    reader.readMessage(message.addressData, () => message.addressData = dependency_2.AddressDataRequest.deserialize(reader));
                    break;
                case 88:
                    reader.readMessage(message.utxoChainsBalances, () => message.utxoChainsBalances = dependency_2.UtxoChainsBalancesRequest.deserialize(reader));
                    break;
                case 31:
                    reader.readMessage(message.overallStat, () => message.overallStat = dependency_12.OverallStatRequest.deserialize(reader));
                    break;
                case 32:
                    reader.readMessage(message.blockchainStat, () => message.blockchainStat = dependency_12.BlockchainStatRequest.deserialize(reader));
                    break;
                case 33:
                    reader.readMessage(message.blockchainYearStat, () => message.blockchainYearStat = dependency_12.BlockchainYearStatRequest.deserialize(reader));
                    break;
                case 34:
                    reader.readMessage(message.transactionDetails, () => message.transactionDetails = dependency_14.TransactionDetailsRequest.deserialize(reader));
                    break;
                case 35:
                    reader.readMessage(message.erc20List, () => message.erc20List = dependency_6.ERC20TokenListRequest.deserialize(reader));
                    break;
                case 80:
                    reader.readMessage(message.erc20ListUnfiltered, () => message.erc20ListUnfiltered = dependency_6.ERC20TokenListUnfilteredRequest.deserialize(reader));
                    break;
                case 36:
                    reader.readMessage(message.erc20Details, () => message.erc20Details = dependency_6.ERC20TokenDetailsRequest.deserialize(reader));
                    break;
                case 40:
                    reader.readMessage(message.logList, () => message.logList = dependency_9.LogListRequest.deserialize(reader));
                    break;
                case 41:
                    reader.readMessage(message.internalTransactionList, () => message.internalTransactionList = dependency_9.InternalTransactionListRequest.deserialize(reader));
                    break;
                case 42:
                    reader.readMessage(message.erc20TransferList, () => message.erc20TransferList = dependency_6.ERC20TransferListRequest.deserialize(reader));
                    break;
                case 43:
                    reader.readMessage(message.erc20HolderList, () => message.erc20HolderList = dependency_6.ERC20HolderListRequest.deserialize(reader));
                    break;
                case 44:
                    reader.readMessage(message.supernetUpdate, () => message.supernetUpdate = dependency_5.SupernetUpdateRequest.deserialize(reader));
                    break;
                case 45:
                    reader.readMessage(message.blockchainUpdate, () => message.blockchainUpdate = dependency_5.BlockchainUpdateRequest.deserialize(reader));
                    break;
                case 46:
                    reader.readMessage(message.blockchainUpdateIcon, () => message.blockchainUpdateIcon = dependency_5.BlockchainUpdateIconRequest.deserialize(reader));
                    break;
                case 47:
                    reader.readMessage(message.erc20Update, () => message.erc20Update = dependency_6.ERC20UpdateRequest.deserialize(reader));
                    break;
                case 48:
                    reader.readMessage(message.erc20UpdateIcon, () => message.erc20UpdateIcon = dependency_6.ERC20UpdateIconRequest.deserialize(reader));
                    break;
                case 49:
                    reader.readMessage(message.checkLogPass, () => message.checkLogPass = dependency_3.CheckLogPassRequest.deserialize(reader));
                    break;
                case 68:
                    reader.readMessage(message.serverTime, () => message.serverTime = dependency_10.ServerTimeRequest.deserialize(reader));
                    break;
                case 69:
                    reader.readMessage(message.hotDApps, () => message.hotDApps = dependency_12.HotDAppsRequest.deserialize(reader));
                    break;
                case 70:
                    reader.readMessage(message.richList, () => message.richList = dependency_12.RichListRequest.deserialize(reader));
                    break;
                case 71:
                    reader.readMessage(message.collectedFeesStat, () => message.collectedFeesStat = dependency_12.CollectedFeesStatRequest.deserialize(reader));
                    break;
                case 72:
                    reader.readMessage(message.transactionListForWallet, () => message.transactionListForWallet = dependency_14.TransactionListForWalletRequest.deserialize(reader));
                    break;
                case 92:
                    reader.readMessage(message.transactionListForWalletByIndexList, () => message.transactionListForWalletByIndexList = dependency_14.TransactionListForWalletByIndexListRequest.deserialize(reader));
                    break;
                case 76:
                    reader.readMessage(message.stakes, () => message.stakes = dependency_15.StakesRequest.deserialize(reader));
                    break;
                case 79:
                    reader.readMessage(message.blockchainListUnfiltered, () => message.blockchainListUnfiltered = dependency_5.BlockchainListUnfilteredRequest.deserialize(reader));
                    break;
                case 85:
                    reader.readMessage(message.intervalStat, () => message.intervalStat = dependency_12.IntervalStatRequest.deserialize(reader));
                    break;
                case 38:
                    reader.readMessage(message.erc721List, () => message.erc721List = dependency_7.ERC721TokenListRequest.deserialize(reader));
                    break;
                case 81:
                    reader.readMessage(message.erc721ListUnfiltered, () => message.erc721ListUnfiltered = dependency_7.ERC721TokenListUnfilteredRequest.deserialize(reader));
                    break;
                case 39:
                    reader.readMessage(message.erc721Details, () => message.erc721Details = dependency_7.ERC721TokenDetailsRequest.deserialize(reader));
                    break;
                case 50:
                    reader.readMessage(message.erc721TransferList, () => message.erc721TransferList = dependency_7.ERC721TransferListRequest.deserialize(reader));
                    break;
                case 51:
                    reader.readMessage(message.erc721HolderList, () => message.erc721HolderList = dependency_7.ERC721HolderListRequest.deserialize(reader));
                    break;
                case 52:
                    reader.readMessage(message.erc721Inventory, () => message.erc721Inventory = dependency_7.ERC721InventoryListRequest.deserialize(reader));
                    break;
                case 53:
                    reader.readMessage(message.erc721Update, () => message.erc721Update = dependency_7.ERC721UpdateRequest.deserialize(reader));
                    break;
                case 54:
                    reader.readMessage(message.erc721UpdateIcon, () => message.erc721UpdateIcon = dependency_7.ERC721UpdateIconRequest.deserialize(reader));
                    break;
                case 60:
                    reader.readMessage(message.erc1155List, () => message.erc1155List = dependency_8.ERC1155TokenListRequest.deserialize(reader));
                    break;
                case 82:
                    reader.readMessage(message.erc1155ListUnfiltered, () => message.erc1155ListUnfiltered = dependency_8.ERC1155TokenListUnfilteredRequest.deserialize(reader));
                    break;
                case 61:
                    reader.readMessage(message.erc1155Details, () => message.erc1155Details = dependency_8.ERC1155TokenDetailsRequest.deserialize(reader));
                    break;
                case 62:
                    reader.readMessage(message.erc1155TransferList, () => message.erc1155TransferList = dependency_8.ERC1155TransferListRequest.deserialize(reader));
                    break;
                case 63:
                    reader.readMessage(message.erc1155HolderList, () => message.erc1155HolderList = dependency_8.ERC1155HolderListRequest.deserialize(reader));
                    break;
                case 64:
                    reader.readMessage(message.erc1155Inventory, () => message.erc1155Inventory = dependency_8.ERC1155InventoryListRequest.deserialize(reader));
                    break;
                case 65:
                    reader.readMessage(message.erc1155Update, () => message.erc1155Update = dependency_8.ERC1155UpdateRequest.deserialize(reader));
                    break;
                case 66:
                    reader.readMessage(message.erc1155UpdateIcon, () => message.erc1155UpdateIcon = dependency_8.ERC1155UpdateIconRequest.deserialize(reader));
                    break;
                case 55:
                    reader.readMessage(message.tokenList, () => message.tokenList = dependency_13.TokenListRequest.deserialize(reader));
                    break;
                case 83:
                    reader.readMessage(message.tokenListUnfiltered, () => message.tokenListUnfiltered = dependency_13.TokenListUnfilteredRequest.deserialize(reader));
                    break;
                case 56:
                    reader.readMessage(message.tokenDetails, () => message.tokenDetails = dependency_13.TokenDetailsRequest.deserialize(reader));
                    break;
                case 57:
                    reader.readMessage(message.transferList, () => message.transferList = dependency_13.TransferListRequest.deserialize(reader));
                    break;
                case 58:
                    reader.readMessage(message.holderList, () => message.holderList = dependency_13.HolderListRequest.deserialize(reader));
                    break;
                case 59:
                    reader.readMessage(message.nftDetails, () => message.nftDetails = dependency_13.NFTDetailsRequest.deserialize(reader));
                    break;
                case 67:
                    reader.readMessage(message.inventoryList, () => message.inventoryList = dependency_13.InventoryListRequest.deserialize(reader));
                    break;
                case 73:
                    reader.readMessage(message.assetData, () => message.assetData = dependency_16.AssetDataRequest.deserialize(reader));
                    break;
                case 74:
                    reader.readMessage(message.assetTransactions, () => message.assetTransactions = dependency_16.AssetTransactionListRequest.deserialize(reader));
                    break;
                case 75:
                    reader.readMessage(message.assetBalances, () => message.assetBalances = dependency_16.AssetBalancesRequest.deserialize(reader));
                    break;
                case 77:
                    reader.readMessage(message.totalStakeAmount, () => message.totalStakeAmount = dependency_15.TotalStakeAmountRequest.deserialize(reader));
                    break;
                case 78:
                    reader.readMessage(message.bytesForChain, () => message.bytesForChain = dependency_17.BytesForChainRequest.deserialize(reader));
                    break;
                case 84:
                    reader.readMessage(message.blockBytes, () => message.blockBytes = dependency_17.BlockBytesRequest.deserialize(reader));
                    break;
                case 86:
                    reader.readMessage(message.networkUsage, () => message.networkUsage = dependency_12.NetworkUsageRequest.deserialize(reader));
                    break;
                case 87:
                    reader.readMessage(message.nativeTransfers, () => message.nativeTransfers = dependency_14.NativeTransferListRequest.deserialize(reader));
                    break;
                case 89:
                    reader.readMessage(message.nodeDetailsUpdate, () => message.nodeDetailsUpdate = dependency_15.NodeDetailsUpdateRequest.deserialize(reader));
                    break;
                case 91:
                    reader.readMessage(message.nodeDetails, () => message.nodeDetails = dependency_15.NodeDetailsRequest.deserialize(reader));
                    break;
                case 93:
                    reader.readMessage(message.refetchErc721Metadata, () => message.refetchErc721Metadata = dependency_7.RefetchERC721MetadataRequest.deserialize(reader));
                    break;
                case 94:
                    reader.readMessage(message.refetchErc1155Metadata, () => message.refetchErc1155Metadata = dependency_8.RefetchERC1155MetadataRequest.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Request {
        return Request.deserialize(bytes);
    }
}
export namespace Request {
    export type AsObject = {
        supernetList?: dependency_5.SupernetListRequest.AsObject;
        blockchainList?: dependency_5.BlockchainListRequest.AsObject;
        blockList?: dependency_4.BlockListRequest.AsObject;
        blockListForChain?: dependency_4.BlockListForChainRequest.AsObject;
        transactionList?: dependency_14.TransactionListRequest.AsObject;
        transactionListForChain?: dependency_14.TransactionListForChainRequest.AsObject;
        validatorList?: dependency_15.ValidatorListRequest.AsObject;
        search?: dependency_11.SearchRequest.AsObject;
        delegatorList?: dependency_15.DelegationListRequest.AsObject;
        addressData?: dependency_2.AddressDataRequest.AsObject;
        utxoChainsBalances?: dependency_2.UtxoChainsBalancesRequest.AsObject;
        overallStat?: dependency_12.OverallStatRequest.AsObject;
        blockchainStat?: dependency_12.BlockchainStatRequest.AsObject;
        blockchainYearStat?: dependency_12.BlockchainYearStatRequest.AsObject;
        transactionDetails?: dependency_14.TransactionDetailsRequest.AsObject;
        erc20List?: dependency_6.ERC20TokenListRequest.AsObject;
        erc20ListUnfiltered?: dependency_6.ERC20TokenListUnfilteredRequest.AsObject;
        erc20Details?: dependency_6.ERC20TokenDetailsRequest.AsObject;
        logList?: dependency_9.LogListRequest.AsObject;
        internalTransactionList?: dependency_9.InternalTransactionListRequest.AsObject;
        erc20TransferList?: dependency_6.ERC20TransferListRequest.AsObject;
        erc20HolderList?: dependency_6.ERC20HolderListRequest.AsObject;
        supernetUpdate?: dependency_5.SupernetUpdateRequest.AsObject;
        blockchainUpdate?: dependency_5.BlockchainUpdateRequest.AsObject;
        blockchainUpdateIcon?: dependency_5.BlockchainUpdateIconRequest.AsObject;
        erc20Update?: dependency_6.ERC20UpdateRequest.AsObject;
        erc20UpdateIcon?: dependency_6.ERC20UpdateIconRequest.AsObject;
        checkLogPass?: dependency_3.CheckLogPassRequest.AsObject;
        serverTime?: dependency_10.ServerTimeRequest.AsObject;
        hotDApps?: dependency_12.HotDAppsRequest.AsObject;
        richList?: dependency_12.RichListRequest.AsObject;
        collectedFeesStat?: dependency_12.CollectedFeesStatRequest.AsObject;
        transactionListForWallet?: dependency_14.TransactionListForWalletRequest.AsObject;
        transactionListForWalletByIndexList?: dependency_14.TransactionListForWalletByIndexListRequest.AsObject;
        stakes?: dependency_15.StakesRequest.AsObject;
        blockchainListUnfiltered?: dependency_5.BlockchainListUnfilteredRequest.AsObject;
        intervalStat?: dependency_12.IntervalStatRequest.AsObject;
        erc721List?: dependency_7.ERC721TokenListRequest.AsObject;
        erc721ListUnfiltered?: dependency_7.ERC721TokenListUnfilteredRequest.AsObject;
        erc721Details?: dependency_7.ERC721TokenDetailsRequest.AsObject;
        erc721TransferList?: dependency_7.ERC721TransferListRequest.AsObject;
        erc721HolderList?: dependency_7.ERC721HolderListRequest.AsObject;
        erc721Inventory?: dependency_7.ERC721InventoryListRequest.AsObject;
        erc721Update?: dependency_7.ERC721UpdateRequest.AsObject;
        erc721UpdateIcon?: dependency_7.ERC721UpdateIconRequest.AsObject;
        erc1155List?: dependency_8.ERC1155TokenListRequest.AsObject;
        erc1155ListUnfiltered?: dependency_8.ERC1155TokenListUnfilteredRequest.AsObject;
        erc1155Details?: dependency_8.ERC1155TokenDetailsRequest.AsObject;
        erc1155TransferList?: dependency_8.ERC1155TransferListRequest.AsObject;
        erc1155HolderList?: dependency_8.ERC1155HolderListRequest.AsObject;
        erc1155Inventory?: dependency_8.ERC1155InventoryListRequest.AsObject;
        erc1155Update?: dependency_8.ERC1155UpdateRequest.AsObject;
        erc1155UpdateIcon?: dependency_8.ERC1155UpdateIconRequest.AsObject;
        tokenList?: dependency_13.TokenListRequest.AsObject;
        tokenListUnfiltered?: dependency_13.TokenListUnfilteredRequest.AsObject;
        tokenDetails?: dependency_13.TokenDetailsRequest.AsObject;
        transferList?: dependency_13.TransferListRequest.AsObject;
        holderList?: dependency_13.HolderListRequest.AsObject;
        nftDetails?: dependency_13.NFTDetailsRequest.AsObject;
        inventoryList?: dependency_13.InventoryListRequest.AsObject;
        assetData?: dependency_16.AssetDataRequest.AsObject;
        assetTransactions?: dependency_16.AssetTransactionListRequest.AsObject;
        assetBalances?: dependency_16.AssetBalancesRequest.AsObject;
        totalStakeAmount?: dependency_15.TotalStakeAmountRequest.AsObject;
        bytesForChain?: dependency_17.BytesForChainRequest.AsObject;
        blockBytes?: dependency_17.BlockBytesRequest.AsObject;
        networkUsage?: dependency_12.NetworkUsageRequest.AsObject;
        nativeTransfers?: dependency_14.NativeTransferListRequest.AsObject;
        nodeDetailsUpdate?: dependency_15.NodeDetailsUpdateRequest.AsObject;
        nodeDetails?: dependency_15.NodeDetailsRequest.AsObject;
        refetchErc721Metadata?: dependency_7.RefetchERC721MetadataRequest.AsObject;
        refetchErc1155Metadata?: dependency_8.RefetchERC1155MetadataRequest.AsObject;
    };
    export type AsObjectPartial = {
        supernetList?: dependency_5.SupernetListRequest.AsObjectPartial;
        blockchainList?: dependency_5.BlockchainListRequest.AsObjectPartial;
        blockList?: dependency_4.BlockListRequest.AsObjectPartial;
        blockListForChain?: dependency_4.BlockListForChainRequest.AsObjectPartial;
        transactionList?: dependency_14.TransactionListRequest.AsObjectPartial;
        transactionListForChain?: dependency_14.TransactionListForChainRequest.AsObjectPartial;
        validatorList?: dependency_15.ValidatorListRequest.AsObjectPartial;
        search?: dependency_11.SearchRequest.AsObjectPartial;
        delegatorList?: dependency_15.DelegationListRequest.AsObjectPartial;
        addressData?: dependency_2.AddressDataRequest.AsObjectPartial;
        utxoChainsBalances?: dependency_2.UtxoChainsBalancesRequest.AsObjectPartial;
        overallStat?: dependency_12.OverallStatRequest.AsObjectPartial;
        blockchainStat?: dependency_12.BlockchainStatRequest.AsObjectPartial;
        blockchainYearStat?: dependency_12.BlockchainYearStatRequest.AsObjectPartial;
        transactionDetails?: dependency_14.TransactionDetailsRequest.AsObjectPartial;
        erc20List?: dependency_6.ERC20TokenListRequest.AsObjectPartial;
        erc20ListUnfiltered?: dependency_6.ERC20TokenListUnfilteredRequest.AsObjectPartial;
        erc20Details?: dependency_6.ERC20TokenDetailsRequest.AsObjectPartial;
        logList?: dependency_9.LogListRequest.AsObjectPartial;
        internalTransactionList?: dependency_9.InternalTransactionListRequest.AsObjectPartial;
        erc20TransferList?: dependency_6.ERC20TransferListRequest.AsObjectPartial;
        erc20HolderList?: dependency_6.ERC20HolderListRequest.AsObjectPartial;
        supernetUpdate?: dependency_5.SupernetUpdateRequest.AsObjectPartial;
        blockchainUpdate?: dependency_5.BlockchainUpdateRequest.AsObjectPartial;
        blockchainUpdateIcon?: dependency_5.BlockchainUpdateIconRequest.AsObjectPartial;
        erc20Update?: dependency_6.ERC20UpdateRequest.AsObjectPartial;
        erc20UpdateIcon?: dependency_6.ERC20UpdateIconRequest.AsObjectPartial;
        checkLogPass?: dependency_3.CheckLogPassRequest.AsObjectPartial;
        serverTime?: dependency_10.ServerTimeRequest.AsObjectPartial;
        hotDApps?: dependency_12.HotDAppsRequest.AsObjectPartial;
        richList?: dependency_12.RichListRequest.AsObjectPartial;
        collectedFeesStat?: dependency_12.CollectedFeesStatRequest.AsObjectPartial;
        transactionListForWallet?: dependency_14.TransactionListForWalletRequest.AsObjectPartial;
        transactionListForWalletByIndexList?: dependency_14.TransactionListForWalletByIndexListRequest.AsObjectPartial;
        stakes?: dependency_15.StakesRequest.AsObjectPartial;
        blockchainListUnfiltered?: dependency_5.BlockchainListUnfilteredRequest.AsObjectPartial;
        intervalStat?: dependency_12.IntervalStatRequest.AsObjectPartial;
        erc721List?: dependency_7.ERC721TokenListRequest.AsObjectPartial;
        erc721ListUnfiltered?: dependency_7.ERC721TokenListUnfilteredRequest.AsObjectPartial;
        erc721Details?: dependency_7.ERC721TokenDetailsRequest.AsObjectPartial;
        erc721TransferList?: dependency_7.ERC721TransferListRequest.AsObjectPartial;
        erc721HolderList?: dependency_7.ERC721HolderListRequest.AsObjectPartial;
        erc721Inventory?: dependency_7.ERC721InventoryListRequest.AsObjectPartial;
        erc721Update?: dependency_7.ERC721UpdateRequest.AsObjectPartial;
        erc721UpdateIcon?: dependency_7.ERC721UpdateIconRequest.AsObjectPartial;
        erc1155List?: dependency_8.ERC1155TokenListRequest.AsObjectPartial;
        erc1155ListUnfiltered?: dependency_8.ERC1155TokenListUnfilteredRequest.AsObjectPartial;
        erc1155Details?: dependency_8.ERC1155TokenDetailsRequest.AsObjectPartial;
        erc1155TransferList?: dependency_8.ERC1155TransferListRequest.AsObjectPartial;
        erc1155HolderList?: dependency_8.ERC1155HolderListRequest.AsObjectPartial;
        erc1155Inventory?: dependency_8.ERC1155InventoryListRequest.AsObjectPartial;
        erc1155Update?: dependency_8.ERC1155UpdateRequest.AsObjectPartial;
        erc1155UpdateIcon?: dependency_8.ERC1155UpdateIconRequest.AsObjectPartial;
        tokenList?: dependency_13.TokenListRequest.AsObjectPartial;
        tokenListUnfiltered?: dependency_13.TokenListUnfilteredRequest.AsObjectPartial;
        tokenDetails?: dependency_13.TokenDetailsRequest.AsObjectPartial;
        transferList?: dependency_13.TransferListRequest.AsObjectPartial;
        holderList?: dependency_13.HolderListRequest.AsObjectPartial;
        nftDetails?: dependency_13.NFTDetailsRequest.AsObjectPartial;
        inventoryList?: dependency_13.InventoryListRequest.AsObjectPartial;
        assetData?: dependency_16.AssetDataRequest.AsObjectPartial;
        assetTransactions?: dependency_16.AssetTransactionListRequest.AsObjectPartial;
        assetBalances?: dependency_16.AssetBalancesRequest.AsObjectPartial;
        totalStakeAmount?: dependency_15.TotalStakeAmountRequest.AsObjectPartial;
        bytesForChain?: dependency_17.BytesForChainRequest.AsObjectPartial;
        blockBytes?: dependency_17.BlockBytesRequest.AsObjectPartial;
        networkUsage?: dependency_12.NetworkUsageRequest.AsObjectPartial;
        nativeTransfers?: dependency_14.NativeTransferListRequest.AsObjectPartial;
        nodeDetailsUpdate?: dependency_15.NodeDetailsUpdateRequest.AsObjectPartial;
        nodeDetails?: dependency_15.NodeDetailsRequest.AsObjectPartial;
        refetchErc721Metadata?: dependency_7.RefetchERC721MetadataRequest.AsObjectPartial;
        refetchErc1155Metadata?: dependency_8.RefetchERC1155MetadataRequest.AsObjectPartial;
    };
}
export class Response extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 12, 2, 3, 4, 6, 8, 14, 18, 24, 88, 31, 32, 33, 34, 35, 36, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 68, 69, 70, 71, 72, 92, 76, 81, 38, 39, 50, 51, 52, 53, 54, 60, 61, 62, 63, 64, 65, 66, 55, 56, 57, 58, 59, 67, 73, 74, 75, 77, 78, 79, 80, 82, 87, 89, 90]];
    constructor(data?: any[] | ({} & (({
        error?: dependency_10.ErrorResponse;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: dependency_10.EmptyMessage;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: dependency_5.SupernetListResponse;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: dependency_5.BlockchainListResponse;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: dependency_4.BlockListResponse;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: dependency_14.TransactionListResponse;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: dependency_15.ValidatorListResponse;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: dependency_11.SearchResponse;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: dependency_15.DelegationListResponse;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: dependency_2.AddressDataResponse;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: dependency_2.UtxoChainsBalancesResponse;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: dependency_12.OverallStatResponse;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: dependency_12.BlockchainStatResponse;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: dependency_12.BlockchainYearStatResponse;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: dependency_14.TransactionDetailsResponse;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: dependency_6.ERC20TokenListResponse;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: dependency_6.ERC20TokenDetailsResponse;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: dependency_9.LogListResponse;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: dependency_9.InternalTransactionListResponse;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: dependency_6.ERC20TransferListResponse;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: dependency_6.ERC20HolderListResponse;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: dependency_5.SupernetUpdateResponse;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: dependency_5.BlockchainUpdateResponse;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: dependency_5.BlockchainUpdateIconResponse;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: dependency_6.ERC20UpdateResponse;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: dependency_6.ERC20UpdateIconResponse;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: dependency_3.CheckLogPassResponse;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: dependency_10.ServerTimeResponse;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: dependency_12.HotDAppsResponse;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: dependency_12.RichListResponse;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: dependency_12.CollectedFeesStatResponse;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: dependency_14.TransactionListForWalletResponse;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: dependency_14.TransactionListForWalletByIndexListResponse;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: dependency_15.StakesResponse;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: dependency_12.IntervalStatResponse;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: dependency_7.ERC721TokenListResponse;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: dependency_7.ERC721TokenDetailsResponse;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: dependency_7.ERC721TransferListResponse;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: dependency_7.ERC721HolderListResponse;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: dependency_7.ERC721InventoryListResponse;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: dependency_7.ERC721UpdateResponse;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: dependency_7.ERC721UpdateIconResponse;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: dependency_8.ERC1155TokenListResponse;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: dependency_8.ERC1155TokenDetailsResponse;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: dependency_8.ERC1155TransferListResponse;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: dependency_8.ERC1155HolderListResponse;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: dependency_8.ERC1155InventoryListResponse;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: dependency_8.ERC1155UpdateResponse;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: dependency_8.ERC1155UpdateIconResponse;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: dependency_13.TokenListResponse;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: dependency_13.TokenDetailsResponse;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: dependency_13.TransferListResponse;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: dependency_13.HolderListResponse;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: dependency_13.NFTDetailsResponse;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: dependency_13.InventoryListResponse;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: dependency_16.AssetDataResponse;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: dependency_16.AssetTransactionListResponse;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: dependency_16.AssetBalancesResponse;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: dependency_15.TotalStakeAmountResponse;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: dependency_17.BytesForChainResponse;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: dependency_17.BlockBytesResponse;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: dependency_2.EtherscanLikeResponse;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: dependency_12.NetworkUsageResponse;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: dependency_14.NativeTransferListResponse;
        nodeDetailsUpdate?: never;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: dependency_15.NodeDetailsUpdateResponse;
        nodeDetails?: never;
    } | {
        error?: never;
        empty?: never;
        supernetList?: never;
        blockchainList?: never;
        blockList?: never;
        transactionList?: never;
        validatorList?: never;
        search?: never;
        delegatorList?: never;
        addressData?: never;
        utxoChainsBalances?: never;
        overallStat?: never;
        blockchainStat?: never;
        blockchainYearStat?: never;
        transactionDetails?: never;
        erc20List?: never;
        erc20Details?: never;
        logList?: never;
        internalTransactionList?: never;
        erc20TransferList?: never;
        erc20HolderList?: never;
        supernetUpdate?: never;
        blockchainUpdate?: never;
        blockchainUpdateIcon?: never;
        erc20Update?: never;
        erc20UpdateIcon?: never;
        checkLogPass?: never;
        serverTime?: never;
        hotDApps?: never;
        richList?: never;
        collectedFeesStat?: never;
        transactionListForWallet?: never;
        transactionListForWalletByIndexList?: never;
        stakes?: never;
        internalStat?: never;
        erc721List?: never;
        erc721Details?: never;
        erc721TransferList?: never;
        erc721HolderList?: never;
        erc721Inventory?: never;
        erc721Update?: never;
        erc721UpdateIcon?: never;
        erc1155List?: never;
        erc1155Details?: never;
        erc1155TransferList?: never;
        erc1155HolderList?: never;
        erc1155Inventory?: never;
        erc1155Update?: never;
        erc1155UpdateIcon?: never;
        tokenList?: never;
        tokenDetails?: never;
        transferList?: never;
        holderList?: never;
        nftDetails?: never;
        inventoryList?: never;
        assetData?: never;
        assetTransactions?: never;
        assetBalances?: never;
        totalStakeAmount?: never;
        bytesForChain?: never;
        blockBytes?: never;
        verification?: never;
        networkUsage?: never;
        nativeTransfers?: never;
        nodeDetailsUpdate?: never;
        nodeDetails?: dependency_15.NodeDetailsResponse;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("error" in data && data.error != undefined) {
                this.error = data.error;
            }
            if ("empty" in data && data.empty != undefined) {
                this.empty = data.empty;
            }
            if ("supernetList" in data && data.supernetList != undefined) {
                this.supernetList = data.supernetList;
            }
            if ("blockchainList" in data && data.blockchainList != undefined) {
                this.blockchainList = data.blockchainList;
            }
            if ("blockList" in data && data.blockList != undefined) {
                this.blockList = data.blockList;
            }
            if ("transactionList" in data && data.transactionList != undefined) {
                this.transactionList = data.transactionList;
            }
            if ("validatorList" in data && data.validatorList != undefined) {
                this.validatorList = data.validatorList;
            }
            if ("search" in data && data.search != undefined) {
                this.search = data.search;
            }
            if ("delegatorList" in data && data.delegatorList != undefined) {
                this.delegatorList = data.delegatorList;
            }
            if ("addressData" in data && data.addressData != undefined) {
                this.addressData = data.addressData;
            }
            if ("utxoChainsBalances" in data && data.utxoChainsBalances != undefined) {
                this.utxoChainsBalances = data.utxoChainsBalances;
            }
            if ("overallStat" in data && data.overallStat != undefined) {
                this.overallStat = data.overallStat;
            }
            if ("blockchainStat" in data && data.blockchainStat != undefined) {
                this.blockchainStat = data.blockchainStat;
            }
            if ("blockchainYearStat" in data && data.blockchainYearStat != undefined) {
                this.blockchainYearStat = data.blockchainYearStat;
            }
            if ("transactionDetails" in data && data.transactionDetails != undefined) {
                this.transactionDetails = data.transactionDetails;
            }
            if ("erc20List" in data && data.erc20List != undefined) {
                this.erc20List = data.erc20List;
            }
            if ("erc20Details" in data && data.erc20Details != undefined) {
                this.erc20Details = data.erc20Details;
            }
            if ("logList" in data && data.logList != undefined) {
                this.logList = data.logList;
            }
            if ("internalTransactionList" in data && data.internalTransactionList != undefined) {
                this.internalTransactionList = data.internalTransactionList;
            }
            if ("erc20TransferList" in data && data.erc20TransferList != undefined) {
                this.erc20TransferList = data.erc20TransferList;
            }
            if ("erc20HolderList" in data && data.erc20HolderList != undefined) {
                this.erc20HolderList = data.erc20HolderList;
            }
            if ("supernetUpdate" in data && data.supernetUpdate != undefined) {
                this.supernetUpdate = data.supernetUpdate;
            }
            if ("blockchainUpdate" in data && data.blockchainUpdate != undefined) {
                this.blockchainUpdate = data.blockchainUpdate;
            }
            if ("blockchainUpdateIcon" in data && data.blockchainUpdateIcon != undefined) {
                this.blockchainUpdateIcon = data.blockchainUpdateIcon;
            }
            if ("erc20Update" in data && data.erc20Update != undefined) {
                this.erc20Update = data.erc20Update;
            }
            if ("erc20UpdateIcon" in data && data.erc20UpdateIcon != undefined) {
                this.erc20UpdateIcon = data.erc20UpdateIcon;
            }
            if ("checkLogPass" in data && data.checkLogPass != undefined) {
                this.checkLogPass = data.checkLogPass;
            }
            if ("serverTime" in data && data.serverTime != undefined) {
                this.serverTime = data.serverTime;
            }
            if ("hotDApps" in data && data.hotDApps != undefined) {
                this.hotDApps = data.hotDApps;
            }
            if ("richList" in data && data.richList != undefined) {
                this.richList = data.richList;
            }
            if ("collectedFeesStat" in data && data.collectedFeesStat != undefined) {
                this.collectedFeesStat = data.collectedFeesStat;
            }
            if ("transactionListForWallet" in data && data.transactionListForWallet != undefined) {
                this.transactionListForWallet = data.transactionListForWallet;
            }
            if ("transactionListForWalletByIndexList" in data && data.transactionListForWalletByIndexList != undefined) {
                this.transactionListForWalletByIndexList = data.transactionListForWalletByIndexList;
            }
            if ("stakes" in data && data.stakes != undefined) {
                this.stakes = data.stakes;
            }
            if ("internalStat" in data && data.internalStat != undefined) {
                this.internalStat = data.internalStat;
            }
            if ("erc721List" in data && data.erc721List != undefined) {
                this.erc721List = data.erc721List;
            }
            if ("erc721Details" in data && data.erc721Details != undefined) {
                this.erc721Details = data.erc721Details;
            }
            if ("erc721TransferList" in data && data.erc721TransferList != undefined) {
                this.erc721TransferList = data.erc721TransferList;
            }
            if ("erc721HolderList" in data && data.erc721HolderList != undefined) {
                this.erc721HolderList = data.erc721HolderList;
            }
            if ("erc721Inventory" in data && data.erc721Inventory != undefined) {
                this.erc721Inventory = data.erc721Inventory;
            }
            if ("erc721Update" in data && data.erc721Update != undefined) {
                this.erc721Update = data.erc721Update;
            }
            if ("erc721UpdateIcon" in data && data.erc721UpdateIcon != undefined) {
                this.erc721UpdateIcon = data.erc721UpdateIcon;
            }
            if ("erc1155List" in data && data.erc1155List != undefined) {
                this.erc1155List = data.erc1155List;
            }
            if ("erc1155Details" in data && data.erc1155Details != undefined) {
                this.erc1155Details = data.erc1155Details;
            }
            if ("erc1155TransferList" in data && data.erc1155TransferList != undefined) {
                this.erc1155TransferList = data.erc1155TransferList;
            }
            if ("erc1155HolderList" in data && data.erc1155HolderList != undefined) {
                this.erc1155HolderList = data.erc1155HolderList;
            }
            if ("erc1155Inventory" in data && data.erc1155Inventory != undefined) {
                this.erc1155Inventory = data.erc1155Inventory;
            }
            if ("erc1155Update" in data && data.erc1155Update != undefined) {
                this.erc1155Update = data.erc1155Update;
            }
            if ("erc1155UpdateIcon" in data && data.erc1155UpdateIcon != undefined) {
                this.erc1155UpdateIcon = data.erc1155UpdateIcon;
            }
            if ("tokenList" in data && data.tokenList != undefined) {
                this.tokenList = data.tokenList;
            }
            if ("tokenDetails" in data && data.tokenDetails != undefined) {
                this.tokenDetails = data.tokenDetails;
            }
            if ("transferList" in data && data.transferList != undefined) {
                this.transferList = data.transferList;
            }
            if ("holderList" in data && data.holderList != undefined) {
                this.holderList = data.holderList;
            }
            if ("nftDetails" in data && data.nftDetails != undefined) {
                this.nftDetails = data.nftDetails;
            }
            if ("inventoryList" in data && data.inventoryList != undefined) {
                this.inventoryList = data.inventoryList;
            }
            if ("assetData" in data && data.assetData != undefined) {
                this.assetData = data.assetData;
            }
            if ("assetTransactions" in data && data.assetTransactions != undefined) {
                this.assetTransactions = data.assetTransactions;
            }
            if ("assetBalances" in data && data.assetBalances != undefined) {
                this.assetBalances = data.assetBalances;
            }
            if ("totalStakeAmount" in data && data.totalStakeAmount != undefined) {
                this.totalStakeAmount = data.totalStakeAmount;
            }
            if ("bytesForChain" in data && data.bytesForChain != undefined) {
                this.bytesForChain = data.bytesForChain;
            }
            if ("blockBytes" in data && data.blockBytes != undefined) {
                this.blockBytes = data.blockBytes;
            }
            if ("verification" in data && data.verification != undefined) {
                this.verification = data.verification;
            }
            if ("networkUsage" in data && data.networkUsage != undefined) {
                this.networkUsage = data.networkUsage;
            }
            if ("nativeTransfers" in data && data.nativeTransfers != undefined) {
                this.nativeTransfers = data.nativeTransfers;
            }
            if ("nodeDetailsUpdate" in data && data.nodeDetailsUpdate != undefined) {
                this.nodeDetailsUpdate = data.nodeDetailsUpdate;
            }
            if ("nodeDetails" in data && data.nodeDetails != undefined) {
                this.nodeDetails = data.nodeDetails;
            }
        }
    }
    get error() {
        return pb_1.Message.getWrapperField(this, dependency_10.ErrorResponse, 1) as dependency_10.ErrorResponse | undefined;
    }
    set error(value: dependency_10.ErrorResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasError() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearError(): void {
        this.error = undefined!;
    }
    get empty() {
        return pb_1.Message.getWrapperField(this, dependency_10.EmptyMessage, 12) as dependency_10.EmptyMessage | undefined;
    }
    set empty(value: dependency_10.EmptyMessage | undefined) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0]!, value);
    }
    get hasEmpty() {
        return pb_1.Message.getField(this, 12) != null;
    }
    clearEmpty(): void {
        this.empty = undefined!;
    }
    get supernetList() {
        return pb_1.Message.getWrapperField(this, dependency_5.SupernetListResponse, 2) as dependency_5.SupernetListResponse | undefined;
    }
    set supernetList(value: dependency_5.SupernetListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasSupernetList() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearSupernetList(): void {
        this.supernetList = undefined!;
    }
    get blockchainList() {
        return pb_1.Message.getWrapperField(this, dependency_5.BlockchainListResponse, 3) as dependency_5.BlockchainListResponse | undefined;
    }
    set blockchainList(value: dependency_5.BlockchainListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainList() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearBlockchainList(): void {
        this.blockchainList = undefined!;
    }
    get blockList() {
        return pb_1.Message.getWrapperField(this, dependency_4.BlockListResponse, 4) as dependency_4.BlockListResponse | undefined;
    }
    set blockList(value: dependency_4.BlockListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasBlockList() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearBlockList(): void {
        this.blockList = undefined!;
    }
    get transactionList() {
        return pb_1.Message.getWrapperField(this, dependency_14.TransactionListResponse, 6) as dependency_14.TransactionListResponse | undefined;
    }
    set transactionList(value: dependency_14.TransactionListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasTransactionList() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearTransactionList(): void {
        this.transactionList = undefined!;
    }
    get validatorList() {
        return pb_1.Message.getWrapperField(this, dependency_15.ValidatorListResponse, 8) as dependency_15.ValidatorListResponse | undefined;
    }
    set validatorList(value: dependency_15.ValidatorListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0]!, value);
    }
    get hasValidatorList() {
        return pb_1.Message.getField(this, 8) != null;
    }
    clearValidatorList(): void {
        this.validatorList = undefined!;
    }
    get search() {
        return pb_1.Message.getWrapperField(this, dependency_11.SearchResponse, 14) as dependency_11.SearchResponse | undefined;
    }
    set search(value: dependency_11.SearchResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 14, this.#one_of_decls[0]!, value);
    }
    get hasSearch() {
        return pb_1.Message.getField(this, 14) != null;
    }
    clearSearch(): void {
        this.search = undefined!;
    }
    get delegatorList() {
        return pb_1.Message.getWrapperField(this, dependency_15.DelegationListResponse, 18) as dependency_15.DelegationListResponse | undefined;
    }
    set delegatorList(value: dependency_15.DelegationListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0]!, value);
    }
    get hasDelegatorList() {
        return pb_1.Message.getField(this, 18) != null;
    }
    clearDelegatorList(): void {
        this.delegatorList = undefined!;
    }
    get addressData() {
        return pb_1.Message.getWrapperField(this, dependency_2.AddressDataResponse, 24) as dependency_2.AddressDataResponse | undefined;
    }
    set addressData(value: dependency_2.AddressDataResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 24, this.#one_of_decls[0]!, value);
    }
    get hasAddressData() {
        return pb_1.Message.getField(this, 24) != null;
    }
    clearAddressData(): void {
        this.addressData = undefined!;
    }
    get utxoChainsBalances() {
        return pb_1.Message.getWrapperField(this, dependency_2.UtxoChainsBalancesResponse, 88) as dependency_2.UtxoChainsBalancesResponse | undefined;
    }
    set utxoChainsBalances(value: dependency_2.UtxoChainsBalancesResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 88, this.#one_of_decls[0]!, value);
    }
    get hasUtxoChainsBalances() {
        return pb_1.Message.getField(this, 88) != null;
    }
    clearUtxoChainsBalances(): void {
        this.utxoChainsBalances = undefined!;
    }
    get overallStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.OverallStatResponse, 31) as dependency_12.OverallStatResponse | undefined;
    }
    set overallStat(value: dependency_12.OverallStatResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 31, this.#one_of_decls[0]!, value);
    }
    get hasOverallStat() {
        return pb_1.Message.getField(this, 31) != null;
    }
    clearOverallStat(): void {
        this.overallStat = undefined!;
    }
    get blockchainStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.BlockchainStatResponse, 32) as dependency_12.BlockchainStatResponse | undefined;
    }
    set blockchainStat(value: dependency_12.BlockchainStatResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 32, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainStat() {
        return pb_1.Message.getField(this, 32) != null;
    }
    clearBlockchainStat(): void {
        this.blockchainStat = undefined!;
    }
    get blockchainYearStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.BlockchainYearStatResponse, 33) as dependency_12.BlockchainYearStatResponse | undefined;
    }
    set blockchainYearStat(value: dependency_12.BlockchainYearStatResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 33, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainYearStat() {
        return pb_1.Message.getField(this, 33) != null;
    }
    clearBlockchainYearStat(): void {
        this.blockchainYearStat = undefined!;
    }
    get transactionDetails() {
        return pb_1.Message.getWrapperField(this, dependency_14.TransactionDetailsResponse, 34) as dependency_14.TransactionDetailsResponse | undefined;
    }
    set transactionDetails(value: dependency_14.TransactionDetailsResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 34, this.#one_of_decls[0]!, value);
    }
    get hasTransactionDetails() {
        return pb_1.Message.getField(this, 34) != null;
    }
    clearTransactionDetails(): void {
        this.transactionDetails = undefined!;
    }
    get erc20List() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20TokenListResponse, 35) as dependency_6.ERC20TokenListResponse | undefined;
    }
    set erc20List(value: dependency_6.ERC20TokenListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 35, this.#one_of_decls[0]!, value);
    }
    get hasErc20List() {
        return pb_1.Message.getField(this, 35) != null;
    }
    clearErc20List(): void {
        this.erc20List = undefined!;
    }
    get erc20Details() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20TokenDetailsResponse, 36) as dependency_6.ERC20TokenDetailsResponse | undefined;
    }
    set erc20Details(value: dependency_6.ERC20TokenDetailsResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 36, this.#one_of_decls[0]!, value);
    }
    get hasErc20Details() {
        return pb_1.Message.getField(this, 36) != null;
    }
    clearErc20Details(): void {
        this.erc20Details = undefined!;
    }
    get logList() {
        return pb_1.Message.getWrapperField(this, dependency_9.LogListResponse, 40) as dependency_9.LogListResponse | undefined;
    }
    set logList(value: dependency_9.LogListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 40, this.#one_of_decls[0]!, value);
    }
    get hasLogList() {
        return pb_1.Message.getField(this, 40) != null;
    }
    clearLogList(): void {
        this.logList = undefined!;
    }
    get internalTransactionList() {
        return pb_1.Message.getWrapperField(this, dependency_9.InternalTransactionListResponse, 41) as dependency_9.InternalTransactionListResponse | undefined;
    }
    set internalTransactionList(value: dependency_9.InternalTransactionListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 41, this.#one_of_decls[0]!, value);
    }
    get hasInternalTransactionList() {
        return pb_1.Message.getField(this, 41) != null;
    }
    clearInternalTransactionList(): void {
        this.internalTransactionList = undefined!;
    }
    get erc20TransferList() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20TransferListResponse, 42) as dependency_6.ERC20TransferListResponse | undefined;
    }
    set erc20TransferList(value: dependency_6.ERC20TransferListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 42, this.#one_of_decls[0]!, value);
    }
    get hasErc20TransferList() {
        return pb_1.Message.getField(this, 42) != null;
    }
    clearErc20TransferList(): void {
        this.erc20TransferList = undefined!;
    }
    get erc20HolderList() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20HolderListResponse, 43) as dependency_6.ERC20HolderListResponse | undefined;
    }
    set erc20HolderList(value: dependency_6.ERC20HolderListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 43, this.#one_of_decls[0]!, value);
    }
    get hasErc20HolderList() {
        return pb_1.Message.getField(this, 43) != null;
    }
    clearErc20HolderList(): void {
        this.erc20HolderList = undefined!;
    }
    get supernetUpdate() {
        return pb_1.Message.getWrapperField(this, dependency_5.SupernetUpdateResponse, 44) as dependency_5.SupernetUpdateResponse | undefined;
    }
    set supernetUpdate(value: dependency_5.SupernetUpdateResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 44, this.#one_of_decls[0]!, value);
    }
    get hasSupernetUpdate() {
        return pb_1.Message.getField(this, 44) != null;
    }
    clearSupernetUpdate(): void {
        this.supernetUpdate = undefined!;
    }
    get blockchainUpdate() {
        return pb_1.Message.getWrapperField(this, dependency_5.BlockchainUpdateResponse, 45) as dependency_5.BlockchainUpdateResponse | undefined;
    }
    set blockchainUpdate(value: dependency_5.BlockchainUpdateResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 45, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainUpdate() {
        return pb_1.Message.getField(this, 45) != null;
    }
    clearBlockchainUpdate(): void {
        this.blockchainUpdate = undefined!;
    }
    get blockchainUpdateIcon() {
        return pb_1.Message.getWrapperField(this, dependency_5.BlockchainUpdateIconResponse, 46) as dependency_5.BlockchainUpdateIconResponse | undefined;
    }
    set blockchainUpdateIcon(value: dependency_5.BlockchainUpdateIconResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 46, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainUpdateIcon() {
        return pb_1.Message.getField(this, 46) != null;
    }
    clearBlockchainUpdateIcon(): void {
        this.blockchainUpdateIcon = undefined!;
    }
    get erc20Update() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20UpdateResponse, 47) as dependency_6.ERC20UpdateResponse | undefined;
    }
    set erc20Update(value: dependency_6.ERC20UpdateResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 47, this.#one_of_decls[0]!, value);
    }
    get hasErc20Update() {
        return pb_1.Message.getField(this, 47) != null;
    }
    clearErc20Update(): void {
        this.erc20Update = undefined!;
    }
    get erc20UpdateIcon() {
        return pb_1.Message.getWrapperField(this, dependency_6.ERC20UpdateIconResponse, 48) as dependency_6.ERC20UpdateIconResponse | undefined;
    }
    set erc20UpdateIcon(value: dependency_6.ERC20UpdateIconResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 48, this.#one_of_decls[0]!, value);
    }
    get hasErc20UpdateIcon() {
        return pb_1.Message.getField(this, 48) != null;
    }
    clearErc20UpdateIcon(): void {
        this.erc20UpdateIcon = undefined!;
    }
    get checkLogPass() {
        return pb_1.Message.getWrapperField(this, dependency_3.CheckLogPassResponse, 49) as dependency_3.CheckLogPassResponse | undefined;
    }
    set checkLogPass(value: dependency_3.CheckLogPassResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 49, this.#one_of_decls[0]!, value);
    }
    get hasCheckLogPass() {
        return pb_1.Message.getField(this, 49) != null;
    }
    clearCheckLogPass(): void {
        this.checkLogPass = undefined!;
    }
    get serverTime() {
        return pb_1.Message.getWrapperField(this, dependency_10.ServerTimeResponse, 68) as dependency_10.ServerTimeResponse | undefined;
    }
    set serverTime(value: dependency_10.ServerTimeResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 68, this.#one_of_decls[0]!, value);
    }
    get hasServerTime() {
        return pb_1.Message.getField(this, 68) != null;
    }
    clearServerTime(): void {
        this.serverTime = undefined!;
    }
    get hotDApps() {
        return pb_1.Message.getWrapperField(this, dependency_12.HotDAppsResponse, 69) as dependency_12.HotDAppsResponse | undefined;
    }
    set hotDApps(value: dependency_12.HotDAppsResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 69, this.#one_of_decls[0]!, value);
    }
    get hasHotDApps() {
        return pb_1.Message.getField(this, 69) != null;
    }
    clearHotDApps(): void {
        this.hotDApps = undefined!;
    }
    get richList() {
        return pb_1.Message.getWrapperField(this, dependency_12.RichListResponse, 70) as dependency_12.RichListResponse | undefined;
    }
    set richList(value: dependency_12.RichListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 70, this.#one_of_decls[0]!, value);
    }
    get hasRichList() {
        return pb_1.Message.getField(this, 70) != null;
    }
    clearRichList(): void {
        this.richList = undefined!;
    }
    get collectedFeesStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.CollectedFeesStatResponse, 71) as dependency_12.CollectedFeesStatResponse | undefined;
    }
    set collectedFeesStat(value: dependency_12.CollectedFeesStatResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 71, this.#one_of_decls[0]!, value);
    }
    get hasCollectedFeesStat() {
        return pb_1.Message.getField(this, 71) != null;
    }
    clearCollectedFeesStat(): void {
        this.collectedFeesStat = undefined!;
    }
    get transactionListForWallet() {
        return pb_1.Message.getWrapperField(this, dependency_14.TransactionListForWalletResponse, 72) as dependency_14.TransactionListForWalletResponse | undefined;
    }
    set transactionListForWallet(value: dependency_14.TransactionListForWalletResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 72, this.#one_of_decls[0]!, value);
    }
    get hasTransactionListForWallet() {
        return pb_1.Message.getField(this, 72) != null;
    }
    clearTransactionListForWallet(): void {
        this.transactionListForWallet = undefined!;
    }
    get transactionListForWalletByIndexList() {
        return pb_1.Message.getWrapperField(this, dependency_14.TransactionListForWalletByIndexListResponse, 92) as dependency_14.TransactionListForWalletByIndexListResponse | undefined;
    }
    set transactionListForWalletByIndexList(value: dependency_14.TransactionListForWalletByIndexListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 92, this.#one_of_decls[0]!, value);
    }
    get hasTransactionListForWalletByIndexList() {
        return pb_1.Message.getField(this, 92) != null;
    }
    clearTransactionListForWalletByIndexList(): void {
        this.transactionListForWalletByIndexList = undefined!;
    }
    get stakes() {
        return pb_1.Message.getWrapperField(this, dependency_15.StakesResponse, 76) as dependency_15.StakesResponse | undefined;
    }
    set stakes(value: dependency_15.StakesResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 76, this.#one_of_decls[0]!, value);
    }
    get hasStakes() {
        return pb_1.Message.getField(this, 76) != null;
    }
    clearStakes(): void {
        this.stakes = undefined!;
    }
    get internalStat() {
        return pb_1.Message.getWrapperField(this, dependency_12.IntervalStatResponse, 81) as dependency_12.IntervalStatResponse | undefined;
    }
    set internalStat(value: dependency_12.IntervalStatResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 81, this.#one_of_decls[0]!, value);
    }
    get hasInternalStat() {
        return pb_1.Message.getField(this, 81) != null;
    }
    clearInternalStat(): void {
        this.internalStat = undefined!;
    }
    get erc721List() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721TokenListResponse, 38) as dependency_7.ERC721TokenListResponse | undefined;
    }
    set erc721List(value: dependency_7.ERC721TokenListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 38, this.#one_of_decls[0]!, value);
    }
    get hasErc721List() {
        return pb_1.Message.getField(this, 38) != null;
    }
    clearErc721List(): void {
        this.erc721List = undefined!;
    }
    get erc721Details() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721TokenDetailsResponse, 39) as dependency_7.ERC721TokenDetailsResponse | undefined;
    }
    set erc721Details(value: dependency_7.ERC721TokenDetailsResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 39, this.#one_of_decls[0]!, value);
    }
    get hasErc721Details() {
        return pb_1.Message.getField(this, 39) != null;
    }
    clearErc721Details(): void {
        this.erc721Details = undefined!;
    }
    get erc721TransferList() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721TransferListResponse, 50) as dependency_7.ERC721TransferListResponse | undefined;
    }
    set erc721TransferList(value: dependency_7.ERC721TransferListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 50, this.#one_of_decls[0]!, value);
    }
    get hasErc721TransferList() {
        return pb_1.Message.getField(this, 50) != null;
    }
    clearErc721TransferList(): void {
        this.erc721TransferList = undefined!;
    }
    get erc721HolderList() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721HolderListResponse, 51) as dependency_7.ERC721HolderListResponse | undefined;
    }
    set erc721HolderList(value: dependency_7.ERC721HolderListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 51, this.#one_of_decls[0]!, value);
    }
    get hasErc721HolderList() {
        return pb_1.Message.getField(this, 51) != null;
    }
    clearErc721HolderList(): void {
        this.erc721HolderList = undefined!;
    }
    get erc721Inventory() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721InventoryListResponse, 52) as dependency_7.ERC721InventoryListResponse | undefined;
    }
    set erc721Inventory(value: dependency_7.ERC721InventoryListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 52, this.#one_of_decls[0]!, value);
    }
    get hasErc721Inventory() {
        return pb_1.Message.getField(this, 52) != null;
    }
    clearErc721Inventory(): void {
        this.erc721Inventory = undefined!;
    }
    get erc721Update() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721UpdateResponse, 53) as dependency_7.ERC721UpdateResponse | undefined;
    }
    set erc721Update(value: dependency_7.ERC721UpdateResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 53, this.#one_of_decls[0]!, value);
    }
    get hasErc721Update() {
        return pb_1.Message.getField(this, 53) != null;
    }
    clearErc721Update(): void {
        this.erc721Update = undefined!;
    }
    get erc721UpdateIcon() {
        return pb_1.Message.getWrapperField(this, dependency_7.ERC721UpdateIconResponse, 54) as dependency_7.ERC721UpdateIconResponse | undefined;
    }
    set erc721UpdateIcon(value: dependency_7.ERC721UpdateIconResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 54, this.#one_of_decls[0]!, value);
    }
    get hasErc721UpdateIcon() {
        return pb_1.Message.getField(this, 54) != null;
    }
    clearErc721UpdateIcon(): void {
        this.erc721UpdateIcon = undefined!;
    }
    get erc1155List() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155TokenListResponse, 60) as dependency_8.ERC1155TokenListResponse | undefined;
    }
    set erc1155List(value: dependency_8.ERC1155TokenListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 60, this.#one_of_decls[0]!, value);
    }
    get hasErc1155List() {
        return pb_1.Message.getField(this, 60) != null;
    }
    clearErc1155List(): void {
        this.erc1155List = undefined!;
    }
    get erc1155Details() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155TokenDetailsResponse, 61) as dependency_8.ERC1155TokenDetailsResponse | undefined;
    }
    set erc1155Details(value: dependency_8.ERC1155TokenDetailsResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 61, this.#one_of_decls[0]!, value);
    }
    get hasErc1155Details() {
        return pb_1.Message.getField(this, 61) != null;
    }
    clearErc1155Details(): void {
        this.erc1155Details = undefined!;
    }
    get erc1155TransferList() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155TransferListResponse, 62) as dependency_8.ERC1155TransferListResponse | undefined;
    }
    set erc1155TransferList(value: dependency_8.ERC1155TransferListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 62, this.#one_of_decls[0]!, value);
    }
    get hasErc1155TransferList() {
        return pb_1.Message.getField(this, 62) != null;
    }
    clearErc1155TransferList(): void {
        this.erc1155TransferList = undefined!;
    }
    get erc1155HolderList() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155HolderListResponse, 63) as dependency_8.ERC1155HolderListResponse | undefined;
    }
    set erc1155HolderList(value: dependency_8.ERC1155HolderListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 63, this.#one_of_decls[0]!, value);
    }
    get hasErc1155HolderList() {
        return pb_1.Message.getField(this, 63) != null;
    }
    clearErc1155HolderList(): void {
        this.erc1155HolderList = undefined!;
    }
    get erc1155Inventory() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155InventoryListResponse, 64) as dependency_8.ERC1155InventoryListResponse | undefined;
    }
    set erc1155Inventory(value: dependency_8.ERC1155InventoryListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 64, this.#one_of_decls[0]!, value);
    }
    get hasErc1155Inventory() {
        return pb_1.Message.getField(this, 64) != null;
    }
    clearErc1155Inventory(): void {
        this.erc1155Inventory = undefined!;
    }
    get erc1155Update() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155UpdateResponse, 65) as dependency_8.ERC1155UpdateResponse | undefined;
    }
    set erc1155Update(value: dependency_8.ERC1155UpdateResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 65, this.#one_of_decls[0]!, value);
    }
    get hasErc1155Update() {
        return pb_1.Message.getField(this, 65) != null;
    }
    clearErc1155Update(): void {
        this.erc1155Update = undefined!;
    }
    get erc1155UpdateIcon() {
        return pb_1.Message.getWrapperField(this, dependency_8.ERC1155UpdateIconResponse, 66) as dependency_8.ERC1155UpdateIconResponse | undefined;
    }
    set erc1155UpdateIcon(value: dependency_8.ERC1155UpdateIconResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 66, this.#one_of_decls[0]!, value);
    }
    get hasErc1155UpdateIcon() {
        return pb_1.Message.getField(this, 66) != null;
    }
    clearErc1155UpdateIcon(): void {
        this.erc1155UpdateIcon = undefined!;
    }
    get tokenList() {
        return pb_1.Message.getWrapperField(this, dependency_13.TokenListResponse, 55) as dependency_13.TokenListResponse | undefined;
    }
    set tokenList(value: dependency_13.TokenListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 55, this.#one_of_decls[0]!, value);
    }
    get hasTokenList() {
        return pb_1.Message.getField(this, 55) != null;
    }
    clearTokenList(): void {
        this.tokenList = undefined!;
    }
    get tokenDetails() {
        return pb_1.Message.getWrapperField(this, dependency_13.TokenDetailsResponse, 56) as dependency_13.TokenDetailsResponse | undefined;
    }
    set tokenDetails(value: dependency_13.TokenDetailsResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 56, this.#one_of_decls[0]!, value);
    }
    get hasTokenDetails() {
        return pb_1.Message.getField(this, 56) != null;
    }
    clearTokenDetails(): void {
        this.tokenDetails = undefined!;
    }
    get transferList() {
        return pb_1.Message.getWrapperField(this, dependency_13.TransferListResponse, 57) as dependency_13.TransferListResponse | undefined;
    }
    set transferList(value: dependency_13.TransferListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 57, this.#one_of_decls[0]!, value);
    }
    get hasTransferList() {
        return pb_1.Message.getField(this, 57) != null;
    }
    clearTransferList(): void {
        this.transferList = undefined!;
    }
    get holderList() {
        return pb_1.Message.getWrapperField(this, dependency_13.HolderListResponse, 58) as dependency_13.HolderListResponse | undefined;
    }
    set holderList(value: dependency_13.HolderListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 58, this.#one_of_decls[0]!, value);
    }
    get hasHolderList() {
        return pb_1.Message.getField(this, 58) != null;
    }
    clearHolderList(): void {
        this.holderList = undefined!;
    }
    get nftDetails() {
        return pb_1.Message.getWrapperField(this, dependency_13.NFTDetailsResponse, 59) as dependency_13.NFTDetailsResponse | undefined;
    }
    set nftDetails(value: dependency_13.NFTDetailsResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 59, this.#one_of_decls[0]!, value);
    }
    get hasNftDetails() {
        return pb_1.Message.getField(this, 59) != null;
    }
    clearNftDetails(): void {
        this.nftDetails = undefined!;
    }
    get inventoryList() {
        return pb_1.Message.getWrapperField(this, dependency_13.InventoryListResponse, 67) as dependency_13.InventoryListResponse | undefined;
    }
    set inventoryList(value: dependency_13.InventoryListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 67, this.#one_of_decls[0]!, value);
    }
    get hasInventoryList() {
        return pb_1.Message.getField(this, 67) != null;
    }
    clearInventoryList(): void {
        this.inventoryList = undefined!;
    }
    get assetData() {
        return pb_1.Message.getWrapperField(this, dependency_16.AssetDataResponse, 73) as dependency_16.AssetDataResponse | undefined;
    }
    set assetData(value: dependency_16.AssetDataResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 73, this.#one_of_decls[0]!, value);
    }
    get hasAssetData() {
        return pb_1.Message.getField(this, 73) != null;
    }
    clearAssetData(): void {
        this.assetData = undefined!;
    }
    get assetTransactions() {
        return pb_1.Message.getWrapperField(this, dependency_16.AssetTransactionListResponse, 74) as dependency_16.AssetTransactionListResponse | undefined;
    }
    set assetTransactions(value: dependency_16.AssetTransactionListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 74, this.#one_of_decls[0]!, value);
    }
    get hasAssetTransactions() {
        return pb_1.Message.getField(this, 74) != null;
    }
    clearAssetTransactions(): void {
        this.assetTransactions = undefined!;
    }
    get assetBalances() {
        return pb_1.Message.getWrapperField(this, dependency_16.AssetBalancesResponse, 75) as dependency_16.AssetBalancesResponse | undefined;
    }
    set assetBalances(value: dependency_16.AssetBalancesResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 75, this.#one_of_decls[0]!, value);
    }
    get hasAssetBalances() {
        return pb_1.Message.getField(this, 75) != null;
    }
    clearAssetBalances(): void {
        this.assetBalances = undefined!;
    }
    get totalStakeAmount() {
        return pb_1.Message.getWrapperField(this, dependency_15.TotalStakeAmountResponse, 77) as dependency_15.TotalStakeAmountResponse | undefined;
    }
    set totalStakeAmount(value: dependency_15.TotalStakeAmountResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 77, this.#one_of_decls[0]!, value);
    }
    get hasTotalStakeAmount() {
        return pb_1.Message.getField(this, 77) != null;
    }
    clearTotalStakeAmount(): void {
        this.totalStakeAmount = undefined!;
    }
    get bytesForChain() {
        return pb_1.Message.getWrapperField(this, dependency_17.BytesForChainResponse, 78) as dependency_17.BytesForChainResponse | undefined;
    }
    set bytesForChain(value: dependency_17.BytesForChainResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 78, this.#one_of_decls[0]!, value);
    }
    get hasBytesForChain() {
        return pb_1.Message.getField(this, 78) != null;
    }
    clearBytesForChain(): void {
        this.bytesForChain = undefined!;
    }
    get blockBytes() {
        return pb_1.Message.getWrapperField(this, dependency_17.BlockBytesResponse, 79) as dependency_17.BlockBytesResponse | undefined;
    }
    set blockBytes(value: dependency_17.BlockBytesResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 79, this.#one_of_decls[0]!, value);
    }
    get hasBlockBytes() {
        return pb_1.Message.getField(this, 79) != null;
    }
    clearBlockBytes(): void {
        this.blockBytes = undefined!;
    }
    get verification() {
        return pb_1.Message.getWrapperField(this, dependency_2.EtherscanLikeResponse, 80) as dependency_2.EtherscanLikeResponse | undefined;
    }
    set verification(value: dependency_2.EtherscanLikeResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 80, this.#one_of_decls[0]!, value);
    }
    get hasVerification() {
        return pb_1.Message.getField(this, 80) != null;
    }
    clearVerification(): void {
        this.verification = undefined!;
    }
    get networkUsage() {
        return pb_1.Message.getWrapperField(this, dependency_12.NetworkUsageResponse, 82) as dependency_12.NetworkUsageResponse | undefined;
    }
    set networkUsage(value: dependency_12.NetworkUsageResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 82, this.#one_of_decls[0]!, value);
    }
    get hasNetworkUsage() {
        return pb_1.Message.getField(this, 82) != null;
    }
    clearNetworkUsage(): void {
        this.networkUsage = undefined!;
    }
    get nativeTransfers() {
        return pb_1.Message.getWrapperField(this, dependency_14.NativeTransferListResponse, 87) as dependency_14.NativeTransferListResponse | undefined;
    }
    set nativeTransfers(value: dependency_14.NativeTransferListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 87, this.#one_of_decls[0]!, value);
    }
    get hasNativeTransfers() {
        return pb_1.Message.getField(this, 87) != null;
    }
    clearNativeTransfers(): void {
        this.nativeTransfers = undefined!;
    }
    get nodeDetailsUpdate() {
        return pb_1.Message.getWrapperField(this, dependency_15.NodeDetailsUpdateResponse, 89) as dependency_15.NodeDetailsUpdateResponse | undefined;
    }
    set nodeDetailsUpdate(value: dependency_15.NodeDetailsUpdateResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 89, this.#one_of_decls[0]!, value);
    }
    get hasNodeDetailsUpdate() {
        return pb_1.Message.getField(this, 89) != null;
    }
    clearNodeDetailsUpdate(): void {
        this.nodeDetailsUpdate = undefined!;
    }
    get nodeDetails() {
        return pb_1.Message.getWrapperField(this, dependency_15.NodeDetailsResponse, 90) as dependency_15.NodeDetailsResponse | undefined;
    }
    set nodeDetails(value: dependency_15.NodeDetailsResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 90, this.#one_of_decls[0]!, value);
    }
    get hasNodeDetails() {
        return pb_1.Message.getField(this, 90) != null;
    }
    clearNodeDetails(): void {
        this.nodeDetails = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "error" | "empty" | "supernetList" | "blockchainList" | "blockList" | "transactionList" | "validatorList" | "search" | "delegatorList" | "addressData" | "utxoChainsBalances" | "overallStat" | "blockchainStat" | "blockchainYearStat" | "transactionDetails" | "erc20List" | "erc20Details" | "logList" | "internalTransactionList" | "erc20TransferList" | "erc20HolderList" | "supernetUpdate" | "blockchainUpdate" | "blockchainUpdateIcon" | "erc20Update" | "erc20UpdateIcon" | "checkLogPass" | "serverTime" | "hotDApps" | "richList" | "collectedFeesStat" | "transactionListForWallet" | "transactionListForWalletByIndexList" | "stakes" | "internalStat" | "erc721List" | "erc721Details" | "erc721TransferList" | "erc721HolderList" | "erc721Inventory" | "erc721Update" | "erc721UpdateIcon" | "erc1155List" | "erc1155Details" | "erc1155TransferList" | "erc1155HolderList" | "erc1155Inventory" | "erc1155Update" | "erc1155UpdateIcon" | "tokenList" | "tokenDetails" | "transferList" | "holderList" | "nftDetails" | "inventoryList" | "assetData" | "assetTransactions" | "assetBalances" | "totalStakeAmount" | "bytesForChain" | "blockBytes" | "verification" | "networkUsage" | "nativeTransfers" | "nodeDetailsUpdate" | "nodeDetails";
        } = {
            0: "none",
            1: "error",
            12: "empty",
            2: "supernetList",
            3: "blockchainList",
            4: "blockList",
            6: "transactionList",
            8: "validatorList",
            14: "search",
            18: "delegatorList",
            24: "addressData",
            88: "utxoChainsBalances",
            31: "overallStat",
            32: "blockchainStat",
            33: "blockchainYearStat",
            34: "transactionDetails",
            35: "erc20List",
            36: "erc20Details",
            40: "logList",
            41: "internalTransactionList",
            42: "erc20TransferList",
            43: "erc20HolderList",
            44: "supernetUpdate",
            45: "blockchainUpdate",
            46: "blockchainUpdateIcon",
            47: "erc20Update",
            48: "erc20UpdateIcon",
            49: "checkLogPass",
            68: "serverTime",
            69: "hotDApps",
            70: "richList",
            71: "collectedFeesStat",
            72: "transactionListForWallet",
            92: "transactionListForWalletByIndexList",
            76: "stakes",
            81: "internalStat",
            38: "erc721List",
            39: "erc721Details",
            50: "erc721TransferList",
            51: "erc721HolderList",
            52: "erc721Inventory",
            53: "erc721Update",
            54: "erc721UpdateIcon",
            60: "erc1155List",
            61: "erc1155Details",
            62: "erc1155TransferList",
            63: "erc1155HolderList",
            64: "erc1155Inventory",
            65: "erc1155Update",
            66: "erc1155UpdateIcon",
            55: "tokenList",
            56: "tokenDetails",
            57: "transferList",
            58: "holderList",
            59: "nftDetails",
            67: "inventoryList",
            73: "assetData",
            74: "assetTransactions",
            75: "assetBalances",
            77: "totalStakeAmount",
            78: "bytesForChain",
            79: "blockBytes",
            80: "verification",
            82: "networkUsage",
            87: "nativeTransfers",
            89: "nodeDetailsUpdate",
            90: "nodeDetails"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 12, 2, 3, 4, 6, 8, 14, 18, 24, 88, 31, 32, 33, 34, 35, 36, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 68, 69, 70, 71, 72, 92, 76, 81, 38, 39, 50, 51, 52, 53, 54, 60, 61, 62, 63, 64, 65, 66, 55, 56, 57, 58, 59, 67, 73, 74, 75, 77, 78, 79, 80, 82, 87, 89, 90])]!;
    }
    static fromObject(data?: Response.AsObjectPartial): Response {
        if (!data) {
            return new Response();
        }
        const message = new Response({});
        if (data.error != null) {
            message.error = dependency_10.ErrorResponse.fromObject(data.error);
        }
        if (data.empty != null) {
            message.empty = dependency_10.EmptyMessage.fromObject(data.empty);
        }
        if (data.supernetList != null) {
            message.supernetList = dependency_5.SupernetListResponse.fromObject(data.supernetList);
        }
        if (data.blockchainList != null) {
            message.blockchainList = dependency_5.BlockchainListResponse.fromObject(data.blockchainList);
        }
        if (data.blockList != null) {
            message.blockList = dependency_4.BlockListResponse.fromObject(data.blockList);
        }
        if (data.transactionList != null) {
            message.transactionList = dependency_14.TransactionListResponse.fromObject(data.transactionList);
        }
        if (data.validatorList != null) {
            message.validatorList = dependency_15.ValidatorListResponse.fromObject(data.validatorList);
        }
        if (data.search != null) {
            message.search = dependency_11.SearchResponse.fromObject(data.search);
        }
        if (data.delegatorList != null) {
            message.delegatorList = dependency_15.DelegationListResponse.fromObject(data.delegatorList);
        }
        if (data.addressData != null) {
            message.addressData = dependency_2.AddressDataResponse.fromObject(data.addressData);
        }
        if (data.utxoChainsBalances != null) {
            message.utxoChainsBalances = dependency_2.UtxoChainsBalancesResponse.fromObject(data.utxoChainsBalances);
        }
        if (data.overallStat != null) {
            message.overallStat = dependency_12.OverallStatResponse.fromObject(data.overallStat);
        }
        if (data.blockchainStat != null) {
            message.blockchainStat = dependency_12.BlockchainStatResponse.fromObject(data.blockchainStat);
        }
        if (data.blockchainYearStat != null) {
            message.blockchainYearStat = dependency_12.BlockchainYearStatResponse.fromObject(data.blockchainYearStat);
        }
        if (data.transactionDetails != null) {
            message.transactionDetails = dependency_14.TransactionDetailsResponse.fromObject(data.transactionDetails);
        }
        if (data.erc20List != null) {
            message.erc20List = dependency_6.ERC20TokenListResponse.fromObject(data.erc20List);
        }
        if (data.erc20Details != null) {
            message.erc20Details = dependency_6.ERC20TokenDetailsResponse.fromObject(data.erc20Details);
        }
        if (data.logList != null) {
            message.logList = dependency_9.LogListResponse.fromObject(data.logList);
        }
        if (data.internalTransactionList != null) {
            message.internalTransactionList = dependency_9.InternalTransactionListResponse.fromObject(data.internalTransactionList);
        }
        if (data.erc20TransferList != null) {
            message.erc20TransferList = dependency_6.ERC20TransferListResponse.fromObject(data.erc20TransferList);
        }
        if (data.erc20HolderList != null) {
            message.erc20HolderList = dependency_6.ERC20HolderListResponse.fromObject(data.erc20HolderList);
        }
        if (data.supernetUpdate != null) {
            message.supernetUpdate = dependency_5.SupernetUpdateResponse.fromObject(data.supernetUpdate);
        }
        if (data.blockchainUpdate != null) {
            message.blockchainUpdate = dependency_5.BlockchainUpdateResponse.fromObject(data.blockchainUpdate);
        }
        if (data.blockchainUpdateIcon != null) {
            message.blockchainUpdateIcon = dependency_5.BlockchainUpdateIconResponse.fromObject(data.blockchainUpdateIcon);
        }
        if (data.erc20Update != null) {
            message.erc20Update = dependency_6.ERC20UpdateResponse.fromObject(data.erc20Update);
        }
        if (data.erc20UpdateIcon != null) {
            message.erc20UpdateIcon = dependency_6.ERC20UpdateIconResponse.fromObject(data.erc20UpdateIcon);
        }
        if (data.checkLogPass != null) {
            message.checkLogPass = dependency_3.CheckLogPassResponse.fromObject(data.checkLogPass);
        }
        if (data.serverTime != null) {
            message.serverTime = dependency_10.ServerTimeResponse.fromObject(data.serverTime);
        }
        if (data.hotDApps != null) {
            message.hotDApps = dependency_12.HotDAppsResponse.fromObject(data.hotDApps);
        }
        if (data.richList != null) {
            message.richList = dependency_12.RichListResponse.fromObject(data.richList);
        }
        if (data.collectedFeesStat != null) {
            message.collectedFeesStat = dependency_12.CollectedFeesStatResponse.fromObject(data.collectedFeesStat);
        }
        if (data.transactionListForWallet != null) {
            message.transactionListForWallet = dependency_14.TransactionListForWalletResponse.fromObject(data.transactionListForWallet);
        }
        if (data.transactionListForWalletByIndexList != null) {
            message.transactionListForWalletByIndexList = dependency_14.TransactionListForWalletByIndexListResponse.fromObject(data.transactionListForWalletByIndexList);
        }
        if (data.stakes != null) {
            message.stakes = dependency_15.StakesResponse.fromObject(data.stakes);
        }
        if (data.internalStat != null) {
            message.internalStat = dependency_12.IntervalStatResponse.fromObject(data.internalStat);
        }
        if (data.erc721List != null) {
            message.erc721List = dependency_7.ERC721TokenListResponse.fromObject(data.erc721List);
        }
        if (data.erc721Details != null) {
            message.erc721Details = dependency_7.ERC721TokenDetailsResponse.fromObject(data.erc721Details);
        }
        if (data.erc721TransferList != null) {
            message.erc721TransferList = dependency_7.ERC721TransferListResponse.fromObject(data.erc721TransferList);
        }
        if (data.erc721HolderList != null) {
            message.erc721HolderList = dependency_7.ERC721HolderListResponse.fromObject(data.erc721HolderList);
        }
        if (data.erc721Inventory != null) {
            message.erc721Inventory = dependency_7.ERC721InventoryListResponse.fromObject(data.erc721Inventory);
        }
        if (data.erc721Update != null) {
            message.erc721Update = dependency_7.ERC721UpdateResponse.fromObject(data.erc721Update);
        }
        if (data.erc721UpdateIcon != null) {
            message.erc721UpdateIcon = dependency_7.ERC721UpdateIconResponse.fromObject(data.erc721UpdateIcon);
        }
        if (data.erc1155List != null) {
            message.erc1155List = dependency_8.ERC1155TokenListResponse.fromObject(data.erc1155List);
        }
        if (data.erc1155Details != null) {
            message.erc1155Details = dependency_8.ERC1155TokenDetailsResponse.fromObject(data.erc1155Details);
        }
        if (data.erc1155TransferList != null) {
            message.erc1155TransferList = dependency_8.ERC1155TransferListResponse.fromObject(data.erc1155TransferList);
        }
        if (data.erc1155HolderList != null) {
            message.erc1155HolderList = dependency_8.ERC1155HolderListResponse.fromObject(data.erc1155HolderList);
        }
        if (data.erc1155Inventory != null) {
            message.erc1155Inventory = dependency_8.ERC1155InventoryListResponse.fromObject(data.erc1155Inventory);
        }
        if (data.erc1155Update != null) {
            message.erc1155Update = dependency_8.ERC1155UpdateResponse.fromObject(data.erc1155Update);
        }
        if (data.erc1155UpdateIcon != null) {
            message.erc1155UpdateIcon = dependency_8.ERC1155UpdateIconResponse.fromObject(data.erc1155UpdateIcon);
        }
        if (data.tokenList != null) {
            message.tokenList = dependency_13.TokenListResponse.fromObject(data.tokenList);
        }
        if (data.tokenDetails != null) {
            message.tokenDetails = dependency_13.TokenDetailsResponse.fromObject(data.tokenDetails);
        }
        if (data.transferList != null) {
            message.transferList = dependency_13.TransferListResponse.fromObject(data.transferList);
        }
        if (data.holderList != null) {
            message.holderList = dependency_13.HolderListResponse.fromObject(data.holderList);
        }
        if (data.nftDetails != null) {
            message.nftDetails = dependency_13.NFTDetailsResponse.fromObject(data.nftDetails);
        }
        if (data.inventoryList != null) {
            message.inventoryList = dependency_13.InventoryListResponse.fromObject(data.inventoryList);
        }
        if (data.assetData != null) {
            message.assetData = dependency_16.AssetDataResponse.fromObject(data.assetData);
        }
        if (data.assetTransactions != null) {
            message.assetTransactions = dependency_16.AssetTransactionListResponse.fromObject(data.assetTransactions);
        }
        if (data.assetBalances != null) {
            message.assetBalances = dependency_16.AssetBalancesResponse.fromObject(data.assetBalances);
        }
        if (data.totalStakeAmount != null) {
            message.totalStakeAmount = dependency_15.TotalStakeAmountResponse.fromObject(data.totalStakeAmount);
        }
        if (data.bytesForChain != null) {
            message.bytesForChain = dependency_17.BytesForChainResponse.fromObject(data.bytesForChain);
        }
        if (data.blockBytes != null) {
            message.blockBytes = dependency_17.BlockBytesResponse.fromObject(data.blockBytes);
        }
        if (data.verification != null) {
            message.verification = dependency_2.EtherscanLikeResponse.fromObject(data.verification);
        }
        if (data.networkUsage != null) {
            message.networkUsage = dependency_12.NetworkUsageResponse.fromObject(data.networkUsage);
        }
        if (data.nativeTransfers != null) {
            message.nativeTransfers = dependency_14.NativeTransferListResponse.fromObject(data.nativeTransfers);
        }
        if (data.nodeDetailsUpdate != null) {
            message.nodeDetailsUpdate = dependency_15.NodeDetailsUpdateResponse.fromObject(data.nodeDetailsUpdate);
        }
        if (data.nodeDetails != null) {
            message.nodeDetails = dependency_15.NodeDetailsResponse.fromObject(data.nodeDetails);
        }
        return message;
    }
    toObject() {
        const data: Response.AsObject = {};
        if (this.error != null) {
            data.error = this.error.toObject();
        }
        if (this.empty != null) {
            data.empty = this.empty.toObject();
        }
        if (this.supernetList != null) {
            data.supernetList = this.supernetList.toObject();
        }
        if (this.blockchainList != null) {
            data.blockchainList = this.blockchainList.toObject();
        }
        if (this.blockList != null) {
            data.blockList = this.blockList.toObject();
        }
        if (this.transactionList != null) {
            data.transactionList = this.transactionList.toObject();
        }
        if (this.validatorList != null) {
            data.validatorList = this.validatorList.toObject();
        }
        if (this.search != null) {
            data.search = this.search.toObject();
        }
        if (this.delegatorList != null) {
            data.delegatorList = this.delegatorList.toObject();
        }
        if (this.addressData != null) {
            data.addressData = this.addressData.toObject();
        }
        if (this.utxoChainsBalances != null) {
            data.utxoChainsBalances = this.utxoChainsBalances.toObject();
        }
        if (this.overallStat != null) {
            data.overallStat = this.overallStat.toObject();
        }
        if (this.blockchainStat != null) {
            data.blockchainStat = this.blockchainStat.toObject();
        }
        if (this.blockchainYearStat != null) {
            data.blockchainYearStat = this.blockchainYearStat.toObject();
        }
        if (this.transactionDetails != null) {
            data.transactionDetails = this.transactionDetails.toObject();
        }
        if (this.erc20List != null) {
            data.erc20List = this.erc20List.toObject();
        }
        if (this.erc20Details != null) {
            data.erc20Details = this.erc20Details.toObject();
        }
        if (this.logList != null) {
            data.logList = this.logList.toObject();
        }
        if (this.internalTransactionList != null) {
            data.internalTransactionList = this.internalTransactionList.toObject();
        }
        if (this.erc20TransferList != null) {
            data.erc20TransferList = this.erc20TransferList.toObject();
        }
        if (this.erc20HolderList != null) {
            data.erc20HolderList = this.erc20HolderList.toObject();
        }
        if (this.supernetUpdate != null) {
            data.supernetUpdate = this.supernetUpdate.toObject();
        }
        if (this.blockchainUpdate != null) {
            data.blockchainUpdate = this.blockchainUpdate.toObject();
        }
        if (this.blockchainUpdateIcon != null) {
            data.blockchainUpdateIcon = this.blockchainUpdateIcon.toObject();
        }
        if (this.erc20Update != null) {
            data.erc20Update = this.erc20Update.toObject();
        }
        if (this.erc20UpdateIcon != null) {
            data.erc20UpdateIcon = this.erc20UpdateIcon.toObject();
        }
        if (this.checkLogPass != null) {
            data.checkLogPass = this.checkLogPass.toObject();
        }
        if (this.serverTime != null) {
            data.serverTime = this.serverTime.toObject();
        }
        if (this.hotDApps != null) {
            data.hotDApps = this.hotDApps.toObject();
        }
        if (this.richList != null) {
            data.richList = this.richList.toObject();
        }
        if (this.collectedFeesStat != null) {
            data.collectedFeesStat = this.collectedFeesStat.toObject();
        }
        if (this.transactionListForWallet != null) {
            data.transactionListForWallet = this.transactionListForWallet.toObject();
        }
        if (this.transactionListForWalletByIndexList != null) {
            data.transactionListForWalletByIndexList = this.transactionListForWalletByIndexList.toObject();
        }
        if (this.stakes != null) {
            data.stakes = this.stakes.toObject();
        }
        if (this.internalStat != null) {
            data.internalStat = this.internalStat.toObject();
        }
        if (this.erc721List != null) {
            data.erc721List = this.erc721List.toObject();
        }
        if (this.erc721Details != null) {
            data.erc721Details = this.erc721Details.toObject();
        }
        if (this.erc721TransferList != null) {
            data.erc721TransferList = this.erc721TransferList.toObject();
        }
        if (this.erc721HolderList != null) {
            data.erc721HolderList = this.erc721HolderList.toObject();
        }
        if (this.erc721Inventory != null) {
            data.erc721Inventory = this.erc721Inventory.toObject();
        }
        if (this.erc721Update != null) {
            data.erc721Update = this.erc721Update.toObject();
        }
        if (this.erc721UpdateIcon != null) {
            data.erc721UpdateIcon = this.erc721UpdateIcon.toObject();
        }
        if (this.erc1155List != null) {
            data.erc1155List = this.erc1155List.toObject();
        }
        if (this.erc1155Details != null) {
            data.erc1155Details = this.erc1155Details.toObject();
        }
        if (this.erc1155TransferList != null) {
            data.erc1155TransferList = this.erc1155TransferList.toObject();
        }
        if (this.erc1155HolderList != null) {
            data.erc1155HolderList = this.erc1155HolderList.toObject();
        }
        if (this.erc1155Inventory != null) {
            data.erc1155Inventory = this.erc1155Inventory.toObject();
        }
        if (this.erc1155Update != null) {
            data.erc1155Update = this.erc1155Update.toObject();
        }
        if (this.erc1155UpdateIcon != null) {
            data.erc1155UpdateIcon = this.erc1155UpdateIcon.toObject();
        }
        if (this.tokenList != null) {
            data.tokenList = this.tokenList.toObject();
        }
        if (this.tokenDetails != null) {
            data.tokenDetails = this.tokenDetails.toObject();
        }
        if (this.transferList != null) {
            data.transferList = this.transferList.toObject();
        }
        if (this.holderList != null) {
            data.holderList = this.holderList.toObject();
        }
        if (this.nftDetails != null) {
            data.nftDetails = this.nftDetails.toObject();
        }
        if (this.inventoryList != null) {
            data.inventoryList = this.inventoryList.toObject();
        }
        if (this.assetData != null) {
            data.assetData = this.assetData.toObject();
        }
        if (this.assetTransactions != null) {
            data.assetTransactions = this.assetTransactions.toObject();
        }
        if (this.assetBalances != null) {
            data.assetBalances = this.assetBalances.toObject();
        }
        if (this.totalStakeAmount != null) {
            data.totalStakeAmount = this.totalStakeAmount.toObject();
        }
        if (this.bytesForChain != null) {
            data.bytesForChain = this.bytesForChain.toObject();
        }
        if (this.blockBytes != null) {
            data.blockBytes = this.blockBytes.toObject();
        }
        if (this.verification != null) {
            data.verification = this.verification.toObject();
        }
        if (this.networkUsage != null) {
            data.networkUsage = this.networkUsage.toObject();
        }
        if (this.nativeTransfers != null) {
            data.nativeTransfers = this.nativeTransfers.toObject();
        }
        if (this.nodeDetailsUpdate != null) {
            data.nodeDetailsUpdate = this.nodeDetailsUpdate.toObject();
        }
        if (this.nodeDetails != null) {
            data.nodeDetails = this.nodeDetails.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasError)
            writer.writeMessage(1, this.error, () => this.error!.serialize(writer));
        if (this.hasEmpty)
            writer.writeMessage(12, this.empty, () => this.empty!.serialize(writer));
        if (this.hasSupernetList)
            writer.writeMessage(2, this.supernetList, () => this.supernetList!.serialize(writer));
        if (this.hasBlockchainList)
            writer.writeMessage(3, this.blockchainList, () => this.blockchainList!.serialize(writer));
        if (this.hasBlockList)
            writer.writeMessage(4, this.blockList, () => this.blockList!.serialize(writer));
        if (this.hasTransactionList)
            writer.writeMessage(6, this.transactionList, () => this.transactionList!.serialize(writer));
        if (this.hasValidatorList)
            writer.writeMessage(8, this.validatorList, () => this.validatorList!.serialize(writer));
        if (this.hasSearch)
            writer.writeMessage(14, this.search, () => this.search!.serialize(writer));
        if (this.hasDelegatorList)
            writer.writeMessage(18, this.delegatorList, () => this.delegatorList!.serialize(writer));
        if (this.hasAddressData)
            writer.writeMessage(24, this.addressData, () => this.addressData!.serialize(writer));
        if (this.hasUtxoChainsBalances)
            writer.writeMessage(88, this.utxoChainsBalances, () => this.utxoChainsBalances!.serialize(writer));
        if (this.hasOverallStat)
            writer.writeMessage(31, this.overallStat, () => this.overallStat!.serialize(writer));
        if (this.hasBlockchainStat)
            writer.writeMessage(32, this.blockchainStat, () => this.blockchainStat!.serialize(writer));
        if (this.hasBlockchainYearStat)
            writer.writeMessage(33, this.blockchainYearStat, () => this.blockchainYearStat!.serialize(writer));
        if (this.hasTransactionDetails)
            writer.writeMessage(34, this.transactionDetails, () => this.transactionDetails!.serialize(writer));
        if (this.hasErc20List)
            writer.writeMessage(35, this.erc20List, () => this.erc20List!.serialize(writer));
        if (this.hasErc20Details)
            writer.writeMessage(36, this.erc20Details, () => this.erc20Details!.serialize(writer));
        if (this.hasLogList)
            writer.writeMessage(40, this.logList, () => this.logList!.serialize(writer));
        if (this.hasInternalTransactionList)
            writer.writeMessage(41, this.internalTransactionList, () => this.internalTransactionList!.serialize(writer));
        if (this.hasErc20TransferList)
            writer.writeMessage(42, this.erc20TransferList, () => this.erc20TransferList!.serialize(writer));
        if (this.hasErc20HolderList)
            writer.writeMessage(43, this.erc20HolderList, () => this.erc20HolderList!.serialize(writer));
        if (this.hasSupernetUpdate)
            writer.writeMessage(44, this.supernetUpdate, () => this.supernetUpdate!.serialize(writer));
        if (this.hasBlockchainUpdate)
            writer.writeMessage(45, this.blockchainUpdate, () => this.blockchainUpdate!.serialize(writer));
        if (this.hasBlockchainUpdateIcon)
            writer.writeMessage(46, this.blockchainUpdateIcon, () => this.blockchainUpdateIcon!.serialize(writer));
        if (this.hasErc20Update)
            writer.writeMessage(47, this.erc20Update, () => this.erc20Update!.serialize(writer));
        if (this.hasErc20UpdateIcon)
            writer.writeMessage(48, this.erc20UpdateIcon, () => this.erc20UpdateIcon!.serialize(writer));
        if (this.hasCheckLogPass)
            writer.writeMessage(49, this.checkLogPass, () => this.checkLogPass!.serialize(writer));
        if (this.hasServerTime)
            writer.writeMessage(68, this.serverTime, () => this.serverTime!.serialize(writer));
        if (this.hasHotDApps)
            writer.writeMessage(69, this.hotDApps, () => this.hotDApps!.serialize(writer));
        if (this.hasRichList)
            writer.writeMessage(70, this.richList, () => this.richList!.serialize(writer));
        if (this.hasCollectedFeesStat)
            writer.writeMessage(71, this.collectedFeesStat, () => this.collectedFeesStat!.serialize(writer));
        if (this.hasTransactionListForWallet)
            writer.writeMessage(72, this.transactionListForWallet, () => this.transactionListForWallet!.serialize(writer));
        if (this.hasTransactionListForWalletByIndexList)
            writer.writeMessage(92, this.transactionListForWalletByIndexList, () => this.transactionListForWalletByIndexList!.serialize(writer));
        if (this.hasStakes)
            writer.writeMessage(76, this.stakes, () => this.stakes!.serialize(writer));
        if (this.hasInternalStat)
            writer.writeMessage(81, this.internalStat, () => this.internalStat!.serialize(writer));
        if (this.hasErc721List)
            writer.writeMessage(38, this.erc721List, () => this.erc721List!.serialize(writer));
        if (this.hasErc721Details)
            writer.writeMessage(39, this.erc721Details, () => this.erc721Details!.serialize(writer));
        if (this.hasErc721TransferList)
            writer.writeMessage(50, this.erc721TransferList, () => this.erc721TransferList!.serialize(writer));
        if (this.hasErc721HolderList)
            writer.writeMessage(51, this.erc721HolderList, () => this.erc721HolderList!.serialize(writer));
        if (this.hasErc721Inventory)
            writer.writeMessage(52, this.erc721Inventory, () => this.erc721Inventory!.serialize(writer));
        if (this.hasErc721Update)
            writer.writeMessage(53, this.erc721Update, () => this.erc721Update!.serialize(writer));
        if (this.hasErc721UpdateIcon)
            writer.writeMessage(54, this.erc721UpdateIcon, () => this.erc721UpdateIcon!.serialize(writer));
        if (this.hasErc1155List)
            writer.writeMessage(60, this.erc1155List, () => this.erc1155List!.serialize(writer));
        if (this.hasErc1155Details)
            writer.writeMessage(61, this.erc1155Details, () => this.erc1155Details!.serialize(writer));
        if (this.hasErc1155TransferList)
            writer.writeMessage(62, this.erc1155TransferList, () => this.erc1155TransferList!.serialize(writer));
        if (this.hasErc1155HolderList)
            writer.writeMessage(63, this.erc1155HolderList, () => this.erc1155HolderList!.serialize(writer));
        if (this.hasErc1155Inventory)
            writer.writeMessage(64, this.erc1155Inventory, () => this.erc1155Inventory!.serialize(writer));
        if (this.hasErc1155Update)
            writer.writeMessage(65, this.erc1155Update, () => this.erc1155Update!.serialize(writer));
        if (this.hasErc1155UpdateIcon)
            writer.writeMessage(66, this.erc1155UpdateIcon, () => this.erc1155UpdateIcon!.serialize(writer));
        if (this.hasTokenList)
            writer.writeMessage(55, this.tokenList, () => this.tokenList!.serialize(writer));
        if (this.hasTokenDetails)
            writer.writeMessage(56, this.tokenDetails, () => this.tokenDetails!.serialize(writer));
        if (this.hasTransferList)
            writer.writeMessage(57, this.transferList, () => this.transferList!.serialize(writer));
        if (this.hasHolderList)
            writer.writeMessage(58, this.holderList, () => this.holderList!.serialize(writer));
        if (this.hasNftDetails)
            writer.writeMessage(59, this.nftDetails, () => this.nftDetails!.serialize(writer));
        if (this.hasInventoryList)
            writer.writeMessage(67, this.inventoryList, () => this.inventoryList!.serialize(writer));
        if (this.hasAssetData)
            writer.writeMessage(73, this.assetData, () => this.assetData!.serialize(writer));
        if (this.hasAssetTransactions)
            writer.writeMessage(74, this.assetTransactions, () => this.assetTransactions!.serialize(writer));
        if (this.hasAssetBalances)
            writer.writeMessage(75, this.assetBalances, () => this.assetBalances!.serialize(writer));
        if (this.hasTotalStakeAmount)
            writer.writeMessage(77, this.totalStakeAmount, () => this.totalStakeAmount!.serialize(writer));
        if (this.hasBytesForChain)
            writer.writeMessage(78, this.bytesForChain, () => this.bytesForChain!.serialize(writer));
        if (this.hasBlockBytes)
            writer.writeMessage(79, this.blockBytes, () => this.blockBytes!.serialize(writer));
        if (this.hasVerification)
            writer.writeMessage(80, this.verification, () => this.verification!.serialize(writer));
        if (this.hasNetworkUsage)
            writer.writeMessage(82, this.networkUsage, () => this.networkUsage!.serialize(writer));
        if (this.hasNativeTransfers)
            writer.writeMessage(87, this.nativeTransfers, () => this.nativeTransfers!.serialize(writer));
        if (this.hasNodeDetailsUpdate)
            writer.writeMessage(89, this.nodeDetailsUpdate, () => this.nodeDetailsUpdate!.serialize(writer));
        if (this.hasNodeDetails)
            writer.writeMessage(90, this.nodeDetails, () => this.nodeDetails!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Response {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Response();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.error, () => message.error = dependency_10.ErrorResponse.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.empty, () => message.empty = dependency_10.EmptyMessage.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.supernetList, () => message.supernetList = dependency_5.SupernetListResponse.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.blockchainList, () => message.blockchainList = dependency_5.BlockchainListResponse.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.blockList, () => message.blockList = dependency_4.BlockListResponse.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.transactionList, () => message.transactionList = dependency_14.TransactionListResponse.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.validatorList, () => message.validatorList = dependency_15.ValidatorListResponse.deserialize(reader));
                    break;
                case 14:
                    reader.readMessage(message.search, () => message.search = dependency_11.SearchResponse.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.delegatorList, () => message.delegatorList = dependency_15.DelegationListResponse.deserialize(reader));
                    break;
                case 24:
                    reader.readMessage(message.addressData, () => message.addressData = dependency_2.AddressDataResponse.deserialize(reader));
                    break;
                case 88:
                    reader.readMessage(message.utxoChainsBalances, () => message.utxoChainsBalances = dependency_2.UtxoChainsBalancesResponse.deserialize(reader));
                    break;
                case 31:
                    reader.readMessage(message.overallStat, () => message.overallStat = dependency_12.OverallStatResponse.deserialize(reader));
                    break;
                case 32:
                    reader.readMessage(message.blockchainStat, () => message.blockchainStat = dependency_12.BlockchainStatResponse.deserialize(reader));
                    break;
                case 33:
                    reader.readMessage(message.blockchainYearStat, () => message.blockchainYearStat = dependency_12.BlockchainYearStatResponse.deserialize(reader));
                    break;
                case 34:
                    reader.readMessage(message.transactionDetails, () => message.transactionDetails = dependency_14.TransactionDetailsResponse.deserialize(reader));
                    break;
                case 35:
                    reader.readMessage(message.erc20List, () => message.erc20List = dependency_6.ERC20TokenListResponse.deserialize(reader));
                    break;
                case 36:
                    reader.readMessage(message.erc20Details, () => message.erc20Details = dependency_6.ERC20TokenDetailsResponse.deserialize(reader));
                    break;
                case 40:
                    reader.readMessage(message.logList, () => message.logList = dependency_9.LogListResponse.deserialize(reader));
                    break;
                case 41:
                    reader.readMessage(message.internalTransactionList, () => message.internalTransactionList = dependency_9.InternalTransactionListResponse.deserialize(reader));
                    break;
                case 42:
                    reader.readMessage(message.erc20TransferList, () => message.erc20TransferList = dependency_6.ERC20TransferListResponse.deserialize(reader));
                    break;
                case 43:
                    reader.readMessage(message.erc20HolderList, () => message.erc20HolderList = dependency_6.ERC20HolderListResponse.deserialize(reader));
                    break;
                case 44:
                    reader.readMessage(message.supernetUpdate, () => message.supernetUpdate = dependency_5.SupernetUpdateResponse.deserialize(reader));
                    break;
                case 45:
                    reader.readMessage(message.blockchainUpdate, () => message.blockchainUpdate = dependency_5.BlockchainUpdateResponse.deserialize(reader));
                    break;
                case 46:
                    reader.readMessage(message.blockchainUpdateIcon, () => message.blockchainUpdateIcon = dependency_5.BlockchainUpdateIconResponse.deserialize(reader));
                    break;
                case 47:
                    reader.readMessage(message.erc20Update, () => message.erc20Update = dependency_6.ERC20UpdateResponse.deserialize(reader));
                    break;
                case 48:
                    reader.readMessage(message.erc20UpdateIcon, () => message.erc20UpdateIcon = dependency_6.ERC20UpdateIconResponse.deserialize(reader));
                    break;
                case 49:
                    reader.readMessage(message.checkLogPass, () => message.checkLogPass = dependency_3.CheckLogPassResponse.deserialize(reader));
                    break;
                case 68:
                    reader.readMessage(message.serverTime, () => message.serverTime = dependency_10.ServerTimeResponse.deserialize(reader));
                    break;
                case 69:
                    reader.readMessage(message.hotDApps, () => message.hotDApps = dependency_12.HotDAppsResponse.deserialize(reader));
                    break;
                case 70:
                    reader.readMessage(message.richList, () => message.richList = dependency_12.RichListResponse.deserialize(reader));
                    break;
                case 71:
                    reader.readMessage(message.collectedFeesStat, () => message.collectedFeesStat = dependency_12.CollectedFeesStatResponse.deserialize(reader));
                    break;
                case 72:
                    reader.readMessage(message.transactionListForWallet, () => message.transactionListForWallet = dependency_14.TransactionListForWalletResponse.deserialize(reader));
                    break;
                case 92:
                    reader.readMessage(message.transactionListForWalletByIndexList, () => message.transactionListForWalletByIndexList = dependency_14.TransactionListForWalletByIndexListResponse.deserialize(reader));
                    break;
                case 76:
                    reader.readMessage(message.stakes, () => message.stakes = dependency_15.StakesResponse.deserialize(reader));
                    break;
                case 81:
                    reader.readMessage(message.internalStat, () => message.internalStat = dependency_12.IntervalStatResponse.deserialize(reader));
                    break;
                case 38:
                    reader.readMessage(message.erc721List, () => message.erc721List = dependency_7.ERC721TokenListResponse.deserialize(reader));
                    break;
                case 39:
                    reader.readMessage(message.erc721Details, () => message.erc721Details = dependency_7.ERC721TokenDetailsResponse.deserialize(reader));
                    break;
                case 50:
                    reader.readMessage(message.erc721TransferList, () => message.erc721TransferList = dependency_7.ERC721TransferListResponse.deserialize(reader));
                    break;
                case 51:
                    reader.readMessage(message.erc721HolderList, () => message.erc721HolderList = dependency_7.ERC721HolderListResponse.deserialize(reader));
                    break;
                case 52:
                    reader.readMessage(message.erc721Inventory, () => message.erc721Inventory = dependency_7.ERC721InventoryListResponse.deserialize(reader));
                    break;
                case 53:
                    reader.readMessage(message.erc721Update, () => message.erc721Update = dependency_7.ERC721UpdateResponse.deserialize(reader));
                    break;
                case 54:
                    reader.readMessage(message.erc721UpdateIcon, () => message.erc721UpdateIcon = dependency_7.ERC721UpdateIconResponse.deserialize(reader));
                    break;
                case 60:
                    reader.readMessage(message.erc1155List, () => message.erc1155List = dependency_8.ERC1155TokenListResponse.deserialize(reader));
                    break;
                case 61:
                    reader.readMessage(message.erc1155Details, () => message.erc1155Details = dependency_8.ERC1155TokenDetailsResponse.deserialize(reader));
                    break;
                case 62:
                    reader.readMessage(message.erc1155TransferList, () => message.erc1155TransferList = dependency_8.ERC1155TransferListResponse.deserialize(reader));
                    break;
                case 63:
                    reader.readMessage(message.erc1155HolderList, () => message.erc1155HolderList = dependency_8.ERC1155HolderListResponse.deserialize(reader));
                    break;
                case 64:
                    reader.readMessage(message.erc1155Inventory, () => message.erc1155Inventory = dependency_8.ERC1155InventoryListResponse.deserialize(reader));
                    break;
                case 65:
                    reader.readMessage(message.erc1155Update, () => message.erc1155Update = dependency_8.ERC1155UpdateResponse.deserialize(reader));
                    break;
                case 66:
                    reader.readMessage(message.erc1155UpdateIcon, () => message.erc1155UpdateIcon = dependency_8.ERC1155UpdateIconResponse.deserialize(reader));
                    break;
                case 55:
                    reader.readMessage(message.tokenList, () => message.tokenList = dependency_13.TokenListResponse.deserialize(reader));
                    break;
                case 56:
                    reader.readMessage(message.tokenDetails, () => message.tokenDetails = dependency_13.TokenDetailsResponse.deserialize(reader));
                    break;
                case 57:
                    reader.readMessage(message.transferList, () => message.transferList = dependency_13.TransferListResponse.deserialize(reader));
                    break;
                case 58:
                    reader.readMessage(message.holderList, () => message.holderList = dependency_13.HolderListResponse.deserialize(reader));
                    break;
                case 59:
                    reader.readMessage(message.nftDetails, () => message.nftDetails = dependency_13.NFTDetailsResponse.deserialize(reader));
                    break;
                case 67:
                    reader.readMessage(message.inventoryList, () => message.inventoryList = dependency_13.InventoryListResponse.deserialize(reader));
                    break;
                case 73:
                    reader.readMessage(message.assetData, () => message.assetData = dependency_16.AssetDataResponse.deserialize(reader));
                    break;
                case 74:
                    reader.readMessage(message.assetTransactions, () => message.assetTransactions = dependency_16.AssetTransactionListResponse.deserialize(reader));
                    break;
                case 75:
                    reader.readMessage(message.assetBalances, () => message.assetBalances = dependency_16.AssetBalancesResponse.deserialize(reader));
                    break;
                case 77:
                    reader.readMessage(message.totalStakeAmount, () => message.totalStakeAmount = dependency_15.TotalStakeAmountResponse.deserialize(reader));
                    break;
                case 78:
                    reader.readMessage(message.bytesForChain, () => message.bytesForChain = dependency_17.BytesForChainResponse.deserialize(reader));
                    break;
                case 79:
                    reader.readMessage(message.blockBytes, () => message.blockBytes = dependency_17.BlockBytesResponse.deserialize(reader));
                    break;
                case 80:
                    reader.readMessage(message.verification, () => message.verification = dependency_2.EtherscanLikeResponse.deserialize(reader));
                    break;
                case 82:
                    reader.readMessage(message.networkUsage, () => message.networkUsage = dependency_12.NetworkUsageResponse.deserialize(reader));
                    break;
                case 87:
                    reader.readMessage(message.nativeTransfers, () => message.nativeTransfers = dependency_14.NativeTransferListResponse.deserialize(reader));
                    break;
                case 89:
                    reader.readMessage(message.nodeDetailsUpdate, () => message.nodeDetailsUpdate = dependency_15.NodeDetailsUpdateResponse.deserialize(reader));
                    break;
                case 90:
                    reader.readMessage(message.nodeDetails, () => message.nodeDetails = dependency_15.NodeDetailsResponse.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Response {
        return Response.deserialize(bytes);
    }
}
export namespace Response {
    export type AsObject = {
        error?: dependency_10.ErrorResponse.AsObject;
        empty?: dependency_10.EmptyMessage.AsObject;
        supernetList?: dependency_5.SupernetListResponse.AsObject;
        blockchainList?: dependency_5.BlockchainListResponse.AsObject;
        blockList?: dependency_4.BlockListResponse.AsObject;
        transactionList?: dependency_14.TransactionListResponse.AsObject;
        validatorList?: dependency_15.ValidatorListResponse.AsObject;
        search?: dependency_11.SearchResponse.AsObject;
        delegatorList?: dependency_15.DelegationListResponse.AsObject;
        addressData?: dependency_2.AddressDataResponse.AsObject;
        utxoChainsBalances?: dependency_2.UtxoChainsBalancesResponse.AsObject;
        overallStat?: dependency_12.OverallStatResponse.AsObject;
        blockchainStat?: dependency_12.BlockchainStatResponse.AsObject;
        blockchainYearStat?: dependency_12.BlockchainYearStatResponse.AsObject;
        transactionDetails?: dependency_14.TransactionDetailsResponse.AsObject;
        erc20List?: dependency_6.ERC20TokenListResponse.AsObject;
        erc20Details?: dependency_6.ERC20TokenDetailsResponse.AsObject;
        logList?: dependency_9.LogListResponse.AsObject;
        internalTransactionList?: dependency_9.InternalTransactionListResponse.AsObject;
        erc20TransferList?: dependency_6.ERC20TransferListResponse.AsObject;
        erc20HolderList?: dependency_6.ERC20HolderListResponse.AsObject;
        supernetUpdate?: dependency_5.SupernetUpdateResponse.AsObject;
        blockchainUpdate?: dependency_5.BlockchainUpdateResponse.AsObject;
        blockchainUpdateIcon?: dependency_5.BlockchainUpdateIconResponse.AsObject;
        erc20Update?: dependency_6.ERC20UpdateResponse.AsObject;
        erc20UpdateIcon?: dependency_6.ERC20UpdateIconResponse.AsObject;
        checkLogPass?: dependency_3.CheckLogPassResponse.AsObject;
        serverTime?: dependency_10.ServerTimeResponse.AsObject;
        hotDApps?: dependency_12.HotDAppsResponse.AsObject;
        richList?: dependency_12.RichListResponse.AsObject;
        collectedFeesStat?: dependency_12.CollectedFeesStatResponse.AsObject;
        transactionListForWallet?: dependency_14.TransactionListForWalletResponse.AsObject;
        transactionListForWalletByIndexList?: dependency_14.TransactionListForWalletByIndexListResponse.AsObject;
        stakes?: dependency_15.StakesResponse.AsObject;
        internalStat?: dependency_12.IntervalStatResponse.AsObject;
        erc721List?: dependency_7.ERC721TokenListResponse.AsObject;
        erc721Details?: dependency_7.ERC721TokenDetailsResponse.AsObject;
        erc721TransferList?: dependency_7.ERC721TransferListResponse.AsObject;
        erc721HolderList?: dependency_7.ERC721HolderListResponse.AsObject;
        erc721Inventory?: dependency_7.ERC721InventoryListResponse.AsObject;
        erc721Update?: dependency_7.ERC721UpdateResponse.AsObject;
        erc721UpdateIcon?: dependency_7.ERC721UpdateIconResponse.AsObject;
        erc1155List?: dependency_8.ERC1155TokenListResponse.AsObject;
        erc1155Details?: dependency_8.ERC1155TokenDetailsResponse.AsObject;
        erc1155TransferList?: dependency_8.ERC1155TransferListResponse.AsObject;
        erc1155HolderList?: dependency_8.ERC1155HolderListResponse.AsObject;
        erc1155Inventory?: dependency_8.ERC1155InventoryListResponse.AsObject;
        erc1155Update?: dependency_8.ERC1155UpdateResponse.AsObject;
        erc1155UpdateIcon?: dependency_8.ERC1155UpdateIconResponse.AsObject;
        tokenList?: dependency_13.TokenListResponse.AsObject;
        tokenDetails?: dependency_13.TokenDetailsResponse.AsObject;
        transferList?: dependency_13.TransferListResponse.AsObject;
        holderList?: dependency_13.HolderListResponse.AsObject;
        nftDetails?: dependency_13.NFTDetailsResponse.AsObject;
        inventoryList?: dependency_13.InventoryListResponse.AsObject;
        assetData?: dependency_16.AssetDataResponse.AsObject;
        assetTransactions?: dependency_16.AssetTransactionListResponse.AsObject;
        assetBalances?: dependency_16.AssetBalancesResponse.AsObject;
        totalStakeAmount?: dependency_15.TotalStakeAmountResponse.AsObject;
        bytesForChain?: dependency_17.BytesForChainResponse.AsObject;
        blockBytes?: dependency_17.BlockBytesResponse.AsObject;
        verification?: dependency_2.EtherscanLikeResponse.AsObject;
        networkUsage?: dependency_12.NetworkUsageResponse.AsObject;
        nativeTransfers?: dependency_14.NativeTransferListResponse.AsObject;
        nodeDetailsUpdate?: dependency_15.NodeDetailsUpdateResponse.AsObject;
        nodeDetails?: dependency_15.NodeDetailsResponse.AsObject;
    };
    export type AsObjectPartial = {
        error?: dependency_10.ErrorResponse.AsObjectPartial;
        empty?: dependency_10.EmptyMessage.AsObjectPartial;
        supernetList?: dependency_5.SupernetListResponse.AsObjectPartial;
        blockchainList?: dependency_5.BlockchainListResponse.AsObjectPartial;
        blockList?: dependency_4.BlockListResponse.AsObjectPartial;
        transactionList?: dependency_14.TransactionListResponse.AsObjectPartial;
        validatorList?: dependency_15.ValidatorListResponse.AsObjectPartial;
        search?: dependency_11.SearchResponse.AsObjectPartial;
        delegatorList?: dependency_15.DelegationListResponse.AsObjectPartial;
        addressData?: dependency_2.AddressDataResponse.AsObjectPartial;
        utxoChainsBalances?: dependency_2.UtxoChainsBalancesResponse.AsObjectPartial;
        overallStat?: dependency_12.OverallStatResponse.AsObjectPartial;
        blockchainStat?: dependency_12.BlockchainStatResponse.AsObjectPartial;
        blockchainYearStat?: dependency_12.BlockchainYearStatResponse.AsObjectPartial;
        transactionDetails?: dependency_14.TransactionDetailsResponse.AsObjectPartial;
        erc20List?: dependency_6.ERC20TokenListResponse.AsObjectPartial;
        erc20Details?: dependency_6.ERC20TokenDetailsResponse.AsObjectPartial;
        logList?: dependency_9.LogListResponse.AsObjectPartial;
        internalTransactionList?: dependency_9.InternalTransactionListResponse.AsObjectPartial;
        erc20TransferList?: dependency_6.ERC20TransferListResponse.AsObjectPartial;
        erc20HolderList?: dependency_6.ERC20HolderListResponse.AsObjectPartial;
        supernetUpdate?: dependency_5.SupernetUpdateResponse.AsObjectPartial;
        blockchainUpdate?: dependency_5.BlockchainUpdateResponse.AsObjectPartial;
        blockchainUpdateIcon?: dependency_5.BlockchainUpdateIconResponse.AsObjectPartial;
        erc20Update?: dependency_6.ERC20UpdateResponse.AsObjectPartial;
        erc20UpdateIcon?: dependency_6.ERC20UpdateIconResponse.AsObjectPartial;
        checkLogPass?: dependency_3.CheckLogPassResponse.AsObjectPartial;
        serverTime?: dependency_10.ServerTimeResponse.AsObjectPartial;
        hotDApps?: dependency_12.HotDAppsResponse.AsObjectPartial;
        richList?: dependency_12.RichListResponse.AsObjectPartial;
        collectedFeesStat?: dependency_12.CollectedFeesStatResponse.AsObjectPartial;
        transactionListForWallet?: dependency_14.TransactionListForWalletResponse.AsObjectPartial;
        transactionListForWalletByIndexList?: dependency_14.TransactionListForWalletByIndexListResponse.AsObjectPartial;
        stakes?: dependency_15.StakesResponse.AsObjectPartial;
        internalStat?: dependency_12.IntervalStatResponse.AsObjectPartial;
        erc721List?: dependency_7.ERC721TokenListResponse.AsObjectPartial;
        erc721Details?: dependency_7.ERC721TokenDetailsResponse.AsObjectPartial;
        erc721TransferList?: dependency_7.ERC721TransferListResponse.AsObjectPartial;
        erc721HolderList?: dependency_7.ERC721HolderListResponse.AsObjectPartial;
        erc721Inventory?: dependency_7.ERC721InventoryListResponse.AsObjectPartial;
        erc721Update?: dependency_7.ERC721UpdateResponse.AsObjectPartial;
        erc721UpdateIcon?: dependency_7.ERC721UpdateIconResponse.AsObjectPartial;
        erc1155List?: dependency_8.ERC1155TokenListResponse.AsObjectPartial;
        erc1155Details?: dependency_8.ERC1155TokenDetailsResponse.AsObjectPartial;
        erc1155TransferList?: dependency_8.ERC1155TransferListResponse.AsObjectPartial;
        erc1155HolderList?: dependency_8.ERC1155HolderListResponse.AsObjectPartial;
        erc1155Inventory?: dependency_8.ERC1155InventoryListResponse.AsObjectPartial;
        erc1155Update?: dependency_8.ERC1155UpdateResponse.AsObjectPartial;
        erc1155UpdateIcon?: dependency_8.ERC1155UpdateIconResponse.AsObjectPartial;
        tokenList?: dependency_13.TokenListResponse.AsObjectPartial;
        tokenDetails?: dependency_13.TokenDetailsResponse.AsObjectPartial;
        transferList?: dependency_13.TransferListResponse.AsObjectPartial;
        holderList?: dependency_13.HolderListResponse.AsObjectPartial;
        nftDetails?: dependency_13.NFTDetailsResponse.AsObjectPartial;
        inventoryList?: dependency_13.InventoryListResponse.AsObjectPartial;
        assetData?: dependency_16.AssetDataResponse.AsObjectPartial;
        assetTransactions?: dependency_16.AssetTransactionListResponse.AsObjectPartial;
        assetBalances?: dependency_16.AssetBalancesResponse.AsObjectPartial;
        totalStakeAmount?: dependency_15.TotalStakeAmountResponse.AsObjectPartial;
        bytesForChain?: dependency_17.BytesForChainResponse.AsObjectPartial;
        blockBytes?: dependency_17.BlockBytesResponse.AsObjectPartial;
        verification?: dependency_2.EtherscanLikeResponse.AsObjectPartial;
        networkUsage?: dependency_12.NetworkUsageResponse.AsObjectPartial;
        nativeTransfers?: dependency_14.NativeTransferListResponse.AsObjectPartial;
        nodeDetailsUpdate?: dependency_15.NodeDetailsUpdateResponse.AsObjectPartial;
        nodeDetails?: dependency_15.NodeDetailsResponse.AsObjectPartial;
    };
}
export class SubData extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 5, 4]];
    constructor(data?: any[] | ({} & (({
        block?: dependency_4.BlockSub;
        validators?: never;
        supernets?: never;
        blockchains?: never;
        delegations?: never;
    } | {
        block?: never;
        validators?: dependency_15.ValidatorListResponse;
        supernets?: never;
        blockchains?: never;
        delegations?: never;
    } | {
        block?: never;
        validators?: never;
        supernets?: dependency_5.SupernetListResponse;
        blockchains?: never;
        delegations?: never;
    } | {
        block?: never;
        validators?: never;
        supernets?: never;
        blockchains?: dependency_5.BlockchainListResponse;
        delegations?: never;
    } | {
        block?: never;
        validators?: never;
        supernets?: never;
        blockchains?: never;
        delegations?: dependency_15.DelegationListResponse;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("block" in data && data.block != undefined) {
                this.block = data.block;
            }
            if ("validators" in data && data.validators != undefined) {
                this.validators = data.validators;
            }
            if ("supernets" in data && data.supernets != undefined) {
                this.supernets = data.supernets;
            }
            if ("blockchains" in data && data.blockchains != undefined) {
                this.blockchains = data.blockchains;
            }
            if ("delegations" in data && data.delegations != undefined) {
                this.delegations = data.delegations;
            }
        }
    }
    get block() {
        return pb_1.Message.getWrapperField(this, dependency_4.BlockSub, 1) as dependency_4.BlockSub | undefined;
    }
    set block(value: dependency_4.BlockSub | undefined) {
        pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasBlock() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearBlock(): void {
        this.block = undefined!;
    }
    get validators() {
        return pb_1.Message.getWrapperField(this, dependency_15.ValidatorListResponse, 2) as dependency_15.ValidatorListResponse | undefined;
    }
    set validators(value: dependency_15.ValidatorListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasValidators() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearValidators(): void {
        this.validators = undefined!;
    }
    get supernets() {
        return pb_1.Message.getWrapperField(this, dependency_5.SupernetListResponse, 3) as dependency_5.SupernetListResponse | undefined;
    }
    set supernets(value: dependency_5.SupernetListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasSupernets() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearSupernets(): void {
        this.supernets = undefined!;
    }
    get blockchains() {
        return pb_1.Message.getWrapperField(this, dependency_5.BlockchainListResponse, 5) as dependency_5.BlockchainListResponse | undefined;
    }
    set blockchains(value: dependency_5.BlockchainListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasBlockchains() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearBlockchains(): void {
        this.blockchains = undefined!;
    }
    get delegations() {
        return pb_1.Message.getWrapperField(this, dependency_15.DelegationListResponse, 4) as dependency_15.DelegationListResponse | undefined;
    }
    set delegations(value: dependency_15.DelegationListResponse | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasDelegations() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearDelegations(): void {
        this.delegations = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "block" | "validators" | "supernets" | "blockchains" | "delegations";
        } = {
            0: "none",
            1: "block",
            2: "validators",
            3: "supernets",
            5: "blockchains",
            4: "delegations"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 5, 4])]!;
    }
    static fromObject(data?: SubData.AsObjectPartial): SubData {
        if (!data) {
            return new SubData();
        }
        const message = new SubData({});
        if (data.block != null) {
            message.block = dependency_4.BlockSub.fromObject(data.block);
        }
        if (data.validators != null) {
            message.validators = dependency_15.ValidatorListResponse.fromObject(data.validators);
        }
        if (data.supernets != null) {
            message.supernets = dependency_5.SupernetListResponse.fromObject(data.supernets);
        }
        if (data.blockchains != null) {
            message.blockchains = dependency_5.BlockchainListResponse.fromObject(data.blockchains);
        }
        if (data.delegations != null) {
            message.delegations = dependency_15.DelegationListResponse.fromObject(data.delegations);
        }
        return message;
    }
    toObject() {
        const data: SubData.AsObject = {};
        if (this.block != null) {
            data.block = this.block.toObject();
        }
        if (this.validators != null) {
            data.validators = this.validators.toObject();
        }
        if (this.supernets != null) {
            data.supernets = this.supernets.toObject();
        }
        if (this.blockchains != null) {
            data.blockchains = this.blockchains.toObject();
        }
        if (this.delegations != null) {
            data.delegations = this.delegations.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasBlock)
            writer.writeMessage(1, this.block, () => this.block!.serialize(writer));
        if (this.hasValidators)
            writer.writeMessage(2, this.validators, () => this.validators!.serialize(writer));
        if (this.hasSupernets)
            writer.writeMessage(3, this.supernets, () => this.supernets!.serialize(writer));
        if (this.hasBlockchains)
            writer.writeMessage(5, this.blockchains, () => this.blockchains!.serialize(writer));
        if (this.hasDelegations)
            writer.writeMessage(4, this.delegations, () => this.delegations!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SubData {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SubData();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.block, () => message.block = dependency_4.BlockSub.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.validators, () => message.validators = dependency_15.ValidatorListResponse.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.supernets, () => message.supernets = dependency_5.SupernetListResponse.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.blockchains, () => message.blockchains = dependency_5.BlockchainListResponse.deserialize(reader));
                    break;
                case 4:
                    reader.readMessage(message.delegations, () => message.delegations = dependency_15.DelegationListResponse.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SubData {
        return SubData.deserialize(bytes);
    }
}
export namespace SubData {
    export type AsObject = {
        block?: dependency_4.BlockSub.AsObject;
        validators?: dependency_15.ValidatorListResponse.AsObject;
        supernets?: dependency_5.SupernetListResponse.AsObject;
        blockchains?: dependency_5.BlockchainListResponse.AsObject;
        delegations?: dependency_15.DelegationListResponse.AsObject;
    };
    export type AsObjectPartial = {
        block?: dependency_4.BlockSub.AsObjectPartial;
        validators?: dependency_15.ValidatorListResponse.AsObjectPartial;
        supernets?: dependency_5.SupernetListResponse.AsObjectPartial;
        blockchains?: dependency_5.BlockchainListResponse.AsObjectPartial;
        delegations?: dependency_15.DelegationListResponse.AsObjectPartial;
    };
}
