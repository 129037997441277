import "./DynamicTable.scss";

import React, { memo, useEffect, useMemo, useState } from "react";

import Button from "../Button/Button";
import { breakpoints } from "../config/Breakpoints";
import useWindowWidth from "../hooks/useWindowWidth";
import MobileCardTemplates, {
  MobileCardTemplateType,
} from "./MobileCardTemplates";
import PaginationControls from "./PaginationControls/PaginationControls";
import RowAmount from "./RowAmount/RowAmount";
import Sort from "./sort.svg?react";

export interface Column {
  title: string | JSX.Element | undefined;
  dataIndex: string;
  colunmWidth?: string;
  sortable?: boolean; // Indiquate if the column is sortable
  onSort?: () => void; // Fonction to call when the column is sorted
  sortableClass?: string; // Class to apply to the button when the column is sorted
  mobile?: {
    titleVisible?: boolean;
    visible: boolean;
    order?: number; // Ordre d'affichage en mode mobile
  };
  render?: (
    value: any,
    record: any,
    index: number,
  ) => JSX.Element | string | number;
}

interface DynamicTableProps {
  data: any[];
  columns: Column[];
  onClick?: (record: any) => void;
  rowHeight?: number;
  maxRow?: number;
  mobile?: boolean;
  mobileBreakpoint?: number;
  className?: string;
  pagined?: boolean;
  scrollable?: boolean;
  renderMobileCard?: (
    record: any,
    columns: Column[],
    rowIndex: number,
  ) => JSX.Element;
  mobileCardTemplateType?: MobileCardTemplateType;
}

const DynamicTable: React.FC<DynamicTableProps> = memo(
  ({
    data,
    columns,
    onClick,
    rowHeight = 55,
    maxRow,
    mobile,
    mobileBreakpoint = breakpoints["breakpoint-md-2"],
    className,
    pagined = false,
    scrollable = false,
    renderMobileCard,
    mobileCardTemplateType = MobileCardTemplateType.Default,
  }) => {
    // Validation to ensure only one of `pagined` or `scrollable` is true
    if (pagined && scrollable) {
      console.error(
        "DynamicTable: `pagined` and `scrollable` cannot be true at the same time. Please choose one.",
      );
      return null; // Prevent rendering if both are true
    }

    const { windowWidth } = useWindowWidth();
    const [sortState, setSortState] = useState<{
      column: string;
      direction: "asc" | "desc";
    } | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState(data);
    const [maxRows, setMaxRows] = useState(maxRow);

    // Default value from props
    useEffect(() => {
      setMaxRows(maxRow);
    }, [maxRow]);

    // when we change the maxRows, we need to check if the current page is still valid
    useEffect(() => {
      if (maxRows) {
        const totalPages = Math.ceil(data.length / maxRows);
        if (totalPages && currentPage > totalPages) {
          setCurrentPage(totalPages); // Set to the last valid page
        }
      }
    }, [maxRows]);

    useEffect(() => {
      setPaginatedData(paginatedData);
    }, [paginatedData]);

    useEffect(() => {
      if (!maxRows || !pagined) {
        setPaginatedData(data);
        return;
      }
      setPaginatedData(
        data.slice((currentPage - 1) * maxRows, currentPage * maxRows),
      );
    }, [data, currentPage, maxRows, scrollable]);

    const handleSort = (col: Column) => {
      if (col.sortable) {
        const direction = sortState?.direction === "asc" ? "desc" : "asc";
        setSortState({ column: col.dataIndex, direction });
        if (col.onSort) {
          col.onSort();
        }
      }
    };

    const getSortClass = (col: Column) => {
      if (sortState && sortState.column === col.dataIndex) {
        return `active`;
      }
      return "";
    };

    const gridTemplateColumns = useMemo(() => {
      return columns.map((col) => col.colunmWidth || "1fr").join(" ");
    }, [columns]);

    const tableBodyStyle: React.CSSProperties = useMemo(() => {
      if (pagined) {
        return {};
      }
      return maxRows
        ? {
            maxHeight: `${maxRows * rowHeight - 2}px`,
            overflowY: scrollable ? "auto" : "unset",
          }
        : {};
    }, [maxRows, rowHeight]);

    // Mobile mode or not
    if (mobile && mobileBreakpoint >= windowWidth) {
      return (
        <div className="mobileTable">
          {paginatedData.map((record, rowIndex) => (
            <MobileCardTemplates
              key={rowIndex}
              record={record}
              columns={columns}
              rowIndex={rowIndex}
              type={mobileCardTemplateType}
              onClick={onClick}
              renderMobileCard={renderMobileCard}
            />
          ))}
          {pagined && maxRows && (
            <div className="tableControllers">
              <RowAmount maxRows={maxRows} setMaxRows={setMaxRows} />
              <PaginationControls
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                totalCount={data.length}
                pageSize={maxRows}
                siblingCount={2}
              />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className={`dynamicTable ${className ?? ""}`}>
          <header
            className="tableHeader"
            style={{ gridTemplateColumns: gridTemplateColumns }}
          >
            {columns.map((col, index) => (
              <span
                key={index}
                className={`col ${col.sortable ? "sortable" : ""}`}
                onClick={() => col.sortable && handleSort(col)}
              >
                {col.title}
                {col.sortable && (
                  <Button
                    icon={<Sort />}
                    style="transparent"
                    // give asc or desc class to the button
                    className={`${col.sortableClass || ""} ${getSortClass(col)}`}
                  />
                )}
              </span>
            ))}
          </header>
          <section className="tableBody" style={tableBodyStyle}>
            {paginatedData.map((record, rowIndex) => (
              <div
                className="tableRow"
                key={rowIndex}
                onClick={() => (onClick ? onClick(record) : undefined)}
                style={{
                  gridTemplateColumns: gridTemplateColumns,
                  height: `${rowHeight}px`,
                  cursor: onClick ? "pointer" : "default",
                  // paddingRight: scrollable ? "13px" : "13px",
                }}
              >
                {columns.map((col, colIndex) => (
                  <span key={colIndex} className="col">
                    {col.render
                      ? col.render(record[col.dataIndex], record, rowIndex)
                      : record[col.dataIndex]}
                  </span>
                ))}
              </div>
            ))}
          </section>
          {pagined && maxRows && !scrollable && (
            <div className="tableControllers">
              <RowAmount maxRows={maxRows} setMaxRows={setMaxRows} />
              <PaginationControls
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                totalCount={data.length}
                pageSize={maxRows}
                siblingCount={2}
              />
            </div>
          )}
        </div>
      );
    }
  },
);

DynamicTable.displayName = "DynamicTable";

export default DynamicTable;
