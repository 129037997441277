import React, { memo } from "react";

import { BreakpointKeys, breakpoints } from "../config/Breakpoints";
import useWindowWidth from "../hooks/useWindowWidth";
import DesktopNavbar from "./DesktopNavbar/DesktopNavbar";
import MobileNavbar from "./MobileNavbar/MobileNavbar";

export type NavItemType = {
  href?: string;
  link?: string;
  text: string;
  icon?: React.ReactNode;
  className: string;
};

interface NavbarProps {
  navItems: NavItemType[];
  children?: React.ReactNode;
  closeable?: boolean;
  mobileChildren?: React.ReactNode;
  showLogo?: boolean;
  showLinkLogo?: boolean;
  breakpoint?: BreakpointKeys;
}

const Navbar: React.FC<NavbarProps> = ({
  navItems,
  children,
  mobileChildren,
  closeable = false,
  showLogo = true,
  showLinkLogo = true,
  breakpoint = "breakpoint-lg-4",
}) => {
  const { windowWidth } = useWindowWidth();

  return (
    <>
      {windowWidth > breakpoints[breakpoint] ? (
        <DesktopNavbar
          navItems={navItems}
          closeable={closeable}
          showLogo={showLogo}
          showLinkLogo={showLinkLogo}
        >
          {children}
        </DesktopNavbar>
      ) : (
        <MobileNavbar navItems={navItems} showLinkLogo={showLinkLogo}>
          {mobileChildren}
        </MobileNavbar>
      )}
    </>
  );
};

export default memo(Navbar);
