/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: transaction.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./validate/validate";
import * as dependency_2 from "./models";
import * as dependency_3 from "./evm";
import * as dependency_4 from "./erc20";
import * as dependency_5 from "./erc721";
import * as dependency_6 from "./erc1155";
import * as pb_1 from "google-protobuf";
export class TransactionAVM extends pb_1.Message {
    #one_of_decls: number[][] = [[4, 5, 6, 7, 8]];
    constructor(data?: any[] | ({
        inputs?: TransactionAVM.TransferableInput[];
        outputs?: TransactionAVM.TransferableOutput[];
        creds?: TransactionAVM.Credentials[];
        candidates?: TransactionAVM.BlockCandidate[];
    } & (({
        base?: TransactionAVM.BaseTx;
        createAsset?: never;
        operation?: never;
        import?: never;
        export?: never;
    } | {
        base?: never;
        createAsset?: TransactionAVM.CreateAssetTx;
        operation?: never;
        import?: never;
        export?: never;
    } | {
        base?: never;
        createAsset?: never;
        operation?: TransactionAVM.OperationTx;
        import?: never;
        export?: never;
    } | {
        base?: never;
        createAsset?: never;
        operation?: never;
        import?: TransactionAVM.ImportTx;
        export?: never;
    } | {
        base?: never;
        createAsset?: never;
        operation?: never;
        import?: never;
        export?: TransactionAVM.ExportTx;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 2, 3, 9], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("inputs" in data && data.inputs != undefined) {
                this.inputs = data.inputs;
            }
            if ("outputs" in data && data.outputs != undefined) {
                this.outputs = data.outputs;
            }
            if ("creds" in data && data.creds != undefined) {
                this.creds = data.creds;
            }
            if ("candidates" in data && data.candidates != undefined) {
                this.candidates = data.candidates;
            }
            if ("base" in data && data.base != undefined) {
                this.base = data.base;
            }
            if ("createAsset" in data && data.createAsset != undefined) {
                this.createAsset = data.createAsset;
            }
            if ("operation" in data && data.operation != undefined) {
                this.operation = data.operation;
            }
            if ("import" in data && data.import != undefined) {
                this.import = data.import;
            }
            if ("export" in data && data.export != undefined) {
                this.export = data.export;
            }
        }
    }
    get inputs() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.TransferableInput, 1) as TransactionAVM.TransferableInput[];
    }
    set inputs(value: TransactionAVM.TransferableInput[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get outputs() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.TransferableOutput, 2) as TransactionAVM.TransferableOutput[];
    }
    set outputs(value: TransactionAVM.TransferableOutput[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    get creds() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.Credentials, 3) as TransactionAVM.Credentials[];
    }
    set creds(value: TransactionAVM.Credentials[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    get candidates() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.BlockCandidate, 9) as TransactionAVM.BlockCandidate[];
    }
    set candidates(value: TransactionAVM.BlockCandidate[]) {
        pb_1.Message.setRepeatedWrapperField(this, 9, value);
    }
    get base() {
        return pb_1.Message.getWrapperField(this, TransactionAVM.BaseTx, 4) as TransactionAVM.BaseTx | undefined;
    }
    set base(value: TransactionAVM.BaseTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasBase() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearBase(): void {
        this.base = undefined!;
    }
    get createAsset() {
        return pb_1.Message.getWrapperField(this, TransactionAVM.CreateAssetTx, 5) as TransactionAVM.CreateAssetTx | undefined;
    }
    set createAsset(value: TransactionAVM.CreateAssetTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasCreateAsset() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearCreateAsset(): void {
        this.createAsset = undefined!;
    }
    get operation() {
        return pb_1.Message.getWrapperField(this, TransactionAVM.OperationTx, 6) as TransactionAVM.OperationTx | undefined;
    }
    set operation(value: TransactionAVM.OperationTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasOperation() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearOperation(): void {
        this.operation = undefined!;
    }
    get import() {
        return pb_1.Message.getWrapperField(this, TransactionAVM.ImportTx, 7) as TransactionAVM.ImportTx | undefined;
    }
    set import(value: TransactionAVM.ImportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasImport() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearImport(): void {
        this.import = undefined!;
    }
    get export() {
        return pb_1.Message.getWrapperField(this, TransactionAVM.ExportTx, 8) as TransactionAVM.ExportTx | undefined;
    }
    set export(value: TransactionAVM.ExportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0]!, value);
    }
    get hasExport() {
        return pb_1.Message.getField(this, 8) != null;
    }
    clearExport(): void {
        this.export = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "base" | "createAsset" | "operation" | "import" | "export";
        } = {
            0: "none",
            4: "base",
            5: "createAsset",
            6: "operation",
            7: "import",
            8: "export"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4, 5, 6, 7, 8])]!;
    }
    static fromObject(data?: TransactionAVM.AsObjectPartial): TransactionAVM {
        if (!data) {
            return new TransactionAVM();
        }
        const message = new TransactionAVM({});
        if (data.inputs != null) {
            message.inputs = data.inputs.map(item => TransactionAVM.TransferableInput.fromObject(item));
        }
        if (data.outputs != null) {
            message.outputs = data.outputs.map(item => TransactionAVM.TransferableOutput.fromObject(item));
        }
        if (data.creds != null) {
            message.creds = data.creds.map(item => TransactionAVM.Credentials.fromObject(item));
        }
        if (data.candidates != null) {
            message.candidates = data.candidates.map(item => TransactionAVM.BlockCandidate.fromObject(item));
        }
        if (data.base != null) {
            message.base = TransactionAVM.BaseTx.fromObject(data.base);
        }
        if (data.createAsset != null) {
            message.createAsset = TransactionAVM.CreateAssetTx.fromObject(data.createAsset);
        }
        if (data.operation != null) {
            message.operation = TransactionAVM.OperationTx.fromObject(data.operation);
        }
        if (data.import != null) {
            message.import = TransactionAVM.ImportTx.fromObject(data.import);
        }
        if (data.export != null) {
            message.export = TransactionAVM.ExportTx.fromObject(data.export);
        }
        return message;
    }
    toObject() {
        const data: TransactionAVM.AsObject = {
            inputs: this.inputs.map((item: TransactionAVM.TransferableInput) => item.toObject()),
            outputs: this.outputs.map((item: TransactionAVM.TransferableOutput) => item.toObject()),
            creds: this.creds.map((item: TransactionAVM.Credentials) => item.toObject()),
            candidates: this.candidates.map((item: TransactionAVM.BlockCandidate) => item.toObject())
        };
        if (this.base != null) {
            data.base = this.base.toObject();
        }
        if (this.createAsset != null) {
            data.createAsset = this.createAsset.toObject();
        }
        if (this.operation != null) {
            data.operation = this.operation.toObject();
        }
        if (this.import != null) {
            data.import = this.import.toObject();
        }
        if (this.export != null) {
            data.export = this.export.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.inputs.length)
            writer.writeRepeatedMessage(1, this.inputs, (item: TransactionAVM.TransferableInput) => item!.serialize(writer));
        if (this.outputs.length)
            writer.writeRepeatedMessage(2, this.outputs, (item: TransactionAVM.TransferableOutput) => item!.serialize(writer));
        if (this.creds.length)
            writer.writeRepeatedMessage(3, this.creds, (item: TransactionAVM.Credentials) => item!.serialize(writer));
        if (this.candidates.length)
            writer.writeRepeatedMessage(9, this.candidates, (item: TransactionAVM.BlockCandidate) => item!.serialize(writer));
        if (this.hasBase)
            writer.writeMessage(4, this.base, () => this.base!.serialize(writer));
        if (this.hasCreateAsset)
            writer.writeMessage(5, this.createAsset, () => this.createAsset!.serialize(writer));
        if (this.hasOperation)
            writer.writeMessage(6, this.operation, () => this.operation!.serialize(writer));
        if (this.hasImport)
            writer.writeMessage(7, this.import, () => this.import!.serialize(writer));
        if (this.hasExport)
            writer.writeMessage(8, this.export, () => this.export!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionAVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionAVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.inputs, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TransactionAVM.TransferableInput.deserialize(reader), TransactionAVM.TransferableInput));
                    break;
                case 2:
                    reader.readMessage(message.outputs, () => pb_1.Message.addToRepeatedWrapperField(message, 2, TransactionAVM.TransferableOutput.deserialize(reader), TransactionAVM.TransferableOutput));
                    break;
                case 3:
                    reader.readMessage(message.creds, () => pb_1.Message.addToRepeatedWrapperField(message, 3, TransactionAVM.Credentials.deserialize(reader), TransactionAVM.Credentials));
                    break;
                case 9:
                    reader.readMessage(message.candidates, () => pb_1.Message.addToRepeatedWrapperField(message, 9, TransactionAVM.BlockCandidate.deserialize(reader), TransactionAVM.BlockCandidate));
                    break;
                case 4:
                    reader.readMessage(message.base, () => message.base = TransactionAVM.BaseTx.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.createAsset, () => message.createAsset = TransactionAVM.CreateAssetTx.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.operation, () => message.operation = TransactionAVM.OperationTx.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.import, () => message.import = TransactionAVM.ImportTx.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.export, () => message.export = TransactionAVM.ExportTx.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionAVM {
        return TransactionAVM.deserialize(bytes);
    }
}
export namespace TransactionAVM {
    export type AsObject = {
        inputs: TransactionAVM.TransferableInput.AsObject[];
        outputs: TransactionAVM.TransferableOutput.AsObject[];
        creds: TransactionAVM.Credentials.AsObject[];
        candidates: TransactionAVM.BlockCandidate.AsObject[];
        base?: TransactionAVM.BaseTx.AsObject;
        createAsset?: TransactionAVM.CreateAssetTx.AsObject;
        operation?: TransactionAVM.OperationTx.AsObject;
        import?: TransactionAVM.ImportTx.AsObject;
        export?: TransactionAVM.ExportTx.AsObject;
    };
    export type AsObjectPartial = {
        inputs?: TransactionAVM.TransferableInput.AsObjectPartial[];
        outputs?: TransactionAVM.TransferableOutput.AsObjectPartial[];
        creds?: TransactionAVM.Credentials.AsObjectPartial[];
        candidates?: TransactionAVM.BlockCandidate.AsObjectPartial[];
        base?: TransactionAVM.BaseTx.AsObjectPartial;
        createAsset?: TransactionAVM.CreateAssetTx.AsObjectPartial;
        operation?: TransactionAVM.OperationTx.AsObjectPartial;
        import?: TransactionAVM.ImportTx.AsObjectPartial;
        export?: TransactionAVM.ExportTx.AsObjectPartial;
    };
    export class BlockCandidate extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            index?: number;
            indexInBc?: number;
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
                if ("indexInBc" in data && data.indexInBc != undefined) {
                    this.indexInBc = data.indexInBc;
                }
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get indexInBc() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set indexInBc(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: BlockCandidate.AsObjectPartial): BlockCandidate {
            if (!data) {
                return new BlockCandidate();
            }
            const message = new BlockCandidate({});
            if (data.index != null) {
                message.index = data.index;
            }
            if (data.indexInBc != null) {
                message.indexInBc = data.indexInBc;
            }
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: BlockCandidate.AsObject = {
                index: this.index,
                indexInBc: this.indexInBc,
                id: this.id
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.index != 0)
                writer.writeInt64(1, this.index);
            if (this.indexInBc != 0)
                writer.writeInt64(2, this.indexInBc);
            if (this.id.length)
                writer.writeString(3, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockCandidate {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockCandidate();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.index = reader.readInt64();
                        break;
                    case 2:
                        message.indexInBc = reader.readInt64();
                        break;
                    case 3:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): BlockCandidate {
            return BlockCandidate.deserialize(bytes);
        }
    }
    export namespace BlockCandidate {
        export type AsObject = {
            index: number;
            indexInBc: number;
            id: string;
        };
        export type AsObjectPartial = {
            index?: number;
            indexInBc?: number;
            id?: string;
        };
    }
    export class AssetInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            symbol?: string;
            denomination?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("symbol" in data && data.symbol != undefined) {
                    this.symbol = data.symbol;
                }
                if ("denomination" in data && data.denomination != undefined) {
                    this.denomination = data.denomination;
                }
            }
        }
        get symbol() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set symbol(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get denomination() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set denomination(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: AssetInfo.AsObjectPartial): AssetInfo {
            if (!data) {
                return new AssetInfo();
            }
            const message = new AssetInfo({});
            if (data.symbol != null) {
                message.symbol = data.symbol;
            }
            if (data.denomination != null) {
                message.denomination = data.denomination;
            }
            return message;
        }
        toObject() {
            const data: AssetInfo.AsObject = {
                symbol: this.symbol,
                denomination: this.denomination
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.symbol.length)
                writer.writeString(1, this.symbol);
            if (this.denomination != 0)
                writer.writeInt32(2, this.denomination);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.symbol = reader.readString();
                        break;
                    case 2:
                        message.denomination = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AssetInfo {
            return AssetInfo.deserialize(bytes);
        }
    }
    export namespace AssetInfo {
        export type AsObject = {
            symbol: string;
            denomination: number;
        };
        export type AsObjectPartial = {
            symbol?: string;
            denomination?: number;
        };
    }
    export class TransferableInput extends pb_1.Message {
        #one_of_decls: number[][] = [[6]];
        constructor(data?: any[] | ({
            assetId?: string;
            assetInfo?: TransactionAVM.AssetInfo;
            fxId?: string;
            txId?: string;
            outputIndex?: number;
        } & (({
            secp256k1TransferInput?: TransactionAVM.TransferableInput.SECP256K1TransferInput;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("assetId" in data && data.assetId != undefined) {
                    this.assetId = data.assetId;
                }
                if ("assetInfo" in data && data.assetInfo != undefined) {
                    this.assetInfo = data.assetInfo;
                }
                if ("fxId" in data && data.fxId != undefined) {
                    this.fxId = data.fxId;
                }
                if ("txId" in data && data.txId != undefined) {
                    this.txId = data.txId;
                }
                if ("outputIndex" in data && data.outputIndex != undefined) {
                    this.outputIndex = data.outputIndex;
                }
                if ("secp256k1TransferInput" in data && data.secp256k1TransferInput != undefined) {
                    this.secp256k1TransferInput = data.secp256k1TransferInput;
                }
            }
        }
        get assetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set assetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get assetInfo() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.AssetInfo, 2) as TransactionAVM.AssetInfo | undefined;
        }
        set assetInfo(value: TransactionAVM.AssetInfo | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasAssetInfo() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearAssetInfo(): void {
            this.assetInfo = undefined!;
        }
        get fxId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set fxId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get txId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set txId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get outputIndex() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set outputIndex(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get secp256k1TransferInput() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableInput.SECP256K1TransferInput, 6) as TransactionAVM.TransferableInput.SECP256K1TransferInput | undefined;
        }
        set secp256k1TransferInput(value: TransactionAVM.TransferableInput.SECP256K1TransferInput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
        }
        get hasSecp256k1TransferInput() {
            return pb_1.Message.getField(this, 6) != null;
        }
        clearSecp256k1TransferInput(): void {
            this.secp256k1TransferInput = undefined!;
        }
        get input() {
            const cases: {
                [index: number]: "none" | "secp256k1TransferInput";
            } = {
                0: "none",
                6: "secp256k1TransferInput"
            };
            return cases[pb_1.Message.computeOneofCase(this, [6])]!;
        }
        static fromObject(data?: TransferableInput.AsObjectPartial): TransferableInput {
            if (!data) {
                return new TransferableInput();
            }
            const message = new TransferableInput({});
            if (data.assetId != null) {
                message.assetId = data.assetId;
            }
            if (data.assetInfo != null) {
                message.assetInfo = TransactionAVM.AssetInfo.fromObject(data.assetInfo);
            }
            if (data.fxId != null) {
                message.fxId = data.fxId;
            }
            if (data.txId != null) {
                message.txId = data.txId;
            }
            if (data.outputIndex != null) {
                message.outputIndex = data.outputIndex;
            }
            if (data.secp256k1TransferInput != null) {
                message.secp256k1TransferInput = TransactionAVM.TransferableInput.SECP256K1TransferInput.fromObject(data.secp256k1TransferInput);
            }
            return message;
        }
        toObject() {
            const data: TransferableInput.AsObject = {
                assetId: this.assetId,
                fxId: this.fxId,
                txId: this.txId,
                outputIndex: this.outputIndex
            };
            if (this.assetInfo != null) {
                data.assetInfo = this.assetInfo.toObject();
            }
            if (this.secp256k1TransferInput != null) {
                data.secp256k1TransferInput = this.secp256k1TransferInput.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.assetId.length)
                writer.writeString(1, this.assetId);
            if (this.hasAssetInfo)
                writer.writeMessage(2, this.assetInfo, () => this.assetInfo!.serialize(writer));
            if (this.fxId.length)
                writer.writeString(3, this.fxId);
            if (this.txId.length)
                writer.writeString(4, this.txId);
            if (this.outputIndex != 0)
                writer.writeUint32(5, this.outputIndex);
            if (this.hasSecp256k1TransferInput)
                writer.writeMessage(6, this.secp256k1TransferInput, () => this.secp256k1TransferInput!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferableInput {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferableInput();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.assetId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.assetInfo, () => message.assetInfo = TransactionAVM.AssetInfo.deserialize(reader));
                        break;
                    case 3:
                        message.fxId = reader.readString();
                        break;
                    case 4:
                        message.txId = reader.readString();
                        break;
                    case 5:
                        message.outputIndex = reader.readUint32();
                        break;
                    case 6:
                        reader.readMessage(message.secp256k1TransferInput, () => message.secp256k1TransferInput = TransactionAVM.TransferableInput.SECP256K1TransferInput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransferableInput {
            return TransferableInput.deserialize(bytes);
        }
    }
    export namespace TransferableInput {
        export type AsObject = {
            assetId: string;
            assetInfo?: TransactionAVM.AssetInfo.AsObject;
            fxId: string;
            txId: string;
            outputIndex: number;
            secp256k1TransferInput?: TransactionAVM.TransferableInput.SECP256K1TransferInput.AsObject;
        };
        export type AsObjectPartial = {
            assetId?: string;
            assetInfo?: TransactionAVM.AssetInfo.AsObjectPartial;
            fxId?: string;
            txId?: string;
            outputIndex?: number;
            secp256k1TransferInput?: TransactionAVM.TransferableInput.SECP256K1TransferInput.AsObjectPartial;
        };
        export class SECP256K1TransferInput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                amount?: string;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 2, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 2, value);
            }
            static fromObject(data?: SECP256K1TransferInput.AsObjectPartial): SECP256K1TransferInput {
                if (!data) {
                    return new SECP256K1TransferInput();
                }
                const message = new SECP256K1TransferInput({});
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: SECP256K1TransferInput.AsObject = {
                    amount: this.amount,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.amount.length)
                    writer.writeString(1, this.amount);
                if (this.addresses.length)
                    writer.writeRepeatedString(2, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SECP256K1TransferInput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SECP256K1TransferInput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.amount = reader.readString();
                            break;
                        case 2:
                            pb_1.Message.addToRepeatedField(message, 2, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): SECP256K1TransferInput {
                return SECP256K1TransferInput.deserialize(bytes);
            }
        }
        export namespace SECP256K1TransferInput {
            export type AsObject = {
                amount: string;
                addresses: string[];
            };
            export type AsObjectPartial = {
                amount?: string;
                addresses?: string[];
            };
        }
    }
    export class TransferableOutput extends pb_1.Message {
        #one_of_decls: number[][] = [[4, 5, 6, 7]];
        constructor(data?: any[] | ({
            assetId?: string;
            assetInfo?: TransactionAVM.AssetInfo;
            fxId?: string;
        } & (({
            secp256k1TransferOutput?: TransactionAVM.TransferableOutput.SECP256K1TransferOutput;
            secp256k1MintOutput?: never;
            nftTransferOutput?: never;
            nftMintOutput?: never;
        } | {
            secp256k1TransferOutput?: never;
            secp256k1MintOutput?: TransactionAVM.TransferableOutput.SECP256K1MintOutput;
            nftTransferOutput?: never;
            nftMintOutput?: never;
        } | {
            secp256k1TransferOutput?: never;
            secp256k1MintOutput?: never;
            nftTransferOutput?: TransactionAVM.TransferableOutput.NFTTransferOutput;
            nftMintOutput?: never;
        } | {
            secp256k1TransferOutput?: never;
            secp256k1MintOutput?: never;
            nftTransferOutput?: never;
            nftMintOutput?: TransactionAVM.TransferableOutput.NFTMintOutput;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("assetId" in data && data.assetId != undefined) {
                    this.assetId = data.assetId;
                }
                if ("assetInfo" in data && data.assetInfo != undefined) {
                    this.assetInfo = data.assetInfo;
                }
                if ("fxId" in data && data.fxId != undefined) {
                    this.fxId = data.fxId;
                }
                if ("secp256k1TransferOutput" in data && data.secp256k1TransferOutput != undefined) {
                    this.secp256k1TransferOutput = data.secp256k1TransferOutput;
                }
                if ("secp256k1MintOutput" in data && data.secp256k1MintOutput != undefined) {
                    this.secp256k1MintOutput = data.secp256k1MintOutput;
                }
                if ("nftTransferOutput" in data && data.nftTransferOutput != undefined) {
                    this.nftTransferOutput = data.nftTransferOutput;
                }
                if ("nftMintOutput" in data && data.nftMintOutput != undefined) {
                    this.nftMintOutput = data.nftMintOutput;
                }
            }
        }
        get assetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set assetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get assetInfo() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.AssetInfo, 2) as TransactionAVM.AssetInfo | undefined;
        }
        set assetInfo(value: TransactionAVM.AssetInfo | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasAssetInfo() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearAssetInfo(): void {
            this.assetInfo = undefined!;
        }
        get fxId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set fxId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get secp256k1TransferOutput() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.SECP256K1TransferOutput, 4) as TransactionAVM.TransferableOutput.SECP256K1TransferOutput | undefined;
        }
        set secp256k1TransferOutput(value: TransactionAVM.TransferableOutput.SECP256K1TransferOutput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
        }
        get hasSecp256k1TransferOutput() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearSecp256k1TransferOutput(): void {
            this.secp256k1TransferOutput = undefined!;
        }
        get secp256k1MintOutput() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.SECP256K1MintOutput, 5) as TransactionAVM.TransferableOutput.SECP256K1MintOutput | undefined;
        }
        set secp256k1MintOutput(value: TransactionAVM.TransferableOutput.SECP256K1MintOutput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
        }
        get hasSecp256k1MintOutput() {
            return pb_1.Message.getField(this, 5) != null;
        }
        clearSecp256k1MintOutput(): void {
            this.secp256k1MintOutput = undefined!;
        }
        get nftTransferOutput() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.NFTTransferOutput, 6) as TransactionAVM.TransferableOutput.NFTTransferOutput | undefined;
        }
        set nftTransferOutput(value: TransactionAVM.TransferableOutput.NFTTransferOutput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
        }
        get hasNftTransferOutput() {
            return pb_1.Message.getField(this, 6) != null;
        }
        clearNftTransferOutput(): void {
            this.nftTransferOutput = undefined!;
        }
        get nftMintOutput() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.NFTMintOutput, 7) as TransactionAVM.TransferableOutput.NFTMintOutput | undefined;
        }
        set nftMintOutput(value: TransactionAVM.TransferableOutput.NFTMintOutput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
        }
        get hasNftMintOutput() {
            return pb_1.Message.getField(this, 7) != null;
        }
        clearNftMintOutput(): void {
            this.nftMintOutput = undefined!;
        }
        get output() {
            const cases: {
                [index: number]: "none" | "secp256k1TransferOutput" | "secp256k1MintOutput" | "nftTransferOutput" | "nftMintOutput";
            } = {
                0: "none",
                4: "secp256k1TransferOutput",
                5: "secp256k1MintOutput",
                6: "nftTransferOutput",
                7: "nftMintOutput"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4, 5, 6, 7])]!;
        }
        static fromObject(data?: TransferableOutput.AsObjectPartial): TransferableOutput {
            if (!data) {
                return new TransferableOutput();
            }
            const message = new TransferableOutput({});
            if (data.assetId != null) {
                message.assetId = data.assetId;
            }
            if (data.assetInfo != null) {
                message.assetInfo = TransactionAVM.AssetInfo.fromObject(data.assetInfo);
            }
            if (data.fxId != null) {
                message.fxId = data.fxId;
            }
            if (data.secp256k1TransferOutput != null) {
                message.secp256k1TransferOutput = TransactionAVM.TransferableOutput.SECP256K1TransferOutput.fromObject(data.secp256k1TransferOutput);
            }
            if (data.secp256k1MintOutput != null) {
                message.secp256k1MintOutput = TransactionAVM.TransferableOutput.SECP256K1MintOutput.fromObject(data.secp256k1MintOutput);
            }
            if (data.nftTransferOutput != null) {
                message.nftTransferOutput = TransactionAVM.TransferableOutput.NFTTransferOutput.fromObject(data.nftTransferOutput);
            }
            if (data.nftMintOutput != null) {
                message.nftMintOutput = TransactionAVM.TransferableOutput.NFTMintOutput.fromObject(data.nftMintOutput);
            }
            return message;
        }
        toObject() {
            const data: TransferableOutput.AsObject = {
                assetId: this.assetId,
                fxId: this.fxId
            };
            if (this.assetInfo != null) {
                data.assetInfo = this.assetInfo.toObject();
            }
            if (this.secp256k1TransferOutput != null) {
                data.secp256k1TransferOutput = this.secp256k1TransferOutput.toObject();
            }
            if (this.secp256k1MintOutput != null) {
                data.secp256k1MintOutput = this.secp256k1MintOutput.toObject();
            }
            if (this.nftTransferOutput != null) {
                data.nftTransferOutput = this.nftTransferOutput.toObject();
            }
            if (this.nftMintOutput != null) {
                data.nftMintOutput = this.nftMintOutput.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.assetId.length)
                writer.writeString(1, this.assetId);
            if (this.hasAssetInfo)
                writer.writeMessage(2, this.assetInfo, () => this.assetInfo!.serialize(writer));
            if (this.fxId.length)
                writer.writeString(3, this.fxId);
            if (this.hasSecp256k1TransferOutput)
                writer.writeMessage(4, this.secp256k1TransferOutput, () => this.secp256k1TransferOutput!.serialize(writer));
            if (this.hasSecp256k1MintOutput)
                writer.writeMessage(5, this.secp256k1MintOutput, () => this.secp256k1MintOutput!.serialize(writer));
            if (this.hasNftTransferOutput)
                writer.writeMessage(6, this.nftTransferOutput, () => this.nftTransferOutput!.serialize(writer));
            if (this.hasNftMintOutput)
                writer.writeMessage(7, this.nftMintOutput, () => this.nftMintOutput!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferableOutput {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferableOutput();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.assetId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.assetInfo, () => message.assetInfo = TransactionAVM.AssetInfo.deserialize(reader));
                        break;
                    case 3:
                        message.fxId = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.secp256k1TransferOutput, () => message.secp256k1TransferOutput = TransactionAVM.TransferableOutput.SECP256K1TransferOutput.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.secp256k1MintOutput, () => message.secp256k1MintOutput = TransactionAVM.TransferableOutput.SECP256K1MintOutput.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.nftTransferOutput, () => message.nftTransferOutput = TransactionAVM.TransferableOutput.NFTTransferOutput.deserialize(reader));
                        break;
                    case 7:
                        reader.readMessage(message.nftMintOutput, () => message.nftMintOutput = TransactionAVM.TransferableOutput.NFTMintOutput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransferableOutput {
            return TransferableOutput.deserialize(bytes);
        }
    }
    export namespace TransferableOutput {
        export type AsObject = {
            assetId: string;
            assetInfo?: TransactionAVM.AssetInfo.AsObject;
            fxId: string;
            secp256k1TransferOutput?: TransactionAVM.TransferableOutput.SECP256K1TransferOutput.AsObject;
            secp256k1MintOutput?: TransactionAVM.TransferableOutput.SECP256K1MintOutput.AsObject;
            nftTransferOutput?: TransactionAVM.TransferableOutput.NFTTransferOutput.AsObject;
            nftMintOutput?: TransactionAVM.TransferableOutput.NFTMintOutput.AsObject;
        };
        export type AsObjectPartial = {
            assetId?: string;
            assetInfo?: TransactionAVM.AssetInfo.AsObjectPartial;
            fxId?: string;
            secp256k1TransferOutput?: TransactionAVM.TransferableOutput.SECP256K1TransferOutput.AsObjectPartial;
            secp256k1MintOutput?: TransactionAVM.TransferableOutput.SECP256K1MintOutput.AsObjectPartial;
            nftTransferOutput?: TransactionAVM.TransferableOutput.NFTTransferOutput.AsObjectPartial;
            nftMintOutput?: TransactionAVM.TransferableOutput.NFTMintOutput.AsObjectPartial;
        };
        export class SECP256K1TransferOutput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                amount?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("threshold" in data && data.threshold != undefined) {
                        this.threshold = data.threshold;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get threshold() {
                return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
            }
            set threshold(value: number) {
                pb_1.Message.setField(this, 3, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 4, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 4, value);
            }
            static fromObject(data?: SECP256K1TransferOutput.AsObjectPartial): SECP256K1TransferOutput {
                if (!data) {
                    return new SECP256K1TransferOutput();
                }
                const message = new SECP256K1TransferOutput({});
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.threshold != null) {
                    message.threshold = data.threshold;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: SECP256K1TransferOutput.AsObject = {
                    amount: this.amount,
                    locktime: this.locktime,
                    threshold: this.threshold,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.amount.length)
                    writer.writeString(1, this.amount);
                if (this.locktime != 0)
                    writer.writeUint64(2, this.locktime);
                if (this.threshold != 0)
                    writer.writeUint32(3, this.threshold);
                if (this.addresses.length)
                    writer.writeRepeatedString(4, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SECP256K1TransferOutput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SECP256K1TransferOutput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.amount = reader.readString();
                            break;
                        case 2:
                            message.locktime = reader.readUint64();
                            break;
                        case 3:
                            message.threshold = reader.readUint32();
                            break;
                        case 4:
                            pb_1.Message.addToRepeatedField(message, 4, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): SECP256K1TransferOutput {
                return SECP256K1TransferOutput.deserialize(bytes);
            }
        }
        export namespace SECP256K1TransferOutput {
            export type AsObject = {
                amount: string;
                locktime: number;
                threshold: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                amount?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            };
        }
        export class SECP256K1MintOutput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("threshold" in data && data.threshold != undefined) {
                        this.threshold = data.threshold;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get threshold() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set threshold(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 3, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 3, value);
            }
            static fromObject(data?: SECP256K1MintOutput.AsObjectPartial): SECP256K1MintOutput {
                if (!data) {
                    return new SECP256K1MintOutput();
                }
                const message = new SECP256K1MintOutput({});
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.threshold != null) {
                    message.threshold = data.threshold;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: SECP256K1MintOutput.AsObject = {
                    locktime: this.locktime,
                    threshold: this.threshold,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.locktime != 0)
                    writer.writeUint64(1, this.locktime);
                if (this.threshold != 0)
                    writer.writeUint32(2, this.threshold);
                if (this.addresses.length)
                    writer.writeRepeatedString(3, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SECP256K1MintOutput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SECP256K1MintOutput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.locktime = reader.readUint64();
                            break;
                        case 2:
                            message.threshold = reader.readUint32();
                            break;
                        case 3:
                            pb_1.Message.addToRepeatedField(message, 3, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): SECP256K1MintOutput {
                return SECP256K1MintOutput.deserialize(bytes);
            }
        }
        export namespace SECP256K1MintOutput {
            export type AsObject = {
                locktime: number;
                threshold: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            };
        }
        export class NFTTransferOutput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                groupId?: number;
                payload?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("groupId" in data && data.groupId != undefined) {
                        this.groupId = data.groupId;
                    }
                    if ("payload" in data && data.payload != undefined) {
                        this.payload = data.payload;
                    }
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("threshold" in data && data.threshold != undefined) {
                        this.threshold = data.threshold;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get groupId() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set groupId(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get payload() {
                return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
            }
            set payload(value: string) {
                pb_1.Message.setField(this, 2, value);
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 3, value);
            }
            get threshold() {
                return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
            }
            set threshold(value: number) {
                pb_1.Message.setField(this, 4, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 5, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 5, value);
            }
            static fromObject(data?: NFTTransferOutput.AsObjectPartial): NFTTransferOutput {
                if (!data) {
                    return new NFTTransferOutput();
                }
                const message = new NFTTransferOutput({});
                if (data.groupId != null) {
                    message.groupId = data.groupId;
                }
                if (data.payload != null) {
                    message.payload = data.payload;
                }
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.threshold != null) {
                    message.threshold = data.threshold;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: NFTTransferOutput.AsObject = {
                    groupId: this.groupId,
                    payload: this.payload,
                    locktime: this.locktime,
                    threshold: this.threshold,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.groupId != 0)
                    writer.writeUint32(1, this.groupId);
                if (this.payload.length)
                    writer.writeString(2, this.payload);
                if (this.locktime != 0)
                    writer.writeUint64(3, this.locktime);
                if (this.threshold != 0)
                    writer.writeUint32(4, this.threshold);
                if (this.addresses.length)
                    writer.writeRepeatedString(5, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NFTTransferOutput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NFTTransferOutput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.groupId = reader.readUint32();
                            break;
                        case 2:
                            message.payload = reader.readString();
                            break;
                        case 3:
                            message.locktime = reader.readUint64();
                            break;
                        case 4:
                            message.threshold = reader.readUint32();
                            break;
                        case 5:
                            pb_1.Message.addToRepeatedField(message, 5, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): NFTTransferOutput {
                return NFTTransferOutput.deserialize(bytes);
            }
        }
        export namespace NFTTransferOutput {
            export type AsObject = {
                groupId: number;
                payload: string;
                locktime: number;
                threshold: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                groupId?: number;
                payload?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            };
        }
        export class NFTMintOutput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                groupId?: number;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("groupId" in data && data.groupId != undefined) {
                        this.groupId = data.groupId;
                    }
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("threshold" in data && data.threshold != undefined) {
                        this.threshold = data.threshold;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get groupId() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set groupId(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get threshold() {
                return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
            }
            set threshold(value: number) {
                pb_1.Message.setField(this, 3, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 4, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 4, value);
            }
            static fromObject(data?: NFTMintOutput.AsObjectPartial): NFTMintOutput {
                if (!data) {
                    return new NFTMintOutput();
                }
                const message = new NFTMintOutput({});
                if (data.groupId != null) {
                    message.groupId = data.groupId;
                }
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.threshold != null) {
                    message.threshold = data.threshold;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: NFTMintOutput.AsObject = {
                    groupId: this.groupId,
                    locktime: this.locktime,
                    threshold: this.threshold,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.groupId != 0)
                    writer.writeUint32(1, this.groupId);
                if (this.locktime != 0)
                    writer.writeUint64(2, this.locktime);
                if (this.threshold != 0)
                    writer.writeUint32(3, this.threshold);
                if (this.addresses.length)
                    writer.writeRepeatedString(4, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NFTMintOutput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NFTMintOutput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.groupId = reader.readUint32();
                            break;
                        case 2:
                            message.locktime = reader.readUint64();
                            break;
                        case 3:
                            message.threshold = reader.readUint32();
                            break;
                        case 4:
                            pb_1.Message.addToRepeatedField(message, 4, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): NFTMintOutput {
                return NFTMintOutput.deserialize(bytes);
            }
        }
        export namespace NFTMintOutput {
            export type AsObject = {
                groupId: number;
                locktime: number;
                threshold: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                groupId?: number;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            };
        }
    }
    export class TransferableOp extends pb_1.Message {
        #one_of_decls: number[][] = [[4, 5, 6]];
        constructor(data?: any[] | ({
            assetId?: string;
            assetInfo?: TransactionAVM.AssetInfo;
            utxoIds?: TransactionAVM.TransferableOp.UTXO[];
        } & (({
            secp256k1MintOperation?: TransactionAVM.TransferableOp.SECP256K1MintOperation;
            nftMintOperation?: never;
            nftTransferOperation?: never;
        } | {
            secp256k1MintOperation?: never;
            nftMintOperation?: TransactionAVM.TransferableOp.NFTMintOperation;
            nftTransferOperation?: never;
        } | {
            secp256k1MintOperation?: never;
            nftMintOperation?: never;
            nftTransferOperation?: TransactionAVM.TransferableOp.NFTTransferOperation;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("assetId" in data && data.assetId != undefined) {
                    this.assetId = data.assetId;
                }
                if ("assetInfo" in data && data.assetInfo != undefined) {
                    this.assetInfo = data.assetInfo;
                }
                if ("utxoIds" in data && data.utxoIds != undefined) {
                    this.utxoIds = data.utxoIds;
                }
                if ("secp256k1MintOperation" in data && data.secp256k1MintOperation != undefined) {
                    this.secp256k1MintOperation = data.secp256k1MintOperation;
                }
                if ("nftMintOperation" in data && data.nftMintOperation != undefined) {
                    this.nftMintOperation = data.nftMintOperation;
                }
                if ("nftTransferOperation" in data && data.nftTransferOperation != undefined) {
                    this.nftTransferOperation = data.nftTransferOperation;
                }
            }
        }
        get assetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set assetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get assetInfo() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.AssetInfo, 2) as TransactionAVM.AssetInfo | undefined;
        }
        set assetInfo(value: TransactionAVM.AssetInfo | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasAssetInfo() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearAssetInfo(): void {
            this.assetInfo = undefined!;
        }
        get utxoIds() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.TransferableOp.UTXO, 3) as TransactionAVM.TransferableOp.UTXO[];
        }
        set utxoIds(value: TransactionAVM.TransferableOp.UTXO[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        get secp256k1MintOperation() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOp.SECP256K1MintOperation, 4) as TransactionAVM.TransferableOp.SECP256K1MintOperation | undefined;
        }
        set secp256k1MintOperation(value: TransactionAVM.TransferableOp.SECP256K1MintOperation | undefined) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
        }
        get hasSecp256k1MintOperation() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearSecp256k1MintOperation(): void {
            this.secp256k1MintOperation = undefined!;
        }
        get nftMintOperation() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOp.NFTMintOperation, 5) as TransactionAVM.TransferableOp.NFTMintOperation | undefined;
        }
        set nftMintOperation(value: TransactionAVM.TransferableOp.NFTMintOperation | undefined) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
        }
        get hasNftMintOperation() {
            return pb_1.Message.getField(this, 5) != null;
        }
        clearNftMintOperation(): void {
            this.nftMintOperation = undefined!;
        }
        get nftTransferOperation() {
            return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOp.NFTTransferOperation, 6) as TransactionAVM.TransferableOp.NFTTransferOperation | undefined;
        }
        set nftTransferOperation(value: TransactionAVM.TransferableOp.NFTTransferOperation | undefined) {
            pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
        }
        get hasNftTransferOperation() {
            return pb_1.Message.getField(this, 6) != null;
        }
        clearNftTransferOperation(): void {
            this.nftTransferOperation = undefined!;
        }
        get transfer_op() {
            const cases: {
                [index: number]: "none" | "secp256k1MintOperation" | "nftMintOperation" | "nftTransferOperation";
            } = {
                0: "none",
                4: "secp256k1MintOperation",
                5: "nftMintOperation",
                6: "nftTransferOperation"
            };
            return cases[pb_1.Message.computeOneofCase(this, [4, 5, 6])]!;
        }
        static fromObject(data?: TransferableOp.AsObjectPartial): TransferableOp {
            if (!data) {
                return new TransferableOp();
            }
            const message = new TransferableOp({});
            if (data.assetId != null) {
                message.assetId = data.assetId;
            }
            if (data.assetInfo != null) {
                message.assetInfo = TransactionAVM.AssetInfo.fromObject(data.assetInfo);
            }
            if (data.utxoIds != null) {
                message.utxoIds = data.utxoIds.map(item => TransactionAVM.TransferableOp.UTXO.fromObject(item));
            }
            if (data.secp256k1MintOperation != null) {
                message.secp256k1MintOperation = TransactionAVM.TransferableOp.SECP256K1MintOperation.fromObject(data.secp256k1MintOperation);
            }
            if (data.nftMintOperation != null) {
                message.nftMintOperation = TransactionAVM.TransferableOp.NFTMintOperation.fromObject(data.nftMintOperation);
            }
            if (data.nftTransferOperation != null) {
                message.nftTransferOperation = TransactionAVM.TransferableOp.NFTTransferOperation.fromObject(data.nftTransferOperation);
            }
            return message;
        }
        toObject() {
            const data: TransferableOp.AsObject = {
                assetId: this.assetId,
                utxoIds: this.utxoIds.map((item: TransactionAVM.TransferableOp.UTXO) => item.toObject())
            };
            if (this.assetInfo != null) {
                data.assetInfo = this.assetInfo.toObject();
            }
            if (this.secp256k1MintOperation != null) {
                data.secp256k1MintOperation = this.secp256k1MintOperation.toObject();
            }
            if (this.nftMintOperation != null) {
                data.nftMintOperation = this.nftMintOperation.toObject();
            }
            if (this.nftTransferOperation != null) {
                data.nftTransferOperation = this.nftTransferOperation.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.assetId.length)
                writer.writeString(1, this.assetId);
            if (this.hasAssetInfo)
                writer.writeMessage(2, this.assetInfo, () => this.assetInfo!.serialize(writer));
            if (this.utxoIds.length)
                writer.writeRepeatedMessage(3, this.utxoIds, (item: TransactionAVM.TransferableOp.UTXO) => item!.serialize(writer));
            if (this.hasSecp256k1MintOperation)
                writer.writeMessage(4, this.secp256k1MintOperation, () => this.secp256k1MintOperation!.serialize(writer));
            if (this.hasNftMintOperation)
                writer.writeMessage(5, this.nftMintOperation, () => this.nftMintOperation!.serialize(writer));
            if (this.hasNftTransferOperation)
                writer.writeMessage(6, this.nftTransferOperation, () => this.nftTransferOperation!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferableOp {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferableOp();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.assetId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.assetInfo, () => message.assetInfo = TransactionAVM.AssetInfo.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.utxoIds, () => pb_1.Message.addToRepeatedWrapperField(message, 3, TransactionAVM.TransferableOp.UTXO.deserialize(reader), TransactionAVM.TransferableOp.UTXO));
                        break;
                    case 4:
                        reader.readMessage(message.secp256k1MintOperation, () => message.secp256k1MintOperation = TransactionAVM.TransferableOp.SECP256K1MintOperation.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.nftMintOperation, () => message.nftMintOperation = TransactionAVM.TransferableOp.NFTMintOperation.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.nftTransferOperation, () => message.nftTransferOperation = TransactionAVM.TransferableOp.NFTTransferOperation.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransferableOp {
            return TransferableOp.deserialize(bytes);
        }
    }
    export namespace TransferableOp {
        export type AsObject = {
            assetId: string;
            assetInfo?: TransactionAVM.AssetInfo.AsObject;
            utxoIds: TransactionAVM.TransferableOp.UTXO.AsObject[];
            secp256k1MintOperation?: TransactionAVM.TransferableOp.SECP256K1MintOperation.AsObject;
            nftMintOperation?: TransactionAVM.TransferableOp.NFTMintOperation.AsObject;
            nftTransferOperation?: TransactionAVM.TransferableOp.NFTTransferOperation.AsObject;
        };
        export type AsObjectPartial = {
            assetId?: string;
            assetInfo?: TransactionAVM.AssetInfo.AsObjectPartial;
            utxoIds?: TransactionAVM.TransferableOp.UTXO.AsObjectPartial[];
            secp256k1MintOperation?: TransactionAVM.TransferableOp.SECP256K1MintOperation.AsObjectPartial;
            nftMintOperation?: TransactionAVM.TransferableOp.NFTMintOperation.AsObjectPartial;
            nftTransferOperation?: TransactionAVM.TransferableOp.NFTTransferOperation.AsObjectPartial;
        };
        export class SECP256K1MintOperation extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                mintOutput?: TransactionAVM.TransferableOutput.SECP256K1MintOutput;
                transferOutput?: TransactionAVM.TransferableOutput.SECP256K1TransferOutput;
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("mintOutput" in data && data.mintOutput != undefined) {
                        this.mintOutput = data.mintOutput;
                    }
                    if ("transferOutput" in data && data.transferOutput != undefined) {
                        this.transferOutput = data.transferOutput;
                    }
                }
            }
            get mintOutput() {
                return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.SECP256K1MintOutput, 1) as TransactionAVM.TransferableOutput.SECP256K1MintOutput | undefined;
            }
            set mintOutput(value: TransactionAVM.TransferableOutput.SECP256K1MintOutput | undefined) {
                pb_1.Message.setWrapperField(this, 1, value);
            }
            get hasMintOutput() {
                return pb_1.Message.getField(this, 1) != null;
            }
            clearMintOutput(): void {
                this.mintOutput = undefined!;
            }
            get transferOutput() {
                return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.SECP256K1TransferOutput, 2) as TransactionAVM.TransferableOutput.SECP256K1TransferOutput | undefined;
            }
            set transferOutput(value: TransactionAVM.TransferableOutput.SECP256K1TransferOutput | undefined) {
                pb_1.Message.setWrapperField(this, 2, value);
            }
            get hasTransferOutput() {
                return pb_1.Message.getField(this, 2) != null;
            }
            clearTransferOutput(): void {
                this.transferOutput = undefined!;
            }
            static fromObject(data?: SECP256K1MintOperation.AsObjectPartial): SECP256K1MintOperation {
                if (!data) {
                    return new SECP256K1MintOperation();
                }
                const message = new SECP256K1MintOperation({});
                if (data.mintOutput != null) {
                    message.mintOutput = TransactionAVM.TransferableOutput.SECP256K1MintOutput.fromObject(data.mintOutput);
                }
                if (data.transferOutput != null) {
                    message.transferOutput = TransactionAVM.TransferableOutput.SECP256K1TransferOutput.fromObject(data.transferOutput);
                }
                return message;
            }
            toObject() {
                const data: SECP256K1MintOperation.AsObject = {};
                if (this.mintOutput != null) {
                    data.mintOutput = this.mintOutput.toObject();
                }
                if (this.transferOutput != null) {
                    data.transferOutput = this.transferOutput.toObject();
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.hasMintOutput)
                    writer.writeMessage(1, this.mintOutput, () => this.mintOutput!.serialize(writer));
                if (this.hasTransferOutput)
                    writer.writeMessage(2, this.transferOutput, () => this.transferOutput!.serialize(writer));
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SECP256K1MintOperation {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SECP256K1MintOperation();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            reader.readMessage(message.mintOutput, () => message.mintOutput = TransactionAVM.TransferableOutput.SECP256K1MintOutput.deserialize(reader));
                            break;
                        case 2:
                            reader.readMessage(message.transferOutput, () => message.transferOutput = TransactionAVM.TransferableOutput.SECP256K1TransferOutput.deserialize(reader));
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): SECP256K1MintOperation {
                return SECP256K1MintOperation.deserialize(bytes);
            }
        }
        export namespace SECP256K1MintOperation {
            export type AsObject = {
                mintOutput?: TransactionAVM.TransferableOutput.SECP256K1MintOutput.AsObject;
                transferOutput?: TransactionAVM.TransferableOutput.SECP256K1TransferOutput.AsObject;
            };
            export type AsObjectPartial = {
                mintOutput?: TransactionAVM.TransferableOutput.SECP256K1MintOutput.AsObjectPartial;
                transferOutput?: TransactionAVM.TransferableOutput.SECP256K1TransferOutput.AsObjectPartial;
            };
        }
        export class NFTMintOperation extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                groupId?: number;
                payload?: string;
                outputs?: TransactionAVM.TransferableOutput.NFTMintOutput[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("groupId" in data && data.groupId != undefined) {
                        this.groupId = data.groupId;
                    }
                    if ("payload" in data && data.payload != undefined) {
                        this.payload = data.payload;
                    }
                    if ("outputs" in data && data.outputs != undefined) {
                        this.outputs = data.outputs;
                    }
                }
            }
            get groupId() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set groupId(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get payload() {
                return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
            }
            set payload(value: string) {
                pb_1.Message.setField(this, 2, value);
            }
            get outputs() {
                return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.TransferableOutput.NFTMintOutput, 3) as TransactionAVM.TransferableOutput.NFTMintOutput[];
            }
            set outputs(value: TransactionAVM.TransferableOutput.NFTMintOutput[]) {
                pb_1.Message.setRepeatedWrapperField(this, 3, value);
            }
            static fromObject(data?: NFTMintOperation.AsObjectPartial): NFTMintOperation {
                if (!data) {
                    return new NFTMintOperation();
                }
                const message = new NFTMintOperation({});
                if (data.groupId != null) {
                    message.groupId = data.groupId;
                }
                if (data.payload != null) {
                    message.payload = data.payload;
                }
                if (data.outputs != null) {
                    message.outputs = data.outputs.map(item => TransactionAVM.TransferableOutput.NFTMintOutput.fromObject(item));
                }
                return message;
            }
            toObject() {
                const data: NFTMintOperation.AsObject = {
                    groupId: this.groupId,
                    payload: this.payload,
                    outputs: this.outputs.map((item: TransactionAVM.TransferableOutput.NFTMintOutput) => item.toObject())
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.groupId != 0)
                    writer.writeUint32(1, this.groupId);
                if (this.payload.length)
                    writer.writeString(2, this.payload);
                if (this.outputs.length)
                    writer.writeRepeatedMessage(3, this.outputs, (item: TransactionAVM.TransferableOutput.NFTMintOutput) => item!.serialize(writer));
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NFTMintOperation {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NFTMintOperation();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.groupId = reader.readUint32();
                            break;
                        case 2:
                            message.payload = reader.readString();
                            break;
                        case 3:
                            reader.readMessage(message.outputs, () => pb_1.Message.addToRepeatedWrapperField(message, 3, TransactionAVM.TransferableOutput.NFTMintOutput.deserialize(reader), TransactionAVM.TransferableOutput.NFTMintOutput));
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): NFTMintOperation {
                return NFTMintOperation.deserialize(bytes);
            }
        }
        export namespace NFTMintOperation {
            export type AsObject = {
                groupId: number;
                payload: string;
                outputs: TransactionAVM.TransferableOutput.NFTMintOutput.AsObject[];
            };
            export type AsObjectPartial = {
                groupId?: number;
                payload?: string;
                outputs?: TransactionAVM.TransferableOutput.NFTMintOutput.AsObjectPartial[];
            };
        }
        export class NFTTransferOperation extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                groupId?: number;
                payload?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("groupId" in data && data.groupId != undefined) {
                        this.groupId = data.groupId;
                    }
                    if ("payload" in data && data.payload != undefined) {
                        this.payload = data.payload;
                    }
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("threshold" in data && data.threshold != undefined) {
                        this.threshold = data.threshold;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get groupId() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set groupId(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get payload() {
                return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
            }
            set payload(value: string) {
                pb_1.Message.setField(this, 2, value);
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 3, value);
            }
            get threshold() {
                return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
            }
            set threshold(value: number) {
                pb_1.Message.setField(this, 4, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 5, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 5, value);
            }
            static fromObject(data?: NFTTransferOperation.AsObjectPartial): NFTTransferOperation {
                if (!data) {
                    return new NFTTransferOperation();
                }
                const message = new NFTTransferOperation({});
                if (data.groupId != null) {
                    message.groupId = data.groupId;
                }
                if (data.payload != null) {
                    message.payload = data.payload;
                }
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.threshold != null) {
                    message.threshold = data.threshold;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: NFTTransferOperation.AsObject = {
                    groupId: this.groupId,
                    payload: this.payload,
                    locktime: this.locktime,
                    threshold: this.threshold,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.groupId != 0)
                    writer.writeUint32(1, this.groupId);
                if (this.payload.length)
                    writer.writeString(2, this.payload);
                if (this.locktime != 0)
                    writer.writeUint64(3, this.locktime);
                if (this.threshold != 0)
                    writer.writeUint32(4, this.threshold);
                if (this.addresses.length)
                    writer.writeRepeatedString(5, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NFTTransferOperation {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NFTTransferOperation();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.groupId = reader.readUint32();
                            break;
                        case 2:
                            message.payload = reader.readString();
                            break;
                        case 3:
                            message.locktime = reader.readUint64();
                            break;
                        case 4:
                            message.threshold = reader.readUint32();
                            break;
                        case 5:
                            pb_1.Message.addToRepeatedField(message, 5, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): NFTTransferOperation {
                return NFTTransferOperation.deserialize(bytes);
            }
        }
        export namespace NFTTransferOperation {
            export type AsObject = {
                groupId: number;
                payload: string;
                locktime: number;
                threshold: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                groupId?: number;
                payload?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            };
        }
        export class UTXO extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                txId?: string;
                utxoIndex?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("txId" in data && data.txId != undefined) {
                        this.txId = data.txId;
                    }
                    if ("utxoIndex" in data && data.utxoIndex != undefined) {
                        this.utxoIndex = data.utxoIndex;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get txId() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set txId(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get utxoIndex() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set utxoIndex(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 3, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 3, value);
            }
            static fromObject(data?: UTXO.AsObjectPartial): UTXO {
                if (!data) {
                    return new UTXO();
                }
                const message = new UTXO({});
                if (data.txId != null) {
                    message.txId = data.txId;
                }
                if (data.utxoIndex != null) {
                    message.utxoIndex = data.utxoIndex;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: UTXO.AsObject = {
                    txId: this.txId,
                    utxoIndex: this.utxoIndex,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.txId.length)
                    writer.writeString(1, this.txId);
                if (this.utxoIndex != 0)
                    writer.writeUint32(2, this.utxoIndex);
                if (this.addresses.length)
                    writer.writeRepeatedString(3, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): UTXO {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new UTXO();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.txId = reader.readString();
                            break;
                        case 2:
                            message.utxoIndex = reader.readUint32();
                            break;
                        case 3:
                            pb_1.Message.addToRepeatedField(message, 3, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): UTXO {
                return UTXO.deserialize(bytes);
            }
        }
        export namespace UTXO {
            export type AsObject = {
                txId: string;
                utxoIndex: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                txId?: string;
                utxoIndex?: number;
                addresses?: string[];
            };
        }
    }
    export class Credentials extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            signatures?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("signatures" in data && data.signatures != undefined) {
                    this.signatures = data.signatures;
                }
            }
        }
        get signatures() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set signatures(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: Credentials.AsObjectPartial): Credentials {
            if (!data) {
                return new Credentials();
            }
            const message = new Credentials({});
            if (data.signatures != null) {
                message.signatures = data.signatures;
            }
            return message;
        }
        toObject() {
            const data: Credentials.AsObject = {
                signatures: this.signatures
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.signatures.length)
                writer.writeRepeatedString(1, this.signatures);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Credentials {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Credentials();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Credentials {
            return Credentials.deserialize(bytes);
        }
    }
    export namespace Credentials {
        export type AsObject = {
            signatures: string[];
        };
        export type AsObjectPartial = {
            signatures?: string[];
        };
    }
    export class BaseTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data?: BaseTx.AsObjectPartial): BaseTx {
            if (!data) {
                return new BaseTx();
            }
            const message = new BaseTx({});
            return message;
        }
        toObject() {
            const data: BaseTx.AsObject = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BaseTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BaseTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): BaseTx {
            return BaseTx.deserialize(bytes);
        }
    }
    export namespace BaseTx {
        export type AsObject = {};
        export type AsObjectPartial = {};
    }
    export class CreateAssetTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            name?: string;
            symbol?: string;
            denomination?: number;
            initialStates?: TransactionAVM.CreateAssetTx.InitialState[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("symbol" in data && data.symbol != undefined) {
                    this.symbol = data.symbol;
                }
                if ("denomination" in data && data.denomination != undefined) {
                    this.denomination = data.denomination;
                }
                if ("initialStates" in data && data.initialStates != undefined) {
                    this.initialStates = data.initialStates;
                }
            }
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get symbol() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set symbol(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get denomination() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set denomination(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get initialStates() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.CreateAssetTx.InitialState, 4) as TransactionAVM.CreateAssetTx.InitialState[];
        }
        set initialStates(value: TransactionAVM.CreateAssetTx.InitialState[]) {
            pb_1.Message.setRepeatedWrapperField(this, 4, value);
        }
        static fromObject(data?: CreateAssetTx.AsObjectPartial): CreateAssetTx {
            if (!data) {
                return new CreateAssetTx();
            }
            const message = new CreateAssetTx({});
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.symbol != null) {
                message.symbol = data.symbol;
            }
            if (data.denomination != null) {
                message.denomination = data.denomination;
            }
            if (data.initialStates != null) {
                message.initialStates = data.initialStates.map(item => TransactionAVM.CreateAssetTx.InitialState.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: CreateAssetTx.AsObject = {
                name: this.name,
                symbol: this.symbol,
                denomination: this.denomination,
                initialStates: this.initialStates.map((item: TransactionAVM.CreateAssetTx.InitialState) => item.toObject())
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.name.length)
                writer.writeString(1, this.name);
            if (this.symbol.length)
                writer.writeString(2, this.symbol);
            if (this.denomination != 0)
                writer.writeUint32(3, this.denomination);
            if (this.initialStates.length)
                writer.writeRepeatedMessage(4, this.initialStates, (item: TransactionAVM.CreateAssetTx.InitialState) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateAssetTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateAssetTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.name = reader.readString();
                        break;
                    case 2:
                        message.symbol = reader.readString();
                        break;
                    case 3:
                        message.denomination = reader.readUint32();
                        break;
                    case 4:
                        reader.readMessage(message.initialStates, () => pb_1.Message.addToRepeatedWrapperField(message, 4, TransactionAVM.CreateAssetTx.InitialState.deserialize(reader), TransactionAVM.CreateAssetTx.InitialState));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateAssetTx {
            return CreateAssetTx.deserialize(bytes);
        }
    }
    export namespace CreateAssetTx {
        export type AsObject = {
            name: string;
            symbol: string;
            denomination: number;
            initialStates: TransactionAVM.CreateAssetTx.InitialState.AsObject[];
        };
        export type AsObjectPartial = {
            name?: string;
            symbol?: string;
            denomination?: number;
            initialStates?: TransactionAVM.CreateAssetTx.InitialState.AsObjectPartial[];
        };
        export class InitialState extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                fxIndex?: number;
                fxId?: string;
                outputs?: TransactionAVM.CreateAssetTx.Output[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("fxIndex" in data && data.fxIndex != undefined) {
                        this.fxIndex = data.fxIndex;
                    }
                    if ("fxId" in data && data.fxId != undefined) {
                        this.fxId = data.fxId;
                    }
                    if ("outputs" in data && data.outputs != undefined) {
                        this.outputs = data.outputs;
                    }
                }
            }
            get fxIndex() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set fxIndex(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get fxId() {
                return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
            }
            set fxId(value: string) {
                pb_1.Message.setField(this, 2, value);
            }
            get outputs() {
                return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.CreateAssetTx.Output, 3) as TransactionAVM.CreateAssetTx.Output[];
            }
            set outputs(value: TransactionAVM.CreateAssetTx.Output[]) {
                pb_1.Message.setRepeatedWrapperField(this, 3, value);
            }
            static fromObject(data?: InitialState.AsObjectPartial): InitialState {
                if (!data) {
                    return new InitialState();
                }
                const message = new InitialState({});
                if (data.fxIndex != null) {
                    message.fxIndex = data.fxIndex;
                }
                if (data.fxId != null) {
                    message.fxId = data.fxId;
                }
                if (data.outputs != null) {
                    message.outputs = data.outputs.map(item => TransactionAVM.CreateAssetTx.Output.fromObject(item));
                }
                return message;
            }
            toObject() {
                const data: InitialState.AsObject = {
                    fxIndex: this.fxIndex,
                    fxId: this.fxId,
                    outputs: this.outputs.map((item: TransactionAVM.CreateAssetTx.Output) => item.toObject())
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.fxIndex != 0)
                    writer.writeUint32(1, this.fxIndex);
                if (this.fxId.length)
                    writer.writeString(2, this.fxId);
                if (this.outputs.length)
                    writer.writeRepeatedMessage(3, this.outputs, (item: TransactionAVM.CreateAssetTx.Output) => item!.serialize(writer));
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): InitialState {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new InitialState();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.fxIndex = reader.readUint32();
                            break;
                        case 2:
                            message.fxId = reader.readString();
                            break;
                        case 3:
                            reader.readMessage(message.outputs, () => pb_1.Message.addToRepeatedWrapperField(message, 3, TransactionAVM.CreateAssetTx.Output.deserialize(reader), TransactionAVM.CreateAssetTx.Output));
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): InitialState {
                return InitialState.deserialize(bytes);
            }
        }
        export namespace InitialState {
            export type AsObject = {
                fxIndex: number;
                fxId: string;
                outputs: TransactionAVM.CreateAssetTx.Output.AsObject[];
            };
            export type AsObjectPartial = {
                fxIndex?: number;
                fxId?: string;
                outputs?: TransactionAVM.CreateAssetTx.Output.AsObjectPartial[];
            };
        }
        export class Output extends pb_1.Message {
            #one_of_decls: number[][] = [[3, 4, 5, 6]];
            constructor(data?: any[] | ({} & (({
                secp256k1TransferOutput?: TransactionAVM.TransferableOutput.SECP256K1TransferOutput;
                secp256k1MintOutput?: never;
                nftTransferOutput?: never;
                nftMintOutput?: never;
            } | {
                secp256k1TransferOutput?: never;
                secp256k1MintOutput?: TransactionAVM.TransferableOutput.SECP256K1MintOutput;
                nftTransferOutput?: never;
                nftMintOutput?: never;
            } | {
                secp256k1TransferOutput?: never;
                secp256k1MintOutput?: never;
                nftTransferOutput?: TransactionAVM.TransferableOutput.NFTTransferOutput;
                nftMintOutput?: never;
            } | {
                secp256k1TransferOutput?: never;
                secp256k1MintOutput?: never;
                nftTransferOutput?: never;
                nftMintOutput?: TransactionAVM.TransferableOutput.NFTMintOutput;
            })))) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("secp256k1TransferOutput" in data && data.secp256k1TransferOutput != undefined) {
                        this.secp256k1TransferOutput = data.secp256k1TransferOutput;
                    }
                    if ("secp256k1MintOutput" in data && data.secp256k1MintOutput != undefined) {
                        this.secp256k1MintOutput = data.secp256k1MintOutput;
                    }
                    if ("nftTransferOutput" in data && data.nftTransferOutput != undefined) {
                        this.nftTransferOutput = data.nftTransferOutput;
                    }
                    if ("nftMintOutput" in data && data.nftMintOutput != undefined) {
                        this.nftMintOutput = data.nftMintOutput;
                    }
                }
            }
            get secp256k1TransferOutput() {
                return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.SECP256K1TransferOutput, 3) as TransactionAVM.TransferableOutput.SECP256K1TransferOutput | undefined;
            }
            set secp256k1TransferOutput(value: TransactionAVM.TransferableOutput.SECP256K1TransferOutput | undefined) {
                pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
            }
            get hasSecp256k1TransferOutput() {
                return pb_1.Message.getField(this, 3) != null;
            }
            clearSecp256k1TransferOutput(): void {
                this.secp256k1TransferOutput = undefined!;
            }
            get secp256k1MintOutput() {
                return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.SECP256K1MintOutput, 4) as TransactionAVM.TransferableOutput.SECP256K1MintOutput | undefined;
            }
            set secp256k1MintOutput(value: TransactionAVM.TransferableOutput.SECP256K1MintOutput | undefined) {
                pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
            }
            get hasSecp256k1MintOutput() {
                return pb_1.Message.getField(this, 4) != null;
            }
            clearSecp256k1MintOutput(): void {
                this.secp256k1MintOutput = undefined!;
            }
            get nftTransferOutput() {
                return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.NFTTransferOutput, 5) as TransactionAVM.TransferableOutput.NFTTransferOutput | undefined;
            }
            set nftTransferOutput(value: TransactionAVM.TransferableOutput.NFTTransferOutput | undefined) {
                pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
            }
            get hasNftTransferOutput() {
                return pb_1.Message.getField(this, 5) != null;
            }
            clearNftTransferOutput(): void {
                this.nftTransferOutput = undefined!;
            }
            get nftMintOutput() {
                return pb_1.Message.getWrapperField(this, TransactionAVM.TransferableOutput.NFTMintOutput, 6) as TransactionAVM.TransferableOutput.NFTMintOutput | undefined;
            }
            set nftMintOutput(value: TransactionAVM.TransferableOutput.NFTMintOutput | undefined) {
                pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
            }
            get hasNftMintOutput() {
                return pb_1.Message.getField(this, 6) != null;
            }
            clearNftMintOutput(): void {
                this.nftMintOutput = undefined!;
            }
            get kind() {
                const cases: {
                    [index: number]: "none" | "secp256k1TransferOutput" | "secp256k1MintOutput" | "nftTransferOutput" | "nftMintOutput";
                } = {
                    0: "none",
                    3: "secp256k1TransferOutput",
                    4: "secp256k1MintOutput",
                    5: "nftTransferOutput",
                    6: "nftMintOutput"
                };
                return cases[pb_1.Message.computeOneofCase(this, [3, 4, 5, 6])]!;
            }
            static fromObject(data?: Output.AsObjectPartial): Output {
                if (!data) {
                    return new Output();
                }
                const message = new Output({});
                if (data.secp256k1TransferOutput != null) {
                    message.secp256k1TransferOutput = TransactionAVM.TransferableOutput.SECP256K1TransferOutput.fromObject(data.secp256k1TransferOutput);
                }
                if (data.secp256k1MintOutput != null) {
                    message.secp256k1MintOutput = TransactionAVM.TransferableOutput.SECP256K1MintOutput.fromObject(data.secp256k1MintOutput);
                }
                if (data.nftTransferOutput != null) {
                    message.nftTransferOutput = TransactionAVM.TransferableOutput.NFTTransferOutput.fromObject(data.nftTransferOutput);
                }
                if (data.nftMintOutput != null) {
                    message.nftMintOutput = TransactionAVM.TransferableOutput.NFTMintOutput.fromObject(data.nftMintOutput);
                }
                return message;
            }
            toObject() {
                const data: Output.AsObject = {};
                if (this.secp256k1TransferOutput != null) {
                    data.secp256k1TransferOutput = this.secp256k1TransferOutput.toObject();
                }
                if (this.secp256k1MintOutput != null) {
                    data.secp256k1MintOutput = this.secp256k1MintOutput.toObject();
                }
                if (this.nftTransferOutput != null) {
                    data.nftTransferOutput = this.nftTransferOutput.toObject();
                }
                if (this.nftMintOutput != null) {
                    data.nftMintOutput = this.nftMintOutput.toObject();
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.hasSecp256k1TransferOutput)
                    writer.writeMessage(3, this.secp256k1TransferOutput, () => this.secp256k1TransferOutput!.serialize(writer));
                if (this.hasSecp256k1MintOutput)
                    writer.writeMessage(4, this.secp256k1MintOutput, () => this.secp256k1MintOutput!.serialize(writer));
                if (this.hasNftTransferOutput)
                    writer.writeMessage(5, this.nftTransferOutput, () => this.nftTransferOutput!.serialize(writer));
                if (this.hasNftMintOutput)
                    writer.writeMessage(6, this.nftMintOutput, () => this.nftMintOutput!.serialize(writer));
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Output {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Output();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 3:
                            reader.readMessage(message.secp256k1TransferOutput, () => message.secp256k1TransferOutput = TransactionAVM.TransferableOutput.SECP256K1TransferOutput.deserialize(reader));
                            break;
                        case 4:
                            reader.readMessage(message.secp256k1MintOutput, () => message.secp256k1MintOutput = TransactionAVM.TransferableOutput.SECP256K1MintOutput.deserialize(reader));
                            break;
                        case 5:
                            reader.readMessage(message.nftTransferOutput, () => message.nftTransferOutput = TransactionAVM.TransferableOutput.NFTTransferOutput.deserialize(reader));
                            break;
                        case 6:
                            reader.readMessage(message.nftMintOutput, () => message.nftMintOutput = TransactionAVM.TransferableOutput.NFTMintOutput.deserialize(reader));
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): Output {
                return Output.deserialize(bytes);
            }
        }
        export namespace Output {
            export type AsObject = {
                secp256k1TransferOutput?: TransactionAVM.TransferableOutput.SECP256K1TransferOutput.AsObject;
                secp256k1MintOutput?: TransactionAVM.TransferableOutput.SECP256K1MintOutput.AsObject;
                nftTransferOutput?: TransactionAVM.TransferableOutput.NFTTransferOutput.AsObject;
                nftMintOutput?: TransactionAVM.TransferableOutput.NFTMintOutput.AsObject;
            };
            export type AsObjectPartial = {
                secp256k1TransferOutput?: TransactionAVM.TransferableOutput.SECP256K1TransferOutput.AsObjectPartial;
                secp256k1MintOutput?: TransactionAVM.TransferableOutput.SECP256K1MintOutput.AsObjectPartial;
                nftTransferOutput?: TransactionAVM.TransferableOutput.NFTTransferOutput.AsObjectPartial;
                nftMintOutput?: TransactionAVM.TransferableOutput.NFTMintOutput.AsObjectPartial;
            };
        }
    }
    export class OperationTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            operations?: TransactionAVM.TransferableOp[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("operations" in data && data.operations != undefined) {
                    this.operations = data.operations;
                }
            }
        }
        get operations() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.TransferableOp, 1) as TransactionAVM.TransferableOp[];
        }
        set operations(value: TransactionAVM.TransferableOp[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data?: OperationTx.AsObjectPartial): OperationTx {
            if (!data) {
                return new OperationTx();
            }
            const message = new OperationTx({});
            if (data.operations != null) {
                message.operations = data.operations.map(item => TransactionAVM.TransferableOp.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: OperationTx.AsObject = {
                operations: this.operations.map((item: TransactionAVM.TransferableOp) => item.toObject())
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.operations.length)
                writer.writeRepeatedMessage(1, this.operations, (item: TransactionAVM.TransferableOp) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OperationTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OperationTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.operations, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TransactionAVM.TransferableOp.deserialize(reader), TransactionAVM.TransferableOp));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): OperationTx {
            return OperationTx.deserialize(bytes);
        }
    }
    export namespace OperationTx {
        export type AsObject = {
            operations: TransactionAVM.TransferableOp.AsObject[];
        };
        export type AsObjectPartial = {
            operations?: TransactionAVM.TransferableOp.AsObjectPartial[];
        };
    }
    export class ImportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sourceBlockchainId?: string;
            importedInputs?: TransactionAVM.TransferableInput[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sourceBlockchainId" in data && data.sourceBlockchainId != undefined) {
                    this.sourceBlockchainId = data.sourceBlockchainId;
                }
                if ("importedInputs" in data && data.importedInputs != undefined) {
                    this.importedInputs = data.importedInputs;
                }
            }
        }
        get sourceBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sourceBlockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get importedInputs() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.TransferableInput, 2) as TransactionAVM.TransferableInput[];
        }
        set importedInputs(value: TransactionAVM.TransferableInput[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data?: ImportTx.AsObjectPartial): ImportTx {
            if (!data) {
                return new ImportTx();
            }
            const message = new ImportTx({});
            if (data.sourceBlockchainId != null) {
                message.sourceBlockchainId = data.sourceBlockchainId;
            }
            if (data.importedInputs != null) {
                message.importedInputs = data.importedInputs.map(item => TransactionAVM.TransferableInput.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: ImportTx.AsObject = {
                sourceBlockchainId: this.sourceBlockchainId,
                importedInputs: this.importedInputs.map((item: TransactionAVM.TransferableInput) => item.toObject())
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sourceBlockchainId.length)
                writer.writeString(1, this.sourceBlockchainId);
            if (this.importedInputs.length)
                writer.writeRepeatedMessage(2, this.importedInputs, (item: TransactionAVM.TransferableInput) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ImportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ImportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sourceBlockchainId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.importedInputs, () => pb_1.Message.addToRepeatedWrapperField(message, 2, TransactionAVM.TransferableInput.deserialize(reader), TransactionAVM.TransferableInput));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ImportTx {
            return ImportTx.deserialize(bytes);
        }
    }
    export namespace ImportTx {
        export type AsObject = {
            sourceBlockchainId: string;
            importedInputs: TransactionAVM.TransferableInput.AsObject[];
        };
        export type AsObjectPartial = {
            sourceBlockchainId?: string;
            importedInputs?: TransactionAVM.TransferableInput.AsObjectPartial[];
        };
    }
    export class ExportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            destinationBlockchainId?: string;
            exportedOutputs?: TransactionAVM.TransferableOutput[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("destinationBlockchainId" in data && data.destinationBlockchainId != undefined) {
                    this.destinationBlockchainId = data.destinationBlockchainId;
                }
                if ("exportedOutputs" in data && data.exportedOutputs != undefined) {
                    this.exportedOutputs = data.exportedOutputs;
                }
            }
        }
        get destinationBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set destinationBlockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get exportedOutputs() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionAVM.TransferableOutput, 2) as TransactionAVM.TransferableOutput[];
        }
        set exportedOutputs(value: TransactionAVM.TransferableOutput[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data?: ExportTx.AsObjectPartial): ExportTx {
            if (!data) {
                return new ExportTx();
            }
            const message = new ExportTx({});
            if (data.destinationBlockchainId != null) {
                message.destinationBlockchainId = data.destinationBlockchainId;
            }
            if (data.exportedOutputs != null) {
                message.exportedOutputs = data.exportedOutputs.map(item => TransactionAVM.TransferableOutput.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: ExportTx.AsObject = {
                destinationBlockchainId: this.destinationBlockchainId,
                exportedOutputs: this.exportedOutputs.map((item: TransactionAVM.TransferableOutput) => item.toObject())
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.destinationBlockchainId.length)
                writer.writeString(1, this.destinationBlockchainId);
            if (this.exportedOutputs.length)
                writer.writeRepeatedMessage(2, this.exportedOutputs, (item: TransactionAVM.TransferableOutput) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ExportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ExportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.destinationBlockchainId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.exportedOutputs, () => pb_1.Message.addToRepeatedWrapperField(message, 2, TransactionAVM.TransferableOutput.deserialize(reader), TransactionAVM.TransferableOutput));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ExportTx {
            return ExportTx.deserialize(bytes);
        }
    }
    export namespace ExportTx {
        export type AsObject = {
            destinationBlockchainId: string;
            exportedOutputs: TransactionAVM.TransferableOutput.AsObject[];
        };
        export type AsObjectPartial = {
            destinationBlockchainId?: string;
            exportedOutputs?: TransactionAVM.TransferableOutput.AsObjectPartial[];
        };
    }
}
export class TransactionShortAVM extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3]];
    constructor(data?: any[] | ({
        kind?: dependency_2.AVMTransactionKind;
    } & (({
        export?: TransactionShortAVM.ExportTx;
        import?: never;
    } | {
        export?: never;
        import?: TransactionShortAVM.ImportTx;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("kind" in data && data.kind != undefined) {
                this.kind = data.kind;
            }
            if ("export" in data && data.export != undefined) {
                this.export = data.export;
            }
            if ("import" in data && data.import != undefined) {
                this.import = data.import;
            }
        }
    }
    get kind() {
        return pb_1.Message.getFieldWithDefault(this, 1, dependency_2.AVMTransactionKind.AVMTransactionKindBase) as dependency_2.AVMTransactionKind;
    }
    set kind(value: dependency_2.AVMTransactionKind) {
        pb_1.Message.setField(this, 1, value);
    }
    get export() {
        return pb_1.Message.getWrapperField(this, TransactionShortAVM.ExportTx, 2) as TransactionShortAVM.ExportTx | undefined;
    }
    set export(value: TransactionShortAVM.ExportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasExport() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearExport(): void {
        this.export = undefined!;
    }
    get import() {
        return pb_1.Message.getWrapperField(this, TransactionShortAVM.ImportTx, 3) as TransactionShortAVM.ImportTx | undefined;
    }
    set import(value: TransactionShortAVM.ImportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasImport() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearImport(): void {
        this.import = undefined!;
    }
    get details() {
        const cases: {
            [index: number]: "none" | "export" | "import";
        } = {
            0: "none",
            2: "export",
            3: "import"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3])]!;
    }
    static fromObject(data?: TransactionShortAVM.AsObjectPartial): TransactionShortAVM {
        if (!data) {
            return new TransactionShortAVM();
        }
        const message = new TransactionShortAVM({});
        if (data.kind != null) {
            message.kind = data.kind;
        }
        if (data.export != null) {
            message.export = TransactionShortAVM.ExportTx.fromObject(data.export);
        }
        if (data.import != null) {
            message.import = TransactionShortAVM.ImportTx.fromObject(data.import);
        }
        return message;
    }
    toObject() {
        const data: TransactionShortAVM.AsObject = {
            kind: this.kind
        };
        if (this.export != null) {
            data.export = this.export.toObject();
        }
        if (this.import != null) {
            data.import = this.import.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.kind != dependency_2.AVMTransactionKind.AVMTransactionKindBase)
            writer.writeEnum(1, this.kind);
        if (this.hasExport)
            writer.writeMessage(2, this.export, () => this.export!.serialize(writer));
        if (this.hasImport)
            writer.writeMessage(3, this.import, () => this.import!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionShortAVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionShortAVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.kind = reader.readEnum();
                    break;
                case 2:
                    reader.readMessage(message.export, () => message.export = TransactionShortAVM.ExportTx.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.import, () => message.import = TransactionShortAVM.ImportTx.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionShortAVM {
        return TransactionShortAVM.deserialize(bytes);
    }
}
export namespace TransactionShortAVM {
    export type AsObject = {
        kind: dependency_2.AVMTransactionKind;
        export?: TransactionShortAVM.ExportTx.AsObject;
        import?: TransactionShortAVM.ImportTx.AsObject;
    };
    export type AsObjectPartial = {
        kind?: dependency_2.AVMTransactionKind;
        export?: TransactionShortAVM.ExportTx.AsObjectPartial;
        import?: TransactionShortAVM.ImportTx.AsObjectPartial;
    };
    export class ExportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            destinationBlockchainId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("destinationBlockchainId" in data && data.destinationBlockchainId != undefined) {
                    this.destinationBlockchainId = data.destinationBlockchainId;
                }
            }
        }
        get destinationBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set destinationBlockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: ExportTx.AsObjectPartial): ExportTx {
            if (!data) {
                return new ExportTx();
            }
            const message = new ExportTx({});
            if (data.destinationBlockchainId != null) {
                message.destinationBlockchainId = data.destinationBlockchainId;
            }
            return message;
        }
        toObject() {
            const data: ExportTx.AsObject = {
                destinationBlockchainId: this.destinationBlockchainId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.destinationBlockchainId.length)
                writer.writeString(1, this.destinationBlockchainId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ExportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ExportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.destinationBlockchainId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ExportTx {
            return ExportTx.deserialize(bytes);
        }
    }
    export namespace ExportTx {
        export type AsObject = {
            destinationBlockchainId: string;
        };
        export type AsObjectPartial = {
            destinationBlockchainId?: string;
        };
    }
    export class ImportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sourceBlockchainId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sourceBlockchainId" in data && data.sourceBlockchainId != undefined) {
                    this.sourceBlockchainId = data.sourceBlockchainId;
                }
            }
        }
        get sourceBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sourceBlockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: ImportTx.AsObjectPartial): ImportTx {
            if (!data) {
                return new ImportTx();
            }
            const message = new ImportTx({});
            if (data.sourceBlockchainId != null) {
                message.sourceBlockchainId = data.sourceBlockchainId;
            }
            return message;
        }
        toObject() {
            const data: ImportTx.AsObject = {
                sourceBlockchainId: this.sourceBlockchainId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sourceBlockchainId.length)
                writer.writeString(1, this.sourceBlockchainId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ImportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ImportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sourceBlockchainId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ImportTx {
            return ImportTx.deserialize(bytes);
        }
    }
    export namespace ImportTx {
        export type AsObject = {
            sourceBlockchainId: string;
        };
        export type AsObjectPartial = {
            sourceBlockchainId?: string;
        };
    }
}
export class PVMRewardUTXO extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        outputIndex?: number;
        output?: TransactionPVM.TransferableOutput;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("outputIndex" in data && data.outputIndex != undefined) {
                this.outputIndex = data.outputIndex;
            }
            if ("output" in data && data.output != undefined) {
                this.output = data.output;
            }
        }
    }
    get outputIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set outputIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get output() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput, 2) as TransactionPVM.TransferableOutput | undefined;
    }
    set output(value: TransactionPVM.TransferableOutput | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasOutput() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearOutput(): void {
        this.output = undefined!;
    }
    static fromObject(data?: PVMRewardUTXO.AsObjectPartial): PVMRewardUTXO {
        if (!data) {
            return new PVMRewardUTXO();
        }
        const message = new PVMRewardUTXO({});
        if (data.outputIndex != null) {
            message.outputIndex = data.outputIndex;
        }
        if (data.output != null) {
            message.output = TransactionPVM.TransferableOutput.fromObject(data.output);
        }
        return message;
    }
    toObject() {
        const data: PVMRewardUTXO.AsObject = {
            outputIndex: this.outputIndex
        };
        if (this.output != null) {
            data.output = this.output.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.outputIndex != 0)
            writer.writeUint32(1, this.outputIndex);
        if (this.hasOutput)
            writer.writeMessage(2, this.output, () => this.output!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): PVMRewardUTXO {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new PVMRewardUTXO();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.outputIndex = reader.readUint32();
                    break;
                case 2:
                    reader.readMessage(message.output, () => message.output = TransactionPVM.TransferableOutput.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): PVMRewardUTXO {
        return PVMRewardUTXO.deserialize(bytes);
    }
}
export namespace PVMRewardUTXO {
    export type AsObject = {
        outputIndex: number;
        output?: TransactionPVM.TransferableOutput.AsObject;
    };
    export type AsObjectPartial = {
        outputIndex?: number;
        output?: TransactionPVM.TransferableOutput.AsObjectPartial;
    };
}
export class TransactionPVM extends pb_1.Message {
    #one_of_decls: number[][] = [[4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20]];
    constructor(data?: any[] | ({
        inputs?: TransactionPVM.TransferableInput[];
        outputs?: TransactionPVM.TransferableOutput[];
        creds?: TransactionPVM.Credentials[];
        candidates?: TransactionPVM.BlockCandidate[];
        fullOutputs?: TransactionPVM.TransferableOutput[];
    } & (({
        base?: TransactionPVM.BaseTx;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: TransactionPVM.AddValidatorTx;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: TransactionPVM.AddSubnetValidatorTx;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: TransactionPVM.AddDelegatorTx;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: TransactionPVM.CreateChainTx;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: TransactionPVM.CreateSubnetTx;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: TransactionPVM.ImportTx;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: TransactionPVM.ExportTx;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: TransactionPVM.AdvanceTimeTx;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: TransactionPVM.RewardValidatorTx;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: TransactionPVM.RemoveSupernetValidatorTx;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: TransactionPVM.TransformSupernetTx;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: TransactionPVM.AddPermissionlessValidatorTx;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: TransactionPVM.AddPermissionlessDelegatorTx;
        transferSupernetOwnership?: never;
    } | {
        base?: never;
        addValidator?: never;
        addSubnetValidator?: never;
        addDelegator?: never;
        createChain?: never;
        createSubnet?: never;
        import?: never;
        export?: never;
        advanceTime?: never;
        rewardValidator?: never;
        removeSupernetValidator?: never;
        transformSupernet?: never;
        addPermissionlessValidator?: never;
        addPermissionlessDelegator?: never;
        transferSupernetOwnership?: TransactionPVM.TransferSupernetOwnershipTx;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1, 2, 3, 14, 15], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("inputs" in data && data.inputs != undefined) {
                this.inputs = data.inputs;
            }
            if ("outputs" in data && data.outputs != undefined) {
                this.outputs = data.outputs;
            }
            if ("creds" in data && data.creds != undefined) {
                this.creds = data.creds;
            }
            if ("candidates" in data && data.candidates != undefined) {
                this.candidates = data.candidates;
            }
            if ("fullOutputs" in data && data.fullOutputs != undefined) {
                this.fullOutputs = data.fullOutputs;
            }
            if ("base" in data && data.base != undefined) {
                this.base = data.base;
            }
            if ("addValidator" in data && data.addValidator != undefined) {
                this.addValidator = data.addValidator;
            }
            if ("addSubnetValidator" in data && data.addSubnetValidator != undefined) {
                this.addSubnetValidator = data.addSubnetValidator;
            }
            if ("addDelegator" in data && data.addDelegator != undefined) {
                this.addDelegator = data.addDelegator;
            }
            if ("createChain" in data && data.createChain != undefined) {
                this.createChain = data.createChain;
            }
            if ("createSubnet" in data && data.createSubnet != undefined) {
                this.createSubnet = data.createSubnet;
            }
            if ("import" in data && data.import != undefined) {
                this.import = data.import;
            }
            if ("export" in data && data.export != undefined) {
                this.export = data.export;
            }
            if ("advanceTime" in data && data.advanceTime != undefined) {
                this.advanceTime = data.advanceTime;
            }
            if ("rewardValidator" in data && data.rewardValidator != undefined) {
                this.rewardValidator = data.rewardValidator;
            }
            if ("removeSupernetValidator" in data && data.removeSupernetValidator != undefined) {
                this.removeSupernetValidator = data.removeSupernetValidator;
            }
            if ("transformSupernet" in data && data.transformSupernet != undefined) {
                this.transformSupernet = data.transformSupernet;
            }
            if ("addPermissionlessValidator" in data && data.addPermissionlessValidator != undefined) {
                this.addPermissionlessValidator = data.addPermissionlessValidator;
            }
            if ("addPermissionlessDelegator" in data && data.addPermissionlessDelegator != undefined) {
                this.addPermissionlessDelegator = data.addPermissionlessDelegator;
            }
            if ("transferSupernetOwnership" in data && data.transferSupernetOwnership != undefined) {
                this.transferSupernetOwnership = data.transferSupernetOwnership;
            }
        }
    }
    get inputs() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionPVM.TransferableInput, 1) as TransactionPVM.TransferableInput[];
    }
    set inputs(value: TransactionPVM.TransferableInput[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get outputs() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionPVM.TransferableOutput, 2) as TransactionPVM.TransferableOutput[];
    }
    set outputs(value: TransactionPVM.TransferableOutput[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    get creds() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionPVM.Credentials, 3) as TransactionPVM.Credentials[];
    }
    set creds(value: TransactionPVM.Credentials[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    get candidates() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionPVM.BlockCandidate, 14) as TransactionPVM.BlockCandidate[];
    }
    set candidates(value: TransactionPVM.BlockCandidate[]) {
        pb_1.Message.setRepeatedWrapperField(this, 14, value);
    }
    get fullOutputs() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionPVM.TransferableOutput, 15) as TransactionPVM.TransferableOutput[];
    }
    set fullOutputs(value: TransactionPVM.TransferableOutput[]) {
        pb_1.Message.setRepeatedWrapperField(this, 15, value);
    }
    get base() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.BaseTx, 4) as TransactionPVM.BaseTx | undefined;
    }
    set base(value: TransactionPVM.BaseTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasBase() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearBase(): void {
        this.base = undefined!;
    }
    get addValidator() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.AddValidatorTx, 5) as TransactionPVM.AddValidatorTx | undefined;
    }
    set addValidator(value: TransactionPVM.AddValidatorTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasAddValidator() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearAddValidator(): void {
        this.addValidator = undefined!;
    }
    get addSubnetValidator() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.AddSubnetValidatorTx, 6) as TransactionPVM.AddSubnetValidatorTx | undefined;
    }
    set addSubnetValidator(value: TransactionPVM.AddSubnetValidatorTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasAddSubnetValidator() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearAddSubnetValidator(): void {
        this.addSubnetValidator = undefined!;
    }
    get addDelegator() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.AddDelegatorTx, 7) as TransactionPVM.AddDelegatorTx | undefined;
    }
    set addDelegator(value: TransactionPVM.AddDelegatorTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasAddDelegator() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearAddDelegator(): void {
        this.addDelegator = undefined!;
    }
    get createChain() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.CreateChainTx, 8) as TransactionPVM.CreateChainTx | undefined;
    }
    set createChain(value: TransactionPVM.CreateChainTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0]!, value);
    }
    get hasCreateChain() {
        return pb_1.Message.getField(this, 8) != null;
    }
    clearCreateChain(): void {
        this.createChain = undefined!;
    }
    get createSubnet() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.CreateSubnetTx, 9) as TransactionPVM.CreateSubnetTx | undefined;
    }
    set createSubnet(value: TransactionPVM.CreateSubnetTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 9, this.#one_of_decls[0]!, value);
    }
    get hasCreateSubnet() {
        return pb_1.Message.getField(this, 9) != null;
    }
    clearCreateSubnet(): void {
        this.createSubnet = undefined!;
    }
    get import() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.ImportTx, 10) as TransactionPVM.ImportTx | undefined;
    }
    set import(value: TransactionPVM.ImportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 10, this.#one_of_decls[0]!, value);
    }
    get hasImport() {
        return pb_1.Message.getField(this, 10) != null;
    }
    clearImport(): void {
        this.import = undefined!;
    }
    get export() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.ExportTx, 11) as TransactionPVM.ExportTx | undefined;
    }
    set export(value: TransactionPVM.ExportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0]!, value);
    }
    get hasExport() {
        return pb_1.Message.getField(this, 11) != null;
    }
    clearExport(): void {
        this.export = undefined!;
    }
    get advanceTime() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.AdvanceTimeTx, 12) as TransactionPVM.AdvanceTimeTx | undefined;
    }
    set advanceTime(value: TransactionPVM.AdvanceTimeTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0]!, value);
    }
    get hasAdvanceTime() {
        return pb_1.Message.getField(this, 12) != null;
    }
    clearAdvanceTime(): void {
        this.advanceTime = undefined!;
    }
    get rewardValidator() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.RewardValidatorTx, 13) as TransactionPVM.RewardValidatorTx | undefined;
    }
    set rewardValidator(value: TransactionPVM.RewardValidatorTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0]!, value);
    }
    get hasRewardValidator() {
        return pb_1.Message.getField(this, 13) != null;
    }
    clearRewardValidator(): void {
        this.rewardValidator = undefined!;
    }
    get removeSupernetValidator() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.RemoveSupernetValidatorTx, 16) as TransactionPVM.RemoveSupernetValidatorTx | undefined;
    }
    set removeSupernetValidator(value: TransactionPVM.RemoveSupernetValidatorTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 16, this.#one_of_decls[0]!, value);
    }
    get hasRemoveSupernetValidator() {
        return pb_1.Message.getField(this, 16) != null;
    }
    clearRemoveSupernetValidator(): void {
        this.removeSupernetValidator = undefined!;
    }
    get transformSupernet() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.TransformSupernetTx, 17) as TransactionPVM.TransformSupernetTx | undefined;
    }
    set transformSupernet(value: TransactionPVM.TransformSupernetTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 17, this.#one_of_decls[0]!, value);
    }
    get hasTransformSupernet() {
        return pb_1.Message.getField(this, 17) != null;
    }
    clearTransformSupernet(): void {
        this.transformSupernet = undefined!;
    }
    get addPermissionlessValidator() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.AddPermissionlessValidatorTx, 18) as TransactionPVM.AddPermissionlessValidatorTx | undefined;
    }
    set addPermissionlessValidator(value: TransactionPVM.AddPermissionlessValidatorTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0]!, value);
    }
    get hasAddPermissionlessValidator() {
        return pb_1.Message.getField(this, 18) != null;
    }
    clearAddPermissionlessValidator(): void {
        this.addPermissionlessValidator = undefined!;
    }
    get addPermissionlessDelegator() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.AddPermissionlessDelegatorTx, 19) as TransactionPVM.AddPermissionlessDelegatorTx | undefined;
    }
    set addPermissionlessDelegator(value: TransactionPVM.AddPermissionlessDelegatorTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 19, this.#one_of_decls[0]!, value);
    }
    get hasAddPermissionlessDelegator() {
        return pb_1.Message.getField(this, 19) != null;
    }
    clearAddPermissionlessDelegator(): void {
        this.addPermissionlessDelegator = undefined!;
    }
    get transferSupernetOwnership() {
        return pb_1.Message.getWrapperField(this, TransactionPVM.TransferSupernetOwnershipTx, 20) as TransactionPVM.TransferSupernetOwnershipTx | undefined;
    }
    set transferSupernetOwnership(value: TransactionPVM.TransferSupernetOwnershipTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 20, this.#one_of_decls[0]!, value);
    }
    get hasTransferSupernetOwnership() {
        return pb_1.Message.getField(this, 20) != null;
    }
    clearTransferSupernetOwnership(): void {
        this.transferSupernetOwnership = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "base" | "addValidator" | "addSubnetValidator" | "addDelegator" | "createChain" | "createSubnet" | "import" | "export" | "advanceTime" | "rewardValidator" | "removeSupernetValidator" | "transformSupernet" | "addPermissionlessValidator" | "addPermissionlessDelegator" | "transferSupernetOwnership";
        } = {
            0: "none",
            4: "base",
            5: "addValidator",
            6: "addSubnetValidator",
            7: "addDelegator",
            8: "createChain",
            9: "createSubnet",
            10: "import",
            11: "export",
            12: "advanceTime",
            13: "rewardValidator",
            16: "removeSupernetValidator",
            17: "transformSupernet",
            18: "addPermissionlessValidator",
            19: "addPermissionlessDelegator",
            20: "transferSupernetOwnership"
        };
        return cases[pb_1.Message.computeOneofCase(this, [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20])]!;
    }
    static fromObject(data?: TransactionPVM.AsObjectPartial): TransactionPVM {
        if (!data) {
            return new TransactionPVM();
        }
        const message = new TransactionPVM({});
        if (data.inputs != null) {
            message.inputs = data.inputs.map(item => TransactionPVM.TransferableInput.fromObject(item));
        }
        if (data.outputs != null) {
            message.outputs = data.outputs.map(item => TransactionPVM.TransferableOutput.fromObject(item));
        }
        if (data.creds != null) {
            message.creds = data.creds.map(item => TransactionPVM.Credentials.fromObject(item));
        }
        if (data.candidates != null) {
            message.candidates = data.candidates.map(item => TransactionPVM.BlockCandidate.fromObject(item));
        }
        if (data.fullOutputs != null) {
            message.fullOutputs = data.fullOutputs.map(item => TransactionPVM.TransferableOutput.fromObject(item));
        }
        if (data.base != null) {
            message.base = TransactionPVM.BaseTx.fromObject(data.base);
        }
        if (data.addValidator != null) {
            message.addValidator = TransactionPVM.AddValidatorTx.fromObject(data.addValidator);
        }
        if (data.addSubnetValidator != null) {
            message.addSubnetValidator = TransactionPVM.AddSubnetValidatorTx.fromObject(data.addSubnetValidator);
        }
        if (data.addDelegator != null) {
            message.addDelegator = TransactionPVM.AddDelegatorTx.fromObject(data.addDelegator);
        }
        if (data.createChain != null) {
            message.createChain = TransactionPVM.CreateChainTx.fromObject(data.createChain);
        }
        if (data.createSubnet != null) {
            message.createSubnet = TransactionPVM.CreateSubnetTx.fromObject(data.createSubnet);
        }
        if (data.import != null) {
            message.import = TransactionPVM.ImportTx.fromObject(data.import);
        }
        if (data.export != null) {
            message.export = TransactionPVM.ExportTx.fromObject(data.export);
        }
        if (data.advanceTime != null) {
            message.advanceTime = TransactionPVM.AdvanceTimeTx.fromObject(data.advanceTime);
        }
        if (data.rewardValidator != null) {
            message.rewardValidator = TransactionPVM.RewardValidatorTx.fromObject(data.rewardValidator);
        }
        if (data.removeSupernetValidator != null) {
            message.removeSupernetValidator = TransactionPVM.RemoveSupernetValidatorTx.fromObject(data.removeSupernetValidator);
        }
        if (data.transformSupernet != null) {
            message.transformSupernet = TransactionPVM.TransformSupernetTx.fromObject(data.transformSupernet);
        }
        if (data.addPermissionlessValidator != null) {
            message.addPermissionlessValidator = TransactionPVM.AddPermissionlessValidatorTx.fromObject(data.addPermissionlessValidator);
        }
        if (data.addPermissionlessDelegator != null) {
            message.addPermissionlessDelegator = TransactionPVM.AddPermissionlessDelegatorTx.fromObject(data.addPermissionlessDelegator);
        }
        if (data.transferSupernetOwnership != null) {
            message.transferSupernetOwnership = TransactionPVM.TransferSupernetOwnershipTx.fromObject(data.transferSupernetOwnership);
        }
        return message;
    }
    toObject() {
        const data: TransactionPVM.AsObject = {
            inputs: this.inputs.map((item: TransactionPVM.TransferableInput) => item.toObject()),
            outputs: this.outputs.map((item: TransactionPVM.TransferableOutput) => item.toObject()),
            creds: this.creds.map((item: TransactionPVM.Credentials) => item.toObject()),
            candidates: this.candidates.map((item: TransactionPVM.BlockCandidate) => item.toObject()),
            fullOutputs: this.fullOutputs.map((item: TransactionPVM.TransferableOutput) => item.toObject())
        };
        if (this.base != null) {
            data.base = this.base.toObject();
        }
        if (this.addValidator != null) {
            data.addValidator = this.addValidator.toObject();
        }
        if (this.addSubnetValidator != null) {
            data.addSubnetValidator = this.addSubnetValidator.toObject();
        }
        if (this.addDelegator != null) {
            data.addDelegator = this.addDelegator.toObject();
        }
        if (this.createChain != null) {
            data.createChain = this.createChain.toObject();
        }
        if (this.createSubnet != null) {
            data.createSubnet = this.createSubnet.toObject();
        }
        if (this.import != null) {
            data.import = this.import.toObject();
        }
        if (this.export != null) {
            data.export = this.export.toObject();
        }
        if (this.advanceTime != null) {
            data.advanceTime = this.advanceTime.toObject();
        }
        if (this.rewardValidator != null) {
            data.rewardValidator = this.rewardValidator.toObject();
        }
        if (this.removeSupernetValidator != null) {
            data.removeSupernetValidator = this.removeSupernetValidator.toObject();
        }
        if (this.transformSupernet != null) {
            data.transformSupernet = this.transformSupernet.toObject();
        }
        if (this.addPermissionlessValidator != null) {
            data.addPermissionlessValidator = this.addPermissionlessValidator.toObject();
        }
        if (this.addPermissionlessDelegator != null) {
            data.addPermissionlessDelegator = this.addPermissionlessDelegator.toObject();
        }
        if (this.transferSupernetOwnership != null) {
            data.transferSupernetOwnership = this.transferSupernetOwnership.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.inputs.length)
            writer.writeRepeatedMessage(1, this.inputs, (item: TransactionPVM.TransferableInput) => item!.serialize(writer));
        if (this.outputs.length)
            writer.writeRepeatedMessage(2, this.outputs, (item: TransactionPVM.TransferableOutput) => item!.serialize(writer));
        if (this.creds.length)
            writer.writeRepeatedMessage(3, this.creds, (item: TransactionPVM.Credentials) => item!.serialize(writer));
        if (this.candidates.length)
            writer.writeRepeatedMessage(14, this.candidates, (item: TransactionPVM.BlockCandidate) => item!.serialize(writer));
        if (this.fullOutputs.length)
            writer.writeRepeatedMessage(15, this.fullOutputs, (item: TransactionPVM.TransferableOutput) => item!.serialize(writer));
        if (this.hasBase)
            writer.writeMessage(4, this.base, () => this.base!.serialize(writer));
        if (this.hasAddValidator)
            writer.writeMessage(5, this.addValidator, () => this.addValidator!.serialize(writer));
        if (this.hasAddSubnetValidator)
            writer.writeMessage(6, this.addSubnetValidator, () => this.addSubnetValidator!.serialize(writer));
        if (this.hasAddDelegator)
            writer.writeMessage(7, this.addDelegator, () => this.addDelegator!.serialize(writer));
        if (this.hasCreateChain)
            writer.writeMessage(8, this.createChain, () => this.createChain!.serialize(writer));
        if (this.hasCreateSubnet)
            writer.writeMessage(9, this.createSubnet, () => this.createSubnet!.serialize(writer));
        if (this.hasImport)
            writer.writeMessage(10, this.import, () => this.import!.serialize(writer));
        if (this.hasExport)
            writer.writeMessage(11, this.export, () => this.export!.serialize(writer));
        if (this.hasAdvanceTime)
            writer.writeMessage(12, this.advanceTime, () => this.advanceTime!.serialize(writer));
        if (this.hasRewardValidator)
            writer.writeMessage(13, this.rewardValidator, () => this.rewardValidator!.serialize(writer));
        if (this.hasRemoveSupernetValidator)
            writer.writeMessage(16, this.removeSupernetValidator, () => this.removeSupernetValidator!.serialize(writer));
        if (this.hasTransformSupernet)
            writer.writeMessage(17, this.transformSupernet, () => this.transformSupernet!.serialize(writer));
        if (this.hasAddPermissionlessValidator)
            writer.writeMessage(18, this.addPermissionlessValidator, () => this.addPermissionlessValidator!.serialize(writer));
        if (this.hasAddPermissionlessDelegator)
            writer.writeMessage(19, this.addPermissionlessDelegator, () => this.addPermissionlessDelegator!.serialize(writer));
        if (this.hasTransferSupernetOwnership)
            writer.writeMessage(20, this.transferSupernetOwnership, () => this.transferSupernetOwnership!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionPVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionPVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.inputs, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TransactionPVM.TransferableInput.deserialize(reader), TransactionPVM.TransferableInput));
                    break;
                case 2:
                    reader.readMessage(message.outputs, () => pb_1.Message.addToRepeatedWrapperField(message, 2, TransactionPVM.TransferableOutput.deserialize(reader), TransactionPVM.TransferableOutput));
                    break;
                case 3:
                    reader.readMessage(message.creds, () => pb_1.Message.addToRepeatedWrapperField(message, 3, TransactionPVM.Credentials.deserialize(reader), TransactionPVM.Credentials));
                    break;
                case 14:
                    reader.readMessage(message.candidates, () => pb_1.Message.addToRepeatedWrapperField(message, 14, TransactionPVM.BlockCandidate.deserialize(reader), TransactionPVM.BlockCandidate));
                    break;
                case 15:
                    reader.readMessage(message.fullOutputs, () => pb_1.Message.addToRepeatedWrapperField(message, 15, TransactionPVM.TransferableOutput.deserialize(reader), TransactionPVM.TransferableOutput));
                    break;
                case 4:
                    reader.readMessage(message.base, () => message.base = TransactionPVM.BaseTx.deserialize(reader));
                    break;
                case 5:
                    reader.readMessage(message.addValidator, () => message.addValidator = TransactionPVM.AddValidatorTx.deserialize(reader));
                    break;
                case 6:
                    reader.readMessage(message.addSubnetValidator, () => message.addSubnetValidator = TransactionPVM.AddSubnetValidatorTx.deserialize(reader));
                    break;
                case 7:
                    reader.readMessage(message.addDelegator, () => message.addDelegator = TransactionPVM.AddDelegatorTx.deserialize(reader));
                    break;
                case 8:
                    reader.readMessage(message.createChain, () => message.createChain = TransactionPVM.CreateChainTx.deserialize(reader));
                    break;
                case 9:
                    reader.readMessage(message.createSubnet, () => message.createSubnet = TransactionPVM.CreateSubnetTx.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.import, () => message.import = TransactionPVM.ImportTx.deserialize(reader));
                    break;
                case 11:
                    reader.readMessage(message.export, () => message.export = TransactionPVM.ExportTx.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.advanceTime, () => message.advanceTime = TransactionPVM.AdvanceTimeTx.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.rewardValidator, () => message.rewardValidator = TransactionPVM.RewardValidatorTx.deserialize(reader));
                    break;
                case 16:
                    reader.readMessage(message.removeSupernetValidator, () => message.removeSupernetValidator = TransactionPVM.RemoveSupernetValidatorTx.deserialize(reader));
                    break;
                case 17:
                    reader.readMessage(message.transformSupernet, () => message.transformSupernet = TransactionPVM.TransformSupernetTx.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.addPermissionlessValidator, () => message.addPermissionlessValidator = TransactionPVM.AddPermissionlessValidatorTx.deserialize(reader));
                    break;
                case 19:
                    reader.readMessage(message.addPermissionlessDelegator, () => message.addPermissionlessDelegator = TransactionPVM.AddPermissionlessDelegatorTx.deserialize(reader));
                    break;
                case 20:
                    reader.readMessage(message.transferSupernetOwnership, () => message.transferSupernetOwnership = TransactionPVM.TransferSupernetOwnershipTx.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionPVM {
        return TransactionPVM.deserialize(bytes);
    }
}
export namespace TransactionPVM {
    export type AsObject = {
        inputs: TransactionPVM.TransferableInput.AsObject[];
        outputs: TransactionPVM.TransferableOutput.AsObject[];
        creds: TransactionPVM.Credentials.AsObject[];
        candidates: TransactionPVM.BlockCandidate.AsObject[];
        fullOutputs: TransactionPVM.TransferableOutput.AsObject[];
        base?: TransactionPVM.BaseTx.AsObject;
        addValidator?: TransactionPVM.AddValidatorTx.AsObject;
        addSubnetValidator?: TransactionPVM.AddSubnetValidatorTx.AsObject;
        addDelegator?: TransactionPVM.AddDelegatorTx.AsObject;
        createChain?: TransactionPVM.CreateChainTx.AsObject;
        createSubnet?: TransactionPVM.CreateSubnetTx.AsObject;
        import?: TransactionPVM.ImportTx.AsObject;
        export?: TransactionPVM.ExportTx.AsObject;
        advanceTime?: TransactionPVM.AdvanceTimeTx.AsObject;
        rewardValidator?: TransactionPVM.RewardValidatorTx.AsObject;
        removeSupernetValidator?: TransactionPVM.RemoveSupernetValidatorTx.AsObject;
        transformSupernet?: TransactionPVM.TransformSupernetTx.AsObject;
        addPermissionlessValidator?: TransactionPVM.AddPermissionlessValidatorTx.AsObject;
        addPermissionlessDelegator?: TransactionPVM.AddPermissionlessDelegatorTx.AsObject;
        transferSupernetOwnership?: TransactionPVM.TransferSupernetOwnershipTx.AsObject;
    };
    export type AsObjectPartial = {
        inputs?: TransactionPVM.TransferableInput.AsObjectPartial[];
        outputs?: TransactionPVM.TransferableOutput.AsObjectPartial[];
        creds?: TransactionPVM.Credentials.AsObjectPartial[];
        candidates?: TransactionPVM.BlockCandidate.AsObjectPartial[];
        fullOutputs?: TransactionPVM.TransferableOutput.AsObjectPartial[];
        base?: TransactionPVM.BaseTx.AsObjectPartial;
        addValidator?: TransactionPVM.AddValidatorTx.AsObjectPartial;
        addSubnetValidator?: TransactionPVM.AddSubnetValidatorTx.AsObjectPartial;
        addDelegator?: TransactionPVM.AddDelegatorTx.AsObjectPartial;
        createChain?: TransactionPVM.CreateChainTx.AsObjectPartial;
        createSubnet?: TransactionPVM.CreateSubnetTx.AsObjectPartial;
        import?: TransactionPVM.ImportTx.AsObjectPartial;
        export?: TransactionPVM.ExportTx.AsObjectPartial;
        advanceTime?: TransactionPVM.AdvanceTimeTx.AsObjectPartial;
        rewardValidator?: TransactionPVM.RewardValidatorTx.AsObjectPartial;
        removeSupernetValidator?: TransactionPVM.RemoveSupernetValidatorTx.AsObjectPartial;
        transformSupernet?: TransactionPVM.TransformSupernetTx.AsObjectPartial;
        addPermissionlessValidator?: TransactionPVM.AddPermissionlessValidatorTx.AsObjectPartial;
        addPermissionlessDelegator?: TransactionPVM.AddPermissionlessDelegatorTx.AsObjectPartial;
        transferSupernetOwnership?: TransactionPVM.TransferSupernetOwnershipTx.AsObjectPartial;
    };
    export class BlockCandidate extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            index?: number;
            indexInBc?: number;
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
                if ("indexInBc" in data && data.indexInBc != undefined) {
                    this.indexInBc = data.indexInBc;
                }
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        get indexInBc() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set indexInBc(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: BlockCandidate.AsObjectPartial): BlockCandidate {
            if (!data) {
                return new BlockCandidate();
            }
            const message = new BlockCandidate({});
            if (data.index != null) {
                message.index = data.index;
            }
            if (data.indexInBc != null) {
                message.indexInBc = data.indexInBc;
            }
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: BlockCandidate.AsObject = {
                index: this.index,
                indexInBc: this.indexInBc,
                id: this.id
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.index != 0)
                writer.writeInt64(1, this.index);
            if (this.indexInBc != 0)
                writer.writeInt64(2, this.indexInBc);
            if (this.id.length)
                writer.writeString(3, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BlockCandidate {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BlockCandidate();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.index = reader.readInt64();
                        break;
                    case 2:
                        message.indexInBc = reader.readInt64();
                        break;
                    case 3:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): BlockCandidate {
            return BlockCandidate.deserialize(bytes);
        }
    }
    export namespace BlockCandidate {
        export type AsObject = {
            index: number;
            indexInBc: number;
            id: string;
        };
        export type AsObjectPartial = {
            index?: number;
            indexInBc?: number;
            id?: string;
        };
    }
    export class TransferableInput extends pb_1.Message {
        #one_of_decls: number[][] = [[5, 6]];
        constructor(data?: any[] | ({
            assetId?: string;
            fxId?: string;
            txId?: string;
            outputIndex?: number;
        } & (({
            secp256k1TransferInput?: TransactionPVM.TransferableInput.SECP256K1TransferInput;
            stakeableLockInput?: never;
        } | {
            secp256k1TransferInput?: never;
            stakeableLockInput?: TransactionPVM.TransferableInput.StakeableLockInput;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("assetId" in data && data.assetId != undefined) {
                    this.assetId = data.assetId;
                }
                if ("fxId" in data && data.fxId != undefined) {
                    this.fxId = data.fxId;
                }
                if ("txId" in data && data.txId != undefined) {
                    this.txId = data.txId;
                }
                if ("outputIndex" in data && data.outputIndex != undefined) {
                    this.outputIndex = data.outputIndex;
                }
                if ("secp256k1TransferInput" in data && data.secp256k1TransferInput != undefined) {
                    this.secp256k1TransferInput = data.secp256k1TransferInput;
                }
                if ("stakeableLockInput" in data && data.stakeableLockInput != undefined) {
                    this.stakeableLockInput = data.stakeableLockInput;
                }
            }
        }
        get assetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set assetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get fxId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set fxId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get txId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set txId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get outputIndex() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set outputIndex(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        get secp256k1TransferInput() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableInput.SECP256K1TransferInput, 5) as TransactionPVM.TransferableInput.SECP256K1TransferInput | undefined;
        }
        set secp256k1TransferInput(value: TransactionPVM.TransferableInput.SECP256K1TransferInput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
        }
        get hasSecp256k1TransferInput() {
            return pb_1.Message.getField(this, 5) != null;
        }
        clearSecp256k1TransferInput(): void {
            this.secp256k1TransferInput = undefined!;
        }
        get stakeableLockInput() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableInput.StakeableLockInput, 6) as TransactionPVM.TransferableInput.StakeableLockInput | undefined;
        }
        set stakeableLockInput(value: TransactionPVM.TransferableInput.StakeableLockInput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
        }
        get hasStakeableLockInput() {
            return pb_1.Message.getField(this, 6) != null;
        }
        clearStakeableLockInput(): void {
            this.stakeableLockInput = undefined!;
        }
        get input() {
            const cases: {
                [index: number]: "none" | "secp256k1TransferInput" | "stakeableLockInput";
            } = {
                0: "none",
                5: "secp256k1TransferInput",
                6: "stakeableLockInput"
            };
            return cases[pb_1.Message.computeOneofCase(this, [5, 6])]!;
        }
        static fromObject(data?: TransferableInput.AsObjectPartial): TransferableInput {
            if (!data) {
                return new TransferableInput();
            }
            const message = new TransferableInput({});
            if (data.assetId != null) {
                message.assetId = data.assetId;
            }
            if (data.fxId != null) {
                message.fxId = data.fxId;
            }
            if (data.txId != null) {
                message.txId = data.txId;
            }
            if (data.outputIndex != null) {
                message.outputIndex = data.outputIndex;
            }
            if (data.secp256k1TransferInput != null) {
                message.secp256k1TransferInput = TransactionPVM.TransferableInput.SECP256K1TransferInput.fromObject(data.secp256k1TransferInput);
            }
            if (data.stakeableLockInput != null) {
                message.stakeableLockInput = TransactionPVM.TransferableInput.StakeableLockInput.fromObject(data.stakeableLockInput);
            }
            return message;
        }
        toObject() {
            const data: TransferableInput.AsObject = {
                assetId: this.assetId,
                fxId: this.fxId,
                txId: this.txId,
                outputIndex: this.outputIndex
            };
            if (this.secp256k1TransferInput != null) {
                data.secp256k1TransferInput = this.secp256k1TransferInput.toObject();
            }
            if (this.stakeableLockInput != null) {
                data.stakeableLockInput = this.stakeableLockInput.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.assetId.length)
                writer.writeString(1, this.assetId);
            if (this.fxId.length)
                writer.writeString(2, this.fxId);
            if (this.txId.length)
                writer.writeString(3, this.txId);
            if (this.outputIndex != 0)
                writer.writeUint32(4, this.outputIndex);
            if (this.hasSecp256k1TransferInput)
                writer.writeMessage(5, this.secp256k1TransferInput, () => this.secp256k1TransferInput!.serialize(writer));
            if (this.hasStakeableLockInput)
                writer.writeMessage(6, this.stakeableLockInput, () => this.stakeableLockInput!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferableInput {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferableInput();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.assetId = reader.readString();
                        break;
                    case 2:
                        message.fxId = reader.readString();
                        break;
                    case 3:
                        message.txId = reader.readString();
                        break;
                    case 4:
                        message.outputIndex = reader.readUint32();
                        break;
                    case 5:
                        reader.readMessage(message.secp256k1TransferInput, () => message.secp256k1TransferInput = TransactionPVM.TransferableInput.SECP256K1TransferInput.deserialize(reader));
                        break;
                    case 6:
                        reader.readMessage(message.stakeableLockInput, () => message.stakeableLockInput = TransactionPVM.TransferableInput.StakeableLockInput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransferableInput {
            return TransferableInput.deserialize(bytes);
        }
    }
    export namespace TransferableInput {
        export type AsObject = {
            assetId: string;
            fxId: string;
            txId: string;
            outputIndex: number;
            secp256k1TransferInput?: TransactionPVM.TransferableInput.SECP256K1TransferInput.AsObject;
            stakeableLockInput?: TransactionPVM.TransferableInput.StakeableLockInput.AsObject;
        };
        export type AsObjectPartial = {
            assetId?: string;
            fxId?: string;
            txId?: string;
            outputIndex?: number;
            secp256k1TransferInput?: TransactionPVM.TransferableInput.SECP256K1TransferInput.AsObjectPartial;
            stakeableLockInput?: TransactionPVM.TransferableInput.StakeableLockInput.AsObjectPartial;
        };
        export class SECP256K1TransferInput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                amount?: string;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 2, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 2, value);
            }
            static fromObject(data?: SECP256K1TransferInput.AsObjectPartial): SECP256K1TransferInput {
                if (!data) {
                    return new SECP256K1TransferInput();
                }
                const message = new SECP256K1TransferInput({});
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: SECP256K1TransferInput.AsObject = {
                    amount: this.amount,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.amount.length)
                    writer.writeString(1, this.amount);
                if (this.addresses.length)
                    writer.writeRepeatedString(2, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SECP256K1TransferInput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SECP256K1TransferInput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.amount = reader.readString();
                            break;
                        case 2:
                            pb_1.Message.addToRepeatedField(message, 2, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): SECP256K1TransferInput {
                return SECP256K1TransferInput.deserialize(bytes);
            }
        }
        export namespace SECP256K1TransferInput {
            export type AsObject = {
                amount: string;
                addresses: string[];
            };
            export type AsObjectPartial = {
                amount?: string;
                addresses?: string[];
            };
        }
        export class StakeableLockInput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                amount?: string;
                locktime?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 3, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 3, value);
            }
            static fromObject(data?: StakeableLockInput.AsObjectPartial): StakeableLockInput {
                if (!data) {
                    return new StakeableLockInput();
                }
                const message = new StakeableLockInput({});
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: StakeableLockInput.AsObject = {
                    amount: this.amount,
                    locktime: this.locktime,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.amount.length)
                    writer.writeString(1, this.amount);
                if (this.locktime != 0)
                    writer.writeUint64(2, this.locktime);
                if (this.addresses.length)
                    writer.writeRepeatedString(3, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StakeableLockInput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StakeableLockInput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.amount = reader.readString();
                            break;
                        case 2:
                            message.locktime = reader.readUint64();
                            break;
                        case 3:
                            pb_1.Message.addToRepeatedField(message, 3, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): StakeableLockInput {
                return StakeableLockInput.deserialize(bytes);
            }
        }
        export namespace StakeableLockInput {
            export type AsObject = {
                amount: string;
                locktime: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                amount?: string;
                locktime?: number;
                addresses?: string[];
            };
        }
    }
    export class TransferableOutput extends pb_1.Message {
        #one_of_decls: number[][] = [[3, 4, 5]];
        constructor(data?: any[] | ({
            assetId?: string;
            fxId?: string;
        } & (({
            secp256k1TransferOutput?: TransactionPVM.TransferableOutput.SECP256K1TransferOutput;
            secp256k1OutputOwnersOutput?: never;
            stakeableLockOutput?: never;
        } | {
            secp256k1TransferOutput?: never;
            secp256k1OutputOwnersOutput?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput;
            stakeableLockOutput?: never;
        } | {
            secp256k1TransferOutput?: never;
            secp256k1OutputOwnersOutput?: never;
            stakeableLockOutput?: TransactionPVM.TransferableOutput.StakeableLockOutput;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("assetId" in data && data.assetId != undefined) {
                    this.assetId = data.assetId;
                }
                if ("fxId" in data && data.fxId != undefined) {
                    this.fxId = data.fxId;
                }
                if ("secp256k1TransferOutput" in data && data.secp256k1TransferOutput != undefined) {
                    this.secp256k1TransferOutput = data.secp256k1TransferOutput;
                }
                if ("secp256k1OutputOwnersOutput" in data && data.secp256k1OutputOwnersOutput != undefined) {
                    this.secp256k1OutputOwnersOutput = data.secp256k1OutputOwnersOutput;
                }
                if ("stakeableLockOutput" in data && data.stakeableLockOutput != undefined) {
                    this.stakeableLockOutput = data.stakeableLockOutput;
                }
            }
        }
        get assetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set assetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get fxId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set fxId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get secp256k1TransferOutput() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.SECP256K1TransferOutput, 3) as TransactionPVM.TransferableOutput.SECP256K1TransferOutput | undefined;
        }
        set secp256k1TransferOutput(value: TransactionPVM.TransferableOutput.SECP256K1TransferOutput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
        }
        get hasSecp256k1TransferOutput() {
            return pb_1.Message.getField(this, 3) != null;
        }
        clearSecp256k1TransferOutput(): void {
            this.secp256k1TransferOutput = undefined!;
        }
        get secp256k1OutputOwnersOutput() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput, 4) as TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined;
        }
        set secp256k1OutputOwnersOutput(value: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
        }
        get hasSecp256k1OutputOwnersOutput() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearSecp256k1OutputOwnersOutput(): void {
            this.secp256k1OutputOwnersOutput = undefined!;
        }
        get stakeableLockOutput() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.StakeableLockOutput, 5) as TransactionPVM.TransferableOutput.StakeableLockOutput | undefined;
        }
        set stakeableLockOutput(value: TransactionPVM.TransferableOutput.StakeableLockOutput | undefined) {
            pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
        }
        get hasStakeableLockOutput() {
            return pb_1.Message.getField(this, 5) != null;
        }
        clearStakeableLockOutput(): void {
            this.stakeableLockOutput = undefined!;
        }
        get output() {
            const cases: {
                [index: number]: "none" | "secp256k1TransferOutput" | "secp256k1OutputOwnersOutput" | "stakeableLockOutput";
            } = {
                0: "none",
                3: "secp256k1TransferOutput",
                4: "secp256k1OutputOwnersOutput",
                5: "stakeableLockOutput"
            };
            return cases[pb_1.Message.computeOneofCase(this, [3, 4, 5])]!;
        }
        static fromObject(data?: TransferableOutput.AsObjectPartial): TransferableOutput {
            if (!data) {
                return new TransferableOutput();
            }
            const message = new TransferableOutput({});
            if (data.assetId != null) {
                message.assetId = data.assetId;
            }
            if (data.fxId != null) {
                message.fxId = data.fxId;
            }
            if (data.secp256k1TransferOutput != null) {
                message.secp256k1TransferOutput = TransactionPVM.TransferableOutput.SECP256K1TransferOutput.fromObject(data.secp256k1TransferOutput);
            }
            if (data.secp256k1OutputOwnersOutput != null) {
                message.secp256k1OutputOwnersOutput = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.fromObject(data.secp256k1OutputOwnersOutput);
            }
            if (data.stakeableLockOutput != null) {
                message.stakeableLockOutput = TransactionPVM.TransferableOutput.StakeableLockOutput.fromObject(data.stakeableLockOutput);
            }
            return message;
        }
        toObject() {
            const data: TransferableOutput.AsObject = {
                assetId: this.assetId,
                fxId: this.fxId
            };
            if (this.secp256k1TransferOutput != null) {
                data.secp256k1TransferOutput = this.secp256k1TransferOutput.toObject();
            }
            if (this.secp256k1OutputOwnersOutput != null) {
                data.secp256k1OutputOwnersOutput = this.secp256k1OutputOwnersOutput.toObject();
            }
            if (this.stakeableLockOutput != null) {
                data.stakeableLockOutput = this.stakeableLockOutput.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.assetId.length)
                writer.writeString(1, this.assetId);
            if (this.fxId.length)
                writer.writeString(2, this.fxId);
            if (this.hasSecp256k1TransferOutput)
                writer.writeMessage(3, this.secp256k1TransferOutput, () => this.secp256k1TransferOutput!.serialize(writer));
            if (this.hasSecp256k1OutputOwnersOutput)
                writer.writeMessage(4, this.secp256k1OutputOwnersOutput, () => this.secp256k1OutputOwnersOutput!.serialize(writer));
            if (this.hasStakeableLockOutput)
                writer.writeMessage(5, this.stakeableLockOutput, () => this.stakeableLockOutput!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferableOutput {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferableOutput();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.assetId = reader.readString();
                        break;
                    case 2:
                        message.fxId = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.secp256k1TransferOutput, () => message.secp256k1TransferOutput = TransactionPVM.TransferableOutput.SECP256K1TransferOutput.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.secp256k1OutputOwnersOutput, () => message.secp256k1OutputOwnersOutput = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.stakeableLockOutput, () => message.stakeableLockOutput = TransactionPVM.TransferableOutput.StakeableLockOutput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransferableOutput {
            return TransferableOutput.deserialize(bytes);
        }
    }
    export namespace TransferableOutput {
        export type AsObject = {
            assetId: string;
            fxId: string;
            secp256k1TransferOutput?: TransactionPVM.TransferableOutput.SECP256K1TransferOutput.AsObject;
            secp256k1OutputOwnersOutput?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObject;
            stakeableLockOutput?: TransactionPVM.TransferableOutput.StakeableLockOutput.AsObject;
        };
        export type AsObjectPartial = {
            assetId?: string;
            fxId?: string;
            secp256k1TransferOutput?: TransactionPVM.TransferableOutput.SECP256K1TransferOutput.AsObjectPartial;
            secp256k1OutputOwnersOutput?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObjectPartial;
            stakeableLockOutput?: TransactionPVM.TransferableOutput.StakeableLockOutput.AsObjectPartial;
        };
        export class SECP256K1TransferOutput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                amount?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("threshold" in data && data.threshold != undefined) {
                        this.threshold = data.threshold;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get threshold() {
                return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
            }
            set threshold(value: number) {
                pb_1.Message.setField(this, 3, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 4, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 4, value);
            }
            static fromObject(data?: SECP256K1TransferOutput.AsObjectPartial): SECP256K1TransferOutput {
                if (!data) {
                    return new SECP256K1TransferOutput();
                }
                const message = new SECP256K1TransferOutput({});
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.threshold != null) {
                    message.threshold = data.threshold;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: SECP256K1TransferOutput.AsObject = {
                    amount: this.amount,
                    locktime: this.locktime,
                    threshold: this.threshold,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.amount.length)
                    writer.writeString(1, this.amount);
                if (this.locktime != 0)
                    writer.writeUint64(2, this.locktime);
                if (this.threshold != 0)
                    writer.writeUint32(3, this.threshold);
                if (this.addresses.length)
                    writer.writeRepeatedString(4, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SECP256K1TransferOutput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SECP256K1TransferOutput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.amount = reader.readString();
                            break;
                        case 2:
                            message.locktime = reader.readUint64();
                            break;
                        case 3:
                            message.threshold = reader.readUint32();
                            break;
                        case 4:
                            pb_1.Message.addToRepeatedField(message, 4, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): SECP256K1TransferOutput {
                return SECP256K1TransferOutput.deserialize(bytes);
            }
        }
        export namespace SECP256K1TransferOutput {
            export type AsObject = {
                amount: string;
                locktime: number;
                threshold: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                amount?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            };
        }
        export class StakeableLockOutput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                amount?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("threshold" in data && data.threshold != undefined) {
                        this.threshold = data.threshold;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get threshold() {
                return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
            }
            set threshold(value: number) {
                pb_1.Message.setField(this, 3, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 4, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 4, value);
            }
            static fromObject(data?: StakeableLockOutput.AsObjectPartial): StakeableLockOutput {
                if (!data) {
                    return new StakeableLockOutput();
                }
                const message = new StakeableLockOutput({});
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.threshold != null) {
                    message.threshold = data.threshold;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: StakeableLockOutput.AsObject = {
                    amount: this.amount,
                    locktime: this.locktime,
                    threshold: this.threshold,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.amount.length)
                    writer.writeString(1, this.amount);
                if (this.locktime != 0)
                    writer.writeUint64(2, this.locktime);
                if (this.threshold != 0)
                    writer.writeUint32(3, this.threshold);
                if (this.addresses.length)
                    writer.writeRepeatedString(4, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): StakeableLockOutput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new StakeableLockOutput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.amount = reader.readString();
                            break;
                        case 2:
                            message.locktime = reader.readUint64();
                            break;
                        case 3:
                            message.threshold = reader.readUint32();
                            break;
                        case 4:
                            pb_1.Message.addToRepeatedField(message, 4, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): StakeableLockOutput {
                return StakeableLockOutput.deserialize(bytes);
            }
        }
        export namespace StakeableLockOutput {
            export type AsObject = {
                amount: string;
                locktime: number;
                threshold: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                amount?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            };
        }
        export class SECP256K1OutputOwnersOutput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("threshold" in data && data.threshold != undefined) {
                        this.threshold = data.threshold;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 1, value);
            }
            get threshold() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set threshold(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 3, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 3, value);
            }
            static fromObject(data?: SECP256K1OutputOwnersOutput.AsObjectPartial): SECP256K1OutputOwnersOutput {
                if (!data) {
                    return new SECP256K1OutputOwnersOutput();
                }
                const message = new SECP256K1OutputOwnersOutput({});
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.threshold != null) {
                    message.threshold = data.threshold;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: SECP256K1OutputOwnersOutput.AsObject = {
                    locktime: this.locktime,
                    threshold: this.threshold,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.locktime != 0)
                    writer.writeUint64(1, this.locktime);
                if (this.threshold != 0)
                    writer.writeUint32(2, this.threshold);
                if (this.addresses.length)
                    writer.writeRepeatedString(3, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SECP256K1OutputOwnersOutput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SECP256K1OutputOwnersOutput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.locktime = reader.readUint64();
                            break;
                        case 2:
                            message.threshold = reader.readUint32();
                            break;
                        case 3:
                            pb_1.Message.addToRepeatedField(message, 3, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): SECP256K1OutputOwnersOutput {
                return SECP256K1OutputOwnersOutput.deserialize(bytes);
            }
        }
        export namespace SECP256K1OutputOwnersOutput {
            export type AsObject = {
                locktime: number;
                threshold: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            };
        }
    }
    export class Credentials extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            signatures?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("signatures" in data && data.signatures != undefined) {
                    this.signatures = data.signatures;
                }
            }
        }
        get signatures() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set signatures(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: Credentials.AsObjectPartial): Credentials {
            if (!data) {
                return new Credentials();
            }
            const message = new Credentials({});
            if (data.signatures != null) {
                message.signatures = data.signatures;
            }
            return message;
        }
        toObject() {
            const data: Credentials.AsObject = {
                signatures: this.signatures
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.signatures.length)
                writer.writeRepeatedString(1, this.signatures);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Credentials {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Credentials();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Credentials {
            return Credentials.deserialize(bytes);
        }
    }
    export namespace Credentials {
        export type AsObject = {
            signatures: string[];
        };
        export type AsObjectPartial = {
            signatures?: string[];
        };
    }
    export class Validator extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            nodeId?: string;
            startTime?: number;
            endTime?: number;
            weight?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("nodeId" in data && data.nodeId != undefined) {
                    this.nodeId = data.nodeId;
                }
                if ("startTime" in data && data.startTime != undefined) {
                    this.startTime = data.startTime;
                }
                if ("endTime" in data && data.endTime != undefined) {
                    this.endTime = data.endTime;
                }
                if ("weight" in data && data.weight != undefined) {
                    this.weight = data.weight;
                }
            }
        }
        get nodeId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set nodeId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get startTime() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set startTime(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        get endTime() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set endTime(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        get weight() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set weight(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data?: Validator.AsObjectPartial): Validator {
            if (!data) {
                return new Validator();
            }
            const message = new Validator({});
            if (data.nodeId != null) {
                message.nodeId = data.nodeId;
            }
            if (data.startTime != null) {
                message.startTime = data.startTime;
            }
            if (data.endTime != null) {
                message.endTime = data.endTime;
            }
            if (data.weight != null) {
                message.weight = data.weight;
            }
            return message;
        }
        toObject() {
            const data: Validator.AsObject = {
                nodeId: this.nodeId,
                startTime: this.startTime,
                endTime: this.endTime,
                weight: this.weight
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.nodeId.length)
                writer.writeString(1, this.nodeId);
            if (this.startTime != 0)
                writer.writeUint64(2, this.startTime);
            if (this.endTime != 0)
                writer.writeUint64(3, this.endTime);
            if (this.weight.length)
                writer.writeString(4, this.weight);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Validator {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Validator();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.nodeId = reader.readString();
                        break;
                    case 2:
                        message.startTime = reader.readUint64();
                        break;
                    case 3:
                        message.endTime = reader.readUint64();
                        break;
                    case 4:
                        message.weight = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Validator {
            return Validator.deserialize(bytes);
        }
    }
    export namespace Validator {
        export type AsObject = {
            nodeId: string;
            startTime: number;
            endTime: number;
            weight: string;
        };
        export type AsObjectPartial = {
            nodeId?: string;
            startTime?: number;
            endTime?: number;
            weight?: string;
        };
    }
    export class Stake extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            lockedOuts?: TransactionPVM.TransferableOutput[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("lockedOuts" in data && data.lockedOuts != undefined) {
                    this.lockedOuts = data.lockedOuts;
                }
            }
        }
        get lockedOuts() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionPVM.TransferableOutput, 1) as TransactionPVM.TransferableOutput[];
        }
        set lockedOuts(value: TransactionPVM.TransferableOutput[]) {
            pb_1.Message.setRepeatedWrapperField(this, 1, value);
        }
        static fromObject(data?: Stake.AsObjectPartial): Stake {
            if (!data) {
                return new Stake();
            }
            const message = new Stake({});
            if (data.lockedOuts != null) {
                message.lockedOuts = data.lockedOuts.map(item => TransactionPVM.TransferableOutput.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: Stake.AsObject = {
                lockedOuts: this.lockedOuts.map((item: TransactionPVM.TransferableOutput) => item.toObject())
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.lockedOuts.length)
                writer.writeRepeatedMessage(1, this.lockedOuts, (item: TransactionPVM.TransferableOutput) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Stake {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Stake();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.lockedOuts, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TransactionPVM.TransferableOutput.deserialize(reader), TransactionPVM.TransferableOutput));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Stake {
            return Stake.deserialize(bytes);
        }
    }
    export namespace Stake {
        export type AsObject = {
            lockedOuts: TransactionPVM.TransferableOutput.AsObject[];
        };
        export type AsObjectPartial = {
            lockedOuts?: TransactionPVM.TransferableOutput.AsObjectPartial[];
        };
    }
    export class BaseTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {}) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") { }
        }
        static fromObject(data?: BaseTx.AsObjectPartial): BaseTx {
            if (!data) {
                return new BaseTx();
            }
            const message = new BaseTx({});
            return message;
        }
        toObject() {
            const data: BaseTx.AsObject = {};
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BaseTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BaseTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): BaseTx {
            return BaseTx.deserialize(bytes);
        }
    }
    export namespace BaseTx {
        export type AsObject = {};
        export type AsObjectPartial = {};
    }
    export class AddValidatorTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            validator?: TransactionPVM.Validator;
            stake?: TransactionPVM.Stake;
            rewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput;
            shares?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("validator" in data && data.validator != undefined) {
                    this.validator = data.validator;
                }
                if ("stake" in data && data.stake != undefined) {
                    this.stake = data.stake;
                }
                if ("rewardsOwner" in data && data.rewardsOwner != undefined) {
                    this.rewardsOwner = data.rewardsOwner;
                }
                if ("shares" in data && data.shares != undefined) {
                    this.shares = data.shares;
                }
            }
        }
        get validator() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.Validator, 1) as TransactionPVM.Validator | undefined;
        }
        set validator(value: TransactionPVM.Validator | undefined) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasValidator() {
            return pb_1.Message.getField(this, 1) != null;
        }
        clearValidator(): void {
            this.validator = undefined!;
        }
        get stake() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.Stake, 2) as TransactionPVM.Stake | undefined;
        }
        set stake(value: TransactionPVM.Stake | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasStake() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearStake(): void {
            this.stake = undefined!;
        }
        get rewardsOwner() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput, 3) as TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined;
        }
        set rewardsOwner(value: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasRewardsOwner() {
            return pb_1.Message.getField(this, 3) != null;
        }
        clearRewardsOwner(): void {
            this.rewardsOwner = undefined!;
        }
        get shares() {
            return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
        }
        set shares(value: number) {
            pb_1.Message.setField(this, 4, value);
        }
        static fromObject(data?: AddValidatorTx.AsObjectPartial): AddValidatorTx {
            if (!data) {
                return new AddValidatorTx();
            }
            const message = new AddValidatorTx({});
            if (data.validator != null) {
                message.validator = TransactionPVM.Validator.fromObject(data.validator);
            }
            if (data.stake != null) {
                message.stake = TransactionPVM.Stake.fromObject(data.stake);
            }
            if (data.rewardsOwner != null) {
                message.rewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.fromObject(data.rewardsOwner);
            }
            if (data.shares != null) {
                message.shares = data.shares;
            }
            return message;
        }
        toObject() {
            const data: AddValidatorTx.AsObject = {
                shares: this.shares
            };
            if (this.validator != null) {
                data.validator = this.validator.toObject();
            }
            if (this.stake != null) {
                data.stake = this.stake.toObject();
            }
            if (this.rewardsOwner != null) {
                data.rewardsOwner = this.rewardsOwner.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasValidator)
                writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
            if (this.hasStake)
                writer.writeMessage(2, this.stake, () => this.stake!.serialize(writer));
            if (this.hasRewardsOwner)
                writer.writeMessage(3, this.rewardsOwner, () => this.rewardsOwner!.serialize(writer));
            if (this.shares != 0)
                writer.writeUint32(4, this.shares);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddValidatorTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddValidatorTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.validator, () => message.validator = TransactionPVM.Validator.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.stake, () => message.stake = TransactionPVM.Stake.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.rewardsOwner, () => message.rewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.deserialize(reader));
                        break;
                    case 4:
                        message.shares = reader.readUint32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddValidatorTx {
            return AddValidatorTx.deserialize(bytes);
        }
    }
    export namespace AddValidatorTx {
        export type AsObject = {
            validator?: TransactionPVM.Validator.AsObject;
            stake?: TransactionPVM.Stake.AsObject;
            rewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObject;
            shares: number;
        };
        export type AsObjectPartial = {
            validator?: TransactionPVM.Validator.AsObjectPartial;
            stake?: TransactionPVM.Stake.AsObjectPartial;
            rewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObjectPartial;
            shares?: number;
        };
    }
    export class AddSubnetValidatorTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            validator?: TransactionPVM.Validator;
            subnetId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("validator" in data && data.validator != undefined) {
                    this.validator = data.validator;
                }
                if ("subnetId" in data && data.subnetId != undefined) {
                    this.subnetId = data.subnetId;
                }
            }
        }
        get validator() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.Validator, 1) as TransactionPVM.Validator | undefined;
        }
        set validator(value: TransactionPVM.Validator | undefined) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasValidator() {
            return pb_1.Message.getField(this, 1) != null;
        }
        clearValidator(): void {
            this.validator = undefined!;
        }
        get subnetId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set subnetId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: AddSubnetValidatorTx.AsObjectPartial): AddSubnetValidatorTx {
            if (!data) {
                return new AddSubnetValidatorTx();
            }
            const message = new AddSubnetValidatorTx({});
            if (data.validator != null) {
                message.validator = TransactionPVM.Validator.fromObject(data.validator);
            }
            if (data.subnetId != null) {
                message.subnetId = data.subnetId;
            }
            return message;
        }
        toObject() {
            const data: AddSubnetValidatorTx.AsObject = {
                subnetId: this.subnetId
            };
            if (this.validator != null) {
                data.validator = this.validator.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasValidator)
                writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
            if (this.subnetId.length)
                writer.writeString(2, this.subnetId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddSubnetValidatorTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddSubnetValidatorTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.validator, () => message.validator = TransactionPVM.Validator.deserialize(reader));
                        break;
                    case 2:
                        message.subnetId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddSubnetValidatorTx {
            return AddSubnetValidatorTx.deserialize(bytes);
        }
    }
    export namespace AddSubnetValidatorTx {
        export type AsObject = {
            validator?: TransactionPVM.Validator.AsObject;
            subnetId: string;
        };
        export type AsObjectPartial = {
            validator?: TransactionPVM.Validator.AsObjectPartial;
            subnetId?: string;
        };
    }
    export class AddDelegatorTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            validator?: TransactionPVM.Validator;
            stake?: TransactionPVM.Stake;
            rewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("validator" in data && data.validator != undefined) {
                    this.validator = data.validator;
                }
                if ("stake" in data && data.stake != undefined) {
                    this.stake = data.stake;
                }
                if ("rewardsOwner" in data && data.rewardsOwner != undefined) {
                    this.rewardsOwner = data.rewardsOwner;
                }
            }
        }
        get validator() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.Validator, 1) as TransactionPVM.Validator | undefined;
        }
        set validator(value: TransactionPVM.Validator | undefined) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasValidator() {
            return pb_1.Message.getField(this, 1) != null;
        }
        clearValidator(): void {
            this.validator = undefined!;
        }
        get stake() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.Stake, 2) as TransactionPVM.Stake | undefined;
        }
        set stake(value: TransactionPVM.Stake | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasStake() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearStake(): void {
            this.stake = undefined!;
        }
        get rewardsOwner() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput, 3) as TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined;
        }
        set rewardsOwner(value: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasRewardsOwner() {
            return pb_1.Message.getField(this, 3) != null;
        }
        clearRewardsOwner(): void {
            this.rewardsOwner = undefined!;
        }
        static fromObject(data?: AddDelegatorTx.AsObjectPartial): AddDelegatorTx {
            if (!data) {
                return new AddDelegatorTx();
            }
            const message = new AddDelegatorTx({});
            if (data.validator != null) {
                message.validator = TransactionPVM.Validator.fromObject(data.validator);
            }
            if (data.stake != null) {
                message.stake = TransactionPVM.Stake.fromObject(data.stake);
            }
            if (data.rewardsOwner != null) {
                message.rewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.fromObject(data.rewardsOwner);
            }
            return message;
        }
        toObject() {
            const data: AddDelegatorTx.AsObject = {};
            if (this.validator != null) {
                data.validator = this.validator.toObject();
            }
            if (this.stake != null) {
                data.stake = this.stake.toObject();
            }
            if (this.rewardsOwner != null) {
                data.rewardsOwner = this.rewardsOwner.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasValidator)
                writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
            if (this.hasStake)
                writer.writeMessage(2, this.stake, () => this.stake!.serialize(writer));
            if (this.hasRewardsOwner)
                writer.writeMessage(3, this.rewardsOwner, () => this.rewardsOwner!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddDelegatorTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddDelegatorTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.validator, () => message.validator = TransactionPVM.Validator.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.stake, () => message.stake = TransactionPVM.Stake.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.rewardsOwner, () => message.rewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddDelegatorTx {
            return AddDelegatorTx.deserialize(bytes);
        }
    }
    export namespace AddDelegatorTx {
        export type AsObject = {
            validator?: TransactionPVM.Validator.AsObject;
            stake?: TransactionPVM.Stake.AsObject;
            rewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObject;
        };
        export type AsObjectPartial = {
            validator?: TransactionPVM.Validator.AsObjectPartial;
            stake?: TransactionPVM.Stake.AsObjectPartial;
            rewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObjectPartial;
        };
    }
    export class CreateChainTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            supernetId?: string;
            chainName?: string;
            vmId?: string;
            fxIds?: string[];
            genesisData?: string;
            assetId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("supernetId" in data && data.supernetId != undefined) {
                    this.supernetId = data.supernetId;
                }
                if ("chainName" in data && data.chainName != undefined) {
                    this.chainName = data.chainName;
                }
                if ("vmId" in data && data.vmId != undefined) {
                    this.vmId = data.vmId;
                }
                if ("fxIds" in data && data.fxIds != undefined) {
                    this.fxIds = data.fxIds;
                }
                if ("genesisData" in data && data.genesisData != undefined) {
                    this.genesisData = data.genesisData;
                }
                if ("assetId" in data && data.assetId != undefined) {
                    this.assetId = data.assetId;
                }
            }
        }
        get supernetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set supernetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get chainName() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set chainName(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get vmId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set vmId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get fxIds() {
            return pb_1.Message.getFieldWithDefault(this, 4, []) as string[];
        }
        set fxIds(value: string[]) {
            pb_1.Message.setField(this, 4, value);
        }
        get genesisData() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set genesisData(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get assetId() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set assetId(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data?: CreateChainTx.AsObjectPartial): CreateChainTx {
            if (!data) {
                return new CreateChainTx();
            }
            const message = new CreateChainTx({});
            if (data.supernetId != null) {
                message.supernetId = data.supernetId;
            }
            if (data.chainName != null) {
                message.chainName = data.chainName;
            }
            if (data.vmId != null) {
                message.vmId = data.vmId;
            }
            if (data.fxIds != null) {
                message.fxIds = data.fxIds;
            }
            if (data.genesisData != null) {
                message.genesisData = data.genesisData;
            }
            if (data.assetId != null) {
                message.assetId = data.assetId;
            }
            return message;
        }
        toObject() {
            const data: CreateChainTx.AsObject = {
                supernetId: this.supernetId,
                chainName: this.chainName,
                vmId: this.vmId,
                fxIds: this.fxIds,
                genesisData: this.genesisData,
                assetId: this.assetId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.supernetId.length)
                writer.writeString(1, this.supernetId);
            if (this.chainName.length)
                writer.writeString(2, this.chainName);
            if (this.vmId.length)
                writer.writeString(3, this.vmId);
            if (this.fxIds.length)
                writer.writeRepeatedString(4, this.fxIds);
            if (this.genesisData.length)
                writer.writeString(5, this.genesisData);
            if (this.assetId.length)
                writer.writeString(6, this.assetId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateChainTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateChainTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.supernetId = reader.readString();
                        break;
                    case 2:
                        message.chainName = reader.readString();
                        break;
                    case 3:
                        message.vmId = reader.readString();
                        break;
                    case 4:
                        pb_1.Message.addToRepeatedField(message, 4, reader.readString());
                        break;
                    case 5:
                        message.genesisData = reader.readString();
                        break;
                    case 6:
                        message.assetId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateChainTx {
            return CreateChainTx.deserialize(bytes);
        }
    }
    export namespace CreateChainTx {
        export type AsObject = {
            supernetId: string;
            chainName: string;
            vmId: string;
            fxIds: string[];
            genesisData: string;
            assetId: string;
        };
        export type AsObjectPartial = {
            supernetId?: string;
            chainName?: string;
            vmId?: string;
            fxIds?: string[];
            genesisData?: string;
            assetId?: string;
        };
    }
    export class CreateSubnetTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            rewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("rewardsOwner" in data && data.rewardsOwner != undefined) {
                    this.rewardsOwner = data.rewardsOwner;
                }
            }
        }
        get rewardsOwner() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput, 1) as TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined;
        }
        set rewardsOwner(value: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasRewardsOwner() {
            return pb_1.Message.getField(this, 1) != null;
        }
        clearRewardsOwner(): void {
            this.rewardsOwner = undefined!;
        }
        static fromObject(data?: CreateSubnetTx.AsObjectPartial): CreateSubnetTx {
            if (!data) {
                return new CreateSubnetTx();
            }
            const message = new CreateSubnetTx({});
            if (data.rewardsOwner != null) {
                message.rewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.fromObject(data.rewardsOwner);
            }
            return message;
        }
        toObject() {
            const data: CreateSubnetTx.AsObject = {};
            if (this.rewardsOwner != null) {
                data.rewardsOwner = this.rewardsOwner.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasRewardsOwner)
                writer.writeMessage(1, this.rewardsOwner, () => this.rewardsOwner!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): CreateSubnetTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new CreateSubnetTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.rewardsOwner, () => message.rewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): CreateSubnetTx {
            return CreateSubnetTx.deserialize(bytes);
        }
    }
    export namespace CreateSubnetTx {
        export type AsObject = {
            rewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObject;
        };
        export type AsObjectPartial = {
            rewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObjectPartial;
        };
    }
    export class ImportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sourceBlockchainId?: string;
            importedInputs?: TransactionPVM.TransferableInput[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sourceBlockchainId" in data && data.sourceBlockchainId != undefined) {
                    this.sourceBlockchainId = data.sourceBlockchainId;
                }
                if ("importedInputs" in data && data.importedInputs != undefined) {
                    this.importedInputs = data.importedInputs;
                }
            }
        }
        get sourceBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sourceBlockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get importedInputs() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionPVM.TransferableInput, 2) as TransactionPVM.TransferableInput[];
        }
        set importedInputs(value: TransactionPVM.TransferableInput[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data?: ImportTx.AsObjectPartial): ImportTx {
            if (!data) {
                return new ImportTx();
            }
            const message = new ImportTx({});
            if (data.sourceBlockchainId != null) {
                message.sourceBlockchainId = data.sourceBlockchainId;
            }
            if (data.importedInputs != null) {
                message.importedInputs = data.importedInputs.map(item => TransactionPVM.TransferableInput.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: ImportTx.AsObject = {
                sourceBlockchainId: this.sourceBlockchainId,
                importedInputs: this.importedInputs.map((item: TransactionPVM.TransferableInput) => item.toObject())
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sourceBlockchainId.length)
                writer.writeString(1, this.sourceBlockchainId);
            if (this.importedInputs.length)
                writer.writeRepeatedMessage(2, this.importedInputs, (item: TransactionPVM.TransferableInput) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ImportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ImportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sourceBlockchainId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.importedInputs, () => pb_1.Message.addToRepeatedWrapperField(message, 2, TransactionPVM.TransferableInput.deserialize(reader), TransactionPVM.TransferableInput));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ImportTx {
            return ImportTx.deserialize(bytes);
        }
    }
    export namespace ImportTx {
        export type AsObject = {
            sourceBlockchainId: string;
            importedInputs: TransactionPVM.TransferableInput.AsObject[];
        };
        export type AsObjectPartial = {
            sourceBlockchainId?: string;
            importedInputs?: TransactionPVM.TransferableInput.AsObjectPartial[];
        };
    }
    export class ExportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            destinationBlockchainId?: string;
            exportedOutputs?: TransactionPVM.TransferableOutput[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("destinationBlockchainId" in data && data.destinationBlockchainId != undefined) {
                    this.destinationBlockchainId = data.destinationBlockchainId;
                }
                if ("exportedOutputs" in data && data.exportedOutputs != undefined) {
                    this.exportedOutputs = data.exportedOutputs;
                }
            }
        }
        get destinationBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set destinationBlockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get exportedOutputs() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionPVM.TransferableOutput, 2) as TransactionPVM.TransferableOutput[];
        }
        set exportedOutputs(value: TransactionPVM.TransferableOutput[]) {
            pb_1.Message.setRepeatedWrapperField(this, 2, value);
        }
        static fromObject(data?: ExportTx.AsObjectPartial): ExportTx {
            if (!data) {
                return new ExportTx();
            }
            const message = new ExportTx({});
            if (data.destinationBlockchainId != null) {
                message.destinationBlockchainId = data.destinationBlockchainId;
            }
            if (data.exportedOutputs != null) {
                message.exportedOutputs = data.exportedOutputs.map(item => TransactionPVM.TransferableOutput.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: ExportTx.AsObject = {
                destinationBlockchainId: this.destinationBlockchainId,
                exportedOutputs: this.exportedOutputs.map((item: TransactionPVM.TransferableOutput) => item.toObject())
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.destinationBlockchainId.length)
                writer.writeString(1, this.destinationBlockchainId);
            if (this.exportedOutputs.length)
                writer.writeRepeatedMessage(2, this.exportedOutputs, (item: TransactionPVM.TransferableOutput) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ExportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ExportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.destinationBlockchainId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.exportedOutputs, () => pb_1.Message.addToRepeatedWrapperField(message, 2, TransactionPVM.TransferableOutput.deserialize(reader), TransactionPVM.TransferableOutput));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ExportTx {
            return ExportTx.deserialize(bytes);
        }
    }
    export namespace ExportTx {
        export type AsObject = {
            destinationBlockchainId: string;
            exportedOutputs: TransactionPVM.TransferableOutput.AsObject[];
        };
        export type AsObjectPartial = {
            destinationBlockchainId?: string;
            exportedOutputs?: TransactionPVM.TransferableOutput.AsObjectPartial[];
        };
    }
    export class AdvanceTimeTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            timestamp?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("timestamp" in data && data.timestamp != undefined) {
                    this.timestamp = data.timestamp;
                }
            }
        }
        get timestamp() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set timestamp(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: AdvanceTimeTx.AsObjectPartial): AdvanceTimeTx {
            if (!data) {
                return new AdvanceTimeTx();
            }
            const message = new AdvanceTimeTx({});
            if (data.timestamp != null) {
                message.timestamp = data.timestamp;
            }
            return message;
        }
        toObject() {
            const data: AdvanceTimeTx.AsObject = {
                timestamp: this.timestamp
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.timestamp != 0)
                writer.writeInt64(1, this.timestamp);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AdvanceTimeTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AdvanceTimeTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.timestamp = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AdvanceTimeTx {
            return AdvanceTimeTx.deserialize(bytes);
        }
    }
    export namespace AdvanceTimeTx {
        export type AsObject = {
            timestamp: number;
        };
        export type AsObjectPartial = {
            timestamp?: number;
        };
    }
    export class RewardValidatorTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            stakingTxId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("stakingTxId" in data && data.stakingTxId != undefined) {
                    this.stakingTxId = data.stakingTxId;
                }
            }
        }
        get stakingTxId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set stakingTxId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: RewardValidatorTx.AsObjectPartial): RewardValidatorTx {
            if (!data) {
                return new RewardValidatorTx();
            }
            const message = new RewardValidatorTx({});
            if (data.stakingTxId != null) {
                message.stakingTxId = data.stakingTxId;
            }
            return message;
        }
        toObject() {
            const data: RewardValidatorTx.AsObject = {
                stakingTxId: this.stakingTxId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.stakingTxId.length)
                writer.writeString(1, this.stakingTxId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RewardValidatorTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RewardValidatorTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.stakingTxId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): RewardValidatorTx {
            return RewardValidatorTx.deserialize(bytes);
        }
    }
    export namespace RewardValidatorTx {
        export type AsObject = {
            stakingTxId: string;
        };
        export type AsObjectPartial = {
            stakingTxId?: string;
        };
    }
    export class RemoveSupernetValidatorTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            nodeId?: string;
            supernetId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("nodeId" in data && data.nodeId != undefined) {
                    this.nodeId = data.nodeId;
                }
                if ("supernetId" in data && data.supernetId != undefined) {
                    this.supernetId = data.supernetId;
                }
            }
        }
        get nodeId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set nodeId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get supernetId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set supernetId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: RemoveSupernetValidatorTx.AsObjectPartial): RemoveSupernetValidatorTx {
            if (!data) {
                return new RemoveSupernetValidatorTx();
            }
            const message = new RemoveSupernetValidatorTx({});
            if (data.nodeId != null) {
                message.nodeId = data.nodeId;
            }
            if (data.supernetId != null) {
                message.supernetId = data.supernetId;
            }
            return message;
        }
        toObject() {
            const data: RemoveSupernetValidatorTx.AsObject = {
                nodeId: this.nodeId,
                supernetId: this.supernetId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.nodeId.length)
                writer.writeString(1, this.nodeId);
            if (this.supernetId.length)
                writer.writeString(2, this.supernetId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): RemoveSupernetValidatorTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new RemoveSupernetValidatorTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.nodeId = reader.readString();
                        break;
                    case 2:
                        message.supernetId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): RemoveSupernetValidatorTx {
            return RemoveSupernetValidatorTx.deserialize(bytes);
        }
    }
    export namespace RemoveSupernetValidatorTx {
        export type AsObject = {
            nodeId: string;
            supernetId: string;
        };
        export type AsObjectPartial = {
            nodeId?: string;
            supernetId?: string;
        };
    }
    export class TransformSupernetTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            supernetId?: string;
            assetId?: string;
            rewardsPoolSupply?: string;
            rewardShare?: string;
            minValidatorStake?: string;
            maxValidatorStake?: string;
            minStakeDuration?: number;
            maxStakeDuration?: number;
            minDelegationFee?: number;
            maxDelegationFee?: number;
            minDelegatorStake?: string;
            maxValidatorWeightFactor?: number;
            uptimeRequirement?: number;
            initialRewardPoolSupply?: string;
            startRewardShare?: number;
            startRewardTime?: number;
            targetRewardShare?: number;
            targetRewardTime?: number;
            stakePeriodRewardShare?: number;
            diminishingRewardShare?: number;
            diminishingRewardTime?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("supernetId" in data && data.supernetId != undefined) {
                    this.supernetId = data.supernetId;
                }
                if ("assetId" in data && data.assetId != undefined) {
                    this.assetId = data.assetId;
                }
                if ("rewardsPoolSupply" in data && data.rewardsPoolSupply != undefined) {
                    this.rewardsPoolSupply = data.rewardsPoolSupply;
                }
                if ("rewardShare" in data && data.rewardShare != undefined) {
                    this.rewardShare = data.rewardShare;
                }
                if ("minValidatorStake" in data && data.minValidatorStake != undefined) {
                    this.minValidatorStake = data.minValidatorStake;
                }
                if ("maxValidatorStake" in data && data.maxValidatorStake != undefined) {
                    this.maxValidatorStake = data.maxValidatorStake;
                }
                if ("minStakeDuration" in data && data.minStakeDuration != undefined) {
                    this.minStakeDuration = data.minStakeDuration;
                }
                if ("maxStakeDuration" in data && data.maxStakeDuration != undefined) {
                    this.maxStakeDuration = data.maxStakeDuration;
                }
                if ("minDelegationFee" in data && data.minDelegationFee != undefined) {
                    this.minDelegationFee = data.minDelegationFee;
                }
                if ("maxDelegationFee" in data && data.maxDelegationFee != undefined) {
                    this.maxDelegationFee = data.maxDelegationFee;
                }
                if ("minDelegatorStake" in data && data.minDelegatorStake != undefined) {
                    this.minDelegatorStake = data.minDelegatorStake;
                }
                if ("maxValidatorWeightFactor" in data && data.maxValidatorWeightFactor != undefined) {
                    this.maxValidatorWeightFactor = data.maxValidatorWeightFactor;
                }
                if ("uptimeRequirement" in data && data.uptimeRequirement != undefined) {
                    this.uptimeRequirement = data.uptimeRequirement;
                }
                if ("initialRewardPoolSupply" in data && data.initialRewardPoolSupply != undefined) {
                    this.initialRewardPoolSupply = data.initialRewardPoolSupply;
                }
                if ("startRewardShare" in data && data.startRewardShare != undefined) {
                    this.startRewardShare = data.startRewardShare;
                }
                if ("startRewardTime" in data && data.startRewardTime != undefined) {
                    this.startRewardTime = data.startRewardTime;
                }
                if ("targetRewardShare" in data && data.targetRewardShare != undefined) {
                    this.targetRewardShare = data.targetRewardShare;
                }
                if ("targetRewardTime" in data && data.targetRewardTime != undefined) {
                    this.targetRewardTime = data.targetRewardTime;
                }
                if ("stakePeriodRewardShare" in data && data.stakePeriodRewardShare != undefined) {
                    this.stakePeriodRewardShare = data.stakePeriodRewardShare;
                }
                if ("diminishingRewardShare" in data && data.diminishingRewardShare != undefined) {
                    this.diminishingRewardShare = data.diminishingRewardShare;
                }
                if ("diminishingRewardTime" in data && data.diminishingRewardTime != undefined) {
                    this.diminishingRewardTime = data.diminishingRewardTime;
                }
            }
        }
        get supernetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set supernetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get assetId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set assetId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get rewardsPoolSupply() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set rewardsPoolSupply(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get rewardShare() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set rewardShare(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get minValidatorStake() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set minValidatorStake(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get maxValidatorStake() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set maxValidatorStake(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get minStakeDuration() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set minStakeDuration(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        get maxStakeDuration() {
            return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
        }
        set maxStakeDuration(value: number) {
            pb_1.Message.setField(this, 8, value);
        }
        get minDelegationFee() {
            return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
        }
        set minDelegationFee(value: number) {
            pb_1.Message.setField(this, 9, value);
        }
        get maxDelegationFee() {
            return pb_1.Message.getFieldWithDefault(this, 19, 0) as number;
        }
        set maxDelegationFee(value: number) {
            pb_1.Message.setField(this, 19, value);
        }
        get minDelegatorStake() {
            return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
        }
        set minDelegatorStake(value: string) {
            pb_1.Message.setField(this, 10, value);
        }
        get maxValidatorWeightFactor() {
            return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
        }
        set maxValidatorWeightFactor(value: number) {
            pb_1.Message.setField(this, 11, value);
        }
        get uptimeRequirement() {
            return pb_1.Message.getFieldWithDefault(this, 12, 0) as number;
        }
        set uptimeRequirement(value: number) {
            pb_1.Message.setField(this, 12, value);
        }
        get initialRewardPoolSupply() {
            return pb_1.Message.getFieldWithDefault(this, 13, "") as string;
        }
        set initialRewardPoolSupply(value: string) {
            pb_1.Message.setField(this, 13, value);
        }
        get startRewardShare() {
            return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
        }
        set startRewardShare(value: number) {
            pb_1.Message.setField(this, 14, value);
        }
        get startRewardTime() {
            return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
        }
        set startRewardTime(value: number) {
            pb_1.Message.setField(this, 15, value);
        }
        get targetRewardShare() {
            return pb_1.Message.getFieldWithDefault(this, 16, 0) as number;
        }
        set targetRewardShare(value: number) {
            pb_1.Message.setField(this, 16, value);
        }
        get targetRewardTime() {
            return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
        }
        set targetRewardTime(value: number) {
            pb_1.Message.setField(this, 17, value);
        }
        get stakePeriodRewardShare() {
            return pb_1.Message.getFieldWithDefault(this, 18, 0) as number;
        }
        set stakePeriodRewardShare(value: number) {
            pb_1.Message.setField(this, 18, value);
        }
        get diminishingRewardShare() {
            return pb_1.Message.getFieldWithDefault(this, 20, 0) as number;
        }
        set diminishingRewardShare(value: number) {
            pb_1.Message.setField(this, 20, value);
        }
        get diminishingRewardTime() {
            return pb_1.Message.getFieldWithDefault(this, 21, 0) as number;
        }
        set diminishingRewardTime(value: number) {
            pb_1.Message.setField(this, 21, value);
        }
        static fromObject(data?: TransformSupernetTx.AsObjectPartial): TransformSupernetTx {
            if (!data) {
                return new TransformSupernetTx();
            }
            const message = new TransformSupernetTx({});
            if (data.supernetId != null) {
                message.supernetId = data.supernetId;
            }
            if (data.assetId != null) {
                message.assetId = data.assetId;
            }
            if (data.rewardsPoolSupply != null) {
                message.rewardsPoolSupply = data.rewardsPoolSupply;
            }
            if (data.rewardShare != null) {
                message.rewardShare = data.rewardShare;
            }
            if (data.minValidatorStake != null) {
                message.minValidatorStake = data.minValidatorStake;
            }
            if (data.maxValidatorStake != null) {
                message.maxValidatorStake = data.maxValidatorStake;
            }
            if (data.minStakeDuration != null) {
                message.minStakeDuration = data.minStakeDuration;
            }
            if (data.maxStakeDuration != null) {
                message.maxStakeDuration = data.maxStakeDuration;
            }
            if (data.minDelegationFee != null) {
                message.minDelegationFee = data.minDelegationFee;
            }
            if (data.maxDelegationFee != null) {
                message.maxDelegationFee = data.maxDelegationFee;
            }
            if (data.minDelegatorStake != null) {
                message.minDelegatorStake = data.minDelegatorStake;
            }
            if (data.maxValidatorWeightFactor != null) {
                message.maxValidatorWeightFactor = data.maxValidatorWeightFactor;
            }
            if (data.uptimeRequirement != null) {
                message.uptimeRequirement = data.uptimeRequirement;
            }
            if (data.initialRewardPoolSupply != null) {
                message.initialRewardPoolSupply = data.initialRewardPoolSupply;
            }
            if (data.startRewardShare != null) {
                message.startRewardShare = data.startRewardShare;
            }
            if (data.startRewardTime != null) {
                message.startRewardTime = data.startRewardTime;
            }
            if (data.targetRewardShare != null) {
                message.targetRewardShare = data.targetRewardShare;
            }
            if (data.targetRewardTime != null) {
                message.targetRewardTime = data.targetRewardTime;
            }
            if (data.stakePeriodRewardShare != null) {
                message.stakePeriodRewardShare = data.stakePeriodRewardShare;
            }
            if (data.diminishingRewardShare != null) {
                message.diminishingRewardShare = data.diminishingRewardShare;
            }
            if (data.diminishingRewardTime != null) {
                message.diminishingRewardTime = data.diminishingRewardTime;
            }
            return message;
        }
        toObject() {
            const data: TransformSupernetTx.AsObject = {
                supernetId: this.supernetId,
                assetId: this.assetId,
                rewardsPoolSupply: this.rewardsPoolSupply,
                rewardShare: this.rewardShare,
                minValidatorStake: this.minValidatorStake,
                maxValidatorStake: this.maxValidatorStake,
                minStakeDuration: this.minStakeDuration,
                maxStakeDuration: this.maxStakeDuration,
                minDelegationFee: this.minDelegationFee,
                maxDelegationFee: this.maxDelegationFee,
                minDelegatorStake: this.minDelegatorStake,
                maxValidatorWeightFactor: this.maxValidatorWeightFactor,
                uptimeRequirement: this.uptimeRequirement,
                initialRewardPoolSupply: this.initialRewardPoolSupply,
                startRewardShare: this.startRewardShare,
                startRewardTime: this.startRewardTime,
                targetRewardShare: this.targetRewardShare,
                targetRewardTime: this.targetRewardTime,
                stakePeriodRewardShare: this.stakePeriodRewardShare,
                diminishingRewardShare: this.diminishingRewardShare,
                diminishingRewardTime: this.diminishingRewardTime
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.supernetId.length)
                writer.writeString(1, this.supernetId);
            if (this.assetId.length)
                writer.writeString(2, this.assetId);
            if (this.rewardsPoolSupply.length)
                writer.writeString(3, this.rewardsPoolSupply);
            if (this.rewardShare.length)
                writer.writeString(4, this.rewardShare);
            if (this.minValidatorStake.length)
                writer.writeString(5, this.minValidatorStake);
            if (this.maxValidatorStake.length)
                writer.writeString(6, this.maxValidatorStake);
            if (this.minStakeDuration != 0)
                writer.writeInt64(7, this.minStakeDuration);
            if (this.maxStakeDuration != 0)
                writer.writeInt64(8, this.maxStakeDuration);
            if (this.minDelegationFee != 0)
                writer.writeInt64(9, this.minDelegationFee);
            if (this.maxDelegationFee != 0)
                writer.writeInt64(19, this.maxDelegationFee);
            if (this.minDelegatorStake.length)
                writer.writeString(10, this.minDelegatorStake);
            if (this.maxValidatorWeightFactor != 0)
                writer.writeUint32(11, this.maxValidatorWeightFactor);
            if (this.uptimeRequirement != 0)
                writer.writeInt64(12, this.uptimeRequirement);
            if (this.initialRewardPoolSupply.length)
                writer.writeString(13, this.initialRewardPoolSupply);
            if (this.startRewardShare != 0)
                writer.writeInt64(14, this.startRewardShare);
            if (this.startRewardTime != 0)
                writer.writeInt64(15, this.startRewardTime);
            if (this.targetRewardShare != 0)
                writer.writeInt64(16, this.targetRewardShare);
            if (this.targetRewardTime != 0)
                writer.writeInt64(17, this.targetRewardTime);
            if (this.stakePeriodRewardShare != 0)
                writer.writeInt64(18, this.stakePeriodRewardShare);
            if (this.diminishingRewardShare != 0)
                writer.writeInt64(20, this.diminishingRewardShare);
            if (this.diminishingRewardTime != 0)
                writer.writeInt64(21, this.diminishingRewardTime);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransformSupernetTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransformSupernetTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.supernetId = reader.readString();
                        break;
                    case 2:
                        message.assetId = reader.readString();
                        break;
                    case 3:
                        message.rewardsPoolSupply = reader.readString();
                        break;
                    case 4:
                        message.rewardShare = reader.readString();
                        break;
                    case 5:
                        message.minValidatorStake = reader.readString();
                        break;
                    case 6:
                        message.maxValidatorStake = reader.readString();
                        break;
                    case 7:
                        message.minStakeDuration = reader.readInt64();
                        break;
                    case 8:
                        message.maxStakeDuration = reader.readInt64();
                        break;
                    case 9:
                        message.minDelegationFee = reader.readInt64();
                        break;
                    case 19:
                        message.maxDelegationFee = reader.readInt64();
                        break;
                    case 10:
                        message.minDelegatorStake = reader.readString();
                        break;
                    case 11:
                        message.maxValidatorWeightFactor = reader.readUint32();
                        break;
                    case 12:
                        message.uptimeRequirement = reader.readInt64();
                        break;
                    case 13:
                        message.initialRewardPoolSupply = reader.readString();
                        break;
                    case 14:
                        message.startRewardShare = reader.readInt64();
                        break;
                    case 15:
                        message.startRewardTime = reader.readInt64();
                        break;
                    case 16:
                        message.targetRewardShare = reader.readInt64();
                        break;
                    case 17:
                        message.targetRewardTime = reader.readInt64();
                        break;
                    case 18:
                        message.stakePeriodRewardShare = reader.readInt64();
                        break;
                    case 20:
                        message.diminishingRewardShare = reader.readInt64();
                        break;
                    case 21:
                        message.diminishingRewardTime = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransformSupernetTx {
            return TransformSupernetTx.deserialize(bytes);
        }
    }
    export namespace TransformSupernetTx {
        export type AsObject = {
            supernetId: string;
            assetId: string;
            rewardsPoolSupply: string;
            rewardShare: string;
            minValidatorStake: string;
            maxValidatorStake: string;
            minStakeDuration: number;
            maxStakeDuration: number;
            minDelegationFee: number;
            maxDelegationFee: number;
            minDelegatorStake: string;
            maxValidatorWeightFactor: number;
            uptimeRequirement: number;
            initialRewardPoolSupply: string;
            startRewardShare: number;
            startRewardTime: number;
            targetRewardShare: number;
            targetRewardTime: number;
            stakePeriodRewardShare: number;
            diminishingRewardShare: number;
            diminishingRewardTime: number;
        };
        export type AsObjectPartial = {
            supernetId?: string;
            assetId?: string;
            rewardsPoolSupply?: string;
            rewardShare?: string;
            minValidatorStake?: string;
            maxValidatorStake?: string;
            minStakeDuration?: number;
            maxStakeDuration?: number;
            minDelegationFee?: number;
            maxDelegationFee?: number;
            minDelegatorStake?: string;
            maxValidatorWeightFactor?: number;
            uptimeRequirement?: number;
            initialRewardPoolSupply?: string;
            startRewardShare?: number;
            startRewardTime?: number;
            targetRewardShare?: number;
            targetRewardTime?: number;
            stakePeriodRewardShare?: number;
            diminishingRewardShare?: number;
            diminishingRewardTime?: number;
        };
    }
    export class AddPermissionlessValidatorTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            validator?: TransactionPVM.Validator;
            supernetId?: string;
            stake?: TransactionPVM.Stake;
            validatorRewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput;
            delegatorRewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput;
            shares?: number;
            signer?: TransactionPVM.AddPermissionlessValidatorTx.Signer;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("validator" in data && data.validator != undefined) {
                    this.validator = data.validator;
                }
                if ("supernetId" in data && data.supernetId != undefined) {
                    this.supernetId = data.supernetId;
                }
                if ("stake" in data && data.stake != undefined) {
                    this.stake = data.stake;
                }
                if ("validatorRewardsOwner" in data && data.validatorRewardsOwner != undefined) {
                    this.validatorRewardsOwner = data.validatorRewardsOwner;
                }
                if ("delegatorRewardsOwner" in data && data.delegatorRewardsOwner != undefined) {
                    this.delegatorRewardsOwner = data.delegatorRewardsOwner;
                }
                if ("shares" in data && data.shares != undefined) {
                    this.shares = data.shares;
                }
                if ("signer" in data && data.signer != undefined) {
                    this.signer = data.signer;
                }
            }
        }
        get validator() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.Validator, 1) as TransactionPVM.Validator | undefined;
        }
        set validator(value: TransactionPVM.Validator | undefined) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasValidator() {
            return pb_1.Message.getField(this, 1) != null;
        }
        clearValidator(): void {
            this.validator = undefined!;
        }
        get supernetId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set supernetId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get stake() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.Stake, 3) as TransactionPVM.Stake | undefined;
        }
        set stake(value: TransactionPVM.Stake | undefined) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasStake() {
            return pb_1.Message.getField(this, 3) != null;
        }
        clearStake(): void {
            this.stake = undefined!;
        }
        get validatorRewardsOwner() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput, 4) as TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined;
        }
        set validatorRewardsOwner(value: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasValidatorRewardsOwner() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearValidatorRewardsOwner(): void {
            this.validatorRewardsOwner = undefined!;
        }
        get delegatorRewardsOwner() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput, 5) as TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined;
        }
        set delegatorRewardsOwner(value: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined) {
            pb_1.Message.setWrapperField(this, 5, value);
        }
        get hasDelegatorRewardsOwner() {
            return pb_1.Message.getField(this, 5) != null;
        }
        clearDelegatorRewardsOwner(): void {
            this.delegatorRewardsOwner = undefined!;
        }
        get shares() {
            return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
        }
        set shares(value: number) {
            pb_1.Message.setField(this, 6, value);
        }
        get signer() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.AddPermissionlessValidatorTx.Signer, 7) as TransactionPVM.AddPermissionlessValidatorTx.Signer | undefined;
        }
        set signer(value: TransactionPVM.AddPermissionlessValidatorTx.Signer | undefined) {
            pb_1.Message.setWrapperField(this, 7, value);
        }
        get hasSigner() {
            return pb_1.Message.getField(this, 7) != null;
        }
        clearSigner(): void {
            this.signer = undefined!;
        }
        static fromObject(data?: AddPermissionlessValidatorTx.AsObjectPartial): AddPermissionlessValidatorTx {
            if (!data) {
                return new AddPermissionlessValidatorTx();
            }
            const message = new AddPermissionlessValidatorTx({});
            if (data.validator != null) {
                message.validator = TransactionPVM.Validator.fromObject(data.validator);
            }
            if (data.supernetId != null) {
                message.supernetId = data.supernetId;
            }
            if (data.stake != null) {
                message.stake = TransactionPVM.Stake.fromObject(data.stake);
            }
            if (data.validatorRewardsOwner != null) {
                message.validatorRewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.fromObject(data.validatorRewardsOwner);
            }
            if (data.delegatorRewardsOwner != null) {
                message.delegatorRewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.fromObject(data.delegatorRewardsOwner);
            }
            if (data.shares != null) {
                message.shares = data.shares;
            }
            if (data.signer != null) {
                message.signer = TransactionPVM.AddPermissionlessValidatorTx.Signer.fromObject(data.signer);
            }
            return message;
        }
        toObject() {
            const data: AddPermissionlessValidatorTx.AsObject = {
                supernetId: this.supernetId,
                shares: this.shares
            };
            if (this.validator != null) {
                data.validator = this.validator.toObject();
            }
            if (this.stake != null) {
                data.stake = this.stake.toObject();
            }
            if (this.validatorRewardsOwner != null) {
                data.validatorRewardsOwner = this.validatorRewardsOwner.toObject();
            }
            if (this.delegatorRewardsOwner != null) {
                data.delegatorRewardsOwner = this.delegatorRewardsOwner.toObject();
            }
            if (this.signer != null) {
                data.signer = this.signer.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasValidator)
                writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
            if (this.supernetId.length)
                writer.writeString(2, this.supernetId);
            if (this.hasStake)
                writer.writeMessage(3, this.stake, () => this.stake!.serialize(writer));
            if (this.hasValidatorRewardsOwner)
                writer.writeMessage(4, this.validatorRewardsOwner, () => this.validatorRewardsOwner!.serialize(writer));
            if (this.hasDelegatorRewardsOwner)
                writer.writeMessage(5, this.delegatorRewardsOwner, () => this.delegatorRewardsOwner!.serialize(writer));
            if (this.shares != 0)
                writer.writeUint32(6, this.shares);
            if (this.hasSigner)
                writer.writeMessage(7, this.signer, () => this.signer!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddPermissionlessValidatorTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddPermissionlessValidatorTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.validator, () => message.validator = TransactionPVM.Validator.deserialize(reader));
                        break;
                    case 2:
                        message.supernetId = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.stake, () => message.stake = TransactionPVM.Stake.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.validatorRewardsOwner, () => message.validatorRewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.delegatorRewardsOwner, () => message.delegatorRewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.deserialize(reader));
                        break;
                    case 6:
                        message.shares = reader.readUint32();
                        break;
                    case 7:
                        reader.readMessage(message.signer, () => message.signer = TransactionPVM.AddPermissionlessValidatorTx.Signer.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddPermissionlessValidatorTx {
            return AddPermissionlessValidatorTx.deserialize(bytes);
        }
    }
    export namespace AddPermissionlessValidatorTx {
        export type AsObject = {
            validator?: TransactionPVM.Validator.AsObject;
            supernetId: string;
            stake?: TransactionPVM.Stake.AsObject;
            validatorRewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObject;
            delegatorRewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObject;
            shares: number;
            signer?: TransactionPVM.AddPermissionlessValidatorTx.Signer.AsObject;
        };
        export type AsObjectPartial = {
            validator?: TransactionPVM.Validator.AsObjectPartial;
            supernetId?: string;
            stake?: TransactionPVM.Stake.AsObjectPartial;
            validatorRewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObjectPartial;
            delegatorRewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObjectPartial;
            shares?: number;
            signer?: TransactionPVM.AddPermissionlessValidatorTx.Signer.AsObjectPartial;
        };
        export class Signer extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                publicKey?: string;
                proofOfPossession?: string;
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("publicKey" in data && data.publicKey != undefined) {
                        this.publicKey = data.publicKey;
                    }
                    if ("proofOfPossession" in data && data.proofOfPossession != undefined) {
                        this.proofOfPossession = data.proofOfPossession;
                    }
                }
            }
            get publicKey() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set publicKey(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get proofOfPossession() {
                return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
            }
            set proofOfPossession(value: string) {
                pb_1.Message.setField(this, 2, value);
            }
            static fromObject(data?: Signer.AsObjectPartial): Signer {
                if (!data) {
                    return new Signer();
                }
                const message = new Signer({});
                if (data.publicKey != null) {
                    message.publicKey = data.publicKey;
                }
                if (data.proofOfPossession != null) {
                    message.proofOfPossession = data.proofOfPossession;
                }
                return message;
            }
            toObject() {
                const data: Signer.AsObject = {
                    publicKey: this.publicKey,
                    proofOfPossession: this.proofOfPossession
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.publicKey.length)
                    writer.writeString(1, this.publicKey);
                if (this.proofOfPossession.length)
                    writer.writeString(2, this.proofOfPossession);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Signer {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Signer();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.publicKey = reader.readString();
                            break;
                        case 2:
                            message.proofOfPossession = reader.readString();
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): Signer {
                return Signer.deserialize(bytes);
            }
        }
        export namespace Signer {
            export type AsObject = {
                publicKey: string;
                proofOfPossession: string;
            };
            export type AsObjectPartial = {
                publicKey?: string;
                proofOfPossession?: string;
            };
        }
    }
    export class AddPermissionlessDelegatorTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            validator?: TransactionPVM.Validator;
            supernetId?: string;
            stake?: TransactionPVM.Stake;
            delegatorRewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("validator" in data && data.validator != undefined) {
                    this.validator = data.validator;
                }
                if ("supernetId" in data && data.supernetId != undefined) {
                    this.supernetId = data.supernetId;
                }
                if ("stake" in data && data.stake != undefined) {
                    this.stake = data.stake;
                }
                if ("delegatorRewardsOwner" in data && data.delegatorRewardsOwner != undefined) {
                    this.delegatorRewardsOwner = data.delegatorRewardsOwner;
                }
            }
        }
        get validator() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.Validator, 1) as TransactionPVM.Validator | undefined;
        }
        set validator(value: TransactionPVM.Validator | undefined) {
            pb_1.Message.setWrapperField(this, 1, value);
        }
        get hasValidator() {
            return pb_1.Message.getField(this, 1) != null;
        }
        clearValidator(): void {
            this.validator = undefined!;
        }
        get supernetId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set supernetId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get stake() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.Stake, 3) as TransactionPVM.Stake | undefined;
        }
        set stake(value: TransactionPVM.Stake | undefined) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasStake() {
            return pb_1.Message.getField(this, 3) != null;
        }
        clearStake(): void {
            this.stake = undefined!;
        }
        get delegatorRewardsOwner() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput, 4) as TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined;
        }
        set delegatorRewardsOwner(value: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasDelegatorRewardsOwner() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearDelegatorRewardsOwner(): void {
            this.delegatorRewardsOwner = undefined!;
        }
        static fromObject(data?: AddPermissionlessDelegatorTx.AsObjectPartial): AddPermissionlessDelegatorTx {
            if (!data) {
                return new AddPermissionlessDelegatorTx();
            }
            const message = new AddPermissionlessDelegatorTx({});
            if (data.validator != null) {
                message.validator = TransactionPVM.Validator.fromObject(data.validator);
            }
            if (data.supernetId != null) {
                message.supernetId = data.supernetId;
            }
            if (data.stake != null) {
                message.stake = TransactionPVM.Stake.fromObject(data.stake);
            }
            if (data.delegatorRewardsOwner != null) {
                message.delegatorRewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.fromObject(data.delegatorRewardsOwner);
            }
            return message;
        }
        toObject() {
            const data: AddPermissionlessDelegatorTx.AsObject = {
                supernetId: this.supernetId
            };
            if (this.validator != null) {
                data.validator = this.validator.toObject();
            }
            if (this.stake != null) {
                data.stake = this.stake.toObject();
            }
            if (this.delegatorRewardsOwner != null) {
                data.delegatorRewardsOwner = this.delegatorRewardsOwner.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasValidator)
                writer.writeMessage(1, this.validator, () => this.validator!.serialize(writer));
            if (this.supernetId.length)
                writer.writeString(2, this.supernetId);
            if (this.hasStake)
                writer.writeMessage(3, this.stake, () => this.stake!.serialize(writer));
            if (this.hasDelegatorRewardsOwner)
                writer.writeMessage(4, this.delegatorRewardsOwner, () => this.delegatorRewardsOwner!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AddPermissionlessDelegatorTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AddPermissionlessDelegatorTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.validator, () => message.validator = TransactionPVM.Validator.deserialize(reader));
                        break;
                    case 2:
                        message.supernetId = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.stake, () => message.stake = TransactionPVM.Stake.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.delegatorRewardsOwner, () => message.delegatorRewardsOwner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AddPermissionlessDelegatorTx {
            return AddPermissionlessDelegatorTx.deserialize(bytes);
        }
    }
    export namespace AddPermissionlessDelegatorTx {
        export type AsObject = {
            validator?: TransactionPVM.Validator.AsObject;
            supernetId: string;
            stake?: TransactionPVM.Stake.AsObject;
            delegatorRewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObject;
        };
        export type AsObjectPartial = {
            validator?: TransactionPVM.Validator.AsObjectPartial;
            supernetId?: string;
            stake?: TransactionPVM.Stake.AsObjectPartial;
            delegatorRewardsOwner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObjectPartial;
        };
    }
    export class TransferSupernetOwnershipTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            supernetId?: string;
            owner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("supernetId" in data && data.supernetId != undefined) {
                    this.supernetId = data.supernetId;
                }
                if ("owner" in data && data.owner != undefined) {
                    this.owner = data.owner;
                }
            }
        }
        get supernetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set supernetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get owner() {
            return pb_1.Message.getWrapperField(this, TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput, 4) as TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined;
        }
        set owner(value: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput | undefined) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasOwner() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearOwner(): void {
            this.owner = undefined!;
        }
        static fromObject(data?: TransferSupernetOwnershipTx.AsObjectPartial): TransferSupernetOwnershipTx {
            if (!data) {
                return new TransferSupernetOwnershipTx();
            }
            const message = new TransferSupernetOwnershipTx({});
            if (data.supernetId != null) {
                message.supernetId = data.supernetId;
            }
            if (data.owner != null) {
                message.owner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.fromObject(data.owner);
            }
            return message;
        }
        toObject() {
            const data: TransferSupernetOwnershipTx.AsObject = {
                supernetId: this.supernetId
            };
            if (this.owner != null) {
                data.owner = this.owner.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.supernetId.length)
                writer.writeString(1, this.supernetId);
            if (this.hasOwner)
                writer.writeMessage(4, this.owner, () => this.owner!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferSupernetOwnershipTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferSupernetOwnershipTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.supernetId = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.owner, () => message.owner = TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransferSupernetOwnershipTx {
            return TransferSupernetOwnershipTx.deserialize(bytes);
        }
    }
    export namespace TransferSupernetOwnershipTx {
        export type AsObject = {
            supernetId: string;
            owner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObject;
        };
        export type AsObjectPartial = {
            supernetId?: string;
            owner?: TransactionPVM.TransferableOutput.SECP256K1OutputOwnersOutput.AsObjectPartial;
        };
    }
}
export class TransactionShortPVM extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3]];
    constructor(data?: any[] | ({
        kind?: dependency_2.PVMTransactionKind;
    } & (({
        export?: TransactionShortPVM.ExportTx;
        import?: never;
    } | {
        export?: never;
        import?: TransactionShortPVM.ImportTx;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("kind" in data && data.kind != undefined) {
                this.kind = data.kind;
            }
            if ("export" in data && data.export != undefined) {
                this.export = data.export;
            }
            if ("import" in data && data.import != undefined) {
                this.import = data.import;
            }
        }
    }
    get kind() {
        return pb_1.Message.getFieldWithDefault(this, 1, dependency_2.PVMTransactionKind.PVMTransactionKindBase) as dependency_2.PVMTransactionKind;
    }
    set kind(value: dependency_2.PVMTransactionKind) {
        pb_1.Message.setField(this, 1, value);
    }
    get export() {
        return pb_1.Message.getWrapperField(this, TransactionShortPVM.ExportTx, 2) as TransactionShortPVM.ExportTx | undefined;
    }
    set export(value: TransactionShortPVM.ExportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasExport() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearExport(): void {
        this.export = undefined!;
    }
    get import() {
        return pb_1.Message.getWrapperField(this, TransactionShortPVM.ImportTx, 3) as TransactionShortPVM.ImportTx | undefined;
    }
    set import(value: TransactionShortPVM.ImportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasImport() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearImport(): void {
        this.import = undefined!;
    }
    get details() {
        const cases: {
            [index: number]: "none" | "export" | "import";
        } = {
            0: "none",
            2: "export",
            3: "import"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3])]!;
    }
    static fromObject(data?: TransactionShortPVM.AsObjectPartial): TransactionShortPVM {
        if (!data) {
            return new TransactionShortPVM();
        }
        const message = new TransactionShortPVM({});
        if (data.kind != null) {
            message.kind = data.kind;
        }
        if (data.export != null) {
            message.export = TransactionShortPVM.ExportTx.fromObject(data.export);
        }
        if (data.import != null) {
            message.import = TransactionShortPVM.ImportTx.fromObject(data.import);
        }
        return message;
    }
    toObject() {
        const data: TransactionShortPVM.AsObject = {
            kind: this.kind
        };
        if (this.export != null) {
            data.export = this.export.toObject();
        }
        if (this.import != null) {
            data.import = this.import.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.kind != dependency_2.PVMTransactionKind.PVMTransactionKindBase)
            writer.writeEnum(1, this.kind);
        if (this.hasExport)
            writer.writeMessage(2, this.export, () => this.export!.serialize(writer));
        if (this.hasImport)
            writer.writeMessage(3, this.import, () => this.import!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionShortPVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionShortPVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.kind = reader.readEnum();
                    break;
                case 2:
                    reader.readMessage(message.export, () => message.export = TransactionShortPVM.ExportTx.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.import, () => message.import = TransactionShortPVM.ImportTx.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionShortPVM {
        return TransactionShortPVM.deserialize(bytes);
    }
}
export namespace TransactionShortPVM {
    export type AsObject = {
        kind: dependency_2.PVMTransactionKind;
        export?: TransactionShortPVM.ExportTx.AsObject;
        import?: TransactionShortPVM.ImportTx.AsObject;
    };
    export type AsObjectPartial = {
        kind?: dependency_2.PVMTransactionKind;
        export?: TransactionShortPVM.ExportTx.AsObjectPartial;
        import?: TransactionShortPVM.ImportTx.AsObjectPartial;
    };
    export class ExportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            destinationBlockchainId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("destinationBlockchainId" in data && data.destinationBlockchainId != undefined) {
                    this.destinationBlockchainId = data.destinationBlockchainId;
                }
            }
        }
        get destinationBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set destinationBlockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: ExportTx.AsObjectPartial): ExportTx {
            if (!data) {
                return new ExportTx();
            }
            const message = new ExportTx({});
            if (data.destinationBlockchainId != null) {
                message.destinationBlockchainId = data.destinationBlockchainId;
            }
            return message;
        }
        toObject() {
            const data: ExportTx.AsObject = {
                destinationBlockchainId: this.destinationBlockchainId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.destinationBlockchainId.length)
                writer.writeString(1, this.destinationBlockchainId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ExportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ExportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.destinationBlockchainId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ExportTx {
            return ExportTx.deserialize(bytes);
        }
    }
    export namespace ExportTx {
        export type AsObject = {
            destinationBlockchainId: string;
        };
        export type AsObjectPartial = {
            destinationBlockchainId?: string;
        };
    }
    export class ImportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            sourceBlockchainId?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("sourceBlockchainId" in data && data.sourceBlockchainId != undefined) {
                    this.sourceBlockchainId = data.sourceBlockchainId;
                }
            }
        }
        get sourceBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set sourceBlockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: ImportTx.AsObjectPartial): ImportTx {
            if (!data) {
                return new ImportTx();
            }
            const message = new ImportTx({});
            if (data.sourceBlockchainId != null) {
                message.sourceBlockchainId = data.sourceBlockchainId;
            }
            return message;
        }
        toObject() {
            const data: ImportTx.AsObject = {
                sourceBlockchainId: this.sourceBlockchainId
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.sourceBlockchainId.length)
                writer.writeString(1, this.sourceBlockchainId);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ImportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ImportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.sourceBlockchainId = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ImportTx {
            return ImportTx.deserialize(bytes);
        }
    }
    export namespace ImportTx {
        export type AsObject = {
            sourceBlockchainId: string;
        };
        export type AsObjectPartial = {
            sourceBlockchainId?: string;
        };
    }
}
export class TransactionEVM extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        from?: string;
        gas?: number;
        gasPrice?: string;
        nonce?: number;
        to?: string;
        value?: string;
        status?: TransactionEVM.TransactionStatus;
        call?: TransactionEVM.Call;
        nativeTransfers?: TransactionEVM.NativeTransfer[];
        erc20Transfers?: TransactionEVM.ERC20Transfer[];
        erc721Transfers?: TransactionEVM.ERC721Transfer[];
        erc1155Transfers?: TransactionEVM.ERC1155Transfer[];
        logCount?: number;
        internalTxCount?: number;
        actions?: TransactionEVM.Action[];
        gasUsed?: number;
        effectiveGasPrice?: string;
        contractAddress?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [10, 11, 12, 13, 16], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("from" in data && data.from != undefined) {
                this.from = data.from;
            }
            if ("gas" in data && data.gas != undefined) {
                this.gas = data.gas;
            }
            if ("gasPrice" in data && data.gasPrice != undefined) {
                this.gasPrice = data.gasPrice;
            }
            if ("nonce" in data && data.nonce != undefined) {
                this.nonce = data.nonce;
            }
            if ("to" in data && data.to != undefined) {
                this.to = data.to;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
            if ("call" in data && data.call != undefined) {
                this.call = data.call;
            }
            if ("nativeTransfers" in data && data.nativeTransfers != undefined) {
                this.nativeTransfers = data.nativeTransfers;
            }
            if ("erc20Transfers" in data && data.erc20Transfers != undefined) {
                this.erc20Transfers = data.erc20Transfers;
            }
            if ("erc721Transfers" in data && data.erc721Transfers != undefined) {
                this.erc721Transfers = data.erc721Transfers;
            }
            if ("erc1155Transfers" in data && data.erc1155Transfers != undefined) {
                this.erc1155Transfers = data.erc1155Transfers;
            }
            if ("logCount" in data && data.logCount != undefined) {
                this.logCount = data.logCount;
            }
            if ("internalTxCount" in data && data.internalTxCount != undefined) {
                this.internalTxCount = data.internalTxCount;
            }
            if ("actions" in data && data.actions != undefined) {
                this.actions = data.actions;
            }
            if ("gasUsed" in data && data.gasUsed != undefined) {
                this.gasUsed = data.gasUsed;
            }
            if ("effectiveGasPrice" in data && data.effectiveGasPrice != undefined) {
                this.effectiveGasPrice = data.effectiveGasPrice;
            }
            if ("contractAddress" in data && data.contractAddress != undefined) {
                this.contractAddress = data.contractAddress;
            }
        }
    }
    get from() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set from(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get gas() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set gas(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get gasPrice() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set gasPrice(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get nonce() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set nonce(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get to() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set to(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 7, value);
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 8, TransactionEVM.TransactionStatus.TransactionStatePending) as TransactionEVM.TransactionStatus;
    }
    set status(value: TransactionEVM.TransactionStatus) {
        pb_1.Message.setField(this, 8, value);
    }
    get call() {
        return pb_1.Message.getWrapperField(this, TransactionEVM.Call, 9) as TransactionEVM.Call | undefined;
    }
    set call(value: TransactionEVM.Call | undefined) {
        pb_1.Message.setWrapperField(this, 9, value);
    }
    get hasCall() {
        return pb_1.Message.getField(this, 9) != null;
    }
    clearCall(): void {
        this.call = undefined!;
    }
    get nativeTransfers() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionEVM.NativeTransfer, 10) as TransactionEVM.NativeTransfer[];
    }
    set nativeTransfers(value: TransactionEVM.NativeTransfer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 10, value);
    }
    get erc20Transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionEVM.ERC20Transfer, 11) as TransactionEVM.ERC20Transfer[];
    }
    set erc20Transfers(value: TransactionEVM.ERC20Transfer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 11, value);
    }
    get erc721Transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionEVM.ERC721Transfer, 12) as TransactionEVM.ERC721Transfer[];
    }
    set erc721Transfers(value: TransactionEVM.ERC721Transfer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 12, value);
    }
    get erc1155Transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionEVM.ERC1155Transfer, 13) as TransactionEVM.ERC1155Transfer[];
    }
    set erc1155Transfers(value: TransactionEVM.ERC1155Transfer[]) {
        pb_1.Message.setRepeatedWrapperField(this, 13, value);
    }
    get logCount() {
        return pb_1.Message.getFieldWithDefault(this, 14, 0) as number;
    }
    set logCount(value: number) {
        pb_1.Message.setField(this, 14, value);
    }
    get internalTxCount() {
        return pb_1.Message.getFieldWithDefault(this, 15, 0) as number;
    }
    set internalTxCount(value: number) {
        pb_1.Message.setField(this, 15, value);
    }
    get actions() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionEVM.Action, 16) as TransactionEVM.Action[];
    }
    set actions(value: TransactionEVM.Action[]) {
        pb_1.Message.setRepeatedWrapperField(this, 16, value);
    }
    get gasUsed() {
        return pb_1.Message.getFieldWithDefault(this, 17, 0) as number;
    }
    set gasUsed(value: number) {
        pb_1.Message.setField(this, 17, value);
    }
    get effectiveGasPrice() {
        return pb_1.Message.getFieldWithDefault(this, 18, "") as string;
    }
    set effectiveGasPrice(value: string) {
        pb_1.Message.setField(this, 18, value);
    }
    get contractAddress() {
        return pb_1.Message.getFieldWithDefault(this, 19, "") as string;
    }
    set contractAddress(value: string) {
        pb_1.Message.setField(this, 19, value);
    }
    static fromObject(data?: TransactionEVM.AsObjectPartial): TransactionEVM {
        if (!data) {
            return new TransactionEVM();
        }
        const message = new TransactionEVM({});
        if (data.from != null) {
            message.from = data.from;
        }
        if (data.gas != null) {
            message.gas = data.gas;
        }
        if (data.gasPrice != null) {
            message.gasPrice = data.gasPrice;
        }
        if (data.nonce != null) {
            message.nonce = data.nonce;
        }
        if (data.to != null) {
            message.to = data.to;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        if (data.status != null) {
            message.status = data.status;
        }
        if (data.call != null) {
            message.call = TransactionEVM.Call.fromObject(data.call);
        }
        if (data.nativeTransfers != null) {
            message.nativeTransfers = data.nativeTransfers.map(item => TransactionEVM.NativeTransfer.fromObject(item));
        }
        if (data.erc20Transfers != null) {
            message.erc20Transfers = data.erc20Transfers.map(item => TransactionEVM.ERC20Transfer.fromObject(item));
        }
        if (data.erc721Transfers != null) {
            message.erc721Transfers = data.erc721Transfers.map(item => TransactionEVM.ERC721Transfer.fromObject(item));
        }
        if (data.erc1155Transfers != null) {
            message.erc1155Transfers = data.erc1155Transfers.map(item => TransactionEVM.ERC1155Transfer.fromObject(item));
        }
        if (data.logCount != null) {
            message.logCount = data.logCount;
        }
        if (data.internalTxCount != null) {
            message.internalTxCount = data.internalTxCount;
        }
        if (data.actions != null) {
            message.actions = data.actions.map(item => TransactionEVM.Action.fromObject(item));
        }
        if (data.gasUsed != null) {
            message.gasUsed = data.gasUsed;
        }
        if (data.effectiveGasPrice != null) {
            message.effectiveGasPrice = data.effectiveGasPrice;
        }
        if (data.contractAddress != null) {
            message.contractAddress = data.contractAddress;
        }
        return message;
    }
    toObject() {
        const data: TransactionEVM.AsObject = {
            from: this.from,
            gas: this.gas,
            gasPrice: this.gasPrice,
            nonce: this.nonce,
            to: this.to,
            value: this.value,
            status: this.status,
            nativeTransfers: this.nativeTransfers.map((item: TransactionEVM.NativeTransfer) => item.toObject()),
            erc20Transfers: this.erc20Transfers.map((item: TransactionEVM.ERC20Transfer) => item.toObject()),
            erc721Transfers: this.erc721Transfers.map((item: TransactionEVM.ERC721Transfer) => item.toObject()),
            erc1155Transfers: this.erc1155Transfers.map((item: TransactionEVM.ERC1155Transfer) => item.toObject()),
            logCount: this.logCount,
            internalTxCount: this.internalTxCount,
            actions: this.actions.map((item: TransactionEVM.Action) => item.toObject()),
            gasUsed: this.gasUsed,
            effectiveGasPrice: this.effectiveGasPrice,
            contractAddress: this.contractAddress
        };
        if (this.call != null) {
            data.call = this.call.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.from.length)
            writer.writeString(1, this.from);
        if (this.gas != 0)
            writer.writeUint64(2, this.gas);
        if (this.gasPrice.length)
            writer.writeString(3, this.gasPrice);
        if (this.nonce != 0)
            writer.writeUint64(5, this.nonce);
        if (this.to.length)
            writer.writeString(6, this.to);
        if (this.value.length)
            writer.writeString(7, this.value);
        if (this.status != TransactionEVM.TransactionStatus.TransactionStatePending)
            writer.writeEnum(8, this.status);
        if (this.hasCall)
            writer.writeMessage(9, this.call, () => this.call!.serialize(writer));
        if (this.nativeTransfers.length)
            writer.writeRepeatedMessage(10, this.nativeTransfers, (item: TransactionEVM.NativeTransfer) => item!.serialize(writer));
        if (this.erc20Transfers.length)
            writer.writeRepeatedMessage(11, this.erc20Transfers, (item: TransactionEVM.ERC20Transfer) => item!.serialize(writer));
        if (this.erc721Transfers.length)
            writer.writeRepeatedMessage(12, this.erc721Transfers, (item: TransactionEVM.ERC721Transfer) => item!.serialize(writer));
        if (this.erc1155Transfers.length)
            writer.writeRepeatedMessage(13, this.erc1155Transfers, (item: TransactionEVM.ERC1155Transfer) => item!.serialize(writer));
        if (this.logCount != 0)
            writer.writeInt64(14, this.logCount);
        if (this.internalTxCount != 0)
            writer.writeInt64(15, this.internalTxCount);
        if (this.actions.length)
            writer.writeRepeatedMessage(16, this.actions, (item: TransactionEVM.Action) => item!.serialize(writer));
        if (this.gasUsed != 0)
            writer.writeUint64(17, this.gasUsed);
        if (this.effectiveGasPrice.length)
            writer.writeString(18, this.effectiveGasPrice);
        if (this.contractAddress.length)
            writer.writeString(19, this.contractAddress);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionEVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionEVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.from = reader.readString();
                    break;
                case 2:
                    message.gas = reader.readUint64();
                    break;
                case 3:
                    message.gasPrice = reader.readString();
                    break;
                case 5:
                    message.nonce = reader.readUint64();
                    break;
                case 6:
                    message.to = reader.readString();
                    break;
                case 7:
                    message.value = reader.readString();
                    break;
                case 8:
                    message.status = reader.readEnum();
                    break;
                case 9:
                    reader.readMessage(message.call, () => message.call = TransactionEVM.Call.deserialize(reader));
                    break;
                case 10:
                    reader.readMessage(message.nativeTransfers, () => pb_1.Message.addToRepeatedWrapperField(message, 10, TransactionEVM.NativeTransfer.deserialize(reader), TransactionEVM.NativeTransfer));
                    break;
                case 11:
                    reader.readMessage(message.erc20Transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 11, TransactionEVM.ERC20Transfer.deserialize(reader), TransactionEVM.ERC20Transfer));
                    break;
                case 12:
                    reader.readMessage(message.erc721Transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 12, TransactionEVM.ERC721Transfer.deserialize(reader), TransactionEVM.ERC721Transfer));
                    break;
                case 13:
                    reader.readMessage(message.erc1155Transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 13, TransactionEVM.ERC1155Transfer.deserialize(reader), TransactionEVM.ERC1155Transfer));
                    break;
                case 14:
                    message.logCount = reader.readInt64();
                    break;
                case 15:
                    message.internalTxCount = reader.readInt64();
                    break;
                case 16:
                    reader.readMessage(message.actions, () => pb_1.Message.addToRepeatedWrapperField(message, 16, TransactionEVM.Action.deserialize(reader), TransactionEVM.Action));
                    break;
                case 17:
                    message.gasUsed = reader.readUint64();
                    break;
                case 18:
                    message.effectiveGasPrice = reader.readString();
                    break;
                case 19:
                    message.contractAddress = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionEVM {
        return TransactionEVM.deserialize(bytes);
    }
}
export namespace TransactionEVM {
    export type AsObject = {
        from: string;
        gas: number;
        gasPrice: string;
        nonce: number;
        to: string;
        value: string;
        status: TransactionEVM.TransactionStatus;
        call?: TransactionEVM.Call.AsObject;
        nativeTransfers: TransactionEVM.NativeTransfer.AsObject[];
        erc20Transfers: TransactionEVM.ERC20Transfer.AsObject[];
        erc721Transfers: TransactionEVM.ERC721Transfer.AsObject[];
        erc1155Transfers: TransactionEVM.ERC1155Transfer.AsObject[];
        logCount: number;
        internalTxCount: number;
        actions: TransactionEVM.Action.AsObject[];
        gasUsed: number;
        effectiveGasPrice: string;
        contractAddress: string;
    };
    export type AsObjectPartial = {
        from?: string;
        gas?: number;
        gasPrice?: string;
        nonce?: number;
        to?: string;
        value?: string;
        status?: TransactionEVM.TransactionStatus;
        call?: TransactionEVM.Call.AsObjectPartial;
        nativeTransfers?: TransactionEVM.NativeTransfer.AsObjectPartial[];
        erc20Transfers?: TransactionEVM.ERC20Transfer.AsObjectPartial[];
        erc721Transfers?: TransactionEVM.ERC721Transfer.AsObjectPartial[];
        erc1155Transfers?: TransactionEVM.ERC1155Transfer.AsObjectPartial[];
        logCount?: number;
        internalTxCount?: number;
        actions?: TransactionEVM.Action.AsObjectPartial[];
        gasUsed?: number;
        effectiveGasPrice?: string;
        contractAddress?: string;
    };
    export enum TransactionStatus {
        TransactionStatePending = 0,
        TransactionStateConfirmed = 1,
        TransactionStateFailed = 2
    }
    export class Call extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            methodId?: string;
            methodName?: string;
            methodArgs?: TransactionEVM.Call.Arg[];
            decodedArgs?: string[];
            input?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3, 4], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("methodId" in data && data.methodId != undefined) {
                    this.methodId = data.methodId;
                }
                if ("methodName" in data && data.methodName != undefined) {
                    this.methodName = data.methodName;
                }
                if ("methodArgs" in data && data.methodArgs != undefined) {
                    this.methodArgs = data.methodArgs;
                }
                if ("decodedArgs" in data && data.decodedArgs != undefined) {
                    this.decodedArgs = data.decodedArgs;
                }
                if ("input" in data && data.input != undefined) {
                    this.input = data.input;
                }
            }
        }
        get methodId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set methodId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get methodName() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set methodName(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get methodArgs() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionEVM.Call.Arg, 3) as TransactionEVM.Call.Arg[];
        }
        set methodArgs(value: TransactionEVM.Call.Arg[]) {
            pb_1.Message.setRepeatedWrapperField(this, 3, value);
        }
        get decodedArgs() {
            return pb_1.Message.getFieldWithDefault(this, 4, []) as string[];
        }
        set decodedArgs(value: string[]) {
            pb_1.Message.setField(this, 4, value);
        }
        get input() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set input(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        static fromObject(data?: Call.AsObjectPartial): Call {
            if (!data) {
                return new Call();
            }
            const message = new Call({});
            if (data.methodId != null) {
                message.methodId = data.methodId;
            }
            if (data.methodName != null) {
                message.methodName = data.methodName;
            }
            if (data.methodArgs != null) {
                message.methodArgs = data.methodArgs.map(item => TransactionEVM.Call.Arg.fromObject(item));
            }
            if (data.decodedArgs != null) {
                message.decodedArgs = data.decodedArgs;
            }
            if (data.input != null) {
                message.input = data.input;
            }
            return message;
        }
        toObject() {
            const data: Call.AsObject = {
                methodId: this.methodId,
                methodName: this.methodName,
                methodArgs: this.methodArgs.map((item: TransactionEVM.Call.Arg) => item.toObject()),
                decodedArgs: this.decodedArgs,
                input: this.input
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.methodId.length)
                writer.writeString(1, this.methodId);
            if (this.methodName.length)
                writer.writeString(2, this.methodName);
            if (this.methodArgs.length)
                writer.writeRepeatedMessage(3, this.methodArgs, (item: TransactionEVM.Call.Arg) => item!.serialize(writer));
            if (this.decodedArgs.length)
                writer.writeRepeatedString(4, this.decodedArgs);
            if (this.input.length)
                writer.writeString(5, this.input);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Call {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Call();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.methodId = reader.readString();
                        break;
                    case 2:
                        message.methodName = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.methodArgs, () => pb_1.Message.addToRepeatedWrapperField(message, 3, TransactionEVM.Call.Arg.deserialize(reader), TransactionEVM.Call.Arg));
                        break;
                    case 4:
                        pb_1.Message.addToRepeatedField(message, 4, reader.readString());
                        break;
                    case 5:
                        message.input = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Call {
            return Call.deserialize(bytes);
        }
    }
    export namespace Call {
        export type AsObject = {
            methodId: string;
            methodName: string;
            methodArgs: TransactionEVM.Call.Arg.AsObject[];
            decodedArgs: string[];
            input: string;
        };
        export type AsObjectPartial = {
            methodId?: string;
            methodName?: string;
            methodArgs?: TransactionEVM.Call.Arg.AsObjectPartial[];
            decodedArgs?: string[];
            input?: string;
        };
        export class Arg extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                name?: string;
                type?: string;
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("name" in data && data.name != undefined) {
                        this.name = data.name;
                    }
                    if ("type" in data && data.type != undefined) {
                        this.type = data.type;
                    }
                }
            }
            get name() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set name(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get type() {
                return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
            }
            set type(value: string) {
                pb_1.Message.setField(this, 2, value);
            }
            static fromObject(data?: Arg.AsObjectPartial): Arg {
                if (!data) {
                    return new Arg();
                }
                const message = new Arg({});
                if (data.name != null) {
                    message.name = data.name;
                }
                if (data.type != null) {
                    message.type = data.type;
                }
                return message;
            }
            toObject() {
                const data: Arg.AsObject = {
                    name: this.name,
                    type: this.type
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.name.length)
                    writer.writeString(1, this.name);
                if (this.type.length)
                    writer.writeString(2, this.type);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Arg {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Arg();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.name = reader.readString();
                            break;
                        case 2:
                            message.type = reader.readString();
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): Arg {
                return Arg.deserialize(bytes);
            }
        }
        export namespace Arg {
            export type AsObject = {
                name: string;
                type: string;
            };
            export type AsObjectPartial = {
                name?: string;
                type?: string;
            };
        }
    }
    export class ERC20Transfer extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            from?: string;
            to?: string;
            value?: string;
            name?: string;
            symbol?: string;
            decimals?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("from" in data && data.from != undefined) {
                    this.from = data.from;
                }
                if ("to" in data && data.to != undefined) {
                    this.to = data.to;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("symbol" in data && data.symbol != undefined) {
                    this.symbol = data.symbol;
                }
                if ("decimals" in data && data.decimals != undefined) {
                    this.decimals = data.decimals;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get from() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set from(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get to() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set to(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set value(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get symbol() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set symbol(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get decimals() {
            return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
        }
        set decimals(value: number) {
            pb_1.Message.setField(this, 7, value);
        }
        static fromObject(data?: ERC20Transfer.AsObjectPartial): ERC20Transfer {
            if (!data) {
                return new ERC20Transfer();
            }
            const message = new ERC20Transfer({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.from != null) {
                message.from = data.from;
            }
            if (data.to != null) {
                message.to = data.to;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.symbol != null) {
                message.symbol = data.symbol;
            }
            if (data.decimals != null) {
                message.decimals = data.decimals;
            }
            return message;
        }
        toObject() {
            const data: ERC20Transfer.AsObject = {
                address: this.address,
                from: this.from,
                to: this.to,
                value: this.value,
                name: this.name,
                symbol: this.symbol,
                decimals: this.decimals
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.from.length)
                writer.writeString(2, this.from);
            if (this.to.length)
                writer.writeString(3, this.to);
            if (this.value.length)
                writer.writeString(4, this.value);
            if (this.name.length)
                writer.writeString(5, this.name);
            if (this.symbol.length)
                writer.writeString(6, this.symbol);
            if (this.decimals != 0)
                writer.writeUint32(7, this.decimals);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC20Transfer {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC20Transfer();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.from = reader.readString();
                        break;
                    case 3:
                        message.to = reader.readString();
                        break;
                    case 4:
                        message.value = reader.readString();
                        break;
                    case 5:
                        message.name = reader.readString();
                        break;
                    case 6:
                        message.symbol = reader.readString();
                        break;
                    case 7:
                        message.decimals = reader.readUint32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ERC20Transfer {
            return ERC20Transfer.deserialize(bytes);
        }
    }
    export namespace ERC20Transfer {
        export type AsObject = {
            address: string;
            from: string;
            to: string;
            value: string;
            name: string;
            symbol: string;
            decimals: number;
        };
        export type AsObjectPartial = {
            address?: string;
            from?: string;
            to?: string;
            value?: string;
            name?: string;
            symbol?: string;
            decimals?: number;
        };
    }
    export class NativeTransfer extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            from?: string;
            to?: string;
            value?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("from" in data && data.from != undefined) {
                    this.from = data.from;
                }
                if ("to" in data && data.to != undefined) {
                    this.to = data.to;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
            }
        }
        get from() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set from(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get to() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set to(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set value(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: NativeTransfer.AsObjectPartial): NativeTransfer {
            if (!data) {
                return new NativeTransfer();
            }
            const message = new NativeTransfer({});
            if (data.from != null) {
                message.from = data.from;
            }
            if (data.to != null) {
                message.to = data.to;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            return message;
        }
        toObject() {
            const data: NativeTransfer.AsObject = {
                from: this.from,
                to: this.to,
                value: this.value
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.from.length)
                writer.writeString(1, this.from);
            if (this.to.length)
                writer.writeString(2, this.to);
            if (this.value.length)
                writer.writeString(3, this.value);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NativeTransfer {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NativeTransfer();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.from = reader.readString();
                        break;
                    case 2:
                        message.to = reader.readString();
                        break;
                    case 3:
                        message.value = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): NativeTransfer {
            return NativeTransfer.deserialize(bytes);
        }
    }
    export namespace NativeTransfer {
        export type AsObject = {
            from: string;
            to: string;
            value: string;
        };
        export type AsObjectPartial = {
            from?: string;
            to?: string;
            value?: string;
        };
    }
    export class ERC721Transfer extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            from?: string;
            to?: string;
            tokenId?: string;
            name?: string;
            symbol?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("from" in data && data.from != undefined) {
                    this.from = data.from;
                }
                if ("to" in data && data.to != undefined) {
                    this.to = data.to;
                }
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("symbol" in data && data.symbol != undefined) {
                    this.symbol = data.symbol;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get from() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set from(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get to() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set to(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get symbol() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set symbol(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        static fromObject(data?: ERC721Transfer.AsObjectPartial): ERC721Transfer {
            if (!data) {
                return new ERC721Transfer();
            }
            const message = new ERC721Transfer({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.from != null) {
                message.from = data.from;
            }
            if (data.to != null) {
                message.to = data.to;
            }
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.symbol != null) {
                message.symbol = data.symbol;
            }
            return message;
        }
        toObject() {
            const data: ERC721Transfer.AsObject = {
                address: this.address,
                from: this.from,
                to: this.to,
                tokenId: this.tokenId,
                name: this.name,
                symbol: this.symbol
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.from.length)
                writer.writeString(2, this.from);
            if (this.to.length)
                writer.writeString(3, this.to);
            if (this.tokenId.length)
                writer.writeString(4, this.tokenId);
            if (this.name.length)
                writer.writeString(5, this.name);
            if (this.symbol.length)
                writer.writeString(6, this.symbol);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC721Transfer {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC721Transfer();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.from = reader.readString();
                        break;
                    case 3:
                        message.to = reader.readString();
                        break;
                    case 4:
                        message.tokenId = reader.readString();
                        break;
                    case 5:
                        message.name = reader.readString();
                        break;
                    case 6:
                        message.symbol = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ERC721Transfer {
            return ERC721Transfer.deserialize(bytes);
        }
    }
    export namespace ERC721Transfer {
        export type AsObject = {
            address: string;
            from: string;
            to: string;
            tokenId: string;
            name: string;
            symbol: string;
        };
        export type AsObjectPartial = {
            address?: string;
            from?: string;
            to?: string;
            tokenId?: string;
            name?: string;
            symbol?: string;
        };
    }
    export class ERC1155Transfer extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            from?: string;
            to?: string;
            tokenId?: string;
            value?: string;
            name?: string;
            symbol?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("from" in data && data.from != undefined) {
                    this.from = data.from;
                }
                if ("to" in data && data.to != undefined) {
                    this.to = data.to;
                }
                if ("tokenId" in data && data.tokenId != undefined) {
                    this.tokenId = data.tokenId;
                }
                if ("value" in data && data.value != undefined) {
                    this.value = data.value;
                }
                if ("name" in data && data.name != undefined) {
                    this.name = data.name;
                }
                if ("symbol" in data && data.symbol != undefined) {
                    this.symbol = data.symbol;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get from() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set from(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get to() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set to(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get tokenId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set tokenId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get value() {
            return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
        }
        set value(value: string) {
            pb_1.Message.setField(this, 5, value);
        }
        get name() {
            return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
        }
        set name(value: string) {
            pb_1.Message.setField(this, 6, value);
        }
        get symbol() {
            return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
        }
        set symbol(value: string) {
            pb_1.Message.setField(this, 7, value);
        }
        static fromObject(data?: ERC1155Transfer.AsObjectPartial): ERC1155Transfer {
            if (!data) {
                return new ERC1155Transfer();
            }
            const message = new ERC1155Transfer({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.from != null) {
                message.from = data.from;
            }
            if (data.to != null) {
                message.to = data.to;
            }
            if (data.tokenId != null) {
                message.tokenId = data.tokenId;
            }
            if (data.value != null) {
                message.value = data.value;
            }
            if (data.name != null) {
                message.name = data.name;
            }
            if (data.symbol != null) {
                message.symbol = data.symbol;
            }
            return message;
        }
        toObject() {
            const data: ERC1155Transfer.AsObject = {
                address: this.address,
                from: this.from,
                to: this.to,
                tokenId: this.tokenId,
                value: this.value,
                name: this.name,
                symbol: this.symbol
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.from.length)
                writer.writeString(2, this.from);
            if (this.to.length)
                writer.writeString(3, this.to);
            if (this.tokenId.length)
                writer.writeString(4, this.tokenId);
            if (this.value.length)
                writer.writeString(5, this.value);
            if (this.name.length)
                writer.writeString(6, this.name);
            if (this.symbol.length)
                writer.writeString(7, this.symbol);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ERC1155Transfer {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ERC1155Transfer();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.from = reader.readString();
                        break;
                    case 3:
                        message.to = reader.readString();
                        break;
                    case 4:
                        message.tokenId = reader.readString();
                        break;
                    case 5:
                        message.value = reader.readString();
                        break;
                    case 6:
                        message.name = reader.readString();
                        break;
                    case 7:
                        message.symbol = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ERC1155Transfer {
            return ERC1155Transfer.deserialize(bytes);
        }
    }
    export namespace ERC1155Transfer {
        export type AsObject = {
            address: string;
            from: string;
            to: string;
            tokenId: string;
            value: string;
            name: string;
            symbol: string;
        };
        export type AsObjectPartial = {
            address?: string;
            from?: string;
            to?: string;
            tokenId?: string;
            value?: string;
            name?: string;
            symbol?: string;
        };
    }
    export class Action extends pb_1.Message {
        #one_of_decls: number[][] = [[1, 2, 3, 4]];
        constructor(data?: any[] | ({} & (({
            erc20Approve?: dependency_4.ERC20ApproveAction;
            erc721Approve?: never;
            erc721ApprovalForAll?: never;
            erc1155ApprovalForAll?: never;
        } | {
            erc20Approve?: never;
            erc721Approve?: dependency_5.ERC721ApproveAction;
            erc721ApprovalForAll?: never;
            erc1155ApprovalForAll?: never;
        } | {
            erc20Approve?: never;
            erc721Approve?: never;
            erc721ApprovalForAll?: dependency_5.ERC721ApprovalForAllAction;
            erc1155ApprovalForAll?: never;
        } | {
            erc20Approve?: never;
            erc721Approve?: never;
            erc721ApprovalForAll?: never;
            erc1155ApprovalForAll?: dependency_6.ERC1155ApprovalForAllAction;
        })))) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("erc20Approve" in data && data.erc20Approve != undefined) {
                    this.erc20Approve = data.erc20Approve;
                }
                if ("erc721Approve" in data && data.erc721Approve != undefined) {
                    this.erc721Approve = data.erc721Approve;
                }
                if ("erc721ApprovalForAll" in data && data.erc721ApprovalForAll != undefined) {
                    this.erc721ApprovalForAll = data.erc721ApprovalForAll;
                }
                if ("erc1155ApprovalForAll" in data && data.erc1155ApprovalForAll != undefined) {
                    this.erc1155ApprovalForAll = data.erc1155ApprovalForAll;
                }
            }
        }
        get erc20Approve() {
            return pb_1.Message.getWrapperField(this, dependency_4.ERC20ApproveAction, 1) as dependency_4.ERC20ApproveAction | undefined;
        }
        set erc20Approve(value: dependency_4.ERC20ApproveAction | undefined) {
            pb_1.Message.setOneofWrapperField(this, 1, this.#one_of_decls[0]!, value);
        }
        get hasErc20Approve() {
            return pb_1.Message.getField(this, 1) != null;
        }
        clearErc20Approve(): void {
            this.erc20Approve = undefined!;
        }
        get erc721Approve() {
            return pb_1.Message.getWrapperField(this, dependency_5.ERC721ApproveAction, 2) as dependency_5.ERC721ApproveAction | undefined;
        }
        set erc721Approve(value: dependency_5.ERC721ApproveAction | undefined) {
            pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
        }
        get hasErc721Approve() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearErc721Approve(): void {
            this.erc721Approve = undefined!;
        }
        get erc721ApprovalForAll() {
            return pb_1.Message.getWrapperField(this, dependency_5.ERC721ApprovalForAllAction, 3) as dependency_5.ERC721ApprovalForAllAction | undefined;
        }
        set erc721ApprovalForAll(value: dependency_5.ERC721ApprovalForAllAction | undefined) {
            pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
        }
        get hasErc721ApprovalForAll() {
            return pb_1.Message.getField(this, 3) != null;
        }
        clearErc721ApprovalForAll(): void {
            this.erc721ApprovalForAll = undefined!;
        }
        get erc1155ApprovalForAll() {
            return pb_1.Message.getWrapperField(this, dependency_6.ERC1155ApprovalForAllAction, 4) as dependency_6.ERC1155ApprovalForAllAction | undefined;
        }
        set erc1155ApprovalForAll(value: dependency_6.ERC1155ApprovalForAllAction | undefined) {
            pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
        }
        get hasErc1155ApprovalForAll() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearErc1155ApprovalForAll(): void {
            this.erc1155ApprovalForAll = undefined!;
        }
        get action() {
            const cases: {
                [index: number]: "none" | "erc20Approve" | "erc721Approve" | "erc721ApprovalForAll" | "erc1155ApprovalForAll";
            } = {
                0: "none",
                1: "erc20Approve",
                2: "erc721Approve",
                3: "erc721ApprovalForAll",
                4: "erc1155ApprovalForAll"
            };
            return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4])]!;
        }
        static fromObject(data?: Action.AsObjectPartial): Action {
            if (!data) {
                return new Action();
            }
            const message = new Action({});
            if (data.erc20Approve != null) {
                message.erc20Approve = dependency_4.ERC20ApproveAction.fromObject(data.erc20Approve);
            }
            if (data.erc721Approve != null) {
                message.erc721Approve = dependency_5.ERC721ApproveAction.fromObject(data.erc721Approve);
            }
            if (data.erc721ApprovalForAll != null) {
                message.erc721ApprovalForAll = dependency_5.ERC721ApprovalForAllAction.fromObject(data.erc721ApprovalForAll);
            }
            if (data.erc1155ApprovalForAll != null) {
                message.erc1155ApprovalForAll = dependency_6.ERC1155ApprovalForAllAction.fromObject(data.erc1155ApprovalForAll);
            }
            return message;
        }
        toObject() {
            const data: Action.AsObject = {};
            if (this.erc20Approve != null) {
                data.erc20Approve = this.erc20Approve.toObject();
            }
            if (this.erc721Approve != null) {
                data.erc721Approve = this.erc721Approve.toObject();
            }
            if (this.erc721ApprovalForAll != null) {
                data.erc721ApprovalForAll = this.erc721ApprovalForAll.toObject();
            }
            if (this.erc1155ApprovalForAll != null) {
                data.erc1155ApprovalForAll = this.erc1155ApprovalForAll.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.hasErc20Approve)
                writer.writeMessage(1, this.erc20Approve, () => this.erc20Approve!.serialize(writer));
            if (this.hasErc721Approve)
                writer.writeMessage(2, this.erc721Approve, () => this.erc721Approve!.serialize(writer));
            if (this.hasErc721ApprovalForAll)
                writer.writeMessage(3, this.erc721ApprovalForAll, () => this.erc721ApprovalForAll!.serialize(writer));
            if (this.hasErc1155ApprovalForAll)
                writer.writeMessage(4, this.erc1155ApprovalForAll, () => this.erc1155ApprovalForAll!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Action {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Action();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        reader.readMessage(message.erc20Approve, () => message.erc20Approve = dependency_4.ERC20ApproveAction.deserialize(reader));
                        break;
                    case 2:
                        reader.readMessage(message.erc721Approve, () => message.erc721Approve = dependency_5.ERC721ApproveAction.deserialize(reader));
                        break;
                    case 3:
                        reader.readMessage(message.erc721ApprovalForAll, () => message.erc721ApprovalForAll = dependency_5.ERC721ApprovalForAllAction.deserialize(reader));
                        break;
                    case 4:
                        reader.readMessage(message.erc1155ApprovalForAll, () => message.erc1155ApprovalForAll = dependency_6.ERC1155ApprovalForAllAction.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Action {
            return Action.deserialize(bytes);
        }
    }
    export namespace Action {
        export type AsObject = {
            erc20Approve?: dependency_4.ERC20ApproveAction.AsObject;
            erc721Approve?: dependency_5.ERC721ApproveAction.AsObject;
            erc721ApprovalForAll?: dependency_5.ERC721ApprovalForAllAction.AsObject;
            erc1155ApprovalForAll?: dependency_6.ERC1155ApprovalForAllAction.AsObject;
        };
        export type AsObjectPartial = {
            erc20Approve?: dependency_4.ERC20ApproveAction.AsObjectPartial;
            erc721Approve?: dependency_5.ERC721ApproveAction.AsObjectPartial;
            erc721ApprovalForAll?: dependency_5.ERC721ApprovalForAllAction.AsObjectPartial;
            erc1155ApprovalForAll?: dependency_6.ERC1155ApprovalForAllAction.AsObjectPartial;
        };
    }
}
export class TransactionShortEVM extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        from?: string;
        gas?: number;
        gasPrice?: string;
        nonce?: number;
        to?: string;
        value?: string;
        status?: TransactionEVM.TransactionStatus;
        call?: TransactionShortEVM.Call;
        logCount?: number;
        internalTxCount?: number;
        gasUsed?: number;
        effectiveGasPrice?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("from" in data && data.from != undefined) {
                this.from = data.from;
            }
            if ("gas" in data && data.gas != undefined) {
                this.gas = data.gas;
            }
            if ("gasPrice" in data && data.gasPrice != undefined) {
                this.gasPrice = data.gasPrice;
            }
            if ("nonce" in data && data.nonce != undefined) {
                this.nonce = data.nonce;
            }
            if ("to" in data && data.to != undefined) {
                this.to = data.to;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
            if ("status" in data && data.status != undefined) {
                this.status = data.status;
            }
            if ("call" in data && data.call != undefined) {
                this.call = data.call;
            }
            if ("logCount" in data && data.logCount != undefined) {
                this.logCount = data.logCount;
            }
            if ("internalTxCount" in data && data.internalTxCount != undefined) {
                this.internalTxCount = data.internalTxCount;
            }
            if ("gasUsed" in data && data.gasUsed != undefined) {
                this.gasUsed = data.gasUsed;
            }
            if ("effectiveGasPrice" in data && data.effectiveGasPrice != undefined) {
                this.effectiveGasPrice = data.effectiveGasPrice;
            }
        }
    }
    get from() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set from(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get gas() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set gas(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get gasPrice() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set gasPrice(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get nonce() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set nonce(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get to() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set to(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get status() {
        return pb_1.Message.getFieldWithDefault(this, 7, TransactionEVM.TransactionStatus.TransactionStatePending) as TransactionEVM.TransactionStatus;
    }
    set status(value: TransactionEVM.TransactionStatus) {
        pb_1.Message.setField(this, 7, value);
    }
    get call() {
        return pb_1.Message.getWrapperField(this, TransactionShortEVM.Call, 8) as TransactionShortEVM.Call | undefined;
    }
    set call(value: TransactionShortEVM.Call | undefined) {
        pb_1.Message.setWrapperField(this, 8, value);
    }
    get hasCall() {
        return pb_1.Message.getField(this, 8) != null;
    }
    clearCall(): void {
        this.call = undefined!;
    }
    get logCount() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set logCount(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get internalTxCount() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set internalTxCount(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get gasUsed() {
        return pb_1.Message.getFieldWithDefault(this, 11, 0) as number;
    }
    set gasUsed(value: number) {
        pb_1.Message.setField(this, 11, value);
    }
    get effectiveGasPrice() {
        return pb_1.Message.getFieldWithDefault(this, 12, "") as string;
    }
    set effectiveGasPrice(value: string) {
        pb_1.Message.setField(this, 12, value);
    }
    static fromObject(data?: TransactionShortEVM.AsObjectPartial): TransactionShortEVM {
        if (!data) {
            return new TransactionShortEVM();
        }
        const message = new TransactionShortEVM({});
        if (data.from != null) {
            message.from = data.from;
        }
        if (data.gas != null) {
            message.gas = data.gas;
        }
        if (data.gasPrice != null) {
            message.gasPrice = data.gasPrice;
        }
        if (data.nonce != null) {
            message.nonce = data.nonce;
        }
        if (data.to != null) {
            message.to = data.to;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        if (data.status != null) {
            message.status = data.status;
        }
        if (data.call != null) {
            message.call = TransactionShortEVM.Call.fromObject(data.call);
        }
        if (data.logCount != null) {
            message.logCount = data.logCount;
        }
        if (data.internalTxCount != null) {
            message.internalTxCount = data.internalTxCount;
        }
        if (data.gasUsed != null) {
            message.gasUsed = data.gasUsed;
        }
        if (data.effectiveGasPrice != null) {
            message.effectiveGasPrice = data.effectiveGasPrice;
        }
        return message;
    }
    toObject() {
        const data: TransactionShortEVM.AsObject = {
            from: this.from,
            gas: this.gas,
            gasPrice: this.gasPrice,
            nonce: this.nonce,
            to: this.to,
            value: this.value,
            status: this.status,
            logCount: this.logCount,
            internalTxCount: this.internalTxCount,
            gasUsed: this.gasUsed,
            effectiveGasPrice: this.effectiveGasPrice
        };
        if (this.call != null) {
            data.call = this.call.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.from.length)
            writer.writeString(1, this.from);
        if (this.gas != 0)
            writer.writeUint64(2, this.gas);
        if (this.gasPrice.length)
            writer.writeString(3, this.gasPrice);
        if (this.nonce != 0)
            writer.writeUint64(4, this.nonce);
        if (this.to.length)
            writer.writeString(5, this.to);
        if (this.value.length)
            writer.writeString(6, this.value);
        if (this.status != TransactionEVM.TransactionStatus.TransactionStatePending)
            writer.writeEnum(7, this.status);
        if (this.hasCall)
            writer.writeMessage(8, this.call, () => this.call!.serialize(writer));
        if (this.logCount != 0)
            writer.writeInt64(9, this.logCount);
        if (this.internalTxCount != 0)
            writer.writeInt64(10, this.internalTxCount);
        if (this.gasUsed != 0)
            writer.writeUint64(11, this.gasUsed);
        if (this.effectiveGasPrice.length)
            writer.writeString(12, this.effectiveGasPrice);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionShortEVM {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionShortEVM();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.from = reader.readString();
                    break;
                case 2:
                    message.gas = reader.readUint64();
                    break;
                case 3:
                    message.gasPrice = reader.readString();
                    break;
                case 4:
                    message.nonce = reader.readUint64();
                    break;
                case 5:
                    message.to = reader.readString();
                    break;
                case 6:
                    message.value = reader.readString();
                    break;
                case 7:
                    message.status = reader.readEnum();
                    break;
                case 8:
                    reader.readMessage(message.call, () => message.call = TransactionShortEVM.Call.deserialize(reader));
                    break;
                case 9:
                    message.logCount = reader.readInt64();
                    break;
                case 10:
                    message.internalTxCount = reader.readInt64();
                    break;
                case 11:
                    message.gasUsed = reader.readUint64();
                    break;
                case 12:
                    message.effectiveGasPrice = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionShortEVM {
        return TransactionShortEVM.deserialize(bytes);
    }
}
export namespace TransactionShortEVM {
    export type AsObject = {
        from: string;
        gas: number;
        gasPrice: string;
        nonce: number;
        to: string;
        value: string;
        status: TransactionEVM.TransactionStatus;
        call?: TransactionShortEVM.Call.AsObject;
        logCount: number;
        internalTxCount: number;
        gasUsed: number;
        effectiveGasPrice: string;
    };
    export type AsObjectPartial = {
        from?: string;
        gas?: number;
        gasPrice?: string;
        nonce?: number;
        to?: string;
        value?: string;
        status?: TransactionEVM.TransactionStatus;
        call?: TransactionShortEVM.Call.AsObjectPartial;
        logCount?: number;
        internalTxCount?: number;
        gasUsed?: number;
        effectiveGasPrice?: string;
    };
    export class Call extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            methodId?: string;
            methodName?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("methodId" in data && data.methodId != undefined) {
                    this.methodId = data.methodId;
                }
                if ("methodName" in data && data.methodName != undefined) {
                    this.methodName = data.methodName;
                }
            }
        }
        get methodId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set methodId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get methodName() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set methodName(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: Call.AsObjectPartial): Call {
            if (!data) {
                return new Call();
            }
            const message = new Call({});
            if (data.methodId != null) {
                message.methodId = data.methodId;
            }
            if (data.methodName != null) {
                message.methodName = data.methodName;
            }
            return message;
        }
        toObject() {
            const data: Call.AsObject = {
                methodId: this.methodId,
                methodName: this.methodName
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.methodId.length)
                writer.writeString(1, this.methodId);
            if (this.methodName.length)
                writer.writeString(2, this.methodName);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Call {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Call();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.methodId = reader.readString();
                        break;
                    case 2:
                        message.methodName = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Call {
            return Call.deserialize(bytes);
        }
    }
    export namespace Call {
        export type AsObject = {
            methodId: string;
            methodName: string;
        };
        export type AsObjectPartial = {
            methodId?: string;
            methodName?: string;
        };
    }
}
export class TransactionCoreETH extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3]];
    constructor(data?: any[] | ({
        creds?: TransactionCoreETH.Credentials[];
    } & (({
        export?: TransactionCoreETH.ExportTx;
        import?: never;
    } | {
        export?: never;
        import?: TransactionCoreETH.ImportTx;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("creds" in data && data.creds != undefined) {
                this.creds = data.creds;
            }
            if ("export" in data && data.export != undefined) {
                this.export = data.export;
            }
            if ("import" in data && data.import != undefined) {
                this.import = data.import;
            }
        }
    }
    get creds() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionCoreETH.Credentials, 1) as TransactionCoreETH.Credentials[];
    }
    set creds(value: TransactionCoreETH.Credentials[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get export() {
        return pb_1.Message.getWrapperField(this, TransactionCoreETH.ExportTx, 2) as TransactionCoreETH.ExportTx | undefined;
    }
    set export(value: TransactionCoreETH.ExportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasExport() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearExport(): void {
        this.export = undefined!;
    }
    get import() {
        return pb_1.Message.getWrapperField(this, TransactionCoreETH.ImportTx, 3) as TransactionCoreETH.ImportTx | undefined;
    }
    set import(value: TransactionCoreETH.ImportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasImport() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearImport(): void {
        this.import = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "export" | "import";
        } = {
            0: "none",
            2: "export",
            3: "import"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3])]!;
    }
    static fromObject(data?: TransactionCoreETH.AsObjectPartial): TransactionCoreETH {
        if (!data) {
            return new TransactionCoreETH();
        }
        const message = new TransactionCoreETH({});
        if (data.creds != null) {
            message.creds = data.creds.map(item => TransactionCoreETH.Credentials.fromObject(item));
        }
        if (data.export != null) {
            message.export = TransactionCoreETH.ExportTx.fromObject(data.export);
        }
        if (data.import != null) {
            message.import = TransactionCoreETH.ImportTx.fromObject(data.import);
        }
        return message;
    }
    toObject() {
        const data: TransactionCoreETH.AsObject = {
            creds: this.creds.map((item: TransactionCoreETH.Credentials) => item.toObject())
        };
        if (this.export != null) {
            data.export = this.export.toObject();
        }
        if (this.import != null) {
            data.import = this.import.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.creds.length)
            writer.writeRepeatedMessage(1, this.creds, (item: TransactionCoreETH.Credentials) => item!.serialize(writer));
        if (this.hasExport)
            writer.writeMessage(2, this.export, () => this.export!.serialize(writer));
        if (this.hasImport)
            writer.writeMessage(3, this.import, () => this.import!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionCoreETH {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionCoreETH();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.creds, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TransactionCoreETH.Credentials.deserialize(reader), TransactionCoreETH.Credentials));
                    break;
                case 2:
                    reader.readMessage(message.export, () => message.export = TransactionCoreETH.ExportTx.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.import, () => message.import = TransactionCoreETH.ImportTx.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionCoreETH {
        return TransactionCoreETH.deserialize(bytes);
    }
}
export namespace TransactionCoreETH {
    export type AsObject = {
        creds: TransactionCoreETH.Credentials.AsObject[];
        export?: TransactionCoreETH.ExportTx.AsObject;
        import?: TransactionCoreETH.ImportTx.AsObject;
    };
    export type AsObjectPartial = {
        creds?: TransactionCoreETH.Credentials.AsObjectPartial[];
        export?: TransactionCoreETH.ExportTx.AsObjectPartial;
        import?: TransactionCoreETH.ImportTx.AsObjectPartial;
    };
    export class AssetInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            symbol?: string;
            denomination?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("symbol" in data && data.symbol != undefined) {
                    this.symbol = data.symbol;
                }
                if ("denomination" in data && data.denomination != undefined) {
                    this.denomination = data.denomination;
                }
            }
        }
        get symbol() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set symbol(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get denomination() {
            return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
        }
        set denomination(value: number) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: AssetInfo.AsObjectPartial): AssetInfo {
            if (!data) {
                return new AssetInfo();
            }
            const message = new AssetInfo({});
            if (data.symbol != null) {
                message.symbol = data.symbol;
            }
            if (data.denomination != null) {
                message.denomination = data.denomination;
            }
            return message;
        }
        toObject() {
            const data: AssetInfo.AsObject = {
                symbol: this.symbol,
                denomination: this.denomination
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.symbol.length)
                writer.writeString(1, this.symbol);
            if (this.denomination != 0)
                writer.writeInt32(2, this.denomination);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.symbol = reader.readString();
                        break;
                    case 2:
                        message.denomination = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AssetInfo {
            return AssetInfo.deserialize(bytes);
        }
    }
    export namespace AssetInfo {
        export type AsObject = {
            symbol: string;
            denomination: number;
        };
        export type AsObjectPartial = {
            symbol?: string;
            denomination?: number;
        };
    }
    export class TransferableInput extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            assetId?: string;
            assetInfo?: TransactionCoreETH.AssetInfo;
            fxId?: string;
            txId?: string;
            outputIndex?: number;
            secp256k1TransferInput?: TransactionCoreETH.TransferableInput.SECP256K1TransferInput;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("assetId" in data && data.assetId != undefined) {
                    this.assetId = data.assetId;
                }
                if ("assetInfo" in data && data.assetInfo != undefined) {
                    this.assetInfo = data.assetInfo;
                }
                if ("fxId" in data && data.fxId != undefined) {
                    this.fxId = data.fxId;
                }
                if ("txId" in data && data.txId != undefined) {
                    this.txId = data.txId;
                }
                if ("outputIndex" in data && data.outputIndex != undefined) {
                    this.outputIndex = data.outputIndex;
                }
                if ("secp256k1TransferInput" in data && data.secp256k1TransferInput != undefined) {
                    this.secp256k1TransferInput = data.secp256k1TransferInput;
                }
            }
        }
        get assetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set assetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get assetInfo() {
            return pb_1.Message.getWrapperField(this, TransactionCoreETH.AssetInfo, 2) as TransactionCoreETH.AssetInfo | undefined;
        }
        set assetInfo(value: TransactionCoreETH.AssetInfo | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasAssetInfo() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearAssetInfo(): void {
            this.assetInfo = undefined!;
        }
        get fxId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set fxId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get txId() {
            return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
        }
        set txId(value: string) {
            pb_1.Message.setField(this, 4, value);
        }
        get outputIndex() {
            return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
        }
        set outputIndex(value: number) {
            pb_1.Message.setField(this, 5, value);
        }
        get secp256k1TransferInput() {
            return pb_1.Message.getWrapperField(this, TransactionCoreETH.TransferableInput.SECP256K1TransferInput, 6) as TransactionCoreETH.TransferableInput.SECP256K1TransferInput | undefined;
        }
        set secp256k1TransferInput(value: TransactionCoreETH.TransferableInput.SECP256K1TransferInput | undefined) {
            pb_1.Message.setWrapperField(this, 6, value);
        }
        get hasSecp256k1TransferInput() {
            return pb_1.Message.getField(this, 6) != null;
        }
        clearSecp256k1TransferInput(): void {
            this.secp256k1TransferInput = undefined!;
        }
        static fromObject(data?: TransferableInput.AsObjectPartial): TransferableInput {
            if (!data) {
                return new TransferableInput();
            }
            const message = new TransferableInput({});
            if (data.assetId != null) {
                message.assetId = data.assetId;
            }
            if (data.assetInfo != null) {
                message.assetInfo = TransactionCoreETH.AssetInfo.fromObject(data.assetInfo);
            }
            if (data.fxId != null) {
                message.fxId = data.fxId;
            }
            if (data.txId != null) {
                message.txId = data.txId;
            }
            if (data.outputIndex != null) {
                message.outputIndex = data.outputIndex;
            }
            if (data.secp256k1TransferInput != null) {
                message.secp256k1TransferInput = TransactionCoreETH.TransferableInput.SECP256K1TransferInput.fromObject(data.secp256k1TransferInput);
            }
            return message;
        }
        toObject() {
            const data: TransferableInput.AsObject = {
                assetId: this.assetId,
                fxId: this.fxId,
                txId: this.txId,
                outputIndex: this.outputIndex
            };
            if (this.assetInfo != null) {
                data.assetInfo = this.assetInfo.toObject();
            }
            if (this.secp256k1TransferInput != null) {
                data.secp256k1TransferInput = this.secp256k1TransferInput.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.assetId.length)
                writer.writeString(1, this.assetId);
            if (this.hasAssetInfo)
                writer.writeMessage(2, this.assetInfo, () => this.assetInfo!.serialize(writer));
            if (this.fxId.length)
                writer.writeString(3, this.fxId);
            if (this.txId.length)
                writer.writeString(4, this.txId);
            if (this.outputIndex != 0)
                writer.writeUint32(5, this.outputIndex);
            if (this.hasSecp256k1TransferInput)
                writer.writeMessage(6, this.secp256k1TransferInput, () => this.secp256k1TransferInput!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferableInput {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferableInput();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.assetId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.assetInfo, () => message.assetInfo = TransactionCoreETH.AssetInfo.deserialize(reader));
                        break;
                    case 3:
                        message.fxId = reader.readString();
                        break;
                    case 4:
                        message.txId = reader.readString();
                        break;
                    case 5:
                        message.outputIndex = reader.readUint32();
                        break;
                    case 6:
                        reader.readMessage(message.secp256k1TransferInput, () => message.secp256k1TransferInput = TransactionCoreETH.TransferableInput.SECP256K1TransferInput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransferableInput {
            return TransferableInput.deserialize(bytes);
        }
    }
    export namespace TransferableInput {
        export type AsObject = {
            assetId: string;
            assetInfo?: TransactionCoreETH.AssetInfo.AsObject;
            fxId: string;
            txId: string;
            outputIndex: number;
            secp256k1TransferInput?: TransactionCoreETH.TransferableInput.SECP256K1TransferInput.AsObject;
        };
        export type AsObjectPartial = {
            assetId?: string;
            assetInfo?: TransactionCoreETH.AssetInfo.AsObjectPartial;
            fxId?: string;
            txId?: string;
            outputIndex?: number;
            secp256k1TransferInput?: TransactionCoreETH.TransferableInput.SECP256K1TransferInput.AsObjectPartial;
        };
        export class SECP256K1TransferInput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                amount?: string;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 2, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 2, value);
            }
            static fromObject(data?: SECP256K1TransferInput.AsObjectPartial): SECP256K1TransferInput {
                if (!data) {
                    return new SECP256K1TransferInput();
                }
                const message = new SECP256K1TransferInput({});
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: SECP256K1TransferInput.AsObject = {
                    amount: this.amount,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.amount.length)
                    writer.writeString(1, this.amount);
                if (this.addresses.length)
                    writer.writeRepeatedString(2, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SECP256K1TransferInput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SECP256K1TransferInput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.amount = reader.readString();
                            break;
                        case 2:
                            pb_1.Message.addToRepeatedField(message, 2, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): SECP256K1TransferInput {
                return SECP256K1TransferInput.deserialize(bytes);
            }
        }
        export namespace SECP256K1TransferInput {
            export type AsObject = {
                amount: string;
                addresses: string[];
            };
            export type AsObjectPartial = {
                amount?: string;
                addresses?: string[];
            };
        }
    }
    export class TransferableOutput extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            assetId?: string;
            assetInfo?: TransactionCoreETH.AssetInfo;
            fxId?: string;
            secp256k1TransferOutput?: TransactionCoreETH.TransferableOutput.SECP256K1TransferOutput;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("assetId" in data && data.assetId != undefined) {
                    this.assetId = data.assetId;
                }
                if ("assetInfo" in data && data.assetInfo != undefined) {
                    this.assetInfo = data.assetInfo;
                }
                if ("fxId" in data && data.fxId != undefined) {
                    this.fxId = data.fxId;
                }
                if ("secp256k1TransferOutput" in data && data.secp256k1TransferOutput != undefined) {
                    this.secp256k1TransferOutput = data.secp256k1TransferOutput;
                }
            }
        }
        get assetId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set assetId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get assetInfo() {
            return pb_1.Message.getWrapperField(this, TransactionCoreETH.AssetInfo, 2) as TransactionCoreETH.AssetInfo | undefined;
        }
        set assetInfo(value: TransactionCoreETH.AssetInfo | undefined) {
            pb_1.Message.setWrapperField(this, 2, value);
        }
        get hasAssetInfo() {
            return pb_1.Message.getField(this, 2) != null;
        }
        clearAssetInfo(): void {
            this.assetInfo = undefined!;
        }
        get fxId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set fxId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get secp256k1TransferOutput() {
            return pb_1.Message.getWrapperField(this, TransactionCoreETH.TransferableOutput.SECP256K1TransferOutput, 4) as TransactionCoreETH.TransferableOutput.SECP256K1TransferOutput | undefined;
        }
        set secp256k1TransferOutput(value: TransactionCoreETH.TransferableOutput.SECP256K1TransferOutput | undefined) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasSecp256k1TransferOutput() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearSecp256k1TransferOutput(): void {
            this.secp256k1TransferOutput = undefined!;
        }
        static fromObject(data?: TransferableOutput.AsObjectPartial): TransferableOutput {
            if (!data) {
                return new TransferableOutput();
            }
            const message = new TransferableOutput({});
            if (data.assetId != null) {
                message.assetId = data.assetId;
            }
            if (data.assetInfo != null) {
                message.assetInfo = TransactionCoreETH.AssetInfo.fromObject(data.assetInfo);
            }
            if (data.fxId != null) {
                message.fxId = data.fxId;
            }
            if (data.secp256k1TransferOutput != null) {
                message.secp256k1TransferOutput = TransactionCoreETH.TransferableOutput.SECP256K1TransferOutput.fromObject(data.secp256k1TransferOutput);
            }
            return message;
        }
        toObject() {
            const data: TransferableOutput.AsObject = {
                assetId: this.assetId,
                fxId: this.fxId
            };
            if (this.assetInfo != null) {
                data.assetInfo = this.assetInfo.toObject();
            }
            if (this.secp256k1TransferOutput != null) {
                data.secp256k1TransferOutput = this.secp256k1TransferOutput.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.assetId.length)
                writer.writeString(1, this.assetId);
            if (this.hasAssetInfo)
                writer.writeMessage(2, this.assetInfo, () => this.assetInfo!.serialize(writer));
            if (this.fxId.length)
                writer.writeString(3, this.fxId);
            if (this.hasSecp256k1TransferOutput)
                writer.writeMessage(4, this.secp256k1TransferOutput, () => this.secp256k1TransferOutput!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransferableOutput {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransferableOutput();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.assetId = reader.readString();
                        break;
                    case 2:
                        reader.readMessage(message.assetInfo, () => message.assetInfo = TransactionCoreETH.AssetInfo.deserialize(reader));
                        break;
                    case 3:
                        message.fxId = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.secp256k1TransferOutput, () => message.secp256k1TransferOutput = TransactionCoreETH.TransferableOutput.SECP256K1TransferOutput.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): TransferableOutput {
            return TransferableOutput.deserialize(bytes);
        }
    }
    export namespace TransferableOutput {
        export type AsObject = {
            assetId: string;
            assetInfo?: TransactionCoreETH.AssetInfo.AsObject;
            fxId: string;
            secp256k1TransferOutput?: TransactionCoreETH.TransferableOutput.SECP256K1TransferOutput.AsObject;
        };
        export type AsObjectPartial = {
            assetId?: string;
            assetInfo?: TransactionCoreETH.AssetInfo.AsObjectPartial;
            fxId?: string;
            secp256k1TransferOutput?: TransactionCoreETH.TransferableOutput.SECP256K1TransferOutput.AsObjectPartial;
        };
        export class SECP256K1TransferOutput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                amount?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [4], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("locktime" in data && data.locktime != undefined) {
                        this.locktime = data.locktime;
                    }
                    if ("threshold" in data && data.threshold != undefined) {
                        this.threshold = data.threshold;
                    }
                    if ("addresses" in data && data.addresses != undefined) {
                        this.addresses = data.addresses;
                    }
                }
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get locktime() {
                return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
            }
            set locktime(value: number) {
                pb_1.Message.setField(this, 2, value);
            }
            get threshold() {
                return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
            }
            set threshold(value: number) {
                pb_1.Message.setField(this, 3, value);
            }
            get addresses() {
                return pb_1.Message.getFieldWithDefault(this, 4, []) as string[];
            }
            set addresses(value: string[]) {
                pb_1.Message.setField(this, 4, value);
            }
            static fromObject(data?: SECP256K1TransferOutput.AsObjectPartial): SECP256K1TransferOutput {
                if (!data) {
                    return new SECP256K1TransferOutput();
                }
                const message = new SECP256K1TransferOutput({});
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.locktime != null) {
                    message.locktime = data.locktime;
                }
                if (data.threshold != null) {
                    message.threshold = data.threshold;
                }
                if (data.addresses != null) {
                    message.addresses = data.addresses;
                }
                return message;
            }
            toObject() {
                const data: SECP256K1TransferOutput.AsObject = {
                    amount: this.amount,
                    locktime: this.locktime,
                    threshold: this.threshold,
                    addresses: this.addresses
                };
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.amount.length)
                    writer.writeString(1, this.amount);
                if (this.locktime != 0)
                    writer.writeUint64(2, this.locktime);
                if (this.threshold != 0)
                    writer.writeUint32(3, this.threshold);
                if (this.addresses.length)
                    writer.writeRepeatedString(4, this.addresses);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SECP256K1TransferOutput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SECP256K1TransferOutput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.amount = reader.readString();
                            break;
                        case 2:
                            message.locktime = reader.readUint64();
                            break;
                        case 3:
                            message.threshold = reader.readUint32();
                            break;
                        case 4:
                            pb_1.Message.addToRepeatedField(message, 4, reader.readString());
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): SECP256K1TransferOutput {
                return SECP256K1TransferOutput.deserialize(bytes);
            }
        }
        export namespace SECP256K1TransferOutput {
            export type AsObject = {
                amount: string;
                locktime: number;
                threshold: number;
                addresses: string[];
            };
            export type AsObjectPartial = {
                amount?: string;
                locktime?: number;
                threshold?: number;
                addresses?: string[];
            };
        }
    }
    export class Credentials extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            signatures?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("signatures" in data && data.signatures != undefined) {
                    this.signatures = data.signatures;
                }
            }
        }
        get signatures() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set signatures(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: Credentials.AsObjectPartial): Credentials {
            if (!data) {
                return new Credentials();
            }
            const message = new Credentials({});
            if (data.signatures != null) {
                message.signatures = data.signatures;
            }
            return message;
        }
        toObject() {
            const data: Credentials.AsObject = {
                signatures: this.signatures
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.signatures.length)
                writer.writeRepeatedString(1, this.signatures);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Credentials {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Credentials();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): Credentials {
            return Credentials.deserialize(bytes);
        }
    }
    export namespace Credentials {
        export type AsObject = {
            signatures: string[];
        };
        export type AsObjectPartial = {
            signatures?: string[];
        };
    }
    export class ExportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            blockchainId?: string;
            destinationBlockchainId?: string;
            valueAssetId?: string;
            valueAssetInfo?: TransactionCoreETH.AssetInfo;
            inputs?: TransactionCoreETH.ExportTx.EVMInput[];
            outputs?: TransactionCoreETH.TransferableOutput[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5, 6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("blockchainId" in data && data.blockchainId != undefined) {
                    this.blockchainId = data.blockchainId;
                }
                if ("destinationBlockchainId" in data && data.destinationBlockchainId != undefined) {
                    this.destinationBlockchainId = data.destinationBlockchainId;
                }
                if ("valueAssetId" in data && data.valueAssetId != undefined) {
                    this.valueAssetId = data.valueAssetId;
                }
                if ("valueAssetInfo" in data && data.valueAssetInfo != undefined) {
                    this.valueAssetInfo = data.valueAssetInfo;
                }
                if ("inputs" in data && data.inputs != undefined) {
                    this.inputs = data.inputs;
                }
                if ("outputs" in data && data.outputs != undefined) {
                    this.outputs = data.outputs;
                }
            }
        }
        get blockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set blockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get destinationBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set destinationBlockchainId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get valueAssetId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set valueAssetId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get valueAssetInfo() {
            return pb_1.Message.getWrapperField(this, TransactionCoreETH.AssetInfo, 4) as TransactionCoreETH.AssetInfo | undefined;
        }
        set valueAssetInfo(value: TransactionCoreETH.AssetInfo | undefined) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasValueAssetInfo() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearValueAssetInfo(): void {
            this.valueAssetInfo = undefined!;
        }
        get inputs() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionCoreETH.ExportTx.EVMInput, 5) as TransactionCoreETH.ExportTx.EVMInput[];
        }
        set inputs(value: TransactionCoreETH.ExportTx.EVMInput[]) {
            pb_1.Message.setRepeatedWrapperField(this, 5, value);
        }
        get outputs() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionCoreETH.TransferableOutput, 6) as TransactionCoreETH.TransferableOutput[];
        }
        set outputs(value: TransactionCoreETH.TransferableOutput[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        static fromObject(data?: ExportTx.AsObjectPartial): ExportTx {
            if (!data) {
                return new ExportTx();
            }
            const message = new ExportTx({});
            if (data.blockchainId != null) {
                message.blockchainId = data.blockchainId;
            }
            if (data.destinationBlockchainId != null) {
                message.destinationBlockchainId = data.destinationBlockchainId;
            }
            if (data.valueAssetId != null) {
                message.valueAssetId = data.valueAssetId;
            }
            if (data.valueAssetInfo != null) {
                message.valueAssetInfo = TransactionCoreETH.AssetInfo.fromObject(data.valueAssetInfo);
            }
            if (data.inputs != null) {
                message.inputs = data.inputs.map(item => TransactionCoreETH.ExportTx.EVMInput.fromObject(item));
            }
            if (data.outputs != null) {
                message.outputs = data.outputs.map(item => TransactionCoreETH.TransferableOutput.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: ExportTx.AsObject = {
                blockchainId: this.blockchainId,
                destinationBlockchainId: this.destinationBlockchainId,
                valueAssetId: this.valueAssetId,
                inputs: this.inputs.map((item: TransactionCoreETH.ExportTx.EVMInput) => item.toObject()),
                outputs: this.outputs.map((item: TransactionCoreETH.TransferableOutput) => item.toObject())
            };
            if (this.valueAssetInfo != null) {
                data.valueAssetInfo = this.valueAssetInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.blockchainId.length)
                writer.writeString(1, this.blockchainId);
            if (this.destinationBlockchainId.length)
                writer.writeString(2, this.destinationBlockchainId);
            if (this.valueAssetId.length)
                writer.writeString(3, this.valueAssetId);
            if (this.hasValueAssetInfo)
                writer.writeMessage(4, this.valueAssetInfo, () => this.valueAssetInfo!.serialize(writer));
            if (this.inputs.length)
                writer.writeRepeatedMessage(5, this.inputs, (item: TransactionCoreETH.ExportTx.EVMInput) => item!.serialize(writer));
            if (this.outputs.length)
                writer.writeRepeatedMessage(6, this.outputs, (item: TransactionCoreETH.TransferableOutput) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ExportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ExportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.blockchainId = reader.readString();
                        break;
                    case 2:
                        message.destinationBlockchainId = reader.readString();
                        break;
                    case 3:
                        message.valueAssetId = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.valueAssetInfo, () => message.valueAssetInfo = TransactionCoreETH.AssetInfo.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.inputs, () => pb_1.Message.addToRepeatedWrapperField(message, 5, TransactionCoreETH.ExportTx.EVMInput.deserialize(reader), TransactionCoreETH.ExportTx.EVMInput));
                        break;
                    case 6:
                        reader.readMessage(message.outputs, () => pb_1.Message.addToRepeatedWrapperField(message, 6, TransactionCoreETH.TransferableOutput.deserialize(reader), TransactionCoreETH.TransferableOutput));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ExportTx {
            return ExportTx.deserialize(bytes);
        }
    }
    export namespace ExportTx {
        export type AsObject = {
            blockchainId: string;
            destinationBlockchainId: string;
            valueAssetId: string;
            valueAssetInfo?: TransactionCoreETH.AssetInfo.AsObject;
            inputs: TransactionCoreETH.ExportTx.EVMInput.AsObject[];
            outputs: TransactionCoreETH.TransferableOutput.AsObject[];
        };
        export type AsObjectPartial = {
            blockchainId?: string;
            destinationBlockchainId?: string;
            valueAssetId?: string;
            valueAssetInfo?: TransactionCoreETH.AssetInfo.AsObjectPartial;
            inputs?: TransactionCoreETH.ExportTx.EVMInput.AsObjectPartial[];
            outputs?: TransactionCoreETH.TransferableOutput.AsObjectPartial[];
        };
        export class EVMInput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                address?: string;
                amount?: string;
                assetId?: string;
                assetInfo?: TransactionCoreETH.AssetInfo;
                nonce?: number;
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("address" in data && data.address != undefined) {
                        this.address = data.address;
                    }
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("assetId" in data && data.assetId != undefined) {
                        this.assetId = data.assetId;
                    }
                    if ("assetInfo" in data && data.assetInfo != undefined) {
                        this.assetInfo = data.assetInfo;
                    }
                    if ("nonce" in data && data.nonce != undefined) {
                        this.nonce = data.nonce;
                    }
                }
            }
            get address() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set address(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 2, value);
            }
            get assetId() {
                return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
            }
            set assetId(value: string) {
                pb_1.Message.setField(this, 3, value);
            }
            get assetInfo() {
                return pb_1.Message.getWrapperField(this, TransactionCoreETH.AssetInfo, 4) as TransactionCoreETH.AssetInfo | undefined;
            }
            set assetInfo(value: TransactionCoreETH.AssetInfo | undefined) {
                pb_1.Message.setWrapperField(this, 4, value);
            }
            get hasAssetInfo() {
                return pb_1.Message.getField(this, 4) != null;
            }
            clearAssetInfo(): void {
                this.assetInfo = undefined!;
            }
            get nonce() {
                return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
            }
            set nonce(value: number) {
                pb_1.Message.setField(this, 5, value);
            }
            static fromObject(data?: EVMInput.AsObjectPartial): EVMInput {
                if (!data) {
                    return new EVMInput();
                }
                const message = new EVMInput({});
                if (data.address != null) {
                    message.address = data.address;
                }
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.assetId != null) {
                    message.assetId = data.assetId;
                }
                if (data.assetInfo != null) {
                    message.assetInfo = TransactionCoreETH.AssetInfo.fromObject(data.assetInfo);
                }
                if (data.nonce != null) {
                    message.nonce = data.nonce;
                }
                return message;
            }
            toObject() {
                const data: EVMInput.AsObject = {
                    address: this.address,
                    amount: this.amount,
                    assetId: this.assetId,
                    nonce: this.nonce
                };
                if (this.assetInfo != null) {
                    data.assetInfo = this.assetInfo.toObject();
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.address.length)
                    writer.writeString(1, this.address);
                if (this.amount.length)
                    writer.writeString(2, this.amount);
                if (this.assetId.length)
                    writer.writeString(3, this.assetId);
                if (this.hasAssetInfo)
                    writer.writeMessage(4, this.assetInfo, () => this.assetInfo!.serialize(writer));
                if (this.nonce != 0)
                    writer.writeUint64(5, this.nonce);
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EVMInput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new EVMInput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.address = reader.readString();
                            break;
                        case 2:
                            message.amount = reader.readString();
                            break;
                        case 3:
                            message.assetId = reader.readString();
                            break;
                        case 4:
                            reader.readMessage(message.assetInfo, () => message.assetInfo = TransactionCoreETH.AssetInfo.deserialize(reader));
                            break;
                        case 5:
                            message.nonce = reader.readUint64();
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): EVMInput {
                return EVMInput.deserialize(bytes);
            }
        }
        export namespace EVMInput {
            export type AsObject = {
                address: string;
                amount: string;
                assetId: string;
                assetInfo?: TransactionCoreETH.AssetInfo.AsObject;
                nonce: number;
            };
            export type AsObjectPartial = {
                address?: string;
                amount?: string;
                assetId?: string;
                assetInfo?: TransactionCoreETH.AssetInfo.AsObjectPartial;
                nonce?: number;
            };
        }
    }
    export class ImportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            blockchainId?: string;
            sourceBlockchainId?: string;
            valueAssetId?: string;
            valueAssetInfo?: TransactionCoreETH.AssetInfo;
            inputs?: TransactionCoreETH.TransferableInput[];
            outputs?: TransactionCoreETH.ImportTx.EVMOutput[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [5, 6], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("blockchainId" in data && data.blockchainId != undefined) {
                    this.blockchainId = data.blockchainId;
                }
                if ("sourceBlockchainId" in data && data.sourceBlockchainId != undefined) {
                    this.sourceBlockchainId = data.sourceBlockchainId;
                }
                if ("valueAssetId" in data && data.valueAssetId != undefined) {
                    this.valueAssetId = data.valueAssetId;
                }
                if ("valueAssetInfo" in data && data.valueAssetInfo != undefined) {
                    this.valueAssetInfo = data.valueAssetInfo;
                }
                if ("inputs" in data && data.inputs != undefined) {
                    this.inputs = data.inputs;
                }
                if ("outputs" in data && data.outputs != undefined) {
                    this.outputs = data.outputs;
                }
            }
        }
        get blockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set blockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get sourceBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set sourceBlockchainId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get valueAssetId() {
            return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
        }
        set valueAssetId(value: string) {
            pb_1.Message.setField(this, 3, value);
        }
        get valueAssetInfo() {
            return pb_1.Message.getWrapperField(this, TransactionCoreETH.AssetInfo, 4) as TransactionCoreETH.AssetInfo | undefined;
        }
        set valueAssetInfo(value: TransactionCoreETH.AssetInfo | undefined) {
            pb_1.Message.setWrapperField(this, 4, value);
        }
        get hasValueAssetInfo() {
            return pb_1.Message.getField(this, 4) != null;
        }
        clearValueAssetInfo(): void {
            this.valueAssetInfo = undefined!;
        }
        get inputs() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionCoreETH.TransferableInput, 5) as TransactionCoreETH.TransferableInput[];
        }
        set inputs(value: TransactionCoreETH.TransferableInput[]) {
            pb_1.Message.setRepeatedWrapperField(this, 5, value);
        }
        get outputs() {
            return pb_1.Message.getRepeatedWrapperField(this, TransactionCoreETH.ImportTx.EVMOutput, 6) as TransactionCoreETH.ImportTx.EVMOutput[];
        }
        set outputs(value: TransactionCoreETH.ImportTx.EVMOutput[]) {
            pb_1.Message.setRepeatedWrapperField(this, 6, value);
        }
        static fromObject(data?: ImportTx.AsObjectPartial): ImportTx {
            if (!data) {
                return new ImportTx();
            }
            const message = new ImportTx({});
            if (data.blockchainId != null) {
                message.blockchainId = data.blockchainId;
            }
            if (data.sourceBlockchainId != null) {
                message.sourceBlockchainId = data.sourceBlockchainId;
            }
            if (data.valueAssetId != null) {
                message.valueAssetId = data.valueAssetId;
            }
            if (data.valueAssetInfo != null) {
                message.valueAssetInfo = TransactionCoreETH.AssetInfo.fromObject(data.valueAssetInfo);
            }
            if (data.inputs != null) {
                message.inputs = data.inputs.map(item => TransactionCoreETH.TransferableInput.fromObject(item));
            }
            if (data.outputs != null) {
                message.outputs = data.outputs.map(item => TransactionCoreETH.ImportTx.EVMOutput.fromObject(item));
            }
            return message;
        }
        toObject() {
            const data: ImportTx.AsObject = {
                blockchainId: this.blockchainId,
                sourceBlockchainId: this.sourceBlockchainId,
                valueAssetId: this.valueAssetId,
                inputs: this.inputs.map((item: TransactionCoreETH.TransferableInput) => item.toObject()),
                outputs: this.outputs.map((item: TransactionCoreETH.ImportTx.EVMOutput) => item.toObject())
            };
            if (this.valueAssetInfo != null) {
                data.valueAssetInfo = this.valueAssetInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.blockchainId.length)
                writer.writeString(1, this.blockchainId);
            if (this.sourceBlockchainId.length)
                writer.writeString(2, this.sourceBlockchainId);
            if (this.valueAssetId.length)
                writer.writeString(3, this.valueAssetId);
            if (this.hasValueAssetInfo)
                writer.writeMessage(4, this.valueAssetInfo, () => this.valueAssetInfo!.serialize(writer));
            if (this.inputs.length)
                writer.writeRepeatedMessage(5, this.inputs, (item: TransactionCoreETH.TransferableInput) => item!.serialize(writer));
            if (this.outputs.length)
                writer.writeRepeatedMessage(6, this.outputs, (item: TransactionCoreETH.ImportTx.EVMOutput) => item!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ImportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ImportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.blockchainId = reader.readString();
                        break;
                    case 2:
                        message.sourceBlockchainId = reader.readString();
                        break;
                    case 3:
                        message.valueAssetId = reader.readString();
                        break;
                    case 4:
                        reader.readMessage(message.valueAssetInfo, () => message.valueAssetInfo = TransactionCoreETH.AssetInfo.deserialize(reader));
                        break;
                    case 5:
                        reader.readMessage(message.inputs, () => pb_1.Message.addToRepeatedWrapperField(message, 5, TransactionCoreETH.TransferableInput.deserialize(reader), TransactionCoreETH.TransferableInput));
                        break;
                    case 6:
                        reader.readMessage(message.outputs, () => pb_1.Message.addToRepeatedWrapperField(message, 6, TransactionCoreETH.ImportTx.EVMOutput.deserialize(reader), TransactionCoreETH.ImportTx.EVMOutput));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ImportTx {
            return ImportTx.deserialize(bytes);
        }
    }
    export namespace ImportTx {
        export type AsObject = {
            blockchainId: string;
            sourceBlockchainId: string;
            valueAssetId: string;
            valueAssetInfo?: TransactionCoreETH.AssetInfo.AsObject;
            inputs: TransactionCoreETH.TransferableInput.AsObject[];
            outputs: TransactionCoreETH.ImportTx.EVMOutput.AsObject[];
        };
        export type AsObjectPartial = {
            blockchainId?: string;
            sourceBlockchainId?: string;
            valueAssetId?: string;
            valueAssetInfo?: TransactionCoreETH.AssetInfo.AsObjectPartial;
            inputs?: TransactionCoreETH.TransferableInput.AsObjectPartial[];
            outputs?: TransactionCoreETH.ImportTx.EVMOutput.AsObjectPartial[];
        };
        export class EVMOutput extends pb_1.Message {
            #one_of_decls: number[][] = [];
            constructor(data?: any[] | {
                address?: string;
                amount?: string;
                assetId?: string;
                assetInfo?: TransactionCoreETH.AssetInfo;
            }) {
                super();
                pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
                if (!Array.isArray(data) && typeof data == "object") {
                    if ("address" in data && data.address != undefined) {
                        this.address = data.address;
                    }
                    if ("amount" in data && data.amount != undefined) {
                        this.amount = data.amount;
                    }
                    if ("assetId" in data && data.assetId != undefined) {
                        this.assetId = data.assetId;
                    }
                    if ("assetInfo" in data && data.assetInfo != undefined) {
                        this.assetInfo = data.assetInfo;
                    }
                }
            }
            get address() {
                return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
            }
            set address(value: string) {
                pb_1.Message.setField(this, 1, value);
            }
            get amount() {
                return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
            }
            set amount(value: string) {
                pb_1.Message.setField(this, 2, value);
            }
            get assetId() {
                return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
            }
            set assetId(value: string) {
                pb_1.Message.setField(this, 3, value);
            }
            get assetInfo() {
                return pb_1.Message.getWrapperField(this, TransactionCoreETH.AssetInfo, 4) as TransactionCoreETH.AssetInfo | undefined;
            }
            set assetInfo(value: TransactionCoreETH.AssetInfo | undefined) {
                pb_1.Message.setWrapperField(this, 4, value);
            }
            get hasAssetInfo() {
                return pb_1.Message.getField(this, 4) != null;
            }
            clearAssetInfo(): void {
                this.assetInfo = undefined!;
            }
            static fromObject(data?: EVMOutput.AsObjectPartial): EVMOutput {
                if (!data) {
                    return new EVMOutput();
                }
                const message = new EVMOutput({});
                if (data.address != null) {
                    message.address = data.address;
                }
                if (data.amount != null) {
                    message.amount = data.amount;
                }
                if (data.assetId != null) {
                    message.assetId = data.assetId;
                }
                if (data.assetInfo != null) {
                    message.assetInfo = TransactionCoreETH.AssetInfo.fromObject(data.assetInfo);
                }
                return message;
            }
            toObject() {
                const data: EVMOutput.AsObject = {
                    address: this.address,
                    amount: this.amount,
                    assetId: this.assetId
                };
                if (this.assetInfo != null) {
                    data.assetInfo = this.assetInfo.toObject();
                }
                return data;
            }
            serialize(): Uint8Array;
            serialize(w: pb_1.BinaryWriter): void;
            serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
                const writer = w || new pb_1.BinaryWriter();
                if (this.address.length)
                    writer.writeString(1, this.address);
                if (this.amount.length)
                    writer.writeString(2, this.amount);
                if (this.assetId.length)
                    writer.writeString(3, this.assetId);
                if (this.hasAssetInfo)
                    writer.writeMessage(4, this.assetInfo, () => this.assetInfo!.serialize(writer));
                if (!w)
                    return writer.getResultBuffer();
            }
            static deserialize(bytes: Uint8Array | pb_1.BinaryReader): EVMOutput {
                const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new EVMOutput();
                while (reader.nextField()) {
                    if (reader.isEndGroup())
                        break;
                    switch (reader.getFieldNumber()) {
                        case 1:
                            message.address = reader.readString();
                            break;
                        case 2:
                            message.amount = reader.readString();
                            break;
                        case 3:
                            message.assetId = reader.readString();
                            break;
                        case 4:
                            reader.readMessage(message.assetInfo, () => message.assetInfo = TransactionCoreETH.AssetInfo.deserialize(reader));
                            break;
                        default: reader.skipField();
                    }
                }
                return message;
            }
            serializeBinary(): Uint8Array {
                return this.serialize();
            }
            static override deserializeBinary(bytes: Uint8Array): EVMOutput {
                return EVMOutput.deserialize(bytes);
            }
        }
        export namespace EVMOutput {
            export type AsObject = {
                address: string;
                amount: string;
                assetId: string;
                assetInfo?: TransactionCoreETH.AssetInfo.AsObject;
            };
            export type AsObjectPartial = {
                address?: string;
                amount?: string;
                assetId?: string;
                assetInfo?: TransactionCoreETH.AssetInfo.AsObjectPartial;
            };
        }
    }
}
export class TransactionShortCoreETH extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3]];
    constructor(data?: any[] | ({
        kind?: dependency_2.CoreETHTransactionKind;
    } & (({
        export?: TransactionShortCoreETH.ExportTx;
        import?: never;
    } | {
        export?: never;
        import?: TransactionShortCoreETH.ImportTx;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("kind" in data && data.kind != undefined) {
                this.kind = data.kind;
            }
            if ("export" in data && data.export != undefined) {
                this.export = data.export;
            }
            if ("import" in data && data.import != undefined) {
                this.import = data.import;
            }
        }
    }
    get kind() {
        return pb_1.Message.getFieldWithDefault(this, 1, dependency_2.CoreETHTransactionKind.CoreETHTransactionKindExport) as dependency_2.CoreETHTransactionKind;
    }
    set kind(value: dependency_2.CoreETHTransactionKind) {
        pb_1.Message.setField(this, 1, value);
    }
    get export() {
        return pb_1.Message.getWrapperField(this, TransactionShortCoreETH.ExportTx, 2) as TransactionShortCoreETH.ExportTx | undefined;
    }
    set export(value: TransactionShortCoreETH.ExportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasExport() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearExport(): void {
        this.export = undefined!;
    }
    get import() {
        return pb_1.Message.getWrapperField(this, TransactionShortCoreETH.ImportTx, 3) as TransactionShortCoreETH.ImportTx | undefined;
    }
    set import(value: TransactionShortCoreETH.ImportTx | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasImport() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearImport(): void {
        this.import = undefined!;
    }
    get details() {
        const cases: {
            [index: number]: "none" | "export" | "import";
        } = {
            0: "none",
            2: "export",
            3: "import"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3])]!;
    }
    static fromObject(data?: TransactionShortCoreETH.AsObjectPartial): TransactionShortCoreETH {
        if (!data) {
            return new TransactionShortCoreETH();
        }
        const message = new TransactionShortCoreETH({});
        if (data.kind != null) {
            message.kind = data.kind;
        }
        if (data.export != null) {
            message.export = TransactionShortCoreETH.ExportTx.fromObject(data.export);
        }
        if (data.import != null) {
            message.import = TransactionShortCoreETH.ImportTx.fromObject(data.import);
        }
        return message;
    }
    toObject() {
        const data: TransactionShortCoreETH.AsObject = {
            kind: this.kind
        };
        if (this.export != null) {
            data.export = this.export.toObject();
        }
        if (this.import != null) {
            data.import = this.import.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.kind != dependency_2.CoreETHTransactionKind.CoreETHTransactionKindExport)
            writer.writeEnum(1, this.kind);
        if (this.hasExport)
            writer.writeMessage(2, this.export, () => this.export!.serialize(writer));
        if (this.hasImport)
            writer.writeMessage(3, this.import, () => this.import!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionShortCoreETH {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionShortCoreETH();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.kind = reader.readEnum();
                    break;
                case 2:
                    reader.readMessage(message.export, () => message.export = TransactionShortCoreETH.ExportTx.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.import, () => message.import = TransactionShortCoreETH.ImportTx.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionShortCoreETH {
        return TransactionShortCoreETH.deserialize(bytes);
    }
}
export namespace TransactionShortCoreETH {
    export type AsObject = {
        kind: dependency_2.CoreETHTransactionKind;
        export?: TransactionShortCoreETH.ExportTx.AsObject;
        import?: TransactionShortCoreETH.ImportTx.AsObject;
    };
    export type AsObjectPartial = {
        kind?: dependency_2.CoreETHTransactionKind;
        export?: TransactionShortCoreETH.ExportTx.AsObjectPartial;
        import?: TransactionShortCoreETH.ImportTx.AsObjectPartial;
    };
    export class AssetInfo extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            id?: string;
            symbol?: string;
            denomination?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
                if ("symbol" in data && data.symbol != undefined) {
                    this.symbol = data.symbol;
                }
                if ("denomination" in data && data.denomination != undefined) {
                    this.denomination = data.denomination;
                }
            }
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get symbol() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set symbol(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get denomination() {
            return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
        }
        set denomination(value: number) {
            pb_1.Message.setField(this, 3, value);
        }
        static fromObject(data?: AssetInfo.AsObjectPartial): AssetInfo {
            if (!data) {
                return new AssetInfo();
            }
            const message = new AssetInfo({});
            if (data.id != null) {
                message.id = data.id;
            }
            if (data.symbol != null) {
                message.symbol = data.symbol;
            }
            if (data.denomination != null) {
                message.denomination = data.denomination;
            }
            return message;
        }
        toObject() {
            const data: AssetInfo.AsObject = {
                id: this.id,
                symbol: this.symbol,
                denomination: this.denomination
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.id.length)
                writer.writeString(1, this.id);
            if (this.symbol.length)
                writer.writeString(2, this.symbol);
            if (this.denomination != 0)
                writer.writeInt32(3, this.denomination);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetInfo {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetInfo();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.id = reader.readString();
                        break;
                    case 2:
                        message.symbol = reader.readString();
                        break;
                    case 3:
                        message.denomination = reader.readInt32();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AssetInfo {
            return AssetInfo.deserialize(bytes);
        }
    }
    export namespace AssetInfo {
        export type AsObject = {
            id: string;
            symbol: string;
            denomination: number;
        };
        export type AsObjectPartial = {
            id?: string;
            symbol?: string;
            denomination?: number;
        };
    }
    export class ExportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            blockchainId?: string;
            destinationBlockchainId?: string;
            valueAssetInfo?: TransactionShortCoreETH.AssetInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("blockchainId" in data && data.blockchainId != undefined) {
                    this.blockchainId = data.blockchainId;
                }
                if ("destinationBlockchainId" in data && data.destinationBlockchainId != undefined) {
                    this.destinationBlockchainId = data.destinationBlockchainId;
                }
                if ("valueAssetInfo" in data && data.valueAssetInfo != undefined) {
                    this.valueAssetInfo = data.valueAssetInfo;
                }
            }
        }
        get blockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set blockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get destinationBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set destinationBlockchainId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get valueAssetInfo() {
            return pb_1.Message.getWrapperField(this, TransactionShortCoreETH.AssetInfo, 3) as TransactionShortCoreETH.AssetInfo | undefined;
        }
        set valueAssetInfo(value: TransactionShortCoreETH.AssetInfo | undefined) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasValueAssetInfo() {
            return pb_1.Message.getField(this, 3) != null;
        }
        clearValueAssetInfo(): void {
            this.valueAssetInfo = undefined!;
        }
        static fromObject(data?: ExportTx.AsObjectPartial): ExportTx {
            if (!data) {
                return new ExportTx();
            }
            const message = new ExportTx({});
            if (data.blockchainId != null) {
                message.blockchainId = data.blockchainId;
            }
            if (data.destinationBlockchainId != null) {
                message.destinationBlockchainId = data.destinationBlockchainId;
            }
            if (data.valueAssetInfo != null) {
                message.valueAssetInfo = TransactionShortCoreETH.AssetInfo.fromObject(data.valueAssetInfo);
            }
            return message;
        }
        toObject() {
            const data: ExportTx.AsObject = {
                blockchainId: this.blockchainId,
                destinationBlockchainId: this.destinationBlockchainId
            };
            if (this.valueAssetInfo != null) {
                data.valueAssetInfo = this.valueAssetInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.blockchainId.length)
                writer.writeString(1, this.blockchainId);
            if (this.destinationBlockchainId.length)
                writer.writeString(2, this.destinationBlockchainId);
            if (this.hasValueAssetInfo)
                writer.writeMessage(3, this.valueAssetInfo, () => this.valueAssetInfo!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ExportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ExportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.blockchainId = reader.readString();
                        break;
                    case 2:
                        message.destinationBlockchainId = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.valueAssetInfo, () => message.valueAssetInfo = TransactionShortCoreETH.AssetInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ExportTx {
            return ExportTx.deserialize(bytes);
        }
    }
    export namespace ExportTx {
        export type AsObject = {
            blockchainId: string;
            destinationBlockchainId: string;
            valueAssetInfo?: TransactionShortCoreETH.AssetInfo.AsObject;
        };
        export type AsObjectPartial = {
            blockchainId?: string;
            destinationBlockchainId?: string;
            valueAssetInfo?: TransactionShortCoreETH.AssetInfo.AsObjectPartial;
        };
    }
    export class ImportTx extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            blockchainId?: string;
            sourceBlockchainId?: string;
            valueAssetInfo?: TransactionShortCoreETH.AssetInfo;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("blockchainId" in data && data.blockchainId != undefined) {
                    this.blockchainId = data.blockchainId;
                }
                if ("sourceBlockchainId" in data && data.sourceBlockchainId != undefined) {
                    this.sourceBlockchainId = data.sourceBlockchainId;
                }
                if ("valueAssetInfo" in data && data.valueAssetInfo != undefined) {
                    this.valueAssetInfo = data.valueAssetInfo;
                }
            }
        }
        get blockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set blockchainId(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get sourceBlockchainId() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set sourceBlockchainId(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        get valueAssetInfo() {
            return pb_1.Message.getWrapperField(this, TransactionShortCoreETH.AssetInfo, 3) as TransactionShortCoreETH.AssetInfo | undefined;
        }
        set valueAssetInfo(value: TransactionShortCoreETH.AssetInfo | undefined) {
            pb_1.Message.setWrapperField(this, 3, value);
        }
        get hasValueAssetInfo() {
            return pb_1.Message.getField(this, 3) != null;
        }
        clearValueAssetInfo(): void {
            this.valueAssetInfo = undefined!;
        }
        static fromObject(data?: ImportTx.AsObjectPartial): ImportTx {
            if (!data) {
                return new ImportTx();
            }
            const message = new ImportTx({});
            if (data.blockchainId != null) {
                message.blockchainId = data.blockchainId;
            }
            if (data.sourceBlockchainId != null) {
                message.sourceBlockchainId = data.sourceBlockchainId;
            }
            if (data.valueAssetInfo != null) {
                message.valueAssetInfo = TransactionShortCoreETH.AssetInfo.fromObject(data.valueAssetInfo);
            }
            return message;
        }
        toObject() {
            const data: ImportTx.AsObject = {
                blockchainId: this.blockchainId,
                sourceBlockchainId: this.sourceBlockchainId
            };
            if (this.valueAssetInfo != null) {
                data.valueAssetInfo = this.valueAssetInfo.toObject();
            }
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.blockchainId.length)
                writer.writeString(1, this.blockchainId);
            if (this.sourceBlockchainId.length)
                writer.writeString(2, this.sourceBlockchainId);
            if (this.hasValueAssetInfo)
                writer.writeMessage(3, this.valueAssetInfo, () => this.valueAssetInfo!.serialize(writer));
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): ImportTx {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new ImportTx();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.blockchainId = reader.readString();
                        break;
                    case 2:
                        message.sourceBlockchainId = reader.readString();
                        break;
                    case 3:
                        reader.readMessage(message.valueAssetInfo, () => message.valueAssetInfo = TransactionShortCoreETH.AssetInfo.deserialize(reader));
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): ImportTx {
            return ImportTx.deserialize(bytes);
        }
    }
    export namespace ImportTx {
        export type AsObject = {
            blockchainId: string;
            sourceBlockchainId: string;
            valueAssetInfo?: TransactionShortCoreETH.AssetInfo.AsObject;
        };
        export type AsObjectPartial = {
            blockchainId?: string;
            sourceBlockchainId?: string;
            valueAssetInfo?: TransactionShortCoreETH.AssetInfo.AsObjectPartial;
        };
    }
}
export class TransactionShort extends pb_1.Message {
    #one_of_decls: number[][] = [[11, 12, 13, 18]];
    constructor(data?: any[] | ({
        index?: number;
        id?: string;
        indexInBc?: number;
        blockchainId?: string;
        blockchainIndex?: number;
        blockId?: string;
        blockIndex?: number;
        blockIndexInBc?: number;
        indexInBlock?: number;
        timestamp?: number;
        from?: string[];
        to?: string[];
        value?: string;
        fee?: string;
    } & (({
        avm?: TransactionShortAVM;
        pvm?: never;
        evm?: never;
        coreEth?: never;
    } | {
        avm?: never;
        pvm?: TransactionShortPVM;
        evm?: never;
        coreEth?: never;
    } | {
        avm?: never;
        pvm?: never;
        evm?: TransactionShortEVM;
        coreEth?: never;
    } | {
        avm?: never;
        pvm?: never;
        evm?: never;
        coreEth?: TransactionShortCoreETH;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [14, 15], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("indexInBc" in data && data.indexInBc != undefined) {
                this.indexInBc = data.indexInBc;
            }
            if ("blockchainId" in data && data.blockchainId != undefined) {
                this.blockchainId = data.blockchainId;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("blockId" in data && data.blockId != undefined) {
                this.blockId = data.blockId;
            }
            if ("blockIndex" in data && data.blockIndex != undefined) {
                this.blockIndex = data.blockIndex;
            }
            if ("blockIndexInBc" in data && data.blockIndexInBc != undefined) {
                this.blockIndexInBc = data.blockIndexInBc;
            }
            if ("indexInBlock" in data && data.indexInBlock != undefined) {
                this.indexInBlock = data.indexInBlock;
            }
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
            if ("from" in data && data.from != undefined) {
                this.from = data.from;
            }
            if ("to" in data && data.to != undefined) {
                this.to = data.to;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
            if ("fee" in data && data.fee != undefined) {
                this.fee = data.fee;
            }
            if ("avm" in data && data.avm != undefined) {
                this.avm = data.avm;
            }
            if ("pvm" in data && data.pvm != undefined) {
                this.pvm = data.pvm;
            }
            if ("evm" in data && data.evm != undefined) {
                this.evm = data.evm;
            }
            if ("coreEth" in data && data.coreEth != undefined) {
                this.coreEth = data.coreEth;
            }
        }
    }
    get index() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set index(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get indexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set indexInBc(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get blockchainId() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set blockchainId(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get blockId() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set blockId(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get blockIndex() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set blockIndex(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get blockIndexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set blockIndexInBc(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    get indexInBlock() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set indexInBlock(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get from() {
        return pb_1.Message.getFieldWithDefault(this, 14, []) as string[];
    }
    set from(value: string[]) {
        pb_1.Message.setField(this, 14, value);
    }
    get to() {
        return pb_1.Message.getFieldWithDefault(this, 15, []) as string[];
    }
    set to(value: string[]) {
        pb_1.Message.setField(this, 15, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 16, value);
    }
    get fee() {
        return pb_1.Message.getFieldWithDefault(this, 17, "") as string;
    }
    set fee(value: string) {
        pb_1.Message.setField(this, 17, value);
    }
    get avm() {
        return pb_1.Message.getWrapperField(this, TransactionShortAVM, 11) as TransactionShortAVM | undefined;
    }
    set avm(value: TransactionShortAVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0]!, value);
    }
    get hasAvm() {
        return pb_1.Message.getField(this, 11) != null;
    }
    clearAvm(): void {
        this.avm = undefined!;
    }
    get pvm() {
        return pb_1.Message.getWrapperField(this, TransactionShortPVM, 12) as TransactionShortPVM | undefined;
    }
    set pvm(value: TransactionShortPVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0]!, value);
    }
    get hasPvm() {
        return pb_1.Message.getField(this, 12) != null;
    }
    clearPvm(): void {
        this.pvm = undefined!;
    }
    get evm() {
        return pb_1.Message.getWrapperField(this, TransactionShortEVM, 13) as TransactionShortEVM | undefined;
    }
    set evm(value: TransactionShortEVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0]!, value);
    }
    get hasEvm() {
        return pb_1.Message.getField(this, 13) != null;
    }
    clearEvm(): void {
        this.evm = undefined!;
    }
    get coreEth() {
        return pb_1.Message.getWrapperField(this, TransactionShortCoreETH, 18) as TransactionShortCoreETH | undefined;
    }
    set coreEth(value: TransactionShortCoreETH | undefined) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0]!, value);
    }
    get hasCoreEth() {
        return pb_1.Message.getField(this, 18) != null;
    }
    clearCoreEth(): void {
        this.coreEth = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "avm" | "pvm" | "evm" | "coreEth";
        } = {
            0: "none",
            11: "avm",
            12: "pvm",
            13: "evm",
            18: "coreEth"
        };
        return cases[pb_1.Message.computeOneofCase(this, [11, 12, 13, 18])]!;
    }
    static fromObject(data?: TransactionShort.AsObjectPartial): TransactionShort {
        if (!data) {
            return new TransactionShort();
        }
        const message = new TransactionShort({});
        if (data.index != null) {
            message.index = data.index;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.indexInBc != null) {
            message.indexInBc = data.indexInBc;
        }
        if (data.blockchainId != null) {
            message.blockchainId = data.blockchainId;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.blockId != null) {
            message.blockId = data.blockId;
        }
        if (data.blockIndex != null) {
            message.blockIndex = data.blockIndex;
        }
        if (data.blockIndexInBc != null) {
            message.blockIndexInBc = data.blockIndexInBc;
        }
        if (data.indexInBlock != null) {
            message.indexInBlock = data.indexInBlock;
        }
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        if (data.from != null) {
            message.from = data.from;
        }
        if (data.to != null) {
            message.to = data.to;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        if (data.fee != null) {
            message.fee = data.fee;
        }
        if (data.avm != null) {
            message.avm = TransactionShortAVM.fromObject(data.avm);
        }
        if (data.pvm != null) {
            message.pvm = TransactionShortPVM.fromObject(data.pvm);
        }
        if (data.evm != null) {
            message.evm = TransactionShortEVM.fromObject(data.evm);
        }
        if (data.coreEth != null) {
            message.coreEth = TransactionShortCoreETH.fromObject(data.coreEth);
        }
        return message;
    }
    toObject() {
        const data: TransactionShort.AsObject = {
            index: this.index,
            id: this.id,
            indexInBc: this.indexInBc,
            blockchainId: this.blockchainId,
            blockchainIndex: this.blockchainIndex,
            blockId: this.blockId,
            blockIndex: this.blockIndex,
            blockIndexInBc: this.blockIndexInBc,
            indexInBlock: this.indexInBlock,
            timestamp: this.timestamp,
            from: this.from,
            to: this.to,
            value: this.value,
            fee: this.fee
        };
        if (this.avm != null) {
            data.avm = this.avm.toObject();
        }
        if (this.pvm != null) {
            data.pvm = this.pvm.toObject();
        }
        if (this.evm != null) {
            data.evm = this.evm.toObject();
        }
        if (this.coreEth != null) {
            data.coreEth = this.coreEth.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.index != 0)
            writer.writeInt64(1, this.index);
        if (this.id.length)
            writer.writeString(2, this.id);
        if (this.indexInBc != 0)
            writer.writeInt64(3, this.indexInBc);
        if (this.blockchainId.length)
            writer.writeString(4, this.blockchainId);
        if (this.blockchainIndex != 0)
            writer.writeInt64(5, this.blockchainIndex);
        if (this.blockId.length)
            writer.writeString(6, this.blockId);
        if (this.blockIndex != 0)
            writer.writeInt64(7, this.blockIndex);
        if (this.blockIndexInBc != 0)
            writer.writeInt64(8, this.blockIndexInBc);
        if (this.indexInBlock != 0)
            writer.writeInt64(9, this.indexInBlock);
        if (this.timestamp != 0)
            writer.writeUint64(10, this.timestamp);
        if (this.from.length)
            writer.writeRepeatedString(14, this.from);
        if (this.to.length)
            writer.writeRepeatedString(15, this.to);
        if (this.value.length)
            writer.writeString(16, this.value);
        if (this.fee.length)
            writer.writeString(17, this.fee);
        if (this.hasAvm)
            writer.writeMessage(11, this.avm, () => this.avm!.serialize(writer));
        if (this.hasPvm)
            writer.writeMessage(12, this.pvm, () => this.pvm!.serialize(writer));
        if (this.hasEvm)
            writer.writeMessage(13, this.evm, () => this.evm!.serialize(writer));
        if (this.hasCoreEth)
            writer.writeMessage(18, this.coreEth, () => this.coreEth!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionShort {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionShort();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.index = reader.readInt64();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.indexInBc = reader.readInt64();
                    break;
                case 4:
                    message.blockchainId = reader.readString();
                    break;
                case 5:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 6:
                    message.blockId = reader.readString();
                    break;
                case 7:
                    message.blockIndex = reader.readInt64();
                    break;
                case 8:
                    message.blockIndexInBc = reader.readInt64();
                    break;
                case 9:
                    message.indexInBlock = reader.readInt64();
                    break;
                case 10:
                    message.timestamp = reader.readUint64();
                    break;
                case 14:
                    pb_1.Message.addToRepeatedField(message, 14, reader.readString());
                    break;
                case 15:
                    pb_1.Message.addToRepeatedField(message, 15, reader.readString());
                    break;
                case 16:
                    message.value = reader.readString();
                    break;
                case 17:
                    message.fee = reader.readString();
                    break;
                case 11:
                    reader.readMessage(message.avm, () => message.avm = TransactionShortAVM.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.pvm, () => message.pvm = TransactionShortPVM.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.evm, () => message.evm = TransactionShortEVM.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.coreEth, () => message.coreEth = TransactionShortCoreETH.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionShort {
        return TransactionShort.deserialize(bytes);
    }
}
export namespace TransactionShort {
    export type AsObject = {
        index: number;
        id: string;
        indexInBc: number;
        blockchainId: string;
        blockchainIndex: number;
        blockId: string;
        blockIndex: number;
        blockIndexInBc: number;
        indexInBlock: number;
        timestamp: number;
        from: string[];
        to: string[];
        value: string;
        fee: string;
        avm?: TransactionShortAVM.AsObject;
        pvm?: TransactionShortPVM.AsObject;
        evm?: TransactionShortEVM.AsObject;
        coreEth?: TransactionShortCoreETH.AsObject;
    };
    export type AsObjectPartial = {
        index?: number;
        id?: string;
        indexInBc?: number;
        blockchainId?: string;
        blockchainIndex?: number;
        blockId?: string;
        blockIndex?: number;
        blockIndexInBc?: number;
        indexInBlock?: number;
        timestamp?: number;
        from?: string[];
        to?: string[];
        value?: string;
        fee?: string;
        avm?: TransactionShortAVM.AsObjectPartial;
        pvm?: TransactionShortPVM.AsObjectPartial;
        evm?: TransactionShortEVM.AsObjectPartial;
        coreEth?: TransactionShortCoreETH.AsObjectPartial;
    };
}
export class Transaction extends pb_1.Message {
    #one_of_decls: number[][] = [[11, 12, 13, 18]];
    constructor(data?: any[] | ({
        index?: number;
        id?: string;
        indexInBc?: number;
        blockchainId?: string;
        blockchainIndex?: number;
        blockId?: string;
        blockIndex?: number;
        blockIndexInBc?: number;
        indexInBlock?: number;
        timestamp?: number;
        from?: string[];
        to?: string[];
        value?: string;
        fee?: string;
    } & (({
        avm?: TransactionAVM;
        pvm?: never;
        evm?: never;
        coreEth?: never;
    } | {
        avm?: never;
        pvm?: TransactionPVM;
        evm?: never;
        coreEth?: never;
    } | {
        avm?: never;
        pvm?: never;
        evm?: TransactionEVM;
        coreEth?: never;
    } | {
        avm?: never;
        pvm?: never;
        evm?: never;
        coreEth?: TransactionCoreETH;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [14, 15], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("indexInBc" in data && data.indexInBc != undefined) {
                this.indexInBc = data.indexInBc;
            }
            if ("blockchainId" in data && data.blockchainId != undefined) {
                this.blockchainId = data.blockchainId;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("blockId" in data && data.blockId != undefined) {
                this.blockId = data.blockId;
            }
            if ("blockIndex" in data && data.blockIndex != undefined) {
                this.blockIndex = data.blockIndex;
            }
            if ("blockIndexInBc" in data && data.blockIndexInBc != undefined) {
                this.blockIndexInBc = data.blockIndexInBc;
            }
            if ("indexInBlock" in data && data.indexInBlock != undefined) {
                this.indexInBlock = data.indexInBlock;
            }
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
            if ("from" in data && data.from != undefined) {
                this.from = data.from;
            }
            if ("to" in data && data.to != undefined) {
                this.to = data.to;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
            if ("fee" in data && data.fee != undefined) {
                this.fee = data.fee;
            }
            if ("avm" in data && data.avm != undefined) {
                this.avm = data.avm;
            }
            if ("pvm" in data && data.pvm != undefined) {
                this.pvm = data.pvm;
            }
            if ("evm" in data && data.evm != undefined) {
                this.evm = data.evm;
            }
            if ("coreEth" in data && data.coreEth != undefined) {
                this.coreEth = data.coreEth;
            }
        }
    }
    get index() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set index(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get indexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set indexInBc(value: number) {
        pb_1.Message.setField(this, 3, value);
    }
    get blockchainId() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set blockchainId(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    get blockId() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set blockId(value: string) {
        pb_1.Message.setField(this, 6, value);
    }
    get blockIndex() {
        return pb_1.Message.getFieldWithDefault(this, 7, 0) as number;
    }
    set blockIndex(value: number) {
        pb_1.Message.setField(this, 7, value);
    }
    get blockIndexInBc() {
        return pb_1.Message.getFieldWithDefault(this, 8, 0) as number;
    }
    set blockIndexInBc(value: number) {
        pb_1.Message.setField(this, 8, value);
    }
    get indexInBlock() {
        return pb_1.Message.getFieldWithDefault(this, 9, 0) as number;
    }
    set indexInBlock(value: number) {
        pb_1.Message.setField(this, 9, value);
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 10, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 10, value);
    }
    get from() {
        return pb_1.Message.getFieldWithDefault(this, 14, []) as string[];
    }
    set from(value: string[]) {
        pb_1.Message.setField(this, 14, value);
    }
    get to() {
        return pb_1.Message.getFieldWithDefault(this, 15, []) as string[];
    }
    set to(value: string[]) {
        pb_1.Message.setField(this, 15, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 16, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 16, value);
    }
    get fee() {
        return pb_1.Message.getFieldWithDefault(this, 17, "") as string;
    }
    set fee(value: string) {
        pb_1.Message.setField(this, 17, value);
    }
    get avm() {
        return pb_1.Message.getWrapperField(this, TransactionAVM, 11) as TransactionAVM | undefined;
    }
    set avm(value: TransactionAVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 11, this.#one_of_decls[0]!, value);
    }
    get hasAvm() {
        return pb_1.Message.getField(this, 11) != null;
    }
    clearAvm(): void {
        this.avm = undefined!;
    }
    get pvm() {
        return pb_1.Message.getWrapperField(this, TransactionPVM, 12) as TransactionPVM | undefined;
    }
    set pvm(value: TransactionPVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 12, this.#one_of_decls[0]!, value);
    }
    get hasPvm() {
        return pb_1.Message.getField(this, 12) != null;
    }
    clearPvm(): void {
        this.pvm = undefined!;
    }
    get evm() {
        return pb_1.Message.getWrapperField(this, TransactionEVM, 13) as TransactionEVM | undefined;
    }
    set evm(value: TransactionEVM | undefined) {
        pb_1.Message.setOneofWrapperField(this, 13, this.#one_of_decls[0]!, value);
    }
    get hasEvm() {
        return pb_1.Message.getField(this, 13) != null;
    }
    clearEvm(): void {
        this.evm = undefined!;
    }
    get coreEth() {
        return pb_1.Message.getWrapperField(this, TransactionCoreETH, 18) as TransactionCoreETH | undefined;
    }
    set coreEth(value: TransactionCoreETH | undefined) {
        pb_1.Message.setOneofWrapperField(this, 18, this.#one_of_decls[0]!, value);
    }
    get hasCoreEth() {
        return pb_1.Message.getField(this, 18) != null;
    }
    clearCoreEth(): void {
        this.coreEth = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "avm" | "pvm" | "evm" | "coreEth";
        } = {
            0: "none",
            11: "avm",
            12: "pvm",
            13: "evm",
            18: "coreEth"
        };
        return cases[pb_1.Message.computeOneofCase(this, [11, 12, 13, 18])]!;
    }
    static fromObject(data?: Transaction.AsObjectPartial): Transaction {
        if (!data) {
            return new Transaction();
        }
        const message = new Transaction({});
        if (data.index != null) {
            message.index = data.index;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.indexInBc != null) {
            message.indexInBc = data.indexInBc;
        }
        if (data.blockchainId != null) {
            message.blockchainId = data.blockchainId;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.blockId != null) {
            message.blockId = data.blockId;
        }
        if (data.blockIndex != null) {
            message.blockIndex = data.blockIndex;
        }
        if (data.blockIndexInBc != null) {
            message.blockIndexInBc = data.blockIndexInBc;
        }
        if (data.indexInBlock != null) {
            message.indexInBlock = data.indexInBlock;
        }
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        if (data.from != null) {
            message.from = data.from;
        }
        if (data.to != null) {
            message.to = data.to;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        if (data.fee != null) {
            message.fee = data.fee;
        }
        if (data.avm != null) {
            message.avm = TransactionAVM.fromObject(data.avm);
        }
        if (data.pvm != null) {
            message.pvm = TransactionPVM.fromObject(data.pvm);
        }
        if (data.evm != null) {
            message.evm = TransactionEVM.fromObject(data.evm);
        }
        if (data.coreEth != null) {
            message.coreEth = TransactionCoreETH.fromObject(data.coreEth);
        }
        return message;
    }
    toObject() {
        const data: Transaction.AsObject = {
            index: this.index,
            id: this.id,
            indexInBc: this.indexInBc,
            blockchainId: this.blockchainId,
            blockchainIndex: this.blockchainIndex,
            blockId: this.blockId,
            blockIndex: this.blockIndex,
            blockIndexInBc: this.blockIndexInBc,
            indexInBlock: this.indexInBlock,
            timestamp: this.timestamp,
            from: this.from,
            to: this.to,
            value: this.value,
            fee: this.fee
        };
        if (this.avm != null) {
            data.avm = this.avm.toObject();
        }
        if (this.pvm != null) {
            data.pvm = this.pvm.toObject();
        }
        if (this.evm != null) {
            data.evm = this.evm.toObject();
        }
        if (this.coreEth != null) {
            data.coreEth = this.coreEth.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.index != 0)
            writer.writeInt64(1, this.index);
        if (this.id.length)
            writer.writeString(2, this.id);
        if (this.indexInBc != 0)
            writer.writeInt64(3, this.indexInBc);
        if (this.blockchainId.length)
            writer.writeString(4, this.blockchainId);
        if (this.blockchainIndex != 0)
            writer.writeInt64(5, this.blockchainIndex);
        if (this.blockId.length)
            writer.writeString(6, this.blockId);
        if (this.blockIndex != 0)
            writer.writeInt64(7, this.blockIndex);
        if (this.blockIndexInBc != 0)
            writer.writeInt64(8, this.blockIndexInBc);
        if (this.indexInBlock != 0)
            writer.writeInt64(9, this.indexInBlock);
        if (this.timestamp != 0)
            writer.writeUint64(10, this.timestamp);
        if (this.from.length)
            writer.writeRepeatedString(14, this.from);
        if (this.to.length)
            writer.writeRepeatedString(15, this.to);
        if (this.value.length)
            writer.writeString(16, this.value);
        if (this.fee.length)
            writer.writeString(17, this.fee);
        if (this.hasAvm)
            writer.writeMessage(11, this.avm, () => this.avm!.serialize(writer));
        if (this.hasPvm)
            writer.writeMessage(12, this.pvm, () => this.pvm!.serialize(writer));
        if (this.hasEvm)
            writer.writeMessage(13, this.evm, () => this.evm!.serialize(writer));
        if (this.hasCoreEth)
            writer.writeMessage(18, this.coreEth, () => this.coreEth!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Transaction {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Transaction();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.index = reader.readInt64();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    message.indexInBc = reader.readInt64();
                    break;
                case 4:
                    message.blockchainId = reader.readString();
                    break;
                case 5:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 6:
                    message.blockId = reader.readString();
                    break;
                case 7:
                    message.blockIndex = reader.readInt64();
                    break;
                case 8:
                    message.blockIndexInBc = reader.readInt64();
                    break;
                case 9:
                    message.indexInBlock = reader.readInt64();
                    break;
                case 10:
                    message.timestamp = reader.readUint64();
                    break;
                case 14:
                    pb_1.Message.addToRepeatedField(message, 14, reader.readString());
                    break;
                case 15:
                    pb_1.Message.addToRepeatedField(message, 15, reader.readString());
                    break;
                case 16:
                    message.value = reader.readString();
                    break;
                case 17:
                    message.fee = reader.readString();
                    break;
                case 11:
                    reader.readMessage(message.avm, () => message.avm = TransactionAVM.deserialize(reader));
                    break;
                case 12:
                    reader.readMessage(message.pvm, () => message.pvm = TransactionPVM.deserialize(reader));
                    break;
                case 13:
                    reader.readMessage(message.evm, () => message.evm = TransactionEVM.deserialize(reader));
                    break;
                case 18:
                    reader.readMessage(message.coreEth, () => message.coreEth = TransactionCoreETH.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Transaction {
        return Transaction.deserialize(bytes);
    }
}
export namespace Transaction {
    export type AsObject = {
        index: number;
        id: string;
        indexInBc: number;
        blockchainId: string;
        blockchainIndex: number;
        blockId: string;
        blockIndex: number;
        blockIndexInBc: number;
        indexInBlock: number;
        timestamp: number;
        from: string[];
        to: string[];
        value: string;
        fee: string;
        avm?: TransactionAVM.AsObject;
        pvm?: TransactionPVM.AsObject;
        evm?: TransactionEVM.AsObject;
        coreEth?: TransactionCoreETH.AsObject;
    };
    export type AsObjectPartial = {
        index?: number;
        id?: string;
        indexInBc?: number;
        blockchainId?: string;
        blockchainIndex?: number;
        blockId?: string;
        blockIndex?: number;
        blockIndexInBc?: number;
        indexInBlock?: number;
        timestamp?: number;
        from?: string[];
        to?: string[];
        value?: string;
        fee?: string;
        avm?: TransactionAVM.AsObjectPartial;
        pvm?: TransactionPVM.AsObjectPartial;
        evm?: TransactionEVM.AsObjectPartial;
        coreEth?: TransactionCoreETH.AsObjectPartial;
    };
}
export class TransactionToSave extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transaction?: Transaction;
        logs?: dependency_3.Log[];
        internalTransactions?: dependency_3.InternalTx[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [2, 3], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transaction" in data && data.transaction != undefined) {
                this.transaction = data.transaction;
            }
            if ("logs" in data && data.logs != undefined) {
                this.logs = data.logs;
            }
            if ("internalTransactions" in data && data.internalTransactions != undefined) {
                this.internalTransactions = data.internalTransactions;
            }
        }
    }
    get transaction() {
        return pb_1.Message.getWrapperField(this, Transaction, 1) as Transaction | undefined;
    }
    set transaction(value: Transaction | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasTransaction() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearTransaction(): void {
        this.transaction = undefined!;
    }
    get logs() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_3.Log, 2) as dependency_3.Log[];
    }
    set logs(value: dependency_3.Log[]) {
        pb_1.Message.setRepeatedWrapperField(this, 2, value);
    }
    get internalTransactions() {
        return pb_1.Message.getRepeatedWrapperField(this, dependency_3.InternalTx, 3) as dependency_3.InternalTx[];
    }
    set internalTransactions(value: dependency_3.InternalTx[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    static fromObject(data?: TransactionToSave.AsObjectPartial): TransactionToSave {
        if (!data) {
            return new TransactionToSave();
        }
        const message = new TransactionToSave({});
        if (data.transaction != null) {
            message.transaction = Transaction.fromObject(data.transaction);
        }
        if (data.logs != null) {
            message.logs = data.logs.map(item => dependency_3.Log.fromObject(item));
        }
        if (data.internalTransactions != null) {
            message.internalTransactions = data.internalTransactions.map(item => dependency_3.InternalTx.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: TransactionToSave.AsObject = {
            logs: this.logs.map((item: dependency_3.Log) => item.toObject()),
            internalTransactions: this.internalTransactions.map((item: dependency_3.InternalTx) => item.toObject())
        };
        if (this.transaction != null) {
            data.transaction = this.transaction.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTransaction)
            writer.writeMessage(1, this.transaction, () => this.transaction!.serialize(writer));
        if (this.logs.length)
            writer.writeRepeatedMessage(2, this.logs, (item: dependency_3.Log) => item!.serialize(writer));
        if (this.internalTransactions.length)
            writer.writeRepeatedMessage(3, this.internalTransactions, (item: dependency_3.InternalTx) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionToSave {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionToSave();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transaction, () => message.transaction = Transaction.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.logs, () => pb_1.Message.addToRepeatedWrapperField(message, 2, dependency_3.Log.deserialize(reader), dependency_3.Log));
                    break;
                case 3:
                    reader.readMessage(message.internalTransactions, () => pb_1.Message.addToRepeatedWrapperField(message, 3, dependency_3.InternalTx.deserialize(reader), dependency_3.InternalTx));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionToSave {
        return TransactionToSave.deserialize(bytes);
    }
}
export namespace TransactionToSave {
    export type AsObject = {
        transaction?: Transaction.AsObject;
        logs: dependency_3.Log.AsObject[];
        internalTransactions: dependency_3.InternalTx.AsObject[];
    };
    export type AsObjectPartial = {
        transaction?: Transaction.AsObjectPartial;
        logs?: dependency_3.Log.AsObjectPartial[];
        internalTransactions?: dependency_3.InternalTx.AsObjectPartial[];
    };
}
export class TransactionCountRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1]];
    constructor(data?: any[] | ({} & (({
        address?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "address";
        } = {
            0: "none",
            1: "address"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1])]!;
    }
    static fromObject(data?: TransactionCountRequest.AsObjectPartial): TransactionCountRequest {
        if (!data) {
            return new TransactionCountRequest();
        }
        const message = new TransactionCountRequest({});
        if (data.address != null) {
            message.address = data.address;
        }
        return message;
    }
    toObject() {
        const data: TransactionCountRequest.AsObject = {
            address: this.address
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAddress)
            writer.writeString(1, this.address);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionCountRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionCountRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionCountRequest {
        return TransactionCountRequest.deserialize(bytes);
    }
}
export namespace TransactionCountRequest {
    export type AsObject = {
        address: string;
    };
    export type AsObjectPartial = {
        address?: string;
    };
}
export class TransactionCountResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        count?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("count" in data && data.count != undefined) {
                this.count = data.count;
            }
        }
    }
    get count() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set count(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data?: TransactionCountResponse.AsObjectPartial): TransactionCountResponse {
        if (!data) {
            return new TransactionCountResponse();
        }
        const message = new TransactionCountResponse({});
        if (data.count != null) {
            message.count = data.count;
        }
        return message;
    }
    toObject() {
        const data: TransactionCountResponse.AsObject = {
            count: this.count
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.count != 0)
            writer.writeInt64(1, this.count);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionCountResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionCountResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.count = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionCountResponse {
        return TransactionCountResponse.deserialize(bytes);
    }
}
export namespace TransactionCountResponse {
    export type AsObject = {
        count: number;
    };
    export type AsObjectPartial = {
        count?: number;
    };
}
export class TransactionListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 2, 3, 4, 5, 6, 7, 10, 11]];
    constructor(data?: any[] | ({
        page?: dependency_2.Page;
    } & (({
        all?: boolean;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: never;
        assetId?: never;
    } | {
        all?: never;
        latestSingle?: boolean;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: never;
        assetId?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: boolean;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: never;
        assetId?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: string;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: never;
        assetId?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: TransactionListRequest.IndexSearch;
        blockId?: never;
        blockIndex?: never;
        address?: never;
        assetId?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: string;
        blockIndex?: never;
        address?: never;
        assetId?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: TransactionListRequest.IndexSearch;
        address?: never;
        assetId?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: string;
        assetId?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: never;
        assetId?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("latestSingle" in data && data.latestSingle != undefined) {
                this.latestSingle = data.latestSingle;
            }
            if ("latestMultiple" in data && data.latestMultiple != undefined) {
                this.latestMultiple = data.latestMultiple;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("blockId" in data && data.blockId != undefined) {
                this.blockId = data.blockId;
            }
            if ("blockIndex" in data && data.blockIndex != undefined) {
                this.blockIndex = data.blockIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("assetId" in data && data.assetId != undefined) {
                this.assetId = data.assetId;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 1, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get latestSingle() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set latestSingle(value: boolean) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasLatestSingle() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearLatestSingle(): void {
        this.latestSingle = undefined!;
    }
    get latestMultiple() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set latestMultiple(value: boolean) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasLatestMultiple() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearLatestMultiple(): void {
        this.latestMultiple = undefined!;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get index() {
        return pb_1.Message.getWrapperField(this, TransactionListRequest.IndexSearch, 5) as TransactionListRequest.IndexSearch | undefined;
    }
    set index(value: TransactionListRequest.IndexSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasIndex() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearIndex(): void {
        this.index = undefined!;
    }
    get blockId() {
        return pb_1.Message.getFieldWithDefault(this, 6, "") as string;
    }
    set blockId(value: string) {
        pb_1.Message.setOneofField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasBlockId() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearBlockId(): void {
        this.blockId = undefined!;
    }
    get blockIndex() {
        return pb_1.Message.getWrapperField(this, TransactionListRequest.IndexSearch, 7) as TransactionListRequest.IndexSearch | undefined;
    }
    set blockIndex(value: TransactionListRequest.IndexSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasBlockIndex() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearBlockIndex(): void {
        this.blockIndex = undefined!;
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 10, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 10) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get assetId() {
        return pb_1.Message.getFieldWithDefault(this, 11, "") as string;
    }
    set assetId(value: string) {
        pb_1.Message.setOneofField(this, 11, this.#one_of_decls[0]!, value);
    }
    get hasAssetId() {
        return pb_1.Message.getField(this, 11) != null;
    }
    clearAssetId(): void {
        this.assetId = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 8) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 8, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 8) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "latestSingle" | "latestMultiple" | "id" | "index" | "blockId" | "blockIndex" | "address" | "assetId";
        } = {
            0: "none",
            1: "all",
            2: "latestSingle",
            3: "latestMultiple",
            4: "id",
            5: "index",
            6: "blockId",
            7: "blockIndex",
            10: "address",
            11: "assetId"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 2, 3, 4, 5, 6, 7, 10, 11])]!;
    }
    static fromObject(data?: TransactionListRequest.AsObjectPartial): TransactionListRequest {
        if (!data) {
            return new TransactionListRequest();
        }
        const message = new TransactionListRequest({});
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.latestSingle != null) {
            message.latestSingle = data.latestSingle;
        }
        if (data.latestMultiple != null) {
            message.latestMultiple = data.latestMultiple;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.index != null) {
            message.index = TransactionListRequest.IndexSearch.fromObject(data.index);
        }
        if (data.blockId != null) {
            message.blockId = data.blockId;
        }
        if (data.blockIndex != null) {
            message.blockIndex = TransactionListRequest.IndexSearch.fromObject(data.blockIndex);
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.assetId != null) {
            message.assetId = data.assetId;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: TransactionListRequest.AsObject = {
            all: this.all,
            latestSingle: this.latestSingle,
            latestMultiple: this.latestMultiple,
            id: this.id,
            blockId: this.blockId,
            address: this.address,
            assetId: this.assetId
        };
        if (this.index != null) {
            data.index = this.index.toObject();
        }
        if (this.blockIndex != null) {
            data.blockIndex = this.blockIndex.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAll)
            writer.writeBool(1, this.all);
        if (this.hasLatestSingle)
            writer.writeBool(2, this.latestSingle);
        if (this.hasLatestMultiple)
            writer.writeBool(3, this.latestMultiple);
        if (this.hasId)
            writer.writeString(4, this.id);
        if (this.hasIndex)
            writer.writeMessage(5, this.index, () => this.index!.serialize(writer));
        if (this.hasBlockId)
            writer.writeString(6, this.blockId);
        if (this.hasBlockIndex)
            writer.writeMessage(7, this.blockIndex, () => this.blockIndex!.serialize(writer));
        if (this.hasAddress)
            writer.writeString(10, this.address);
        if (this.hasAssetId)
            writer.writeString(11, this.assetId);
        if (this.hasPage)
            writer.writeMessage(8, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.all = reader.readBool();
                    break;
                case 2:
                    message.latestSingle = reader.readBool();
                    break;
                case 3:
                    message.latestMultiple = reader.readBool();
                    break;
                case 4:
                    message.id = reader.readString();
                    break;
                case 5:
                    reader.readMessage(message.index, () => message.index = TransactionListRequest.IndexSearch.deserialize(reader));
                    break;
                case 6:
                    message.blockId = reader.readString();
                    break;
                case 7:
                    reader.readMessage(message.blockIndex, () => message.blockIndex = TransactionListRequest.IndexSearch.deserialize(reader));
                    break;
                case 10:
                    message.address = reader.readString();
                    break;
                case 11:
                    message.assetId = reader.readString();
                    break;
                case 8:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionListRequest {
        return TransactionListRequest.deserialize(bytes);
    }
}
export namespace TransactionListRequest {
    export type AsObject = {
        all: boolean;
        latestSingle: boolean;
        latestMultiple: boolean;
        id: string;
        index?: TransactionListRequest.IndexSearch.AsObject;
        blockId: string;
        blockIndex?: TransactionListRequest.IndexSearch.AsObject;
        address: string;
        assetId: string;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        all?: boolean;
        latestSingle?: boolean;
        latestMultiple?: boolean;
        id?: string;
        index?: TransactionListRequest.IndexSearch.AsObjectPartial;
        blockId?: string;
        blockIndex?: TransactionListRequest.IndexSearch.AsObjectPartial;
        address?: string;
        assetId?: string;
        page?: dependency_2.Page.AsObjectPartial;
    };
    export class IndexSearch extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            index?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
            }
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: IndexSearch.AsObjectPartial): IndexSearch {
            if (!data) {
                return new IndexSearch();
            }
            const message = new IndexSearch({});
            if (data.index != null) {
                message.index = data.index;
            }
            return message;
        }
        toObject() {
            const data: IndexSearch.AsObject = {
                index: this.index
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.index != 0)
                writer.writeInt64(1, this.index);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IndexSearch {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IndexSearch();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.index = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IndexSearch {
            return IndexSearch.deserialize(bytes);
        }
    }
    export namespace IndexSearch {
        export type AsObject = {
            index: number;
        };
        export type AsObjectPartial = {
            index?: number;
        };
    }
}
export class TransactionListForWalletRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[1, 4]];
    constructor(data?: any[] | ({
        page?: dependency_2.Page;
        blockchainIndices?: number[];
    } & (({
        address?: string;
        id?: never;
    } | {
        address?: never;
        id?: TransactionListForWalletRequest.IdSearch;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
            if ("blockchainIndices" in data && data.blockchainIndices != undefined) {
                this.blockchainIndices = data.blockchainIndices;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 1, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get id() {
        return pb_1.Message.getWrapperField(this, TransactionListForWalletRequest.IdSearch, 4) as TransactionListForWalletRequest.IdSearch | undefined;
    }
    set id(value: TransactionListForWalletRequest.IdSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 2) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get blockchainIndices() {
        return pb_1.Message.getFieldWithDefault(this, 3, []) as number[];
    }
    set blockchainIndices(value: number[]) {
        pb_1.Message.setField(this, 3, value);
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "address" | "id";
        } = {
            0: "none",
            1: "address",
            4: "id"
        };
        return cases[pb_1.Message.computeOneofCase(this, [1, 4])]!;
    }
    static fromObject(data?: TransactionListForWalletRequest.AsObjectPartial): TransactionListForWalletRequest {
        if (!data) {
            return new TransactionListForWalletRequest();
        }
        const message = new TransactionListForWalletRequest({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.id != null) {
            message.id = TransactionListForWalletRequest.IdSearch.fromObject(data.id);
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        if (data.blockchainIndices != null) {
            message.blockchainIndices = data.blockchainIndices;
        }
        return message;
    }
    toObject() {
        const data: TransactionListForWalletRequest.AsObject = {
            address: this.address,
            blockchainIndices: this.blockchainIndices
        };
        if (this.id != null) {
            data.id = this.id.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAddress)
            writer.writeString(1, this.address);
        if (this.hasId)
            writer.writeMessage(4, this.id, () => this.id!.serialize(writer));
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (this.blockchainIndices.length)
            writer.writePackedInt64(3, this.blockchainIndices);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionListForWalletRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionListForWalletRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 4:
                    reader.readMessage(message.id, () => message.id = TransactionListForWalletRequest.IdSearch.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                case 3:
                    message.blockchainIndices = reader.readPackedInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionListForWalletRequest {
        return TransactionListForWalletRequest.deserialize(bytes);
    }
}
export namespace TransactionListForWalletRequest {
    export type AsObject = {
        address: string;
        id?: TransactionListForWalletRequest.IdSearch.AsObject;
        page?: dependency_2.Page.AsObject;
        blockchainIndices: number[];
    };
    export type AsObjectPartial = {
        address?: string;
        id?: TransactionListForWalletRequest.IdSearch.AsObjectPartial;
        page?: dependency_2.Page.AsObjectPartial;
        blockchainIndices?: number[];
    };
    export class IdSearch extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            address?: string;
            id?: string;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("address" in data && data.address != undefined) {
                    this.address = data.address;
                }
                if ("id" in data && data.id != undefined) {
                    this.id = data.id;
                }
            }
        }
        get address() {
            return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
        }
        set address(value: string) {
            pb_1.Message.setField(this, 1, value);
        }
        get id() {
            return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
        }
        set id(value: string) {
            pb_1.Message.setField(this, 2, value);
        }
        static fromObject(data?: IdSearch.AsObjectPartial): IdSearch {
            if (!data) {
                return new IdSearch();
            }
            const message = new IdSearch({});
            if (data.address != null) {
                message.address = data.address;
            }
            if (data.id != null) {
                message.id = data.id;
            }
            return message;
        }
        toObject() {
            const data: IdSearch.AsObject = {
                address: this.address,
                id: this.id
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.address.length)
                writer.writeString(1, this.address);
            if (this.id.length)
                writer.writeString(2, this.id);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IdSearch {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IdSearch();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.address = reader.readString();
                        break;
                    case 2:
                        message.id = reader.readString();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IdSearch {
            return IdSearch.deserialize(bytes);
        }
    }
    export namespace IdSearch {
        export type AsObject = {
            address: string;
            id: string;
        };
        export type AsObjectPartial = {
            address?: string;
            id?: string;
        };
    }
}
export class TransactionListForWalletByIndexListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        indicesInBlockchain?: number[];
        blockchainIndex?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("indicesInBlockchain" in data && data.indicesInBlockchain != undefined) {
                this.indicesInBlockchain = data.indicesInBlockchain;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
        }
    }
    get indicesInBlockchain() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as number[];
    }
    set indicesInBlockchain(value: number[]) {
        pb_1.Message.setField(this, 1, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: TransactionListForWalletByIndexListRequest.AsObjectPartial): TransactionListForWalletByIndexListRequest {
        if (!data) {
            return new TransactionListForWalletByIndexListRequest();
        }
        const message = new TransactionListForWalletByIndexListRequest({});
        if (data.indicesInBlockchain != null) {
            message.indicesInBlockchain = data.indicesInBlockchain;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        return message;
    }
    toObject() {
        const data: TransactionListForWalletByIndexListRequest.AsObject = {
            indicesInBlockchain: this.indicesInBlockchain,
            blockchainIndex: this.blockchainIndex
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.indicesInBlockchain.length)
            writer.writePackedInt64(1, this.indicesInBlockchain);
        if (this.blockchainIndex != 0)
            writer.writeInt64(2, this.blockchainIndex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionListForWalletByIndexListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionListForWalletByIndexListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.indicesInBlockchain = reader.readPackedInt64();
                    break;
                case 2:
                    message.blockchainIndex = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionListForWalletByIndexListRequest {
        return TransactionListForWalletByIndexListRequest.deserialize(bytes);
    }
}
export namespace TransactionListForWalletByIndexListRequest {
    export type AsObject = {
        indicesInBlockchain: number[];
        blockchainIndex: number;
    };
    export type AsObjectPartial = {
        indicesInBlockchain?: number[];
        blockchainIndex?: number;
    };
}
export class TransactionListForChainRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4, 5, 6, 7, 8, 10]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        page?: dependency_2.Page;
    } & (({
        all?: boolean;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: never;
    } | {
        all?: never;
        latestSingle?: boolean;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: boolean;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: string;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: TransactionListForChainRequest.IndexSearch;
        blockId?: never;
        blockIndex?: never;
        address?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: string;
        blockIndex?: never;
        address?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: TransactionListForChainRequest.IndexSearch;
        address?: never;
    } | {
        all?: never;
        latestSingle?: never;
        latestMultiple?: never;
        id?: never;
        index?: never;
        blockId?: never;
        blockIndex?: never;
        address?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("latestSingle" in data && data.latestSingle != undefined) {
                this.latestSingle = data.latestSingle;
            }
            if ("latestMultiple" in data && data.latestMultiple != undefined) {
                this.latestMultiple = data.latestMultiple;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
            if ("blockId" in data && data.blockId != undefined) {
                this.blockId = data.blockId;
            }
            if ("blockIndex" in data && data.blockIndex != undefined) {
                this.blockIndex = data.blockIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get latestSingle() {
        return pb_1.Message.getFieldWithDefault(this, 3, false) as boolean;
    }
    set latestSingle(value: boolean) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasLatestSingle() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearLatestSingle(): void {
        this.latestSingle = undefined!;
    }
    get latestMultiple() {
        return pb_1.Message.getFieldWithDefault(this, 4, false) as boolean;
    }
    set latestMultiple(value: boolean) {
        pb_1.Message.setOneofField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasLatestMultiple() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearLatestMultiple(): void {
        this.latestMultiple = undefined!;
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 5, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 5) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get index() {
        return pb_1.Message.getWrapperField(this, TransactionListForChainRequest.IndexSearch, 6) as TransactionListForChainRequest.IndexSearch | undefined;
    }
    set index(value: TransactionListForChainRequest.IndexSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 6, this.#one_of_decls[0]!, value);
    }
    get hasIndex() {
        return pb_1.Message.getField(this, 6) != null;
    }
    clearIndex(): void {
        this.index = undefined!;
    }
    get blockId() {
        return pb_1.Message.getFieldWithDefault(this, 7, "") as string;
    }
    set blockId(value: string) {
        pb_1.Message.setOneofField(this, 7, this.#one_of_decls[0]!, value);
    }
    get hasBlockId() {
        return pb_1.Message.getField(this, 7) != null;
    }
    clearBlockId(): void {
        this.blockId = undefined!;
    }
    get blockIndex() {
        return pb_1.Message.getWrapperField(this, TransactionListForChainRequest.IndexSearch, 8) as TransactionListForChainRequest.IndexSearch | undefined;
    }
    set blockIndex(value: TransactionListForChainRequest.IndexSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 8, this.#one_of_decls[0]!, value);
    }
    get hasBlockIndex() {
        return pb_1.Message.getField(this, 8) != null;
    }
    clearBlockIndex(): void {
        this.blockIndex = undefined!;
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 10, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 10, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 10) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 9) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 9, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 9) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "latestSingle" | "latestMultiple" | "id" | "index" | "blockId" | "blockIndex" | "address";
        } = {
            0: "none",
            2: "all",
            3: "latestSingle",
            4: "latestMultiple",
            5: "id",
            6: "index",
            7: "blockId",
            8: "blockIndex",
            10: "address"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4, 5, 6, 7, 8, 10])]!;
    }
    static fromObject(data?: TransactionListForChainRequest.AsObjectPartial): TransactionListForChainRequest {
        if (!data) {
            return new TransactionListForChainRequest();
        }
        const message = new TransactionListForChainRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.latestSingle != null) {
            message.latestSingle = data.latestSingle;
        }
        if (data.latestMultiple != null) {
            message.latestMultiple = data.latestMultiple;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.index != null) {
            message.index = TransactionListForChainRequest.IndexSearch.fromObject(data.index);
        }
        if (data.blockId != null) {
            message.blockId = data.blockId;
        }
        if (data.blockIndex != null) {
            message.blockIndex = TransactionListForChainRequest.IndexSearch.fromObject(data.blockIndex);
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: TransactionListForChainRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            all: this.all,
            latestSingle: this.latestSingle,
            latestMultiple: this.latestMultiple,
            id: this.id,
            blockId: this.blockId,
            address: this.address
        };
        if (this.index != null) {
            data.index = this.index.toObject();
        }
        if (this.blockIndex != null) {
            data.blockIndex = this.blockIndex.toObject();
        }
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasAll)
            writer.writeBool(2, this.all);
        if (this.hasLatestSingle)
            writer.writeBool(3, this.latestSingle);
        if (this.hasLatestMultiple)
            writer.writeBool(4, this.latestMultiple);
        if (this.hasId)
            writer.writeString(5, this.id);
        if (this.hasIndex)
            writer.writeMessage(6, this.index, () => this.index!.serialize(writer));
        if (this.hasBlockId)
            writer.writeString(7, this.blockId);
        if (this.hasBlockIndex)
            writer.writeMessage(8, this.blockIndex, () => this.blockIndex!.serialize(writer));
        if (this.hasAddress)
            writer.writeString(10, this.address);
        if (this.hasPage)
            writer.writeMessage(9, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionListForChainRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionListForChainRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.all = reader.readBool();
                    break;
                case 3:
                    message.latestSingle = reader.readBool();
                    break;
                case 4:
                    message.latestMultiple = reader.readBool();
                    break;
                case 5:
                    message.id = reader.readString();
                    break;
                case 6:
                    reader.readMessage(message.index, () => message.index = TransactionListForChainRequest.IndexSearch.deserialize(reader));
                    break;
                case 7:
                    message.blockId = reader.readString();
                    break;
                case 8:
                    reader.readMessage(message.blockIndex, () => message.blockIndex = TransactionListForChainRequest.IndexSearch.deserialize(reader));
                    break;
                case 10:
                    message.address = reader.readString();
                    break;
                case 9:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionListForChainRequest {
        return TransactionListForChainRequest.deserialize(bytes);
    }
}
export namespace TransactionListForChainRequest {
    export type AsObject = {
        blockchainIndex: number;
        all: boolean;
        latestSingle: boolean;
        latestMultiple: boolean;
        id: string;
        index?: TransactionListForChainRequest.IndexSearch.AsObject;
        blockId: string;
        blockIndex?: TransactionListForChainRequest.IndexSearch.AsObject;
        address: string;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        all?: boolean;
        latestSingle?: boolean;
        latestMultiple?: boolean;
        id?: string;
        index?: TransactionListForChainRequest.IndexSearch.AsObjectPartial;
        blockId?: string;
        blockIndex?: TransactionListForChainRequest.IndexSearch.AsObjectPartial;
        address?: string;
        page?: dependency_2.Page.AsObjectPartial;
    };
    export class IndexSearch extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            index?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
            }
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: IndexSearch.AsObjectPartial): IndexSearch {
            if (!data) {
                return new IndexSearch();
            }
            const message = new IndexSearch({});
            if (data.index != null) {
                message.index = data.index;
            }
            return message;
        }
        toObject() {
            const data: IndexSearch.AsObject = {
                index: this.index
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.index != 0)
                writer.writeInt64(1, this.index);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IndexSearch {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IndexSearch();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.index = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IndexSearch {
            return IndexSearch.deserialize(bytes);
        }
    }
    export namespace IndexSearch {
        export type AsObject = {
            index: number;
        };
        export type AsObjectPartial = {
            index?: number;
        };
    }
}
export class TransactionListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transactions?: TransactionShort[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get transactions() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionShort, 1) as TransactionShort[];
    }
    set transactions(value: TransactionShort[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: TransactionListResponse.AsObjectPartial): TransactionListResponse {
        if (!data) {
            return new TransactionListResponse();
        }
        const message = new TransactionListResponse({});
        if (data.transactions != null) {
            message.transactions = data.transactions.map(item => TransactionShort.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: TransactionListResponse.AsObject = {
            transactions: this.transactions.map((item: TransactionShort) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transactions.length)
            writer.writeRepeatedMessage(1, this.transactions, (item: TransactionShort) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TransactionShort.deserialize(reader), TransactionShort));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionListResponse {
        return TransactionListResponse.deserialize(bytes);
    }
}
export namespace TransactionListResponse {
    export type AsObject = {
        transactions: TransactionShort.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        transactions?: TransactionShort.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class TransactionForWallet extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transaction?: Transaction;
        assetBalanceDeltas?: Map<string, string>;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transaction" in data && data.transaction != undefined) {
                this.transaction = data.transaction;
            }
            if ("assetBalanceDeltas" in data && data.assetBalanceDeltas != undefined) {
                this.assetBalanceDeltas = data.assetBalanceDeltas;
            }
        }
        if (!this.assetBalanceDeltas)
            this.assetBalanceDeltas = new Map();
    }
    get transaction() {
        return pb_1.Message.getWrapperField(this, Transaction, 1) as Transaction | undefined;
    }
    set transaction(value: Transaction | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasTransaction() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearTransaction(): void {
        this.transaction = undefined!;
    }
    get assetBalanceDeltas() {
        return pb_1.Message.getField(this, 2) as any as Map<string, string>;
    }
    set assetBalanceDeltas(value: Map<string, string>) {
        pb_1.Message.setField(this, 2, value as any);
    }
    static fromObject(data?: TransactionForWallet.AsObjectPartial): TransactionForWallet {
        if (!data) {
            return new TransactionForWallet();
        }
        const message = new TransactionForWallet({});
        if (data.transaction != null) {
            message.transaction = Transaction.fromObject(data.transaction);
        }
        if (typeof data.assetBalanceDeltas == "object") {
            message.assetBalanceDeltas = new Map(Object.entries(data.assetBalanceDeltas));
        }
        return message;
    }
    toObject() {
        const data: TransactionForWallet.AsObject = {
            assetBalanceDeltas: (Object.fromEntries)(this.assetBalanceDeltas)
        };
        if (this.transaction != null) {
            data.transaction = this.transaction.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTransaction)
            writer.writeMessage(1, this.transaction, () => this.transaction!.serialize(writer));
        for (const [key, value] of this.assetBalanceDeltas) {
            writer.writeMessage(2, this.assetBalanceDeltas, () => {
                writer.writeString(1, key);
                writer.writeString(2, value);
            });
        }
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionForWallet {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionForWallet();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transaction, () => message.transaction = Transaction.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.assetBalanceDeltas as any, reader, reader.readString, reader.readString));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionForWallet {
        return TransactionForWallet.deserialize(bytes);
    }
}
export namespace TransactionForWallet {
    export type AsObject = {
        transaction?: Transaction.AsObject;
        assetBalanceDeltas: {
            [key: string]: string;
        };
    };
    export type AsObjectPartial = {
        transaction?: Transaction.AsObjectPartial;
        assetBalanceDeltas?: {
            [key: string]: string;
        };
    };
}
export class TransactionListForWalletResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transactions?: TransactionForWallet[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get transactions() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionForWallet, 1) as TransactionForWallet[];
    }
    set transactions(value: TransactionForWallet[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: TransactionListForWalletResponse.AsObjectPartial): TransactionListForWalletResponse {
        if (!data) {
            return new TransactionListForWalletResponse();
        }
        const message = new TransactionListForWalletResponse({});
        if (data.transactions != null) {
            message.transactions = data.transactions.map(item => TransactionForWallet.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: TransactionListForWalletResponse.AsObject = {
            transactions: this.transactions.map((item: TransactionForWallet) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transactions.length)
            writer.writeRepeatedMessage(1, this.transactions, (item: TransactionForWallet) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionListForWalletResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionListForWalletResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TransactionForWallet.deserialize(reader), TransactionForWallet));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionListForWalletResponse {
        return TransactionListForWalletResponse.deserialize(bytes);
    }
}
export namespace TransactionListForWalletResponse {
    export type AsObject = {
        transactions: TransactionForWallet.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        transactions?: TransactionForWallet.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class TransactionListForWalletByIndexListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transactions?: Transaction[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
        }
    }
    get transactions() {
        return pb_1.Message.getRepeatedWrapperField(this, Transaction, 1) as Transaction[];
    }
    set transactions(value: Transaction[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: TransactionListForWalletByIndexListResponse.AsObjectPartial): TransactionListForWalletByIndexListResponse {
        if (!data) {
            return new TransactionListForWalletByIndexListResponse();
        }
        const message = new TransactionListForWalletByIndexListResponse({});
        if (data.transactions != null) {
            message.transactions = data.transactions.map(item => Transaction.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: TransactionListForWalletByIndexListResponse.AsObject = {
            transactions: this.transactions.map((item: Transaction) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transactions.length)
            writer.writeRepeatedMessage(1, this.transactions, (item: Transaction) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionListForWalletByIndexListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionListForWalletByIndexListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Transaction.deserialize(reader), Transaction));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionListForWalletByIndexListResponse {
        return TransactionListForWalletByIndexListResponse.deserialize(bytes);
    }
}
export namespace TransactionListForWalletByIndexListResponse {
    export type AsObject = {
        transactions: Transaction.AsObject[];
    };
    export type AsObjectPartial = {
        transactions?: Transaction.AsObjectPartial[];
    };
}
export class TransactionDetailsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
    } & (({
        id?: string;
        index?: never;
    } | {
        id?: never;
        index?: TransactionDetailsRequest.IndexSearch;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("index" in data && data.index != undefined) {
                this.index = data.index;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set id(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasId() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearId(): void {
        this.id = undefined!;
    }
    get index() {
        return pb_1.Message.getWrapperField(this, TransactionDetailsRequest.IndexSearch, 3) as TransactionDetailsRequest.IndexSearch | undefined;
    }
    set index(value: TransactionDetailsRequest.IndexSearch | undefined) {
        pb_1.Message.setOneofWrapperField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasIndex() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearIndex(): void {
        this.index = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "id" | "index";
        } = {
            0: "none",
            2: "id",
            3: "index"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3])]!;
    }
    static fromObject(data?: TransactionDetailsRequest.AsObjectPartial): TransactionDetailsRequest {
        if (!data) {
            return new TransactionDetailsRequest();
        }
        const message = new TransactionDetailsRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.index != null) {
            message.index = TransactionDetailsRequest.IndexSearch.fromObject(data.index);
        }
        return message;
    }
    toObject() {
        const data: TransactionDetailsRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            id: this.id
        };
        if (this.index != null) {
            data.index = this.index.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasId)
            writer.writeString(2, this.id);
        if (this.hasIndex)
            writer.writeMessage(3, this.index, () => this.index!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionDetailsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionDetailsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.id = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.index, () => message.index = TransactionDetailsRequest.IndexSearch.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionDetailsRequest {
        return TransactionDetailsRequest.deserialize(bytes);
    }
}
export namespace TransactionDetailsRequest {
    export type AsObject = {
        blockchainIndex: number;
        id: string;
        index?: TransactionDetailsRequest.IndexSearch.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        id?: string;
        index?: TransactionDetailsRequest.IndexSearch.AsObjectPartial;
    };
    export class IndexSearch extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            index?: number;
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("index" in data && data.index != undefined) {
                    this.index = data.index;
                }
            }
        }
        get index() {
            return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
        }
        set index(value: number) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: IndexSearch.AsObjectPartial): IndexSearch {
            if (!data) {
                return new IndexSearch();
            }
            const message = new IndexSearch({});
            if (data.index != null) {
                message.index = data.index;
            }
            return message;
        }
        toObject() {
            const data: IndexSearch.AsObject = {
                index: this.index
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.index != 0)
                writer.writeInt64(1, this.index);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): IndexSearch {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new IndexSearch();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        message.index = reader.readInt64();
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): IndexSearch {
            return IndexSearch.deserialize(bytes);
        }
    }
    export namespace IndexSearch {
        export type AsObject = {
            index: number;
        };
        export type AsObjectPartial = {
            index?: number;
        };
    }
}
export class TransactionDetailsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transaction?: Transaction;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transaction" in data && data.transaction != undefined) {
                this.transaction = data.transaction;
            }
        }
    }
    get transaction() {
        return pb_1.Message.getWrapperField(this, Transaction, 1) as Transaction | undefined;
    }
    set transaction(value: Transaction | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasTransaction() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearTransaction(): void {
        this.transaction = undefined!;
    }
    static fromObject(data?: TransactionDetailsResponse.AsObjectPartial): TransactionDetailsResponse {
        if (!data) {
            return new TransactionDetailsResponse();
        }
        const message = new TransactionDetailsResponse({});
        if (data.transaction != null) {
            message.transaction = Transaction.fromObject(data.transaction);
        }
        return message;
    }
    toObject() {
        const data: TransactionDetailsResponse.AsObject = {};
        if (this.transaction != null) {
            data.transaction = this.transaction.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTransaction)
            writer.writeMessage(1, this.transaction, () => this.transaction!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): TransactionDetailsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new TransactionDetailsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transaction, () => message.transaction = Transaction.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): TransactionDetailsResponse {
        return TransactionDetailsResponse.deserialize(bytes);
    }
}
export namespace TransactionDetailsResponse {
    export type AsObject = {
        transaction?: Transaction.AsObject;
    };
    export type AsObjectPartial = {
        transaction?: Transaction.AsObjectPartial;
    };
}
export class SaveTransactionsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transactions?: TransactionToSave[];
        isGenesis?: boolean;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
            if ("isGenesis" in data && data.isGenesis != undefined) {
                this.isGenesis = data.isGenesis;
            }
        }
    }
    get transactions() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionToSave, 1) as TransactionToSave[];
    }
    set transactions(value: TransactionToSave[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get isGenesis() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set isGenesis(value: boolean) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: SaveTransactionsRequest.AsObjectPartial): SaveTransactionsRequest {
        if (!data) {
            return new SaveTransactionsRequest();
        }
        const message = new SaveTransactionsRequest({});
        if (data.transactions != null) {
            message.transactions = data.transactions.map(item => TransactionToSave.fromObject(item));
        }
        if (data.isGenesis != null) {
            message.isGenesis = data.isGenesis;
        }
        return message;
    }
    toObject() {
        const data: SaveTransactionsRequest.AsObject = {
            transactions: this.transactions.map((item: TransactionToSave) => item.toObject()),
            isGenesis: this.isGenesis
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transactions.length)
            writer.writeRepeatedMessage(1, this.transactions, (item: TransactionToSave) => item!.serialize(writer));
        if (this.isGenesis != false)
            writer.writeBool(2, this.isGenesis);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveTransactionsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveTransactionsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TransactionToSave.deserialize(reader), TransactionToSave));
                    break;
                case 2:
                    message.isGenesis = reader.readBool();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SaveTransactionsRequest {
        return SaveTransactionsRequest.deserialize(bytes);
    }
}
export namespace SaveTransactionsRequest {
    export type AsObject = {
        transactions: TransactionToSave.AsObject[];
        isGenesis: boolean;
    };
    export type AsObjectPartial = {
        transactions?: TransactionToSave.AsObjectPartial[];
        isGenesis?: boolean;
    };
}
export class SaveTransactionsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transactions?: TransactionToSave[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
        }
    }
    get transactions() {
        return pb_1.Message.getRepeatedWrapperField(this, TransactionToSave, 1) as TransactionToSave[];
    }
    set transactions(value: TransactionToSave[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: SaveTransactionsResponse.AsObjectPartial): SaveTransactionsResponse {
        if (!data) {
            return new SaveTransactionsResponse();
        }
        const message = new SaveTransactionsResponse({});
        if (data.transactions != null) {
            message.transactions = data.transactions.map(item => TransactionToSave.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: SaveTransactionsResponse.AsObject = {
            transactions: this.transactions.map((item: TransactionToSave) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transactions.length)
            writer.writeRepeatedMessage(1, this.transactions, (item: TransactionToSave) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveTransactionsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveTransactionsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transactions, () => pb_1.Message.addToRepeatedWrapperField(message, 1, TransactionToSave.deserialize(reader), TransactionToSave));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SaveTransactionsResponse {
        return SaveTransactionsResponse.deserialize(bytes);
    }
}
export namespace SaveTransactionsResponse {
    export type AsObject = {
        transactions: TransactionToSave.AsObject[];
    };
    export type AsObjectPartial = {
        transactions?: TransactionToSave.AsObjectPartial[];
    };
}
export class SaveRewardUtxosRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        txid?: string;
        outs?: PVMRewardUTXO[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [3], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("txid" in data && data.txid != undefined) {
                this.txid = data.txid;
            }
            if ("outs" in data && data.outs != undefined) {
                this.outs = data.outs;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get txid() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set txid(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get outs() {
        return pb_1.Message.getRepeatedWrapperField(this, PVMRewardUTXO, 3) as PVMRewardUTXO[];
    }
    set outs(value: PVMRewardUTXO[]) {
        pb_1.Message.setRepeatedWrapperField(this, 3, value);
    }
    static fromObject(data?: SaveRewardUtxosRequest.AsObjectPartial): SaveRewardUtxosRequest {
        if (!data) {
            return new SaveRewardUtxosRequest();
        }
        const message = new SaveRewardUtxosRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.txid != null) {
            message.txid = data.txid;
        }
        if (data.outs != null) {
            message.outs = data.outs.map(item => PVMRewardUTXO.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: SaveRewardUtxosRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            txid: this.txid,
            outs: this.outs.map((item: PVMRewardUTXO) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.txid.length)
            writer.writeString(2, this.txid);
        if (this.outs.length)
            writer.writeRepeatedMessage(3, this.outs, (item: PVMRewardUTXO) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveRewardUtxosRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveRewardUtxosRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.txid = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.outs, () => pb_1.Message.addToRepeatedWrapperField(message, 3, PVMRewardUTXO.deserialize(reader), PVMRewardUTXO));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SaveRewardUtxosRequest {
        return SaveRewardUtxosRequest.deserialize(bytes);
    }
}
export namespace SaveRewardUtxosRequest {
    export type AsObject = {
        blockchainIndex: number;
        txid: string;
        outs: PVMRewardUTXO.AsObject[];
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        txid?: string;
        outs?: PVMRewardUTXO.AsObjectPartial[];
    };
}
export class SaveRewardUtxosResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transaction?: Transaction;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transaction" in data && data.transaction != undefined) {
                this.transaction = data.transaction;
            }
        }
    }
    get transaction() {
        return pb_1.Message.getWrapperField(this, Transaction, 1) as Transaction | undefined;
    }
    set transaction(value: Transaction | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasTransaction() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearTransaction(): void {
        this.transaction = undefined!;
    }
    static fromObject(data?: SaveRewardUtxosResponse.AsObjectPartial): SaveRewardUtxosResponse {
        if (!data) {
            return new SaveRewardUtxosResponse();
        }
        const message = new SaveRewardUtxosResponse({});
        if (data.transaction != null) {
            message.transaction = Transaction.fromObject(data.transaction);
        }
        return message;
    }
    toObject() {
        const data: SaveRewardUtxosResponse.AsObject = {};
        if (this.transaction != null) {
            data.transaction = this.transaction.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasTransaction)
            writer.writeMessage(1, this.transaction, () => this.transaction!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): SaveRewardUtxosResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new SaveRewardUtxosResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transaction, () => message.transaction = Transaction.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): SaveRewardUtxosResponse {
        return SaveRewardUtxosResponse.deserialize(bytes);
    }
}
export namespace SaveRewardUtxosResponse {
    export type AsObject = {
        transaction?: Transaction.AsObject;
    };
    export type AsObjectPartial = {
        transaction?: Transaction.AsObjectPartial;
    };
}
export class XChainCollectedFeesRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data?: XChainCollectedFeesRequest.AsObjectPartial): XChainCollectedFeesRequest {
        if (!data) {
            return new XChainCollectedFeesRequest();
        }
        const message = new XChainCollectedFeesRequest({});
        return message;
    }
    toObject() {
        const data: XChainCollectedFeesRequest.AsObject = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): XChainCollectedFeesRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new XChainCollectedFeesRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): XChainCollectedFeesRequest {
        return XChainCollectedFeesRequest.deserialize(bytes);
    }
}
export namespace XChainCollectedFeesRequest {
    export type AsObject = {};
    export type AsObjectPartial = {};
}
export class XChainCollectedFeesResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        CreateAssetFees?: string;
        ExportFees?: string;
        ImportFees?: string;
        MintFees?: string;
        BaseFees?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("CreateAssetFees" in data && data.CreateAssetFees != undefined) {
                this.CreateAssetFees = data.CreateAssetFees;
            }
            if ("ExportFees" in data && data.ExportFees != undefined) {
                this.ExportFees = data.ExportFees;
            }
            if ("ImportFees" in data && data.ImportFees != undefined) {
                this.ImportFees = data.ImportFees;
            }
            if ("MintFees" in data && data.MintFees != undefined) {
                this.MintFees = data.MintFees;
            }
            if ("BaseFees" in data && data.BaseFees != undefined) {
                this.BaseFees = data.BaseFees;
            }
        }
    }
    get CreateAssetFees() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set CreateAssetFees(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get ExportFees() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set ExportFees(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get ImportFees() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set ImportFees(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get MintFees() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set MintFees(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get BaseFees() {
        return pb_1.Message.getFieldWithDefault(this, 5, "") as string;
    }
    set BaseFees(value: string) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data?: XChainCollectedFeesResponse.AsObjectPartial): XChainCollectedFeesResponse {
        if (!data) {
            return new XChainCollectedFeesResponse();
        }
        const message = new XChainCollectedFeesResponse({});
        if (data.CreateAssetFees != null) {
            message.CreateAssetFees = data.CreateAssetFees;
        }
        if (data.ExportFees != null) {
            message.ExportFees = data.ExportFees;
        }
        if (data.ImportFees != null) {
            message.ImportFees = data.ImportFees;
        }
        if (data.MintFees != null) {
            message.MintFees = data.MintFees;
        }
        if (data.BaseFees != null) {
            message.BaseFees = data.BaseFees;
        }
        return message;
    }
    toObject() {
        const data: XChainCollectedFeesResponse.AsObject = {
            CreateAssetFees: this.CreateAssetFees,
            ExportFees: this.ExportFees,
            ImportFees: this.ImportFees,
            MintFees: this.MintFees,
            BaseFees: this.BaseFees
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.CreateAssetFees.length)
            writer.writeString(1, this.CreateAssetFees);
        if (this.ExportFees.length)
            writer.writeString(2, this.ExportFees);
        if (this.ImportFees.length)
            writer.writeString(3, this.ImportFees);
        if (this.MintFees.length)
            writer.writeString(4, this.MintFees);
        if (this.BaseFees.length)
            writer.writeString(5, this.BaseFees);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): XChainCollectedFeesResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new XChainCollectedFeesResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.CreateAssetFees = reader.readString();
                    break;
                case 2:
                    message.ExportFees = reader.readString();
                    break;
                case 3:
                    message.ImportFees = reader.readString();
                    break;
                case 4:
                    message.MintFees = reader.readString();
                    break;
                case 5:
                    message.BaseFees = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): XChainCollectedFeesResponse {
        return XChainCollectedFeesResponse.deserialize(bytes);
    }
}
export namespace XChainCollectedFeesResponse {
    export type AsObject = {
        CreateAssetFees: string;
        ExportFees: string;
        ImportFees: string;
        MintFees: string;
        BaseFees: string;
    };
    export type AsObjectPartial = {
        CreateAssetFees?: string;
        ExportFees?: string;
        ImportFees?: string;
        MintFees?: string;
        BaseFees?: string;
    };
}
export class NativeTransferListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2]];
    constructor(data?: any[] | ({
        blockchainIndex?: number;
        page?: dependency_2.Page;
    } & (({
        address?: string;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAddress() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAddress(): void {
        this.address = undefined!;
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_2.Page, 3) as dependency_2.Page | undefined;
    }
    set page(value: dependency_2.Page | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "address";
        } = {
            0: "none",
            2: "address"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2])]!;
    }
    static fromObject(data?: NativeTransferListRequest.AsObjectPartial): NativeTransferListRequest {
        if (!data) {
            return new NativeTransferListRequest();
        }
        const message = new NativeTransferListRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.page != null) {
            message.page = dependency_2.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: NativeTransferListRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            address: this.address
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.hasAddress)
            writer.writeString(2, this.address);
        if (this.hasPage)
            writer.writeMessage(3, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NativeTransferListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NativeTransferListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.address = reader.readString();
                    break;
                case 3:
                    reader.readMessage(message.page, () => message.page = dependency_2.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NativeTransferListRequest {
        return NativeTransferListRequest.deserialize(bytes);
    }
}
export namespace NativeTransferListRequest {
    export type AsObject = {
        blockchainIndex: number;
        address: string;
        page?: dependency_2.Page.AsObject;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        address?: string;
        page?: dependency_2.Page.AsObjectPartial;
    };
}
export class NativeTransferListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transfers?: NativeTransferExt[];
        navigation?: dependency_2.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transfers" in data && data.transfers != undefined) {
                this.transfers = data.transfers;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get transfers() {
        return pb_1.Message.getRepeatedWrapperField(this, NativeTransferExt, 1) as NativeTransferExt[];
    }
    set transfers(value: NativeTransferExt[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_2.NavigationData, 2) as dependency_2.NavigationData | undefined;
    }
    set navigation(value: dependency_2.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: NativeTransferListResponse.AsObjectPartial): NativeTransferListResponse {
        if (!data) {
            return new NativeTransferListResponse();
        }
        const message = new NativeTransferListResponse({});
        if (data.transfers != null) {
            message.transfers = data.transfers.map(item => NativeTransferExt.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_2.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: NativeTransferListResponse.AsObject = {
            transfers: this.transfers.map((item: NativeTransferExt) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transfers.length)
            writer.writeRepeatedMessage(1, this.transfers, (item: NativeTransferExt) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NativeTransferListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NativeTransferListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.transfers, () => pb_1.Message.addToRepeatedWrapperField(message, 1, NativeTransferExt.deserialize(reader), NativeTransferExt));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_2.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NativeTransferListResponse {
        return NativeTransferListResponse.deserialize(bytes);
    }
}
export namespace NativeTransferListResponse {
    export type AsObject = {
        transfers: NativeTransferExt.AsObject[];
        navigation?: dependency_2.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        transfers?: NativeTransferExt.AsObjectPartial[];
        navigation?: dependency_2.NavigationData.AsObjectPartial;
    };
}
export class NativeTransferExt extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        txId?: string;
        fromAddress?: string;
        toAddress?: string;
        value?: string;
        timestamp?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("txId" in data && data.txId != undefined) {
                this.txId = data.txId;
            }
            if ("fromAddress" in data && data.fromAddress != undefined) {
                this.fromAddress = data.fromAddress;
            }
            if ("toAddress" in data && data.toAddress != undefined) {
                this.toAddress = data.toAddress;
            }
            if ("value" in data && data.value != undefined) {
                this.value = data.value;
            }
            if ("timestamp" in data && data.timestamp != undefined) {
                this.timestamp = data.timestamp;
            }
        }
    }
    get txId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set txId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get fromAddress() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set fromAddress(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get toAddress() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set toAddress(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get value() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set value(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    get timestamp() {
        return pb_1.Message.getFieldWithDefault(this, 5, 0) as number;
    }
    set timestamp(value: number) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data?: NativeTransferExt.AsObjectPartial): NativeTransferExt {
        if (!data) {
            return new NativeTransferExt();
        }
        const message = new NativeTransferExt({});
        if (data.txId != null) {
            message.txId = data.txId;
        }
        if (data.fromAddress != null) {
            message.fromAddress = data.fromAddress;
        }
        if (data.toAddress != null) {
            message.toAddress = data.toAddress;
        }
        if (data.value != null) {
            message.value = data.value;
        }
        if (data.timestamp != null) {
            message.timestamp = data.timestamp;
        }
        return message;
    }
    toObject() {
        const data: NativeTransferExt.AsObject = {
            txId: this.txId,
            fromAddress: this.fromAddress,
            toAddress: this.toAddress,
            value: this.value,
            timestamp: this.timestamp
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.txId.length)
            writer.writeString(1, this.txId);
        if (this.fromAddress.length)
            writer.writeString(2, this.fromAddress);
        if (this.toAddress.length)
            writer.writeString(3, this.toAddress);
        if (this.value.length)
            writer.writeString(4, this.value);
        if (this.timestamp != 0)
            writer.writeUint64(5, this.timestamp);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): NativeTransferExt {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new NativeTransferExt();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.txId = reader.readString();
                    break;
                case 2:
                    message.fromAddress = reader.readString();
                    break;
                case 3:
                    message.toAddress = reader.readString();
                    break;
                case 4:
                    message.value = reader.readString();
                    break;
                case 5:
                    message.timestamp = reader.readUint64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): NativeTransferExt {
        return NativeTransferExt.deserialize(bytes);
    }
}
export namespace NativeTransferExt {
    export type AsObject = {
        txId: string;
        fromAddress: string;
        toAddress: string;
        value: string;
        timestamp: number;
    };
    export type AsObjectPartial = {
        txId?: string;
        fromAddress?: string;
        toAddress?: string;
        value?: string;
        timestamp?: number;
    };
}
