import { Blockchain, JuneoClient, MainNetwork, MCNProvider } from "juneojs";
import { create } from "zustand";
import LocalStorageController, {
  LOCAL_STORAGE_KEYS,
} from "../controllers/LocalStorageController";

import DEFAULD_NETWORK from "../config/DefaultNetwork";
import { getEnvironment } from "../hooks/useEnvironment";
import NetworkType from "../types/Network";

interface NetworkState {
  network: NetworkType;
  setNetwork: (network: NetworkType) => void;

  networksList: NetworkType[];

  // provider for all app.
  // this provider is linked to the current network.
  provider: MCNProvider;
  setProvider: (provider: MCNProvider) => void;

  // state about available blockchain in the current network
  chainDict: Blockchain[];
  setChainDict: (chainDict: Blockchain[]) => void;

  blockchainSelect: Blockchain;
  setBlockchainSelect: (chain: Blockchain) => void;

  // state for staking config
  MAX_TOTAL_STAKE: bigint;
  setMAX_TOTAL_STAKE: (MAX_TOTAL_STAKE: bigint) => void;

  MIN_TOTAL_STAKE: bigint;
  setMIN_TOTAL_STAKE: (MIN_TOTAL_STAKE: bigint) => void;

  MIN_DELEGATION_STAKE: bigint;
  setMIN_DELEGATION_STAKE: (MIN_DELEGATION_STAKE: bigint) => void;

  MIN_STAKE_DURATION: number;
  setMIN_STAKE_DURATION: (MIN_STAKE_DURATION: number) => void;

  MAX_STAKE_DURATION: number;
  setMAX_STAKE_DURATION: (MAX_STAKE_DURATION: number) => void;

  initializeNetwork: (provider: MCNProvider) => void;
}

const SOCOTRA_NETWORK: NetworkType = {
  name: "Socotra Testnet",
  rpc: "https://rpc.socotra-testnet.network",
  api: "https://socotra.juneoscan.io",
  explorer: "https://socotra.juneoscan.io",
  blockchainIndices: [0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13],
};

export const MAIN_NETWORK: NetworkType = {
  name: "Juneo Mainnet",
  rpc: "https://rpc-private.juneo-mainnet.network",
  api: "https://juneoscan.io",
  explorer: "https://juneoscan.io",
  blockchainIndices: [0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13],
};

export const TEST_NETWORK: NetworkType = {
  name: "test",
  rpc: "https://cluster-1-ovh-beacon-x1.socotra-testnet.network",
  api: "",
  explorer: "",
  blockchainIndices: [],
};

const environment = getEnvironment();
// check if the environment is production
// if it is, set the provider to the mainnet provider
export const getDefaultProvider = () => {
  return new MCNProvider(
    environment === "production" ? MainNetwork : DEFAULD_NETWORK,
    environment === "production"
      ? JuneoClient.parse(MAIN_NETWORK.rpc)
      : undefined,
  );
};

const getDefaultNetworkList = () => {
  return environment === "production"
    ? [MAIN_NETWORK, SOCOTRA_NETWORK]
    : [SOCOTRA_NETWORK, MAIN_NETWORK, TEST_NETWORK];
};

const provider = getDefaultProvider();

/**
 * A Zustand store for managing network-related state and configurations.
 *
 * @typedef {Object} NetworkState
 * @property {string} network - The current network, either MAIN_NETWORK or SOCOTRA_NETWORK.
 * @property {function} setNetwork - Function to set the current network.
 * @property {Array<string>} networksList - List of available networks.
 * @property {MCNProvider} provider - The current provider.
 * @property {function} setProvider - Function to set the current provider.
 * @property {Object} chainDict - Dictionary of chains from the provider.
 * @property {function} setChainDict - Function to set the chain dictionary.
 * @property {bigint} MAX_TOTAL_STAKE - Maximum total stake allowed.
 * @property {function} setMAX_TOTAL_STAKE - Function to set the maximum total stake.
 * @property {bigint} MIN_TOTAL_STAKE - Minimum total stake required.
 * @property {function} setMIN_TOTAL_STAKE - Function to set the minimum total stake.
 * @property {bigint} MIN_DELEGATION_STAKE - Minimum delegation stake required.
 * @property {function} setMIN_DELEGATION_STAKE - Function to set the minimum delegation stake.
 * @property {number} MIN_STAKE_DURATION - Minimum stake duration in days.
 * @property {function} setMIN_STAKE_DURATION - Function to set the minimum stake duration.
 * @property {number} MAX_STAKE_DURATION - Maximum stake duration in days.
 * @property {function} setMAX_STAKE_DURATION - Function to set the maximum stake duration.
 * @property {function} initializeNetwork - Function to initialize the network with a given provider.
 *
 * @param {function} set - Zustand's set function to update the state.
 * @param {function} get - Zustand's get function to retrieve the current state.
 *
 * @returns {NetworkState} The network state and functions to update it.
 */
const useNetworkStore = create<NetworkState>((set, get) => ({
  network: environment === "production" ? MAIN_NETWORK : SOCOTRA_NETWORK,
  setNetwork: (network) => set({ network }),

  networksList: getDefaultNetworkList(),

  // provider
  provider: provider,
  setProvider: (provider) => set({ provider }),

  // chainDict
  chainDict: provider.mcn.primary.chains,
  setChainDict: (chainDict) => set({ chainDict }),

  blockchainSelect: provider.mcn.primary.june,
  setBlockchainSelect: (chain) => set({ blockchainSelect: chain }),

  // state for staking config
  MAX_TOTAL_STAKE: BigInt(provider.platformChain.stakeConfig.maxValidatorStake),
  setMAX_TOTAL_STAKE: (MAX_TOTAL_STAKE) => set({ MAX_TOTAL_STAKE }),

  MIN_TOTAL_STAKE: BigInt(provider.platformChain.stakeConfig.minValidatorStake),
  setMIN_TOTAL_STAKE: (MIN_TOTAL_STAKE) => set({ MIN_TOTAL_STAKE }),

  MIN_DELEGATION_STAKE: BigInt(
    provider.platformChain.stakeConfig.minDelegatorStake,
  ),
  setMIN_DELEGATION_STAKE: (MIN_DELEGATION_STAKE) =>
    set({ MIN_DELEGATION_STAKE }),

  MIN_STAKE_DURATION: Number(
    provider.platformChain.stakeConfig.minStakeDuration / 3600n / 24n,
  ),
  setMIN_STAKE_DURATION: (MIN_STAKE_DURATION) => set({ MIN_STAKE_DURATION }),

  MAX_STAKE_DURATION: Number(
    provider.platformChain.stakeConfig.maxStakeDuration / 3600n / 24n,
  ),
  setMAX_STAKE_DURATION: (MAX_STAKE_DURATION) => set({ MAX_STAKE_DURATION }),

  initializeNetwork: async (provider: MCNProvider) => {
    const {
      setChainDict,
      setMAX_TOTAL_STAKE,
      setMIN_TOTAL_STAKE,
      setMIN_DELEGATION_STAKE,
      setMIN_STAKE_DURATION,
      setBlockchainSelect,
    } = get();

    const chainDict = provider.mcn.primary.chains;
    setChainDict(chainDict);
    // Initialize icons
    setMAX_TOTAL_STAKE(provider.platformChain.stakeConfig.maxValidatorStake);
    setMIN_TOTAL_STAKE(provider.platformChain.stakeConfig.minValidatorStake);
    setMIN_DELEGATION_STAKE(
      provider.platformChain.stakeConfig.minDelegatorStake,
    );
    setMIN_STAKE_DURATION(
      Number(provider.platformChain.stakeConfig.minStakeDuration / 3600n / 24n),
    );

    const id =
      LocalStorageController.getItem<string>(LOCAL_STORAGE_KEYS.chain) ||
      provider.mcn.primary.june.id;
    const chain = chainDict.find((c: Blockchain) => c.id === id);
    if (chain) {
      setBlockchainSelect(chain);
    }
  },
}));

export default useNetworkStore;
