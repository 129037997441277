/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 3.13.0
 * source: asset.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as dependency_1 from "./models";
import * as dependency_2 from "./transaction";
import * as pb_1 from "google-protobuf";
export class Asset extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        assetId?: string;
        name?: string;
        symbol?: string;
        denomination?: number;
        fixedSupply?: boolean;
        blockchainIndex?: number;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("assetId" in data && data.assetId != undefined) {
                this.assetId = data.assetId;
            }
            if ("name" in data && data.name != undefined) {
                this.name = data.name;
            }
            if ("symbol" in data && data.symbol != undefined) {
                this.symbol = data.symbol;
            }
            if ("denomination" in data && data.denomination != undefined) {
                this.denomination = data.denomination;
            }
            if ("fixedSupply" in data && data.fixedSupply != undefined) {
                this.fixedSupply = data.fixedSupply;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
        }
    }
    get assetId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set assetId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get name() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set name(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get symbol() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set symbol(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get denomination() {
        return pb_1.Message.getFieldWithDefault(this, 4, 0) as number;
    }
    set denomination(value: number) {
        pb_1.Message.setField(this, 4, value);
    }
    get fixedSupply() {
        return pb_1.Message.getFieldWithDefault(this, 5, false) as boolean;
    }
    set fixedSupply(value: boolean) {
        pb_1.Message.setField(this, 5, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 6, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 6, value);
    }
    static fromObject(data?: Asset.AsObjectPartial): Asset {
        if (!data) {
            return new Asset();
        }
        const message = new Asset({});
        if (data.assetId != null) {
            message.assetId = data.assetId;
        }
        if (data.name != null) {
            message.name = data.name;
        }
        if (data.symbol != null) {
            message.symbol = data.symbol;
        }
        if (data.denomination != null) {
            message.denomination = data.denomination;
        }
        if (data.fixedSupply != null) {
            message.fixedSupply = data.fixedSupply;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        return message;
    }
    toObject() {
        const data: Asset.AsObject = {
            assetId: this.assetId,
            name: this.name,
            symbol: this.symbol,
            denomination: this.denomination,
            fixedSupply: this.fixedSupply,
            blockchainIndex: this.blockchainIndex
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.assetId.length)
            writer.writeString(1, this.assetId);
        if (this.name.length)
            writer.writeString(2, this.name);
        if (this.symbol.length)
            writer.writeString(3, this.symbol);
        if (this.denomination != 0)
            writer.writeUint32(4, this.denomination);
        if (this.fixedSupply != false)
            writer.writeBool(5, this.fixedSupply);
        if (this.blockchainIndex != 0)
            writer.writeInt64(6, this.blockchainIndex);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Asset {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Asset();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.assetId = reader.readString();
                    break;
                case 2:
                    message.name = reader.readString();
                    break;
                case 3:
                    message.symbol = reader.readString();
                    break;
                case 4:
                    message.denomination = reader.readUint32();
                    break;
                case 5:
                    message.fixedSupply = reader.readBool();
                    break;
                case 6:
                    message.blockchainIndex = reader.readInt64();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Asset {
        return Asset.deserialize(bytes);
    }
}
export namespace Asset {
    export type AsObject = {
        assetId: string;
        name: string;
        symbol: string;
        denomination: number;
        fixedSupply: boolean;
        blockchainIndex: number;
    };
    export type AsObjectPartial = {
        assetId?: string;
        name?: string;
        symbol?: string;
        denomination?: number;
        fixedSupply?: boolean;
        blockchainIndex?: number;
    };
}
export class Balance extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        address?: string;
        blockchainIndex?: number;
        assetId?: string;
        balance?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("address" in data && data.address != undefined) {
                this.address = data.address;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("assetId" in data && data.assetId != undefined) {
                this.assetId = data.assetId;
            }
            if ("balance" in data && data.balance != undefined) {
                this.balance = data.balance;
            }
        }
    }
    get address() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set address(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 2, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 2, value);
    }
    get assetId() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set assetId(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    get balance() {
        return pb_1.Message.getFieldWithDefault(this, 4, "") as string;
    }
    set balance(value: string) {
        pb_1.Message.setField(this, 4, value);
    }
    static fromObject(data?: Balance.AsObjectPartial): Balance {
        if (!data) {
            return new Balance();
        }
        const message = new Balance({});
        if (data.address != null) {
            message.address = data.address;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.assetId != null) {
            message.assetId = data.assetId;
        }
        if (data.balance != null) {
            message.balance = data.balance;
        }
        return message;
    }
    toObject() {
        const data: Balance.AsObject = {
            address: this.address,
            blockchainIndex: this.blockchainIndex,
            assetId: this.assetId,
            balance: this.balance
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.address.length)
            writer.writeString(1, this.address);
        if (this.blockchainIndex != 0)
            writer.writeInt64(2, this.blockchainIndex);
        if (this.assetId.length)
            writer.writeString(3, this.assetId);
        if (this.balance.length)
            writer.writeString(4, this.balance);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Balance {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Balance();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.address = reader.readString();
                    break;
                case 2:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 3:
                    message.assetId = reader.readString();
                    break;
                case 4:
                    message.balance = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): Balance {
        return Balance.deserialize(bytes);
    }
}
export namespace Balance {
    export type AsObject = {
        address: string;
        blockchainIndex: number;
        assetId: string;
        balance: string;
    };
    export type AsObjectPartial = {
        address?: string;
        blockchainIndex?: number;
        assetId?: string;
        balance?: string;
    };
}
export class BalanceList extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        balances?: Balance[];
        navigation?: dependency_1.NavigationData;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("balances" in data && data.balances != undefined) {
                this.balances = data.balances;
            }
            if ("navigation" in data && data.navigation != undefined) {
                this.navigation = data.navigation;
            }
        }
    }
    get balances() {
        return pb_1.Message.getRepeatedWrapperField(this, Balance, 1) as Balance[];
    }
    set balances(value: Balance[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    get navigation() {
        return pb_1.Message.getWrapperField(this, dependency_1.NavigationData, 2) as dependency_1.NavigationData | undefined;
    }
    set navigation(value: dependency_1.NavigationData | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasNavigation() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearNavigation(): void {
        this.navigation = undefined!;
    }
    static fromObject(data?: BalanceList.AsObjectPartial): BalanceList {
        if (!data) {
            return new BalanceList();
        }
        const message = new BalanceList({});
        if (data.balances != null) {
            message.balances = data.balances.map(item => Balance.fromObject(item));
        }
        if (data.navigation != null) {
            message.navigation = dependency_1.NavigationData.fromObject(data.navigation);
        }
        return message;
    }
    toObject() {
        const data: BalanceList.AsObject = {
            balances: this.balances.map((item: Balance) => item.toObject())
        };
        if (this.navigation != null) {
            data.navigation = this.navigation.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.balances.length)
            writer.writeRepeatedMessage(1, this.balances, (item: Balance) => item!.serialize(writer));
        if (this.hasNavigation)
            writer.writeMessage(2, this.navigation, () => this.navigation!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): BalanceList {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new BalanceList();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.balances, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Balance.deserialize(reader), Balance));
                    break;
                case 2:
                    reader.readMessage(message.navigation, () => message.navigation = dependency_1.NavigationData.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): BalanceList {
        return BalanceList.deserialize(bytes);
    }
}
export namespace BalanceList {
    export type AsObject = {
        balances: Balance.AsObject[];
        navigation?: dependency_1.NavigationData.AsObject;
    };
    export type AsObjectPartial = {
        balances?: Balance.AsObjectPartial[];
        navigation?: dependency_1.NavigationData.AsObjectPartial;
    };
}
export class AssetListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [[2, 3, 4]];
    constructor(data?: any[] | ({
        page?: dependency_1.Page;
    } & (({
        all?: boolean;
        blockchainIndex?: never;
        assetIds?: never;
    } | {
        all?: never;
        blockchainIndex?: number;
        assetIds?: never;
    } | {
        all?: never;
        blockchainIndex?: never;
        assetIds?: AssetListRequest.AssetIds;
    })))) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
            if ("all" in data && data.all != undefined) {
                this.all = data.all;
            }
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("assetIds" in data && data.assetIds != undefined) {
                this.assetIds = data.assetIds;
            }
        }
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_1.Page, 1) as dependency_1.Page | undefined;
    }
    set page(value: dependency_1.Page | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    get all() {
        return pb_1.Message.getFieldWithDefault(this, 2, false) as boolean;
    }
    set all(value: boolean) {
        pb_1.Message.setOneofField(this, 2, this.#one_of_decls[0]!, value);
    }
    get hasAll() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearAll(): void {
        this.all = undefined!;
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 3, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setOneofField(this, 3, this.#one_of_decls[0]!, value);
    }
    get hasBlockchainIndex() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearBlockchainIndex(): void {
        this.blockchainIndex = undefined!;
    }
    get assetIds() {
        return pb_1.Message.getWrapperField(this, AssetListRequest.AssetIds, 4) as AssetListRequest.AssetIds | undefined;
    }
    set assetIds(value: AssetListRequest.AssetIds | undefined) {
        pb_1.Message.setOneofWrapperField(this, 4, this.#one_of_decls[0]!, value);
    }
    get hasAssetIds() {
        return pb_1.Message.getField(this, 4) != null;
    }
    clearAssetIds(): void {
        this.assetIds = undefined!;
    }
    get kind() {
        const cases: {
            [index: number]: "none" | "all" | "blockchainIndex" | "assetIds";
        } = {
            0: "none",
            2: "all",
            3: "blockchainIndex",
            4: "assetIds"
        };
        return cases[pb_1.Message.computeOneofCase(this, [2, 3, 4])]!;
    }
    static fromObject(data?: AssetListRequest.AsObjectPartial): AssetListRequest {
        if (!data) {
            return new AssetListRequest();
        }
        const message = new AssetListRequest({});
        if (data.page != null) {
            message.page = dependency_1.Page.fromObject(data.page);
        }
        if (data.all != null) {
            message.all = data.all;
        }
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.assetIds != null) {
            message.assetIds = AssetListRequest.AssetIds.fromObject(data.assetIds);
        }
        return message;
    }
    toObject() {
        const data: AssetListRequest.AsObject = {
            all: this.all,
            blockchainIndex: this.blockchainIndex
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        if (this.assetIds != null) {
            data.assetIds = this.assetIds.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasPage)
            writer.writeMessage(1, this.page, () => this.page!.serialize(writer));
        if (this.hasAll)
            writer.writeBool(2, this.all);
        if (this.hasBlockchainIndex)
            writer.writeInt64(3, this.blockchainIndex);
        if (this.hasAssetIds)
            writer.writeMessage(4, this.assetIds, () => this.assetIds!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.page, () => message.page = dependency_1.Page.deserialize(reader));
                    break;
                case 2:
                    message.all = reader.readBool();
                    break;
                case 3:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 4:
                    reader.readMessage(message.assetIds, () => message.assetIds = AssetListRequest.AssetIds.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AssetListRequest {
        return AssetListRequest.deserialize(bytes);
    }
}
export namespace AssetListRequest {
    export type AsObject = {
        page?: dependency_1.Page.AsObject;
        all: boolean;
        blockchainIndex: number;
        assetIds?: AssetListRequest.AssetIds.AsObject;
    };
    export type AsObjectPartial = {
        page?: dependency_1.Page.AsObjectPartial;
        all?: boolean;
        blockchainIndex?: number;
        assetIds?: AssetListRequest.AssetIds.AsObjectPartial;
    };
    export class AssetIds extends pb_1.Message {
        #one_of_decls: number[][] = [];
        constructor(data?: any[] | {
            assetIds?: string[];
        }) {
            super();
            pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
            if (!Array.isArray(data) && typeof data == "object") {
                if ("assetIds" in data && data.assetIds != undefined) {
                    this.assetIds = data.assetIds;
                }
            }
        }
        get assetIds() {
            return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
        }
        set assetIds(value: string[]) {
            pb_1.Message.setField(this, 1, value);
        }
        static fromObject(data?: AssetIds.AsObjectPartial): AssetIds {
            if (!data) {
                return new AssetIds();
            }
            const message = new AssetIds({});
            if (data.assetIds != null) {
                message.assetIds = data.assetIds;
            }
            return message;
        }
        toObject() {
            const data: AssetIds.AsObject = {
                assetIds: this.assetIds
            };
            return data;
        }
        serialize(): Uint8Array;
        serialize(w: pb_1.BinaryWriter): void;
        serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
            const writer = w || new pb_1.BinaryWriter();
            if (this.assetIds.length)
                writer.writeRepeatedString(1, this.assetIds);
            if (!w)
                return writer.getResultBuffer();
        }
        static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetIds {
            const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetIds();
            while (reader.nextField()) {
                if (reader.isEndGroup())
                    break;
                switch (reader.getFieldNumber()) {
                    case 1:
                        pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                        break;
                    default: reader.skipField();
                }
            }
            return message;
        }
        serializeBinary(): Uint8Array {
            return this.serialize();
        }
        static override deserializeBinary(bytes: Uint8Array): AssetIds {
            return AssetIds.deserialize(bytes);
        }
    }
    export namespace AssetIds {
        export type AsObject = {
            assetIds: string[];
        };
        export type AsObjectPartial = {
            assetIds?: string[];
        };
    }
}
export class AssetListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        list?: Asset[];
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("list" in data && data.list != undefined) {
                this.list = data.list;
            }
        }
    }
    get list() {
        return pb_1.Message.getRepeatedWrapperField(this, Asset, 1) as Asset[];
    }
    set list(value: Asset[]) {
        pb_1.Message.setRepeatedWrapperField(this, 1, value);
    }
    static fromObject(data?: AssetListResponse.AsObjectPartial): AssetListResponse {
        if (!data) {
            return new AssetListResponse();
        }
        const message = new AssetListResponse({});
        if (data.list != null) {
            message.list = data.list.map(item => Asset.fromObject(item));
        }
        return message;
    }
    toObject() {
        const data: AssetListResponse.AsObject = {
            list: this.list.map((item: Asset) => item.toObject())
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.list.length)
            writer.writeRepeatedMessage(1, this.list, (item: Asset) => item!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.list, () => pb_1.Message.addToRepeatedWrapperField(message, 1, Asset.deserialize(reader), Asset));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AssetListResponse {
        return AssetListResponse.deserialize(bytes);
    }
}
export namespace AssetListResponse {
    export type AsObject = {
        list: Asset.AsObject[];
    };
    export type AsObjectPartial = {
        list?: Asset.AsObjectPartial[];
    };
}
export class AssetDataRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        blockchainIndex?: number;
        assetId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("blockchainIndex" in data && data.blockchainIndex != undefined) {
                this.blockchainIndex = data.blockchainIndex;
            }
            if ("assetId" in data && data.assetId != undefined) {
                this.assetId = data.assetId;
            }
        }
    }
    get blockchainIndex() {
        return pb_1.Message.getFieldWithDefault(this, 1, 0) as number;
    }
    set blockchainIndex(value: number) {
        pb_1.Message.setField(this, 1, value);
    }
    get assetId() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set assetId(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data?: AssetDataRequest.AsObjectPartial): AssetDataRequest {
        if (!data) {
            return new AssetDataRequest();
        }
        const message = new AssetDataRequest({});
        if (data.blockchainIndex != null) {
            message.blockchainIndex = data.blockchainIndex;
        }
        if (data.assetId != null) {
            message.assetId = data.assetId;
        }
        return message;
    }
    toObject() {
        const data: AssetDataRequest.AsObject = {
            blockchainIndex: this.blockchainIndex,
            assetId: this.assetId
        };
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.blockchainIndex != 0)
            writer.writeInt64(1, this.blockchainIndex);
        if (this.assetId.length)
            writer.writeString(2, this.assetId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetDataRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetDataRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.blockchainIndex = reader.readInt64();
                    break;
                case 2:
                    message.assetId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AssetDataRequest {
        return AssetDataRequest.deserialize(bytes);
    }
}
export namespace AssetDataRequest {
    export type AsObject = {
        blockchainIndex: number;
        assetId: string;
    };
    export type AsObjectPartial = {
        blockchainIndex?: number;
        assetId?: string;
    };
}
export class AssetDataResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        asset?: Asset;
        transactions?: dependency_2.TransactionListResponse;
        balances?: BalanceList;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("asset" in data && data.asset != undefined) {
                this.asset = data.asset;
            }
            if ("transactions" in data && data.transactions != undefined) {
                this.transactions = data.transactions;
            }
            if ("balances" in data && data.balances != undefined) {
                this.balances = data.balances;
            }
        }
    }
    get asset() {
        return pb_1.Message.getWrapperField(this, Asset, 1) as Asset | undefined;
    }
    set asset(value: Asset | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasAsset() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearAsset(): void {
        this.asset = undefined!;
    }
    get transactions() {
        return pb_1.Message.getWrapperField(this, dependency_2.TransactionListResponse, 2) as dependency_2.TransactionListResponse | undefined;
    }
    set transactions(value: dependency_2.TransactionListResponse | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasTransactions() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearTransactions(): void {
        this.transactions = undefined!;
    }
    get balances() {
        return pb_1.Message.getWrapperField(this, BalanceList, 3) as BalanceList | undefined;
    }
    set balances(value: BalanceList | undefined) {
        pb_1.Message.setWrapperField(this, 3, value);
    }
    get hasBalances() {
        return pb_1.Message.getField(this, 3) != null;
    }
    clearBalances(): void {
        this.balances = undefined!;
    }
    static fromObject(data?: AssetDataResponse.AsObjectPartial): AssetDataResponse {
        if (!data) {
            return new AssetDataResponse();
        }
        const message = new AssetDataResponse({});
        if (data.asset != null) {
            message.asset = Asset.fromObject(data.asset);
        }
        if (data.transactions != null) {
            message.transactions = dependency_2.TransactionListResponse.fromObject(data.transactions);
        }
        if (data.balances != null) {
            message.balances = BalanceList.fromObject(data.balances);
        }
        return message;
    }
    toObject() {
        const data: AssetDataResponse.AsObject = {};
        if (this.asset != null) {
            data.asset = this.asset.toObject();
        }
        if (this.transactions != null) {
            data.transactions = this.transactions.toObject();
        }
        if (this.balances != null) {
            data.balances = this.balances.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasAsset)
            writer.writeMessage(1, this.asset, () => this.asset!.serialize(writer));
        if (this.hasTransactions)
            writer.writeMessage(2, this.transactions, () => this.transactions!.serialize(writer));
        if (this.hasBalances)
            writer.writeMessage(3, this.balances, () => this.balances!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetDataResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetDataResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.asset, () => message.asset = Asset.deserialize(reader));
                    break;
                case 2:
                    reader.readMessage(message.transactions, () => message.transactions = dependency_2.TransactionListResponse.deserialize(reader));
                    break;
                case 3:
                    reader.readMessage(message.balances, () => message.balances = BalanceList.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AssetDataResponse {
        return AssetDataResponse.deserialize(bytes);
    }
}
export namespace AssetDataResponse {
    export type AsObject = {
        asset?: Asset.AsObject;
        transactions?: dependency_2.TransactionListResponse.AsObject;
        balances?: BalanceList.AsObject;
    };
    export type AsObjectPartial = {
        asset?: Asset.AsObjectPartial;
        transactions?: dependency_2.TransactionListResponse.AsObjectPartial;
        balances?: BalanceList.AsObjectPartial;
    };
}
export class AssetTransactionListRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        assetId?: string;
        page?: dependency_1.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("assetId" in data && data.assetId != undefined) {
                this.assetId = data.assetId;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get assetId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set assetId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_1.Page, 2) as dependency_1.Page | undefined;
    }
    set page(value: dependency_1.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: AssetTransactionListRequest.AsObjectPartial): AssetTransactionListRequest {
        if (!data) {
            return new AssetTransactionListRequest();
        }
        const message = new AssetTransactionListRequest({});
        if (data.assetId != null) {
            message.assetId = data.assetId;
        }
        if (data.page != null) {
            message.page = dependency_1.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: AssetTransactionListRequest.AsObject = {
            assetId: this.assetId
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.assetId.length)
            writer.writeString(1, this.assetId);
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetTransactionListRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetTransactionListRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.assetId = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_1.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AssetTransactionListRequest {
        return AssetTransactionListRequest.deserialize(bytes);
    }
}
export namespace AssetTransactionListRequest {
    export type AsObject = {
        assetId: string;
        page?: dependency_1.Page.AsObject;
    };
    export type AsObjectPartial = {
        assetId?: string;
        page?: dependency_1.Page.AsObjectPartial;
    };
}
export class AssetTransactionListResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        list?: dependency_2.TransactionListResponse;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("list" in data && data.list != undefined) {
                this.list = data.list;
            }
        }
    }
    get list() {
        return pb_1.Message.getWrapperField(this, dependency_2.TransactionListResponse, 1) as dependency_2.TransactionListResponse | undefined;
    }
    set list(value: dependency_2.TransactionListResponse | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasList() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearList(): void {
        this.list = undefined!;
    }
    static fromObject(data?: AssetTransactionListResponse.AsObjectPartial): AssetTransactionListResponse {
        if (!data) {
            return new AssetTransactionListResponse();
        }
        const message = new AssetTransactionListResponse({});
        if (data.list != null) {
            message.list = dependency_2.TransactionListResponse.fromObject(data.list);
        }
        return message;
    }
    toObject() {
        const data: AssetTransactionListResponse.AsObject = {};
        if (this.list != null) {
            data.list = this.list.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasList)
            writer.writeMessage(1, this.list, () => this.list!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetTransactionListResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetTransactionListResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.list, () => message.list = dependency_2.TransactionListResponse.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AssetTransactionListResponse {
        return AssetTransactionListResponse.deserialize(bytes);
    }
}
export namespace AssetTransactionListResponse {
    export type AsObject = {
        list?: dependency_2.TransactionListResponse.AsObject;
    };
    export type AsObjectPartial = {
        list?: dependency_2.TransactionListResponse.AsObjectPartial;
    };
}
export class AssetBalancesRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        assetId?: string;
        page?: dependency_1.Page;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("assetId" in data && data.assetId != undefined) {
                this.assetId = data.assetId;
            }
            if ("page" in data && data.page != undefined) {
                this.page = data.page;
            }
        }
    }
    get assetId() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set assetId(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get page() {
        return pb_1.Message.getWrapperField(this, dependency_1.Page, 2) as dependency_1.Page | undefined;
    }
    set page(value: dependency_1.Page | undefined) {
        pb_1.Message.setWrapperField(this, 2, value);
    }
    get hasPage() {
        return pb_1.Message.getField(this, 2) != null;
    }
    clearPage(): void {
        this.page = undefined!;
    }
    static fromObject(data?: AssetBalancesRequest.AsObjectPartial): AssetBalancesRequest {
        if (!data) {
            return new AssetBalancesRequest();
        }
        const message = new AssetBalancesRequest({});
        if (data.assetId != null) {
            message.assetId = data.assetId;
        }
        if (data.page != null) {
            message.page = dependency_1.Page.fromObject(data.page);
        }
        return message;
    }
    toObject() {
        const data: AssetBalancesRequest.AsObject = {
            assetId: this.assetId
        };
        if (this.page != null) {
            data.page = this.page.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.assetId.length)
            writer.writeString(1, this.assetId);
        if (this.hasPage)
            writer.writeMessage(2, this.page, () => this.page!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetBalancesRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetBalancesRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.assetId = reader.readString();
                    break;
                case 2:
                    reader.readMessage(message.page, () => message.page = dependency_1.Page.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AssetBalancesRequest {
        return AssetBalancesRequest.deserialize(bytes);
    }
}
export namespace AssetBalancesRequest {
    export type AsObject = {
        assetId: string;
        page?: dependency_1.Page.AsObject;
    };
    export type AsObjectPartial = {
        assetId?: string;
        page?: dependency_1.Page.AsObjectPartial;
    };
}
export class AssetBalancesResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        list?: BalanceList;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("list" in data && data.list != undefined) {
                this.list = data.list;
            }
        }
    }
    get list() {
        return pb_1.Message.getWrapperField(this, BalanceList, 1) as BalanceList | undefined;
    }
    set list(value: BalanceList | undefined) {
        pb_1.Message.setWrapperField(this, 1, value);
    }
    get hasList() {
        return pb_1.Message.getField(this, 1) != null;
    }
    clearList(): void {
        this.list = undefined!;
    }
    static fromObject(data?: AssetBalancesResponse.AsObjectPartial): AssetBalancesResponse {
        if (!data) {
            return new AssetBalancesResponse();
        }
        const message = new AssetBalancesResponse({});
        if (data.list != null) {
            message.list = BalanceList.fromObject(data.list);
        }
        return message;
    }
    toObject() {
        const data: AssetBalancesResponse.AsObject = {};
        if (this.list != null) {
            data.list = this.list.toObject();
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.hasList)
            writer.writeMessage(1, this.list, () => this.list!.serialize(writer));
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): AssetBalancesResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new AssetBalancesResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message.list, () => message.list = BalanceList.deserialize(reader));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static override deserializeBinary(bytes: Uint8Array): AssetBalancesResponse {
        return AssetBalancesResponse.deserialize(bytes);
    }
}
export namespace AssetBalancesResponse {
    export type AsObject = {
        list?: BalanceList.AsObject;
    };
    export type AsObjectPartial = {
        list?: BalanceList.AsObjectPartial;
    };
}
